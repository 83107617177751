import { GroupTestResultItem } from "../../../@types/app-types";
import _ from "lodash";

interface Test {
  key: string;
  label: string;
  refRange: string;
  unit: string;
}

export function convertObjectToGroupResultsArray(
  updatedFormObj: {
    [key: string]: any;
  },
  tests: any[],
  formValues: {
    [key: string]: any;
  }
): GroupTestResultItem[] {
  const resultArray: GroupTestResultItem[] = [];
  const addedTestNames: { [key: string]: boolean } = {};

  _.forEach(updatedFormObj, (value, key) => {
    const testName = key.split("_")[0];
    const test = tests.find((test) => test?.key == testName);

    const testLabelName = test?.label as string;

    if (!addedTestNames[testName]) {
      const testObj: GroupTestResultItem = {
        testName: testLabelName,
        referenceRange: formValues[`${testName}_refRange`],
        units: formValues[`${testName}_units`],
        testResult: formValues[`${testName}_result`],
        key: formValues[`${testName}_flag`],
      };

      addedTestNames[testName] = true;
      resultArray.push(testObj);
    }
  });

  return resultArray;
}
const removeSpaces = (str: string) => {
  if (str.includes(" ")) {
    return str.replace(/\s/g, "");
  }
  return str;
};

export const convertGroupResultArrayToFormVals = (
  resultArray: GroupTestResultItem[]
): { [key: string]: any } => {
  const formVals: { [key: string]: any } = {};

  resultArray?.forEach((item) => {
    let testName = item?.testName;
    let testKey = removeSpaces(testName);

    if (testKey) {
      formVals[`${testKey}_refRange`] = item.referenceRange;
      formVals[`${testKey}_units`] = item.units;
      formVals[`${testKey}_result`] = item.testResult;
      formVals[`${testKey}_flag`] = item.key;
    }
  });
  return formVals;
};

export const setValuesOfRefRangeNdUnits = (
  tests: Test[],
  formikKeys: string[]
) => {
  let values: any = {};

  formikKeys.forEach((_key) => {
    //set default values
    values[_key] = "";

    //set units value
    if (_key?.includes("units")) {
      let testSuffix = _key?.split("_")[0];

      const unitValue = tests.find((test) => test.key == testSuffix)?.unit;
      values[_key] = unitValue;
    }

    //set ref range value
    if (_key?.includes("refRange")) {
      let testSuffix = _key?.split("_")[0];

      const unitValue = tests.find((test) => test.key == testSuffix)?.refRange;
      values[_key] = unitValue;
    }
  });

  return values;
};
