import * as React from "react";

import {
  Form,
  Field,
  FormikProvider,
  useFormik,
} from "formik";
import { BsFilterSquareFill } from "react-icons/bs";
import { VscOutput } from "react-icons/vsc";

import { useNavigate } from "react-router-dom";
import Spinner from "../../components/UI/Spinner";

import {  MdOutlineClose } from "react-icons/md";
import AppButton from "../../components/UI/button";
import AddPurchaseType from "../../components/modals/AddPurchaseType";
import PurchaseDelete from "../../components/modals/PurchaseDelete";
import {
  useAddNewPurchaseTypeMutation,
  useDeletePurchaseItemMutation,
  useGetAllPurchaseItemsQuery,
} from "../../store/rtk-query/purchaseApi";
import moment from "moment";
import { toast } from "react-toastify";
import Paginate from "../../components/Paginate";
import { formatServerErrorMessage } from "../../utils/functions";

const Page = () => {
  const [showDeleteConfirm, setShowDelete] = React.useState(false);
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [selectedItem, setSelected] = React.useState<any>();

  const [showTypeForm, setShowTypeForm] = React.useState(false);

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const { data: purchaseItemsData, isLoading: purchaseLoading } =
    useGetAllPurchaseItemsQuery({
      params: {
        skip: itemOffset,
        take: perPage,
      },
    });

  const [deletePurchaseType, deletePurchaseTypeState] =
    useDeletePurchaseItemMutation();
  const [addPurchaseType, addPurchaseTypeState] =
    useAddNewPurchaseTypeMutation();

  const navigate = useNavigate();

  const initialValues = {
    purchaseCategory: "",
    purchaseDate: "",
    periodFrom: "",
    periodTo: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
    },
  });

  const onAddPurchaseType = async (values: any) => {
    const data = {
      itemPurchaseCategory: values?.name,
    };

    const res: any = await addPurchaseType(data);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Purchase Type added successfully");
    setShowTypeForm(false);
  };

  const onDeleteItem = async () => {
    if (!selectedItem) {
      return;
    }

    const res: any = await deletePurchaseType({
      purchaseItemId: selectedItem?.id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Purchase deleted successfully");
    setShowDelete(false);
  };

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  const renderHeading = () => {
    return (
      <div className="flex justify-end w-full gap-10">
        <AppButton
          buttontype={"cyan"}
          title="Add New Purchase"
          onClick={() => navigate("/add-purchase")}
        />
        <AppButton
          buttontype={"green"}
          onClick={() => setShowTypeForm(true)}
          title="Add New Purchase Type"
        />
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
            <div
              className="container"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                className="w-full font-semibold bg-sky-100 p-4 border border-sky-200 rounded-lg shadow-md"
                style={{ display: "flex", alignItems: "center" }}
              >
                <BsFilterSquareFill
                  size="1.5rem"
                  style={{ marginRight: "0.5rem" }}
                />
                Filter Purchased Items
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Purchase Category</label>
                <Field className={inputClasses} name="purchaseCategory" />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Purchase Date</label>
                <Field
                  className={inputClasses}
                  type="date"
                  name="purchaseDate"
                />
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period From</label>
                <Field className={inputClasses} type="date" name="periodFrom" />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period To</label>
                <Field className={inputClasses} type="date" name="periodTo" />
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            className="w-full my-4 font-semibold bg-emerald-100 p-4 border border-sky-200 rounded-lg shadow-md"
            style={{ display: "flex", alignItems: "center" }}
          >
            <VscOutput size="1.5rem" style={{ marginRight: "0.5rem" }} />
            Results
            {purchaseLoading && <Spinner />}
          </div>
        </div>
        <div className="tableContainer relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 "></th>
                <th scope="col" className="px-6 py-3">
                  Purchase Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Purchase Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Supplier Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Supplier Address
                </th>
                <th scope="col" className="px-6 py-3">
                  Supplier
                  <br /> Phone No.
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Purchase Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Added by
                </th>
              </tr>
            </thead>
            <tbody>
              {purchaseItemsData?.data?.map((item: any) => {
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <div className="flex gap-2">
                        {/* <Link to={'/update-purchase/' + item?.id}>
                                                        <div

                                                            className='p-2 bg-[#2fade7] text-white rounded-md'>
                                                            <MdModeEditOutline
                                                            />
                                                        </div>
                                                    </Link> */}
                        <div
                          onClick={() => {
                            setShowDelete(true);
                            setSelected(item);
                          }}
                          className="p-2 bg-[#dc5d3a] text-white rounded-md"
                        >
                          <MdOutlineClose />
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4">{item?.itemName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.itemCategory}
                    </td>
                    <td className="px-6 py-4">{item?.supplierName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.supplierAddress}
                    </td>
                    <td className="px-6 py-4">{item?.supplierPhoneNumber}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.quantity}</td>
                    <td className="px-6 py-4">{item?.price}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {moment(item?.dateOfPurchase).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">{item?.addedBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Purchases</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {renderHeading()}
        {renderFilters()}
        {renderResults()}
        {purchaseItemsData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={purchaseItemsData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
        <PurchaseDelete
          show={showDeleteConfirm}
          onClose={() => {
            setSelected(undefined);
            setShowDelete(false);
          }}
          onDelete={onDeleteItem}
          data={selectedItem}
        />
        <AddPurchaseType
          show={showTypeForm}
          onClose={() => setShowTypeForm(false)}
          onSubmit={onAddPurchaseType}
          isLoading={addPurchaseTypeState.isLoading}
        />
      </div>
    </div>
  );
};

export default Page;
