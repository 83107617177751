import { userApi } from "./userApi";

export const patientApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    searchPatients: builder.query({
      query: ({ searchPhrase, X_pagination_take, X_pagination_skip }) => {
        return {
          url: "patients/search",
          method: "GET",
          params: { searchPhrase },
          headers: { X_pagination_take, X_pagination_skip },
        };
      },
    }),
    getPatients: builder.query({
      query: ({ headers, queryParams }) => {
        return {
          url: "patients",
          method: "GET",
          params: { ...queryParams },
          headers: { ...headers },
        };
      },
    }),
    getAdvancedMedicalReport: builder.query({
      query: ({ queryParams, type }) => {
        return {
          url: "advanced-medical-reporting/" + type,
          method: "GET",
          params: { ...queryParams },
        };
      },
    }),
    getPatientDetails: builder.query({
      query: ({ patientId }) => {
        return {
          url: "patients/info/" + patientId,
          method: "GET",
        };
      },
    }),
    viewPatientsVisits: builder.query({
      query: ({ patientId, params }) => {
        return {
          url: "patients/visits/" + patientId,
          method: "GET",
          params: { ...params },
        };
      },
    }),
    exportPatientData: builder.query({
      query: ({ queryParams }) => {
        return {
          url: "patients/export-data",
          method: "GET",
          params: { ...queryParams },
          responseHandler: (response: any) => {
            const text = response.text();
            return text;
          },
        };
      },
    }),
    exportMedReport: builder.query({
      query: ({ type, queryParams }) => {
        return {
          url: `advanced-medical-reporting/${type}/export-data`,
          method: "GET",
          params: { ...queryParams },
          responseHandler: (response: any) => {
            const text = response.text();
            return text;
          },
        };
      },
    }),
    addFirstVisit: builder.mutation({
      query: (body) => {
        return {
          url: "patients/create-first-visit",
          method: "POST",
          body,
        };
      },
    }),
    addNextVisit: builder.mutation({
      query: ({ body, patientId }) => {
        return {
          url: "patients/add-visit/" + patientId,
          method: "POST",
          body,
        };
      },
    }),
    updatePatientInfo: builder.mutation({
      query: ({ body, patientId }) => {
        return {
          url: "patients/edit/" + patientId,
          method: "PUT",
          body,
        };
      },
    }),
    deletePatientInfo: builder.mutation({
      query: ({ patientId }) => {
        return {
          url: "patients/delete/" + patientId,
          method: "DELETE",
        };
      },
    }),
    exportAdvancedMedReport: builder.mutation({
      query: ({ type }) => {
        return {
          url: `advanced-medical-reporting/${type}/export-data`,
          method: "GET",
          responseHandler: (response: any) => {
            const text = response.text();
            return text;
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPatientsQuery,
  useSearchPatientsQuery,
  useGetPatientDetailsQuery,
  useGetAdvancedMedicalReportQuery,
  useViewPatientsVisitsQuery,
  useExportPatientDataQuery,
  useExportMedReportQuery,
  useAddFirstVisitMutation,
  useAddNextVisitMutation,
  useUpdatePatientInfoMutation,
  useDeletePatientInfoMutation,
  useExportAdvancedMedReportMutation,
} = patientApi;
