import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../UI/button";
import { useGetAllLabCategoriesQuery } from "../../store/rtk-query/labApi";
import Spinner from "../UI/Spinner";
import { useGetAllDocOrLabSciQuery } from "../../store/rtk-query/docOrLabsciApi";
import { useGetPatientsQuery } from "../../store/rtk-query/patientApi";
import PatientSearchBar from "../PatientSearchBar";
import PageHeader from "../pageHeader";

interface FormValues {
  [key: string]: string;
}

let initialValues = {
  patientRegNumber: "",
  remark: "",
  appointmentDate: "",
  appointmentWithUserId: "",
};

interface Props {
  onSubmit: any;
  initialValues?: typeof initialValues;
  isLoading: boolean;
}

const AddAppointmentForm = (props: Props) => {
  const { data: scientistsData, isLoading: sciLoading } =
    useGetAllDocOrLabSciQuery({});
  const { data: patientsData, isLoading } = useGetPatientsQuery({
    searchPhrase: "",
  });

  if (props.initialValues) {
    initialValues = {
      ...initialValues,
      ...props.initialValues,
    };
  }

  // const firstScientist = scientistsData?.[0]
  // if (firstScientist) {
  //     initialValues.appointmentWithUserId = firstScientist?.id
  // }

  const formValidationSchema = Yup.object().shape({
    patientRegNumber: Yup.string().required("Required"),
    remark: Yup.string().required("Required"),
    appointmentDate: Yup.string().required("Required"),
    appointmentWithUserId: Yup.string().required("Required"),
  });

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";
  const inputWrap = "flex justify-between md:justify-start gap-4";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      onSubmit={(values) => {
        // same shape as initial values
        console.log(values);
        props.onSubmit(values);
      }}
      enableReinitialize
    >
      {({ errors, touched, setFieldValue }) => {
        return (
          <Form>
            <>
              <PageHeader title="Create New Appointment" />
              <PatientSearchBar
                extraclass="my-2 sm:w-3/12 mt-6 mb-4"
                onSelectPx={(patient) =>
                  setFieldValue("patientRegNumber", patient?.regNumber)
                }
              />
              <div className={inputWrap}>
                
                <div className="w-full sm:w-3/12 m-2">
                  <label className={labelClasses}>Patient REG No:</label>
                  <Field
                    disabled
                    className={inputClasses}
                    name="patientRegNumber"
                  />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="patientRegNumber"
                  />
                </div>
                <div className="w-full sm:w-3/12 m-2">
                  <label className={labelClasses}>Remark</label>
                  <Field className={inputClasses} name="remark" />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="remark"
                  />
                </div>
              </div>
              <div className={inputWrap}>
              <div className="w-full sm:w-3/12 m-2">
                  <label className={labelClasses}>Appointment Date</label>
                  <Field
                    type="date"
                    className={inputClasses}
                    name="appointmentDate"
                  />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="appointmentDate"
                  />
                </div>
                <div className="w-full sm:w-3/12 m-2">
                  <label className={labelClasses}>Appointment with:</label>
                  <Field
                    as="select"
                    name="appointmentWithUserId"
                    className={inputClasses}
                  >
                    <option value="" disabled hidden>
                      Select
                    </option>
                    {scientistsData?.map((sci: any) => {
                      const name = `${sci?.firstName} ${sci?.lastName}`;
                      return (
                        <option key={sci?.id} value={sci?.id}>
                          {name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="appointmentWithUserId"
                  />
                </div>
              </div>

              {props.isLoading && <Spinner />}
              <AppButton extraclass="mt-4" type="submit" buttontype={"primary"}>
                {props.initialValues ? "Update" : "Add"}
              </AppButton>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddAppointmentForm;
