import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";
import ItemsForm from "../components/forms/ItemsForm";
import { useParams } from "react-router-dom";
import {
  useGetAllInventoryItemsQuery,
  useUpdateInventoryItemMutation,
} from "../store/rtk-query/inventoryApi";
import { toast } from "react-toastify";

import _ from "lodash";
import moment from "moment";
import { formatServerErrorMessage } from "../utils/functions";

const Page = () => {
  const { data: itemsData } = useGetAllInventoryItemsQuery({});

  const [updateItem, updateItemState] = useUpdateInventoryItemMutation();

  const initialValues: any = {
    itemName: "",
    itemNumber: "",
    itemType: "",
    quantityPerUnit: "",
    quantity: "",
    location: "",
    cost: "",
    expirationDate: "",
    isLabTest: "",
    refRange: "",
    units: "",
    generalPrice: "",
  };

  const { item_id } = useParams();

  let item: any;
  if (itemsData) {
    item = itemsData?.data?.filter((item: any) => item?.id == item_id)[0];

    initialValues.itemName = item.itemName;
    initialValues.itemNumber = item.itemNumber;
    initialValues.itemType = item.itemType;
    initialValues.quantity = item.quantity;
    initialValues.quantityPerUnit = item.quantityPerUnit;
    initialValues.cost = item.cost;
    initialValues.location = item.location;
    initialValues.location = item.location;
    initialValues.expirationDate = moment(item.expirationDate).format(
      "YYYY-MM-DD"
    );


    //add prices

    const itemPriceObj = item?.price

    initialValues.generalPrice = itemPriceObj?.generalPrice;

    //add company and hmo prices
    itemPriceObj?.companyPrice?.forEach((company: any) => {
      initialValues[`company_${company?.id}`] = company?.price
    })
    itemPriceObj?.hmoPrice?.forEach((hmo: any) => {
      initialValues[`hmo_${hmo?.id}`] = hmo?.price
    })

  }

  async function onSubmit(values: any) {
    let data: any = {
      itemName: values.itemName,
      cost: values.cost,
      itemNumber: values.itemNumber,
      itemType: values.itemType,
      quantity: values.quantity,
      quantityPerUnit: values.quantityPerUnit,
      expirationDate: moment(values.expirationDate).format("MM/DD/YYYY"),
      generalPrice: values.generalPrice,
      isLaboratoryTest: values.isLaboratoryTest,
      location: values.location,
      units: values.units,
      referenceRange: values.refRange,
      useGeneralPriceForCompanyAndHMO: values.useGeneralPriceForCompanyAndHMO,
    };

    if (values?.hmoPrice) {
      data = {
        ...data,
        hmoPrice: [...values.hmoPrice
        ],
      };
    }

    if (values?.companyPrice) {
      data = {
        ...data,
        companyPrice: [
          ...values.companyPrice
        ],
      };
    }

    data = _.omitBy(data, _.isNil);

    console.log('data ', data)


    const res: any = await updateItem({ body: data, inventoryItemId: item_id });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Item updated successfully");
  }

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Update Item
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <ItemsForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          isLoading={updateItemState.isLoading}
        />
      </div>
    </div>
  );
};

export default Page;
