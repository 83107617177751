import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import BarChart from "../components/Charts/Barchart";
import DoughnutChart from "../components/Charts/DoughnutChart";
import LineChart from "../components/Charts/LineChart";
import Notifications from "../components/modals/Notifications";
import DropdownBtn from "../components/TimeFrameSelector";
import { markNotificationViewed } from "../store/reducers/miscSlice";
import {
  useGetDashboardLabInfoQuery,
  useGetGraphDataQuery,
} from "../store/rtk-query/dashboardApi";
import DashboardCard from "../components/UI/Cards";
import GreetingWithIcons from "./Greetings";

const Dashboard = () => {
  const dispatch = useDispatch();
  const miscState = useSelector((state: any) => state.misc);

  const { data: labInfoData, isLoading: labInfoLoading } =
    useGetDashboardLabInfoQuery({});
  const { data: graphData, isLoading: graphDataLoading } = useGetGraphDataQuery(
    {}
  );

  const showNotifications = !miscState?.dashboardNotificationViewed;

  const cardClass =
    "space-y-2 p-7 rounded-md shadow-md flex flex-col items-center bg-white";

  const chartClass =
    "space-y-2 px-6 py-10 rounded-md shadow-md flex flex-col items-center bg-white h-96";

  const laboratoryData = [
    {
      title: "Total Registered Patients",
      value: labInfoData?.totalRegisteredPatient,
      theme: "blue",
    },
    {
      title: "Total Tests on Queue",
      value: labInfoData?.totalTestOnQueue,
      theme: "blue",
    },
    {
      title: "Total Active Tests on Queue",
      value: labInfoData?.totalActiveTestOnQueue,
      theme: "blue",
    },
    {
      title: "Total Results approved",
      value: labInfoData?.totalApprovedTestResult,
      theme: "blue",
    },
  ];

  return (
    <div className="pb-[100px]">
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-4 mx-3 my-1 sm:p-4">
        <GreetingWithIcons />
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mx-3 my-1.5 sm:p-5">
        {laboratoryData?.map((cardData) => (
          <DashboardCard key={cardData.title} {...cardData} />
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-14 p-5  sm:p-10 w-full">
        <div className={chartClass}>
          <div className="flex lg:justify-around flex-wrap lg:flex-nowrap space-y-3">
            <h2 className="text-lg font-bold">Patients Registered</h2>
          </div>
          <BarChart data={graphData?.[0]?.patientData} />
        </div>
        <div className={chartClass}>
          <h2 className="text-lg font-bold">Laboratory Test Category</h2>

          <DoughnutChart data={graphData?.[0]?.labTestCategoryData} />
        </div>
        <div className={chartClass}>
          <h2 className="text-lg font-bold">Status of tests on Queue</h2>

          <LineChart data={graphData?.[0]?.testQueueData} />
        </div>
        <div className={chartClass}>
          <h2 className="text-lg font-bold">Patients Visits</h2>

          <BarChart data={graphData?.[0]?.patientVisitData} />
        </div>
      </div>
      <Notifications
        show={showNotifications}
        onClose={() => dispatch(markNotificationViewed())}
      />
    </div>
  );
};

export default Dashboard;
