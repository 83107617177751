import * as React from "react";

import { Form, Field, useFormik, FormikProvider } from "formik";
import { VscOutput } from "react-icons/vsc";

import { useNavigate } from "react-router-dom";
import Spinner from "../../components/UI/Spinner";

import AppButton from "../../components/UI/button";
import {
  useGetDebtorsQuery,
  usePayDebtMutation,
} from "../../store/rtk-query/debtApi";
import Paginate from "../../components/Paginate";
import moment from "moment";
import PayDebt from "../../components/modals/PayDebt";
import { toast } from "react-toastify";
import { formatServerErrorMessage } from "../../utils/functions";
import { RenderDebtsRecordContent } from "../../components/modals/exportDebtsRecord";
import ExportDataModal from "../../components/modals/exportData";
import FilterHeader from "../../components/filterHeader";
import _ from "lodash";
import { useGetAllLabCategoriesQuery } from "../../store/rtk-query/labApi";
import { useGetAllCompaniesQuery } from "../../store/rtk-query/companyApi";
import { useGetAllHmosQuery } from "../../store/rtk-query/hmoApi";
import ItemsAndAmount from "../../components/ItemsAndAmount";

const Page = () => {
  const [showDeleteConfirm, setShowDelete] = React.useState(false);

  const [showPayModal, setShowPay] = React.useState(false);

  const [selectedItem, setSelected] = React.useState<any>();

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const [payDebt, payDebtState] = usePayDebtMutation();
  const [showExportModal, setShowExportModal] = React.useState<boolean>(false);

  const { data: labCategoryData } = useGetAllLabCategoriesQuery({});
  const { data: companyData, isLoading: companyLoading } =
    useGetAllCompaniesQuery({});

  const { data: hmoData, isLoading: hmoLoading } = useGetAllHmosQuery({});

  const navigate = useNavigate();

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  let labCategories: string[] = [];

  if (labCategoryData) {
    labCategories = Object.keys(labCategoryData);
  }

  const initialValues = {
    searchParams: "",
    itemCategory: "",
    saleDate: "",
    periodFrom: "",
    periodTo: "",
    hmo: "",
    company: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
    },
  });

  let params: any = {
    searchPhrase: formik.values.searchParams
      ? formik.values.searchParams
      : undefined,
    hmoId: formik.values.hmo,
    companyId: formik.values.company,
    itemCategory: formik.values.itemCategory,
    //dates
    ...(formik.values.periodFrom && {
      periodFrom: moment(formik.values.periodFrom).format("YYYY-MM-DD"),
    }),
    ...(formik.values.periodTo && {
      periodTo: moment(formik.values.periodTo).format("YYYY-MM-DD"),
    }),
  };

  params = _.omitBy(params, (val) => {
    return val === undefined || val?.toString()?.trim() == "";
  });
  const { data: debtorsData, isLoading: debtorsLoading } = useGetDebtorsQuery({
    params: {
      ...params,
      skip: itemOffset,
      take: perPage,
    },
  });

  const onPayDebt = async (values: any) => {
    let data = {
      modeOfPayment: values.modeOfPay?.toUpperCase(),
      paying: values?.amountPaid,
    };

    // return
    const res: any = await payDebt({
      body: data,
      salesReceiptId: selectedItem?.salesReciept,
    });

    console.log("data ", data);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Debt paid successfully");
  };

  const renderSearchBar = () => {
    return (
      <div className="flex">
        <div className="relative w-full">
          <Field
            name="searchParams"
            type="text"
            id="search-dropdown"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg   border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                        "
            placeholder="Search Sales"
          />
          <button
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
            <FilterHeader title="Filter Debts" />
            {renderSearchBar()}

            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Item Category</label>
                <Field className={inputClasses} as="select" name="testCategory">
                  <option value="" disabled hidden>
                    Select Test Category
                  </option>

                  {labCategories.map((Category) => {
                    return (
                      <option value={Category} key={Category}>
                        {Category}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Date</label>
                <Field className={inputClasses} type="date" name="saleDate" />
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period From</label>
                <Field className={inputClasses} type="date" name="periodFrom" />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period To</label>
                <Field className={inputClasses} type="date" name="periodTo" />
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>HMO</label>
                <Field className={inputClasses} name="hmo" as="select">
                  <option value="">Choose an HMO</option>
                  {hmoData?.data?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Company</label>
                <Field className={inputClasses} name="company" as="select">
                  <option value="">Choose a Company</option>
                  {companyData?.data?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            className="w-full my-4 font-semibold bg-emerald-100 p-4 border border-sky-200 rounded-lg shadow-md"
            style={{ display: "flex", alignItems: "center" }}
          >
            <VscOutput size="1.5rem" style={{ marginRight: "0.5rem" }} />
            Results
            {debtorsLoading && <Spinner />}
          </div>
        </div>
        <div className="tableContainer relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 "></th>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[250px]">
                  Item
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Customer Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Debt
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Net Amount Payable
                </th>
                <th scope="col" className="px-6 py-3">
                  Sales Receipt
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Payment Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Transaction Date
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Sold by
                </th>
              </tr>
            </thead>
            <tbody>
              {debtorsData?.data?.map((item: any) => {
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <div className="flex gap-2">
                        <AppButton
                          onClick={() => {
                            setSelected(item);
                            setShowPay(true);
                          }}
                          title="Pay Now"
                          buttontype={"green"}
                        />
                      </div>
                    </td>

                    <td className="px-6 py-4">{item?.id}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      <ItemsAndAmount inputString={item?.itemsAndAmount} />
                    </td>
                    <td className="px-6 py-4">{item?.customerName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.customerPhoneNumber}
                    </td>
                    <td className="px-6 py-4">
                      {item?.totalDebt.toLocaleString("en-NG")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.netAmountPayable}
                    </td>
                    <td className="px-6 py-4">{item?.salesReciept}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.paymentStatus}
                    </td>
                    <td className="px-6 py-4">
                      {moment(item?.transactionDate).format(
                        "DD/MM/YYYY hh:mm A"
                      )}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.soldBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Debtors</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        <div className="flex justify-end">
          <AppButton
            onClick={() => setShowExportModal(true)}
            title="Export Data"
            extraclass="!bg-[#2fade7] hover:!bg-[#3492c9]"
          />
        </div>
        {renderFilters()}
        {renderResults()}
        {debtorsData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={debtorsData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
        <PayDebt
          show={showPayModal}
          onClose={() => setShowPay(false)}
          onSubmit={onPayDebt}
          debtData={selectedItem}
          isLoading={payDebtState.isLoading}
        />
      </div>
      <ExportDataModal
        show={showExportModal}
        onClose={() => setShowExportModal(false)}
        content={() => {
          return <RenderDebtsRecordContent />;
        }}
      />
    </div>
  );
};

export default Page;
