import * as React from "react";

import {
  Form,
  Field,
  FormikProvider,
  useFormik,
} from "formik";
import AppButton from "../../components/UI/button";
import {  useNavigate } from "react-router-dom";
import {
  useDeleteAppointmentMutation,
  useSearchAppointmentQuery,
} from "../../store/rtk-query/appointmentApi";
import moment from "moment";
import Paginate from "../../components/Paginate";
import Spinner from "../../components/UI/Spinner";
import { MdModeEditOutline, MdOutlineClose } from "react-icons/md";
import AppointmentDelete from "../../components/modals/AppointmentDelete";
import { toast } from "react-toastify";
import { formatServerErrorMessage } from "../../utils/functions";
import FilterResultHeader from "../../components/filterResultHeader";

const Page = () => {
  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const [showDeleteConfirm, setShowDelete] = React.useState(false);
  const [selectedItem, setSelected] = React.useState<any>();

  const [deleteAppointment, deleteAppointmentState] =
    useDeleteAppointmentMutation();

  const { data: appointmentData, isLoading: appointmentsLoading } =
    useSearchAppointmentQuery({
      searchPhrase: "",
      queryParams: {
        skip: itemOffset,
        take: perPage,
      },
    });

  const navigate = useNavigate();

  const initialValues = {
    patientName: "",
  };

  const onValueChange = () => {};

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const onDeleteItem = async () => {
    if (!selectedItem) {
      return;
    }

    const res: any = await deleteAppointment({
      appointmentId: selectedItem?.id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Appointment deleted successfully");
    setShowDelete(false);
  };

  const renderSearchBar = () => {
    return (
      <div className="flex  w-full md:w-[350px]">
        <div className="relative w-full">
          <Field
            name="patientName"
            type="text"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                        "
            placeholder="Search Patient"
            required
          />
          <button
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </div>
    );
  };

  const renderHeading = () => {
    return (
      <div
        className="flex
            flex-wrap
            sm:flex-nowrap
            gap-2
            justify-between w-full"
      >
        <FormikProvider value={formik}>
          <Form>{renderSearchBar()}</Form>
        </FormikProvider>
        <AppButton
          extraclass="!bg-[#22c0cb]"
          onClick={() => navigate("/add-appointment")}
          title="Add New Appointment"
        />
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full">
        <FilterResultHeader
          title="All Appointments"
          isLoading={appointmentsLoading}
        />
        <div className="tableContainer relative  shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 w-[40px]"></th>
                <th scope="col" className="px-6 py-3">
                  Appointment
                  <br /> Number
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Patient
                  <br /> Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Patient
                  <br /> Reg No
                </th>
                <th scope="col" className="px-6 py-3">
                  Appointment
                  <br />
                  With
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Remark
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Time
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Added
                  <br />
                  by
                </th>
                <th scope="col" className="px-6 py-3">
                  Rescheduled
                  <br />
                  by
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Reschedule
                  <br />
                  reason
                </th>
              </tr>
            </thead>
            <tbody>
              {appointmentData?.data.map((each: any) => {
                return (
                  <tr key={each?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <div className="flex gap-2">
                        <div
                          onClick={() => {
                            navigate("/update-appointment/" + each?.id);
                          }}
                          className="p-2 bg-[#2fade7] text-white rounded-md"
                        >
                          <MdModeEditOutline />
                        </div>
                        <div
                          onClick={() => {
                            setShowDelete(true);
                            setSelected(each);
                          }}
                          className="p-2 bg-[#dc5d3a] text-white rounded-md"
                        >
                          <MdOutlineClose />
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">{each?.appointmentNumber}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {each?.patientName}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {each?.patientRegNumber}
                    </td>
                    <td className="px-6 py-4">{each?.appointmentWithName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{each?.remark}</td>
                    <td className="px-6 py-4">{each?.status}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {moment(each?.appointmentDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">{each?.appointmentTime}</td>
                    <td className="px-6 py-4 bg-gray-50 "></td>
                    <td className="px-6 py-4">{each?.rescheduledByName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {each?.rescheduledReason}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Appointment List
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {renderHeading()}
        {renderResults()}
        {appointmentData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={appointmentData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
        {deleteAppointmentState.isLoading && <Spinner />}
        <AppointmentDelete
          show={showDeleteConfirm}
          onClose={() => {
            setSelected(undefined);
            setShowDelete(false);
          }}
          onDelete={onDeleteItem}
          data={selectedItem}
          isLoading={deleteAppointmentState.isLoading}
        />
      </div>
    </div>
  );
};

export default Page;
