import * as React from "react";
import { TsSvgProps, DashboardCardProps } from "../../@types/app-types";

import { addClassNames } from "../../utils/functions";

import { MdCalendarViewMonth } from "react-icons/md";

const DashboardCard = (props: DashboardCardProps) => {
  let cardStyle =
    "relative overflow-hidden bg-[#FCFCFC] h-[136px] rounded-[3px] w-[260px] px-[24px] pt-[26px] pb-[12px] flex flex-col justify-between";

  if (props.theme == "blue") {
    cardStyle = cardStyle + " " + "bg-lab-blue";
  }

  const iconProps: TsSvgProps = {};

  if (props.theme == "gray") {
    iconProps.strokecolor = "#1A2DD8";
  }

  const renderCircleDesign = () => {
    return (
      <div
        className={addClassNames(
          "absolute z-10 bottom-[-150px] right-[-110px] w-[200px] h-[200px] rounded-[50%]",
          props.theme == "blue" ? "bg-[#4A9DFF66]" : "",
          props.theme == "gray" ? "bg-[#4A9DFF05]" : ""
        )}
      ></div>
    );
  };

  return (
    <div className={cardStyle}>
      <div className="flex gap-[13px] items-center">
        <div
          style={{
            ...(props.theme == "blue" && { backgroundColor: "#FFF" }),
          }}
          className="bg-[#F8FAFC] flex items-center justify-center p-[8px] rounded-md"
        >
          <div className="w-[18px] h-[20px]">
            <MdCalendarViewMonth size="1.2rem" {...iconProps} />
          </div>
        </div>
        <div
          style={{
            color: props.theme == "blue" ? "#fff" : "#1A2DD8",
          }}
          className="font-[500] text-[18px]"
        >
          {props.title}
        </div>
      </div>
      <div
        style={{
          color: props.theme == "blue" ? "#fff" : "#1A2DD8",
        }}
        className="text-[28px]"
      >
        {props.value}
      </div>
      {renderCircleDesign()}
    </div>
  );
};

export default DashboardCard;
