import * as React from "react";
import { NavLink, Outlet } from "react-router-dom";
import Header from "../components/header";
import SideBar from "../components/sidebar";

import { useLocation } from "react-router-dom";
import { useGetAllLabCategoriesQuery } from "../store/rtk-query/labApi";
import { AppContextProviderValues } from "../@types/app-types";
import { AppContext } from "../context/AppContext";

interface SubMenuItem {
  title: string;
  path: string;
}

interface Routes {
  [key: string]: {
    subMenu: SubMenuItem[];
  };
}

const DashboardLayout = () => {
  const location = useLocation();

  const { data: labCatsData } = useGetAllLabCategoriesQuery({});

  const {
    hideSideBar, setHideSideBar
  } = React.useContext<AppContextProviderValues>(AppContext)

  const menuObject = React.useMemo<Routes>(() => {
    let routes: Routes = {
      patient: {
        subMenu: [
          { title: "Add first visit", path: "/first-visit" },
          { title: "Add next visit", path: "/next-visit" },
          { title: "Records", path: "/records" },
          { title: "HMO", path: "/hmo" },
          { title: "Company", path: "/company" },
        ],
      },
      reminders: {
        subMenu: [
          { title: "Waiting Queue", path: "/waiting-queue" },
          { title: "Appointments", path: "/appointments" },
          { title: "Birthday", path: "/birthday" },
        ],
      },
      inventory: {
        subMenu: [
          { title: "Items", path: "/items" },
          { title: "Invoice", path: "/invoice" },
          { title: "Debtors", path: "/debtors" },
          // { title: "Debt Repayment", path: "/debt-repayment-hx" },
          // { title: "Inventory Ledger", path: "/inventory-ledger" },
          { title: "Purchases", path: "/purchases" },
          { title: "Sales", path: "/sales" },
        ],
      },
      "business-analytics": {
        subMenu: [
          { title: "Bank Lodgement", path: "/bank-lodgement" },
          { title: "Bank Withdrawal", path: "/bank-withdrawal" },
          { title: "Cash collection", path: "/cash-collection" },
          { title: "General ledger", path: "/general-ledger" },
          { title: "New Cash", path: "/new-cash" },
          { title: "Payment Voucher", path: "/payment-voucher" },
        ],
      },
      administration: {
        subMenu: [
          { title: "Staff Management", path: "/staff-management" },
          { title: "App Settings", path: "/app-settings" },
        ],
      },
      laboratory: {
        subMenu: [
          // { title: 'Create New Test', path: '/add-test' },
          { title: "All labs", path: "/" },
        ],
      },
      template: {
        subMenu: [
          { title: "All Templates", path: "/" },
          { title: "Add Template", path: "/add" },
        ],
      },
    };

    if (labCatsData) {
      let labSubMenu: SubMenuItem[] = [...routes.laboratory.subMenu];
      //convert GET lab response to route compatible object
      Object.keys(labCatsData).forEach((key) =>
        labSubMenu.push({
          title: key,
          path: "/" + key?.replaceAll("/", "_slash_"),
        })
      );

      routes["laboratory"] = {
        subMenu: [...labSubMenu],
      };
    }


    return routes;
  }, [labCatsData]);

  const joinClassNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ')
  }

  let currentPageKey = location.pathname.replace("/", "");
  //remove text after second slash
  currentPageKey = currentPageKey.split("/")[0];

  const renderInnerMenu = () => {
    const selectedMenuItem =
      menuObject[currentPageKey as keyof typeof menuObject];
    if (!selectedMenuItem) return;

    return (
      <div className="">
        {/* push content right */}
        <div className="sm:w-40 hidden sm:block" />
        <div className="relative sm:fixed sm:h-full flex flex-col items-center w-full sm:w-40  bg-gray-800 text-white ">
          {selectedMenuItem.subMenu.map((item, index) => {
            const link = currentPageKey + item.path;
            return (
              <NavLink
                to={link}
                key={index}
                className={({ isActive }) => {
                  return `flex items-center w-full h-10 px-3 mt-1 rounded active:bg-gray-700 hover:bg-gray-700 hover:text-gray-300 ${isActive && "bg-gray-700 border-l-2"
                    }`;
                }}
              >
                <span className="ml-2 text-xs font-medium">{item.title}</span>
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <SideBar /> <Header />
      <div className={
        joinClassNames(
          "ml-16 md:ml-40 pt-10 bg-gray-100",
          hideSideBar ? '!ml-16' : 'ml-40'
        )
      }>
        <div className="block sm:flex flex-wrap md:flex-nowrap mt-1 w-full h-full">
          {renderInnerMenu()}
          <div className="flex-1 sm:w-64 px-2 py-2  h-full">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
