import * as React from "react";
import { Link, NavLink } from "react-router-dom";

import classes from "./sidebar.module.css";

import { MdArrowRight, MdOutlineDashboard } from "react-icons/md";
import { FaHospitalUser } from "react-icons/fa";
import { BsAlarm } from "react-icons/bs";
import { IoIosFlask } from "react-icons/io";
import { MdOutlineInventory2 } from "react-icons/md";
import { SiSimpleanalytics } from "react-icons/si";
import { RiAdminFill } from "react-icons/ri";

import { ArrowLeftOnRectangleIcon } from "@heroicons/react/20/solid";

import { useDispatch } from "react-redux";
import { logout } from "../../store/reducers/userSlice";

import { redirect } from "react-router-dom";
import Logo from "../Logo";
import { useGetUserProfileQuery } from "../../store/rtk-query/userApi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import { AppContextProviderValues } from "../../@types/app-types";

const menuItems1 = [
  {
    title: "Dashboard",
    icon: <MdOutlineDashboard />,
    link: "/",
    hasSubmenu: false,
  },
  {
    title: "Patient",
    icon: <FaHospitalUser />,
    link: "/patient",
    hasSubmenu: true,
  },
  {
    title: "Reminders",
    icon: <BsAlarm />,
    link: "/reminders",
    hasSubmenu: true,
  },
  {
    title: "Laboratory",
    icon: <IoIosFlask />,
    link: "/laboratory",
    hasSubmenu: true,
  },
  {
    title: "Inventory",
    icon: <MdOutlineInventory2 />,
    link: "/inventory",
    hasSubmenu: true,
  },
];


const SideBar = () => {
  const { data: profileData } = useGetUserProfileQuery({})

  const {
    hideSideBar, setHideSideBar
  } = React.useContext<AppContextProviderValues>(AppContext)


  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
    redirect("/signin");
  };


  const administrationRoles = ['admin', 'super_admin']


  let items2: typeof menuItems1 = [
  ];


  const accountCategory: string = profileData?.appUserData?.accountCategory
  //only show admin page to admin staffs
  if (administrationRoles?.some((role) => role?.toLowerCase() == accountCategory?.toLowerCase())) {

    items2 = [
      ...items2,
      {
        title: "Administration",
        icon: <RiAdminFill />,
        link: "/administration",
        hasSubmenu: true,
      },
    ]
  }

  const joinClassNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ')
  }

  const renderMenuSizeControl = () => {
    return (
      <div className="absolute z-30 inset-y-0 right-[-20px] w-[fit-content] hidden sm:flex flex-col justify-center">
        <div
          onClick={() => setHideSideBar?.(!hideSideBar)}
          className={
            joinClassNames(
              "bg-gray-700 h-[40px] w-[40px] rounded-[50%] top",
              "cursor-pointer",
              "flex items-center justify-center text-white shadow-lg"
            )
          }>
          {hideSideBar ? <AiOutlineDoubleRight /> : <AiOutlineDoubleLeft />}
        </div>
      </div>
    )
  }

  const renderMenuItems = () => {
    return (
      <div
        className={
          joinClassNames(
            classes["items-wrapper"],
            " flex flex-col items-center w-16 sm:w-40 h-full overflow-hidden text-gray-400 bg-gray-900 transition",
            hideSideBar ? '!w-16' : ''
          )
        }
      >
        <div className="w-[fit-content]">
          <Logo />
        </div>
        <div className="w-full px-2">
          <div className="flex flex-col items-center w-full mt-3 border-t border-gray-700">
            {menuItems1.map((item, index) => {
              return (
                <NavLink
                  to={item.link}
                  key={index}
                  className={({ isActive }) => {
                    return `flex items-center w-full h-10 px-3 mt-1 rounded active:bg-gray-700 hover:bg-gray-700 hover:text-white ${isActive && "bg-gray-700 text-white border-l-2"
                      }`;
                  }}
                >
                  {item.icon}
                  <span className={
                    joinClassNames(
                      "hidden sm:inline  ml-2 text-sm font-medium",
                      hideSideBar ? '!hidden' : '!inline'
                    )
                  }>
                    {item.title}
                  </span>
                  {item.hasSubmenu && <MdArrowRight />}
                </NavLink>
              );
            })}
          </div>
          <div className="flex flex-col items-center w-full mt-3 border-t border-gray-700">
            {items2.map((item, index) => {
              return (
                <NavLink
                  to={item.link}
                  key={index}
                  className={({ isActive }) => {
                    return `flex items-center w-full h-10 px-3 mt-1 rounded active:bg-gray-700 hover:bg-gray-700 hover:text-gray-300 ${isActive && "bg-gray-700 text-white"
                      }`;
                  }}
                >
                  {item.icon}

                  <span className={
                    joinClassNames(
                      "hidden sm:inline  ml-2 text-sm font-medium",
                      hideSideBar ? '!hidden' : '!inline'
                    )
                  }>
                    {item.title}
                  </span>
                  {item.hasSubmenu && <MdArrowRight />}
                </NavLink>
              );
            })}
          </div>
        </div>
        <button
          onClick={onLogout}
          className="flex items-center justify-center w-full h-16 mt-auto bg-gray-800 hover:bg-gray-700 hover:text-gray-300"
        >
          <ArrowLeftOnRectangleIcon className="w-6" />
          <span className={
            joinClassNames(
              "hidden sm:inline  ml-2 text-sm font-medium",
              hideSideBar ? '!hidden' : '!inline'
            )
          }>
            Logout
          </span>
        </button>
      </div>
    );
  };
  return <div className="z-10 fixed top-0 bottom-0">
    {renderMenuItems()}
    {renderMenuSizeControl()}
  </div>;
};

export default SideBar;
