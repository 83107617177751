import { userApi } from "./userApi";

export const saleApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSales: builder.query({
      query: ({ params }) => {
        return {
          url: "inventory/sales/all-sales-items",
          method: "GET",
          params: { ...params },
        };
      },
      providesTags: ["Sale"],
    }),
    exportSalesData: builder.query({
      query: ({ queryParams }) => {
        return {
          url: "inventory/sales/export-data",
          method: "GET",
          params: { ...queryParams },
          responseHandler: (response: any) => {
            const text = response.text();
            return text;
          },
        };
      },
    }),
    addNewSale: builder.mutation({
      query: (body) => {
        return {
          url: "inventory/sales/add-new-sale",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Sale"],
    }),
    deleteSale: builder.mutation({
      query: ({ saleId }) => {
        return {
          url: "inventory/sales/delete/" + saleId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Sale"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllSalesQuery,
  useExportSalesDataQuery,
  useAddNewSaleMutation,
  useDeleteSaleMutation,
} = saleApi;
