import * as React from "react";

import FilterHeader from "../filterHeader";
import TestTable from "../testTable";
import { TestTableColumnItem } from "../../@types/app-types";

const testTableColumns: TestTableColumnItem[] = [
  {
    label: "S/N",
    key: "index",
  },
  {
    label: "Test Name",
    key: "testName",
  },
  {
    label: "Test Category",
    key: "testCategory",
  },
];

interface ViewTestRequestedProps {
  data: any;
}

export const ViewTestRequested = (props: ViewTestRequestedProps) => {
  const tableData: any[] = React.useMemo(() => {
    let data = [];
    if (props.data?.laboratoryTests) {
      data = props.data?.laboratoryTests?.map((test: any, index: number) => {
        return {
          index: `${index + 1}).`,
          testName: `${test?.testName}`,
          testCategory: test?.testCategory,
        };
      });
    }
    return data;
  }, [props.data?.laboratoryTests]);

  return (
    <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
      <FilterHeader title="Tests Requested" />
      <TestTable
        data={tableData}
        columns={testTableColumns}
        extraStyles={{ marginTop: 30 }}
        extraRowClasses={"h-[67px]"}
      />
    </div>
  );
};
