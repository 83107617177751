import * as React from 'react'

import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AppButton from '../UI/button';
import { useGetAllLabCategoriesQuery } from '../../store/rtk-query/labApi';
import Spinner from '../UI/Spinner';
import PhoneNumberInput from '../PhoneTelInput';


interface FormValues {
    [key: string]: string
}

let initialValues = {
    "firstName": "",
    "lastName": "",
    "email": "",
    "phoneNumber": ""
}

interface Props {
    onSubmit: any;
    initialValues?: typeof initialValues;
    isLoading: boolean
}

const AddDocOrSciForm = (props: Props) => {

    const { data: labData } = useGetAllLabCategoriesQuery({})

    if (props.initialValues) {
        initialValues = {
            ...initialValues,
            ...props.initialValues
        }
    }

    let testTypes: string[] = [
    ]


    if (labData) {
        testTypes = Object.keys(labData).map((key) => key)
    }


    const formValidationSchema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().required('Required').email('Invalid email'),
        phoneNumber: Yup.string().required('Required'),
    })

    const inputClasses = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'
    const inputWrap = 'flex justify-between md:justify-start gap-4'


    const renderPriceScheme = () => {
        return (
            <div>
                <h3 className='text-3xl my-3' >Price Scheme</h3>
                <div className={inputWrap}>

                    <div>
                        <label className={labelClasses}>General Price</label>
                        <Field type='number' className={inputClasses} name="generalPrice" />
                        <ErrorMessage className={errorMessageClasses} component={'p'} name="generalPrice" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={formValidationSchema}
            onSubmit={values => {
                // same shape as initial values
                console.log(values);
                props.onSubmit(values)
            }}
            enableReinitialize
        >
            <Form>
                <>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>First name</label>
                            <Field className={inputClasses} name="firstName" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="firstName" />
                        </div>
                        <div>
                            <label className={labelClasses}>Last name</label>
                            <Field className={inputClasses} name="lastName" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="lastName" />
                        </div>
                    </div>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Email</label>
                            <Field className={inputClasses} name="email" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="email" />
                        </div>
                        <div>
                            <label className={labelClasses}>Phone Number</label>
                            <PhoneNumberInput
                                buttonClass="!rounded-l-lg"
                                containerClass={'min-h-[40px] '}
                                inputClass={'min-h-[40px] !rounded-lg'}
                                name="phoneNumber" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="phoneNumber" />
                        </div>
                    </div>

                    {props.isLoading && <Spinner />}
                    <AppButton
                        extraclass='mt-4'
                        type="submit" buttontype={'primary'}>
                        {props.initialValues ? "Update" : "Add"}
                    </AppButton>
                </>
            </Form>
        </Formik >
    )
}

export default AddDocOrSciForm