import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props {
    name: string;
    inputClass?: string;
    containerClass?: string;
    buttonClass?: string
}

const PhoneNumberInput = (props: Props) => {
    const { name } = props
    return (
        <div className='w-full'>
            <Field name={name}>
                {({ field, form }: any) => (
                    <PhoneInput
                        {...field}
                        country={'ng'} // Set default country here if needed
                        onChange={(value) => {
                            let formattedValue = value.replace(/[^\d+]/g, '');
                            formattedValue = '+' + formattedValue
                            form.setFieldValue(name, formattedValue)
                        }}
                        inputClass={`!w-[auto] ${props.inputClass}`}
                        buttonClass={props.buttonClass}
                        containerClass={`!rounded-l-lg ${props.containerClass}`}
                    />
                )}
            </Field>
        </div>
    );
};

export default PhoneNumberInput;
