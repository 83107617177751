import * as React from 'react'

import { Formik, Form, Field, FormikProps, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import AppButton from '../UI/button';
import { useGetAllLabCategoriesQuery } from '../../store/rtk-query/labApi';
import Spinner from '../UI/Spinner';
import { useGetAllInventoryItemsQuery } from '../../store/rtk-query/inventoryApi';
import FormikInputError from '../FormikInputError';
import PatientSearchBar from '../PatientSearchBar';


interface AddSaleValues {
    "patientId": undefined | number;
    "itemId": undefined | number;
    "quantity": undefined | number;
    "amountPayable": undefined | number;
    "modeOfPayment": string;
    "transactionDate": string
}

let initialValues: AddSaleValues = {
    "patientId": 0,
    "itemId": 0,
    "quantity": 0,
    "amountPayable": 0,
    "modeOfPayment": "",
    "transactionDate": ""
}

interface Props {
    onSubmit: any;
    initialValues?: typeof initialValues;
    isLoading: boolean
}

const AddSaleForm = (props: Props) => {

    const { data: itemsData, isLoading: itemsLoading } = useGetAllInventoryItemsQuery({})

    if (props.initialValues) {
        initialValues = {
            ...initialValues,
            ...props.initialValues
        }
    }


    const modesOfPay = [
        'Cash',
        'Cheque',
        'Bank',
        'e-Transfer',
        'POS'
    ]

    const formValidationSchema = Yup.object().shape({
        patientId: Yup.string().required('Please select a patient'),
        itemId: Yup.string().required('Required'),
        quantity: Yup.string().required('Required'),
        amountPayable: Yup.string().required('Required'),
        modeOfPayment: Yup.string().required('Required'),
        transactionDate: Yup.string().required('Required'),
    })

    const inputClasses = "disabled:bg-[#eee] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'
    const inputWrap = 'flex justify-between md:justify-start gap-4'

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: values => {
            console.log('values ', values)
            props.onSubmit(values)
        },
        validationSchema: formValidationSchema
    });


    return (
        <div>
            <div className='max-w-[700px]'>
                <PatientSearchBar
                    extraclass='my-2'
                    onSelectPx={(patient) => formik.setFieldValue('patientId', patient?.id)}
                />
            </div>

            <form
                onSubmit={formik.handleSubmit}
            >
                <>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Item</label>
                            <select name="itemId" className={inputClasses}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                defaultValue=''
                            >
                                <option value="" disabled hidden>Select</option>
                                {itemsData?.data?.map((item: any) => {
                                    return (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.itemName}
                                        </option>
                                    );
                                })}
                            </select>
                            <FormikInputError extraclass={errorMessageClasses}
                                errors={formik.errors}
                                name="itemId" />
                        </div>
                        <div>
                            <label className={labelClasses}>Patient Id</label>
                            <input type="number" className={inputClasses} name="patientId"
                                value={formik.values.patientId}
                                disabled
                            />
                            <FormikInputError extraclass={errorMessageClasses}
                                errors={formik.errors}
                                name="patientId" />
                        </div>
                    </div>
                    <div className={inputWrap}>

                        <div>
                            <label className={labelClasses}>Quantity</label>
                            <input type="number" className={inputClasses} name="quantity"
                                value={formik.values.quantity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikInputError extraclass={errorMessageClasses}
                                errors={formik.errors}
                                name="quantity" />
                        </div>
                        <div>
                            <label className={labelClasses}>Amount Payable</label>
                            <input type="number" className={inputClasses} name="amountPayable"
                                value={formik.values.amountPayable}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikInputError
                                errors={formik.errors}
                                extraclass={errorMessageClasses} name="amountPayable" />
                        </div>
                    </div>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Mode of Payment</label>

                            <select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="modeOfPayment"
                                defaultValue={''}
                                className={inputClasses}>
                                <option
                                    value=""
                                    disabled hidden >Select</option>
                                {modesOfPay.map((each) => {
                                    return <option
                                        key={each}
                                        value={each?.toUpperCase()}>{each}</option>
                                })}
                            </select>
                            <FormikInputError
                                errors={formik.errors}
                                extraclass={errorMessageClasses} name="modeOfPayment" />
                        </div>
                        <div>
                            <label className={labelClasses}>Transaction Date</label>
                            <input type="date"
                                value={formik.values.transactionDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={inputClasses} name="transactionDate" />
                            <FormikInputError
                                errors={formik.errors}
                                extraclass={errorMessageClasses} name="transactionDate" />
                        </div>
                    </div>

                    {props.isLoading && <Spinner />}
                    <AppButton
                        extraclass='mt-4'
                        type="submit" buttontype={'primary'}>Add</AppButton>
                </>
            </form>
        </div>
    )
}

export default AddSaleForm