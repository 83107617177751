import * as React from 'react';


import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
//
import WidalScreening from './others/WidalScreening';
import Serology from './others/Serology';
import SemenAnalysis from './others/SemenAnalysis';
import MolecularAssay from './others/MolecularAssay';
import BloodGroup from './others/BloodGroup';
import Genotype from './others/Genotype';
import MCS from './others/MCS';
import ECS from './others/ECS';

interface Props {
    onChangeForm: any
}
const Others = (props: Props) => {



    const accordionData = [
        {
            label: 'Widal Screening',
            component: <WidalScreening
                onChangeForm={props.onChangeForm}
            />
        },
        {
            label: 'Serology (HIV, HBsAg, H.Pylori, Pregnancy)',
            component: <Serology
                onChangeForm={props.onChangeForm}
            />
        },
        {
            label: 'Blood Group',
            component: <BloodGroup
                onChangeForm={props.onChangeForm}
            />
        },
        {
            label: 'Genotype',
            component: <Genotype
                onChangeForm={props.onChangeForm}
            />
        },
        {
            label: 'M/C/S',
            component: <MCS
                onChangeForm={props.onChangeForm}
            />
        },
        {
            label: 'E/C/S',
            component: <ECS
                onChangeForm={props.onChangeForm}
            />
        },
        {
            label: 'Molecular Assay',
            component: <MolecularAssay
                onChangeForm={props.onChangeForm}
            />
        },
        {
            label: 'Semen Analysis',
            component: <SemenAnalysis
                onChangeForm={props.onChangeForm}
            />
        },
    ]

    return (
        <div>
            <div className='text-2xl font-semibold mb-2'> Others</div>
            {
                accordionData?.map((data) => {
                    return (
                        <Disclosure key={data.label} >
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="mb-3 h-[50px] flex items-center w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                        <span className='text-lg'>
                                            {data?.label}
                                        </span>
                                        <ChevronUpIcon
                                            className={`${open ? "rotate-180 transform" : ""
                                                } h-5 w-5 text-gray-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                        unmount={false}
                                        className="px-4 pt-4 pb-2 text-sm text-gray-500 ">
                                        {data?.component}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    )
                })
            }

        </div>
    )
}

export default Others