import * as React from 'react'

import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AppButton from '../UI/button';
import { useGetAllLabCategoriesQuery } from '../../store/rtk-query/labApi';
import Spinner from '../UI/Spinner';


interface FormValues {
    [key: string]: string
}

let initialValues = {
    "oldPassword": "",
    "newPassword": "",
    "confirmPassword": "",
}

interface Props {
    onSubmit: any;
    initialValues?: typeof initialValues;
    isLoading: boolean
}

const ChangePasswordForm = (props: Props) => {

    if (props.initialValues) {
        initialValues = {
            ...initialValues,
            ...props.initialValues
        }
    }

    const formValidationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Required'),
        newPassword: Yup.string().required('Required'),
        confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    })

    const inputClasses = "bg-gray-50 disabled:bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'
    const inputWrap = 'flex justify-between md:justify-start gap-4'




    return (
        <Formik
            initialValues={initialValues}
            validationSchema={formValidationSchema}
            onSubmit={values => {
                // same shape as initial values
                console.log(values);
                props.onSubmit(values)
            }}
            enableReinitialize
        >
            <Form>
                <>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Old Password</label>
                            <Field className={inputClasses} name="oldPassword" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="oldPassword" />
                        </div>
                    </div>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>New Password</label>
                            <Field className={inputClasses} name="newPassword" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="newPassword" />
                        </div>
                    </div>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Confirm New Password</label>
                            <Field className={inputClasses} name="confirmPassword" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="confirmPassword" />
                        </div>
                    </div>

                    {props.isLoading && <Spinner />}
                    <AppButton
                        extraclass='mt-4'
                        type="submit" buttontype={'primary'}>Change</AppButton>
                </>
            </Form>
        </Formik >
    )
}

export default ChangePasswordForm