import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";
import ItemsForm from "../components/forms/ItemsForm";
import { toast } from "react-toastify";
import moment from "moment";

import { useAddNewDocOrLabSciMutation } from "../store/rtk-query/docOrLabsciApi";

import UpdateProfileForm from "../components/forms/UpdateProfileForm";
import { useChangePasswordMutation, useGetUserProfileQuery, useUpdateProfileMutation } from "../store/rtk-query/userApi";
import ChangePasswordForm from "../components/forms/ChangePasswordForm";
import { formatServerErrorMessage } from "../utils/functions";

const EditProfile = () => {
  const [updateProfile, updateProfileState] = useUpdateProfileMutation();
  const [changePass, changePassState] = useChangePasswordMutation();

  const { data: profileData } = useGetUserProfileQuery({})

  let profileFormInitValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    // password: "",
  };

  let changePasssFormInitVals = {
    "oldPassword": "",
    "newPassword": "",
    "confirmPassword": "",
  }

  if (profileData) {
    profileFormInitValues.email = profileData?.appUserData?.email
    profileFormInitValues.firstName = profileData?.appUserData?.firstName
    profileFormInitValues.lastName = profileData?.appUserData?.lastName
    profileFormInitValues.phoneNumber = profileData?.firebaseUserData?.phoneNumber
  }

  const onUpdateProfile = async (values: typeof profileFormInitValues) => {
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      role: profileData?.appUserData?.accountCategory
    };

    console.log('data ', data)

    const res: any = await updateProfile(data);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Profile Updated successfully");
  };

  const onChangePass = async (values: typeof changePasssFormInitVals) => {
    let data = {
      password: values.newPassword
    };

    const res: any = await changePass(data);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Password changed successfully");
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Update Profile</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md mb-5">
        <UpdateProfileForm
          onSubmit={onUpdateProfile}
          initialValues={profileFormInitValues}
          isLoading={updateProfileState.isLoading}
        />
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <div className="my-2 py-2  font-semibold text-lg">
          Change Password
        </div>
        <ChangePasswordForm
          onSubmit={onChangePass}
          initialValues={changePasssFormInitVals}
          isLoading={changePassState.isLoading}
        />
      </div>
    </div>
  );
};

export default EditProfile;
