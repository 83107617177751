import { userApi } from "./userApi";

export const appointmentApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    searchAppointment: builder.query({
      query: ({ searchPhrase, queryParams }) => {
        return {
          url: "appointment/search",
          method: "GET",
          params: { 
             searchPhrase, 
            ...queryParams },
        };
      },
      providesTags: ["Appointment"],
    }),
    getAppointmentInfo: builder.query({
      query: ({ appointmentId }) => {
        return {
          url: "appointment/info/" + appointmentId,
          method: "GET",
        };
      },
      providesTags: ["Appointment"],
    }),
    createNewAppointment: builder.mutation({
      query: (body) => {
        return {
          url: "appointment/create-new-appointment",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Appointment"],
    }),
    updateAppointmentStatus: builder.mutation({
      query: ({ body, appointmentId }) => {
        return {
          url: "appointment/update-status/" + appointmentId,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Appointment"],
    }),
    editAppointment: builder.mutation({
      query: ({ body, appointmentId }) => {
        return {
          url: "appointment/edit/" + appointmentId,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Appointment"],
    }),
    deleteAppointment: builder.mutation({
      query: ({ appointmentId }) => {
        return {
          url: "appointment/delete/" + appointmentId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Appointment"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAppointmentInfoQuery,
  useCreateNewAppointmentMutation,
  useSearchAppointmentQuery,
  useEditAppointmentMutation,
  useUpdateAppointmentStatusMutation,
  useDeleteAppointmentMutation,
} = appointmentApi;
