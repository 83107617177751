import * as React from "react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttontype?: String;
  extraclass?: string;
}

const AppButton = (props: Props) => {
  const { extraclass, buttontype } = props;

  let primaryClass =
    "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";
  let dangerClass =
    "focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900";
  let outlinceClass =
    "bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow";
  let buttonClass = primaryClass;

  if (props.buttontype === "danger") {
    buttonClass = dangerClass;
  }

  if (buttontype === "cyan") {
    buttonClass = `${buttonClass} cyan-button`;
  }

  if (buttontype === "green") {
    buttonClass = `${buttonClass} green-button`;
  }

  if (buttontype === "danger") {
    buttonClass = `${buttonClass} !bg-[#dc5d3a]`;
  }

  if (buttontype === "outline") {
    buttonClass = `${outlinceClass}`;
  }

  return (
    <button type="button" className={`${buttonClass} ${extraclass}`} {...props}>
      {props?.title ? (
        props.title
      ) : (
        <div className="w-full">{props.children}</div>
      )}
    </button>
  );
};

export default AppButton;
