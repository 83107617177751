import * as React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { useGetAllLabCategoriesQuery } from "../store/rtk-query/labApi";
import { useGetAllLabItemsQuery } from "../store/rtk-query/inventoryApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetPatientDetailsQuery } from "../store/rtk-query/patientApi";
import { useGetAllDocOrLabSciQuery } from "../store/rtk-query/docOrLabsciApi";
import { toast } from "react-toastify";
import { useAddTestToQueueMutation } from "../store/rtk-query/testQueueApi";
import Spinner from "../components/UI/Spinner";
import { formatServerErrorMessage } from "../utils/functions";
import PageHeader from "../components/pageHeader";
import { AiOutlineArrowRight } from "react-icons/ai";

interface TestItem {
  checked?: boolean;
  testData: any;
}

interface LabFormState {
  [key: string]: {
    tests: Array<TestItem>;
    title: string;
  };
}

interface FormValues {
  [key: string]: string;
}

const Page = () => {
  const [labFormState, setLabFormState] = React.useState<LabFormState>();

  const navigate = useNavigate();
  const { patient_id } = useParams();
  const location = useLocation();
  const visitId = location?.state?.visitId;
  console.log(location.state);

  const { data: patientData } = useGetPatientDetailsQuery({
    patientId: patient_id,
  });

  const { data: labCatsData } = useGetAllLabCategoriesQuery({});

  const { data: itemsData, isLoading: itemsLoading } = useGetAllLabItemsQuery(
    {}
  );

  const { data: scientistsData, isLoading: sciLoading } =
    useGetAllDocOrLabSciQuery({});

  const testPriorityLevel = [
    "ROUTINE",
    "EMERGENCY",
    "REPEAT TEST",
    "BIOSPY",
    "H&E SECOND OPINION ",
  ];

  const specimenType = [
    "Blood",
    "Urine",
    "Stool",
    "Tissue Block",
    "Swab",
    "Skin Scrapping",
    "Sputum",
    "Histology/Biopsy",
    "Cytology",
    "Semen",
  ];
  const [selectedTestIds, setSelectedTestIds] = React.useState<number[]>([]);

  const [addToQueue, addToQueueState] = useAddTestToQueueMutation();

  let itemTypes: string[] = [];
  if (labCatsData) {
    for (let key in labCatsData) {
      itemTypes.push(key);
    }
  }

  const remarks = ["Laboratory Tests"];

  React.useEffect(() => {
    const data = buildAccordionData();
    setLabFormState(data);
  }, [itemsData, itemTypes?.length]);

  const buildAccordionData = () => {
    let accordionData: LabFormState = {};

    //add Item types
    // item types are the accordion headings
    itemTypes.forEach((type) => {
      let catKey = type?.toLowerCase()?.replaceAll("/", "_");

      //add tests

      //tests by category are retrieved from inventory api
      const testsInCat = itemsData?.data
        ?.filter((item: any) => {
          return item?.itemType?.toLowerCase() == type?.toLowerCase();
        })
        .map((item: any) => {
          return {
            testData: item,
            // checked: false
          };
        });

      accordionData[catKey] = {
        title: type,
        tests: testsInCat,
      };
    });

    return accordionData;
  };

  const firstScientist = scientistsData?.[0];

  const initialValues: FormValues = {
    // orderDoctor: '',
    labScientistOrDoc: "",
    patient: `${patientData?.firstName} ${patientData?.lastName}`,
    remark: "",
    priority: "",
    specimenType: "",
    requisitionNumber: "",
  };

  const formValidationSchema = Yup.object().shape({
    labScientistOrDoc: Yup.string().required("Lab Scientist name is required"),
    specimenType: Yup.string().required("Specimen type is required"),
    priority: Yup.string().required("Test priority is required"),
  });

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  const toggleCheckbox = (testId: number) => {
    const checked = selectedTestIds?.some((id) => id == testId);
    let newSelected: number[] = [];
    if (selectedTestIds) {
      newSelected = [...selectedTestIds];
    }

    if (checked) {
      newSelected = newSelected.filter((id) => id !== testId);
      setSelectedTestIds(newSelected);
    } else {
      newSelected = [...newSelected, testId];
      setSelectedTestIds(newSelected);
    }

    return;
  };

  const onSubmit = async (values: typeof initialValues) => {
    let data = {
      testIds: selectedTestIds,
      orderingDoctorId: values.labScientistOrDoc,
      remark: values.remark,
      patientVisitId: visitId,
      priority: values.priority,
      specimenType: values.specimenType,
      requisitionNumber: values.requisitionNumber,
    };

    console.log("data ", data);

    const res: any = await addToQueue({
      body: data,
      patientId: patientData?.id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Added to Queue successfully");
    navigate("/pay-invoice/" + res?.data?.id);
  };

  const renderHeading = () => {
    return (
      <>
        <div className="w-10/12 mx-auto mt-8 border rounded-lg p-6 space-y-6 mb-6">
          <div className="flex flex-wrap sm:flex-nowrap justify-center gap-[5%] my-2">
            <div className="w-full sm:w-[40%]">
              <label className={labelClasses}>Patient</label>
              <Field
                disabled
                className={inputClasses + " bg-gray-300"}
                name="patient"
              />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="patient"
              />
            </div>
            <div className="w-full sm:w-[40%]">
              <label className={labelClasses}>Doctor/Lab Scientist</label>
              <Field
                as="select"
                name="labScientistOrDoc"
                className={inputClasses}
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {scientistsData?.map((sci: any) => {
                  const name = `${sci?.firstName} ${sci?.lastName}`;
                  return (
                    <option key={sci?.id} value={sci?.id}>
                      {name}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="labScientistOrDoc"
              />
            </div>
            <div className="w-full sm:w-[40%]">
              <label className={labelClasses}>Test Priority</label>
              <Field as="select" name="priority" className={inputClasses}>
                <option value="" disabled hidden>
                  Select
                </option>
                {testPriorityLevel?.map((sci: any) => {
                  return (
                    <option key={sci} value={sci}>
                      {sci}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="priority"
              />
            </div>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap justify-center gap-[5%] my-2">
            <div className="w-full sm:w-[40%]">
              <label className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Remark
              </label>
              <Field className={inputClasses} name="remark" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="remark"
              />
            </div>
            <div className="w-full sm:w-[40%]">
              <label className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Requisition Number
              </label>
              <Field className={inputClasses} name="requisitionNumber" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="requisitionNumber"
              />
            </div>
            <div className="w-full sm:w-[40%]">
              <label className={labelClasses}>Sample Collected</label>
              <Field as="select" name="specimenType" className={inputClasses}>
                <option value="" disabled hidden>
                  Select
                </option>
                {specimenType?.map((sample: any) => {
                  return (
                    <option key={sample} value={sample}>
                      {sample}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="specimenType"
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderForm = () => {
    return (
      <div className="w-10/12 mx-auto mt-8 mb-4 border rounded-lg p-6 space-y-6">
        {labFormState &&
          Object.keys(labFormState).map((key) => {
            const labData = labFormState[key];

            return (
              <Disclosure key={key}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                      <span>{labData.title}</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-gray-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 space-y-4">
                      {labData?.tests?.map((test, index) => {
                        const checked = selectedTestIds?.some(
                          (id) => id === test?.testData?.id
                        );

                        return (
                          <div
                            key={index}
                            onClick={() => toggleCheckbox(test?.testData?.id)}
                            className="flex items-center cursor-pointer"
                          >
                            <input
                              id={`checkbox-${index}`}
                              type="checkbox"
                              checked={checked}
                              onChange={() => {}}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor={`checkbox-${index}`}
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              {test?.testData?.itemName}
                            </label>
                          </div>
                        );
                      })}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Add To Queue
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        <Formik
          initialValues={initialValues}
          validationSchema={formValidationSchema}
          onSubmit={(values) => {
            // same shape as initial values
            console.log(values);
            onSubmit(values);
          }}
          enableReinitialize
        >
          {({ errors, values }) => {
            // console.log('errors ', errors)
            return (
              <Form>
                <PageHeader title="Patient Test Information" />
                {renderHeading()}
                <PageHeader title="Select Laboratory Test(s)" />
                {renderForm()}
                <div className="flex justify-center pt-3">
                  <AppButton type="submit">
                    <div className="flex justify-center gap-1">
                      <p>Add to Queue</p> <AiOutlineArrowRight fontSize={20} />
                    </div>
                  </AppButton>
                </div>

                {addToQueueState.isLoading && <Spinner />}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Page;
