import { userApi } from "./userApi";

export const inventoryApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getHmoInfo: builder.query({
      query: ({ hmoId }) => {
        return {
          url: "hmo/info/" + hmoId,
          method: "GET",
        };
      },
    }),
    getAllHmos: builder.query({
      query: ({ headers, params }) => {
        return {
          url: "hmo/get-all-hmos",
          method: "GET",
          headers: { ...headers },
          params: { ...params },
        };
      },
      providesTags: ["HMO"],
    }),
    addNewHmo: builder.mutation({
      query: (body) => {
        return {
          url: "hmo/create-new-hmo",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["HMO"],
    }),
    updateHmo: builder.mutation({
      query: ({ body, hmoId }) => {
        return {
          url: "hmo/edit/" + hmoId,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["HMO"],
    }),
    deleteHmo: builder.mutation({
      query: ({ hmoId }) => {
        return {
          url: "hmo/delete/" + hmoId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["HMO"],
    }),
  }),
  overrideExisting: false,
});

export const {
  //
  useGetHmoInfoQuery,
  useGetAllHmosQuery,
  useAddNewHmoMutation,
  useUpdateHmoMutation,
  useDeleteHmoMutation,
} = inventoryApi;
