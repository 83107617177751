import * as React from "react";
import moment from "moment";

import { Form, Field, ErrorMessage, FormikProvider, useFormik } from "formik";

import AppButton from "../../components/UI/button";
import Spinner from "../../components/UI/Spinner";
import { months } from "../../utils/consts";
import { FormValues } from "../../@types/app-types";
import { useExportPatientDataQuery } from "../../store/rtk-query/patientApi";
import { formatServerErrorMessage } from "../../utils/functions";
import { toast } from "react-toastify";
import _ from "lodash";
import { AiOutlineArrowRight } from "react-icons/ai";

const inputClasses =
  "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
const labelClasses = `block mt-3 mb-1 text-sm font-medium text-gray-900 `;
const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

interface ExportPatientRecordProps {
  labCategoryData: any;
  itemsData: any;
  companyData: any;
  hmoData: any;
}

export const RenderExportDataContent = (props: ExportPatientRecordProps) => {
  const [isExporting, setIsExporting] = React.useState<boolean>(false);

  const initialValues: FormValues = {
    dateFrom: "",
    dateTo: "",
    patientName: "",
    hospitalNo: "",
    referral: "",
    hmo: "",
    company: "",
    periodMonth: "",
    periodYear: "",
    dateVisited: "",
    //
    testCategory: "",
    testCategoryFrom: "",
    testCategoryTo: "",
    //
    testName: "",
    testNameFrom: "",
    testNameTo: "",
  };
  let labCategories: string[] = [];

  if (props?.labCategoryData) {
    labCategories = Object.keys(props?.labCategoryData);
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
      //
    },
  });

  let queryParams: any = {
    searchParams: formik.values.patientName
      ? formik.values.patientName
      : undefined,
    regNumber: formik.values.hospitalNo,
    hmoId: formik.values.hmo,
    companyId: formik.values.company,
    periodMonth: formik.values.periodMonth,
    periodYear: formik.values.periodYear,
    testName: formik.values.testName,
    testCategory: formik.values.testCategory,
    //dates
    ...(formik.values.dateFrom && {
      dateCreatedFrom: moment(formik.values.dateFrom).format("YYYY-MM-DD"),
    }),
    ...(formik.values.dateTo && {
      dateCreatedTo: moment(formik.values.dateTo).format("YYYY-MM-DD"),
    }),
    ...(formik.values.testCategoryFrom && {
      testCategoryFrom: moment(formik.values.testCategoryFrom).format(
        "YYYY-MM-DD"
      ),
    }),
    ...(formik.values.testCategoryTo && {
      testCategoryTo: moment(formik.values.testCategoryTo).format("YYYY-MM-DD"),
    }),
    ...(formik.values.testNameFrom && {
      testNameFrom: moment(formik.values.testNameFrom).format("YYYY-MM-DD"),
    }),
    ...(formik.values.testNameTo && {
      testNameTo: moment(formik.values.testNameTo).format("YYYY-MM-DD"),
    }),
    ...(formik.values.dateVisited && {
      "date-visited": moment(formik.values.dateVisited).format("YYYY-MM-DD"),
    }),
  };

  queryParams = _.omitBy(queryParams, (val) => {
    return val === undefined || val?.toString()?.trim() === "";
  });
  const { data, isLoading, isError } = useExportPatientDataQuery({queryParams});

  const onExportData = async () => {
    if (isLoading || isExporting) {
      return;
    }

    setIsExporting(true);

    try {
      if (isError) {
        let message = formatServerErrorMessage("An error occured.");

        if (message.length > 0) {
          toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        return;
      }

      const blob = new Blob([data], { type: "text/csv" }); // creates a Blob from the response data
      const url = window.URL.createObjectURL(blob); // creates a URL for the Blob
      const a = document.createElement("a"); // creates an <a> element to download the file
      a.href = url;
      a.download = `data_${moment().format('DD/MM/YYYY hh:mm A"')}.csv`;
      a.click();
  
      toast.success("Data exported successfully");

    } catch (error) {
      console.error("Export error:", error);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="w-full  mx-auto mt-3 mb-2 border rounded-lg p-4 space-y-1">
          <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Period</label>
              <div className="flex gap-3">
                <Field className={inputClasses} as="select" name="periodMonth">
                  <option value="" disabled hidden>
                    Select Month
                  </option>

                  {months.map((month) => {
                    return (
                      <option value={month} key={month}>
                        {month}
                      </option>
                    );
                  })}
                </Field>
                <Field
                  className={inputClasses}
                  placeholder="year"
                  type="number"
                  name="periodYear"
                />
              </div>
            </div>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Date Visited</label>
              <Field className={inputClasses} type="date" name="dateVisited" />
            </div>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>From</label>
              <Field className={inputClasses} type="date" name="dateFrom" />
            </div>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>To</label>
              <Field className={inputClasses} type="date" name="dateTo" />
            </div>
          </div>
          <div>
            <label className={labelClasses}>Test Category and Period</label>
            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <div className="w-full sm:w-1/3 ">
                <Field className={inputClasses} as="select" name="testCategory">
                  <option value="" disabled hidden>
                    Select Test Category
                  </option>

                  {labCategories.map((month) => {
                    return (
                      <option value={month} key={month}>
                        {month}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="w-full sm:w-1/3">
                <Field
                  className={inputClasses}
                  type="date"
                  name="testCategoryFrom"
                />
              </div>
              <div className="self-center">⇒</div>
              <div className="w-full sm:w-1/3">
                <Field
                  className={inputClasses}
                  type="date"
                  name="testCategoryTo"
                />
              </div>
            </div>
          </div>
          <div>
            <label className={labelClasses}>Test Name and Period</label>
            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <div className="w-full sm:w-1/3 ">
                <Field className={inputClasses} as="select" name="testName">
                  <option value="" disabled hidden>
                    Select Test Name
                  </option>

                  {props.itemsData?.data?.map((item: any) => {
                    return (
                      <option value={item?.itemName} key={item?.id}>
                        #{item?.id} {item?.itemName}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="w-full sm:w-1/3">
                <Field
                  className={inputClasses}
                  type="date"
                  name="testNameFrom"
                />
              </div>
              <div className="self-center">⇒</div>
              <div className="w-full sm:w-1/3">
                <Field className={inputClasses} type="date" name="testNameTo" />
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <div>
              <label className={labelClasses}>Reg Number</label>
              <Field className={inputClasses} name="hospitalNo" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="hospitalNo"
              />
            </div>
          </div>
          <div className="flex justify-between gap-4">
            <div>
              <label className={labelClasses}>HMO</label>
              <Field className={inputClasses} name="hmo" as="select">
                <option value="">Choose an HMO</option>
                {props.hmoData?.data?.map((item: any) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Field>
            </div>
            <div>
              <label className={labelClasses}>Company</label>
              <Field className={inputClasses} name="company" as="select">
                <option value="">Choose a Company</option>
                {props.companyData?.data?.map((item: any) => {
                  return (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  );
                })}
              </Field>
            </div>
          </div>

          <div className="flex justify-center pt-3">
            <AppButton onClick={onExportData}>
              <div className="flex justify-center gap-1">
                <p>Export Data</p> <AiOutlineArrowRight fontSize={20} />
              </div>
            </AppButton>
            {isLoading && <Spinner />}
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
