import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";
import ItemsForm from "../components/forms/ItemsForm";
import { toast } from "react-toastify";
import moment from "moment";

import { useAddNewDocOrLabSciMutation } from "../store/rtk-query/docOrLabsciApi";
import AddPurchaseForm from "../components/forms/AddPurchaseForm";
import { useAddNewPurchaseItemMutation } from "../store/rtk-query/purchaseApi";
import { formatServerErrorMessage } from "../utils/functions";

const AddPurchase = () => {
  const [addPurchase, addPurchaseState] = useAddNewPurchaseItemMutation();

  let initialValues = {
    date: "",
    name: "",
    itemCategoryId: "",
    supplierName: "",
    supplierAddress: "",
    supplierPhone: "",
    quantity: "",
    price: "",
  };

  const onSubmit = async (values: typeof initialValues) => {
    let data = {
      dateOfPurchase: moment(values.date).format("MM/DD/YYYY"),
      itemName: values.name,
      purchaseItemTypeId: values.itemCategoryId,
      supplierName: values.supplierName,
      supplierAddress: values.supplierAddress,
      supplierPhoneNumber: values.supplierPhone,
      quantity: values.quantity,
      price: values.price,
    };

    const res: any = await addPurchase(data);

    console.log('res ', res)

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Purchase added successfully");
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Add Purchase
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <AddPurchaseForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          isLoading={addPurchaseState.isLoading}
        />
      </div>
    </div>
  );
};

export default AddPurchase;
