import { Field, FormikProvider, useFormik } from "formik";
import * as React from "react";
import {
  GenotypeItem,
  LabTestsProviderValues,
  ResultTemplateType,
} from "../../../@types/app-types";

import _ from "lodash";
import { updatedDiff } from "deep-object-diff";
import { LabTestContext } from "../../../context/LabTestProvider";
import AppButton from "../../UI/button";
import { usePrintResultMutation } from "../../../store/rtk-query/labResult";
import { toast } from "react-toastify";
import {
  formatServerErrorMessage,
  generateAndDownloadResultPdf,
} from "../../../utils/functions";
import Spinner from "../../UI/Spinner";

interface Props {
  onChangeForm: any;
}

const Genotype = (props: Props) => {
  const { originalTestResult, isUpdateMode, labTestResultId } =
    React.useContext<LabTestsProviderValues>(LabTestContext);

  const [printResult, printResultState] = usePrintResultMutation();

  const genoTypeResult = originalTestResult?.genotype;

  const formColumns = [
    //will become {testName}_
    "Test Findings",
  ];

  const testFindings = ["AA", "AS", "SS", "AC"];

  let initVals = {
 
    testName: "",
    testResult: ""

  };

  let initialValues = React.useMemo(() => {
    if (isUpdateMode) {
      if (genoTypeResult) {
        initVals["testName"] = genoTypeResult?.testName || "";
        initVals["testResult"] = genoTypeResult?.testResult || "";
      }
    }

    return initVals;
  }, [isUpdateMode, originalTestResult]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
    enableReinitialize: true,
  });


  React.useEffect(() => {

    let formikValues: any = { ...formik.values };

    const updatedObj = updatedDiff(initVals, formikValues);
    if (Object.keys(updatedObj).length == 0) {
      props.onChangeForm(undefined, "genotypeResult");
      return;
    }

    props.onChangeForm(
      {
        ...formikValues,
      },
      "genotypeResult"
    );
  }, [formik.values]);


  const onPrintResult = async () => {
    const res: any = await printResult({
      labResultId: labTestResultId,
      queryParams: {
        templateType: ResultTemplateType.GENOTYPE_TEST_TEMPLATE,
      },
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadResultPdf(res?.data, "Widal");

    toast.success("Result pdf generated successfully");
  };

  const inputClasses =
    "bg-white border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

  return (
    <FormikProvider value={formik}>
      <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg">
        <table className="horiz-table w-full text-sm">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="px-6 py-3 bg-gray-50 ">
                Test Name
              </th>
              <th scope="col" className="px-6 py-3 min-w-[300px]">
                Test Findings
              </th>
            </tr>
          </thead>
          <tbody>
                <tr className="border-b border-gray-200 ">
                  <td className="px-6 py-3 bg-gray-50 ">Genotype</td>
                  <td className="px-6 py-3 bg-gray-50 min-w-[120px] ">
                    <Field
                      as="select"
                      name="testResult"
                      className={inputClasses}
                      defaultValue=""
                    >
                      <option value="">Select</option>
                      {testFindings.map((opt) => {
                        return <option key={opt} value={opt} label={opt} />;
                      })}
                    </Field>
                  </td>
                </tr>
          </tbody>
        </table>
        {isUpdateMode && !_.isEmpty(genoTypeResult) && (
          <div className="flex gap-1 mt-8">
            <AppButton
              onClick={onPrintResult}
              buttontype={"cyan"}
              title="Generate Result"
              extraclass="!text-xs"
            />
            {printResultState.isLoading && <Spinner />}
          </div>
        )}
      </div>
    </FormikProvider>
  );
};

export default Genotype;
