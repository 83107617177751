import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../rtk-query/userApi";

const initialState = {
  loggedIn: false,
  userData: undefined,
  idToken: undefined,
  refreshToken: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.loggedIn = true;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
    },
    logout: (state) => {
      state.loggedIn = false;
    },
    updateToken: (state, action) => {
      state.idToken = action.payload.idToken;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.idToken = payload.idToken;
        state.refreshToken = payload.refreshToken;
        state.loggedIn = true;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { loginSuccess, logout, updateToken } = userSlice.actions;

export default userSlice.reducer;
