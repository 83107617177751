import * as React from "react";

import { Route, Routes, Navigate } from "react-router-dom";
//
import DashboardLayout from "../layout/dashboardLayout";

//
import Dashboard from "../pages/dashboard";
import NewCompany from "../pages/NewCompany";
import NewHmo from "../pages/NewHmo";
import AddToQueue from "../pages/AddToQueue";
import AddNewItem from "../pages/AddNewItem";
import UpdateItem from "../pages/UpdateItem";
import Visits from "../pages/Visits";
//
import Laboratory from "../pages/laboratory/Laboratory";
import AddTest from '../pages/laboratory/AddNewTest'
//
import FirstVisit from '../pages/patient/AddFirstVist'
import AddNextVisit from '../pages/patient/AddNextVisit'
import ViewPatientsRecord from '../pages/patient/ViewPatientsRecord'
import HMO from '../pages/patient/HMO'
import Company from '../pages/patient/Company'
import UpdatePatient from '../pages/patient/UpdatePatient'
//
import Appointments from '../pages/reminders/Appointments'
import Birthday from '../pages/reminders/Birthday'
import WaitingQueue from '../pages/reminders/WaitingQueue'
//
import Debtors from '../pages/inventory/Debtors'
import DebtRepaymentHx from '../pages/inventory/DebtRepaymentHx'
import InventoryLedger from '../pages/inventory/InventoryLedger'
import Invoice from '../pages/inventory/Invoice'
import Items from '../pages/inventory/Items'
import Sales from '../pages/inventory/Sales'
import Purchases from '../pages/inventory/Purchases'
//
import AppSettings from '../pages/administration/AppSettings'
import ClockInSystem from '../pages/administration/ClockInSystem'
import DatabaseBackup from '../pages/administration/DatabaseBackup'
import LeaveManagement from '../pages/administration/LeaveManagement'
import StaffEvaluation from '../pages/administration/StaffEvaluation'
import StaffManagement from '../pages/administration/StaffManagement'
import UsersAccount from '../pages/administration/UsersAccount'
//
import BankLodgement from '../pages/analytics/BankLodgement'
import BankWithdrawal from '../pages/analytics/BankWithdrawal'
import CashCollection from '../pages/analytics/CashCollection'
import GeneralLedger from '../pages/analytics/GeneralLedger'
import NewCash from '../pages/analytics/NewCash'
import PaymentVoucher from '../pages/analytics/PaymentVoucher'
//
import SignIn from "../pages/auth/signin";
import ForgotPass from "../pages/auth/ForgotPass";

//redux
import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
//
import NotFound from "../pages/NotFound";
import SignedOutRoutes from "./SignedOutRoutes";
import AddStaff from "../pages/AddStaff";
import AddDocOrSci from "../pages/AddDocOrSci";
import AttendToPatient from "../pages/AttendToPatient";
import AddSale from "../pages/AddSale";
import AddAppointment from "../pages/AddAppointment";
import AddPurchase from "../pages/AddPurchase";
import PayTestInvoice from "../pages/PayTestInvoice";
import Templates from "../pages/template/Templates";
import AddTemplate from "../pages/template/AddTemplate";
import MedicalReporting from "../pages/MedicalReporting";
import UpdateVisitDetails from "../pages/UpdateVisitDetails";
import EditProfile from "../pages/EditProfile";
import QrDetailsPage from "../pages/QrDetailsPage";

const RootRouter = () => {
    const userState = useSelector((state: any) => state.user)


    const loggedInRoutes = <Route element={
        <ProtectedRoute>
            <DashboardLayout />
        </ProtectedRoute>
    }>
        <Route index element={<Dashboard />} />
        <Route path='/medical-reports/:report_type' element={<MedicalReporting />} />
        <Route path='/attend-to-patient/:queue_id' element={<AttendToPatient />} />
        <Route path='/pay-invoice/:queue_id' element={<PayTestInvoice />} />
        {/* CREATE */}
        <Route path='/add-sale' element={<AddSale />} />
        <Route path='/add-appointment' element={<AddAppointment />} />
        <Route path='/add-purchase' element={<AddPurchase />} />
        <Route path='/add-staff' element={<AddStaff />} />
        <Route path='/add-scientist' element={<AddDocOrSci />} />
        <Route path='/add-hmo' element={<NewHmo />} />
        <Route path='/add-company' element={<NewCompany />} />
        <Route path='/add-to-queue/:patient_id' element={<AddToQueue />} />
        <Route path='/new-item' element={<AddNewItem />} />
        {/* UPDATE */}
        <Route path='/update-profile' element={<EditProfile />} />
        {/* <Route path='/update-sale/:sale_id' element={<AddSale />} /> */}
        <Route path='/update-appointment/:appointment_id' element={<AddAppointment />} />
        {/* <Route path='/update-purchase/:purchase_id' element={<AddPurchase />} /> */}
        <Route path='/update-staff/:staff_id' element={<AddStaff />} />
        <Route path='/update-scientist/:scientist_id' element={<AddDocOrSci />} />
        <Route path='/update-hmo/:hmo_id' element={<NewHmo />} />
        <Route path='/update-company/:company_id' element={<NewCompany />} />
        <Route path='/update-item/:item_id' element={<UpdateItem />} />
        {/* OTHERS */}
        <Route path='/patient-visits/:patient_id' element={<Visits />} />
        <Route path='/patient-visits/update/:visit_id' element={<UpdateVisitDetails />} />
        {/*  */}
        <Route path="laboratory" >
            <Route index element={<Laboratory />} />
            <Route path=':labType' element={<Laboratory />} />
            <Route path='add-test' element={<AddTest />} />
        </Route>
        <Route path="patient" >
            <Route index element={<FirstVisit />} />
            <Route path="first-visit" element={<FirstVisit />} />
            <Route path="next-visit" element={<AddNextVisit />} />
            <Route path="records" element={<ViewPatientsRecord />} />
            <Route path="hmo" element={<HMO />} />
            <Route path="company" element={<Company />} />
            <Route path="update/:patient_id" element={<UpdatePatient />} />
        </Route>
        <Route path="reminders" >
            <Route index element={<WaitingQueue />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="birthday" element={<Birthday />} />
            <Route path="waiting-queue" element={<WaitingQueue />} />
        </Route>
        <Route path="inventory" >
            <Route index element={<Items />} />
            <Route path="debtors" element={<Debtors />} />
            <Route path="debt-repayment-hx" element={<DebtRepaymentHx />} />
            <Route path="inventory-ledger" element={<InventoryLedger />} />
            <Route path="invoice" element={<Invoice />} />
            <Route path="items" element={<Items />} />
            <Route path="purchases" element={<Purchases />} />
            <Route path="sales" element={<Sales />} />
        </Route>
        <Route path="business-analytics" >
            <Route index element={<BankLodgement />} />
            <Route path="bank-lodgement" element={<BankLodgement />} />
            <Route path="bank-withdrawal" element={<BankWithdrawal />} />
            <Route path="cash-collection" element={<CashCollection />} />
            <Route path="general-ledger" element={<GeneralLedger />} />
            <Route path="new-cash" element={<NewCash />} />
            <Route path="payment-voucher" element={<PaymentVoucher />} />
        </Route>
        <Route path="administration" >
            <Route index element={<StaffManagement />} />
            <Route path="app-settings" element={<AppSettings />} />
            <Route path="clock-in-system" element={<ClockInSystem />} />
            <Route path="database-backup" element={<DatabaseBackup />} />
            <Route path="leave-management" element={<LeaveManagement />} />
            <Route path="staff-evaluation" element={<StaffEvaluation />} />
            <Route path="staff-management" element={<StaffManagement />} />
            <Route path="users-account" element={<UsersAccount />} />
        </Route>
        <Route path="template" >
            <Route index element={<Templates />} />
            <Route path='add' element={<AddTemplate />} />
        </Route>
    </Route>

    const loggedOutRoutes = <>
        <Route path="signin" element={<SignedOutRoutes><SignIn /></SignedOutRoutes>} />
        <Route path="reset-password" element={<SignedOutRoutes><ForgotPass /></SignedOutRoutes>} />
    </>

    return (<Routes>
        {loggedInRoutes}
        {loggedOutRoutes}
        <Route path='/patient-summary' element={<ProtectedRoute><QrDetailsPage /></ProtectedRoute>} />
        <Route path="*" element={<NotFound />} />
    </Routes>)
}

export default RootRouter