import * as React from 'react'

import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AppButton from '../UI/button';
import { useGetAllLabCategoriesQuery } from '../../store/rtk-query/labApi';
import Spinner from '../UI/Spinner';
import PhoneNumberInput from '../PhoneTelInput';


interface FormValues {
    "firstName": string,
    "lastName": string,
    "email": string,
    "phoneNumber": string,
    "password"?: string,
    "confirmPassword"?: string,
    "role": string
}

let initialValues = {
    "firstName": "",
    "lastName": "",
    "email": "",
    "phoneNumber": "",
    "password": "",
    "confirmPassword": "",
    "role": ''
}

interface Props {
    onSubmit: any;
    initialValues?: FormValues;
    isLoading: boolean
}

const AddStaffForm = (props: Props) => {

    if (props.initialValues) {
        initialValues = {
            ...initialValues,
            ...props.initialValues
        }
    }

    const isUpdateMode = props.initialValues != undefined

    const formValidationSchema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().required('Required').email('Invalid email'),
        phoneNumber: Yup.string().required('Required'),
        ...(!isUpdateMode && { password: Yup.string().required('Required') }),
        ...(!isUpdateMode && {
            confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match')
        })
    })

    const inputClasses = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'
    const inputWrap = 'flex justify-between md:justify-start gap-4'


    let roles = React.useMemo(() => {
        let rls = ['Staff', 'Admin'];

        // if previous user role isnt in list then add
        if (initialValues.role) {
            // console.log('initialValues.role ', initialValues.role)
            if (!rls.find((role) => role?.toLowerCase() == initialValues.role?.toLowerCase())) {
                rls = [
                    ...rls,
                    initialValues.role
                ]
            }
        }

        return rls
    }, [initialValues.role])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={formValidationSchema}
            onSubmit={values => {
                // same shape as initial values
                console.log(values);
                props.onSubmit(values)
            }}
            enableReinitialize
        >
            <Form>
                <>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>First name</label>
                            <Field className={inputClasses} name="firstName" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="firstName" />
                        </div>
                        <div>
                            <label className={labelClasses}>Last name</label>
                            <Field className={inputClasses} name="lastName" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="lastName" />
                        </div>
                    </div>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Email</label>
                            <Field className={inputClasses} name="email" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="email" />
                        </div>
                        <div>
                            <label className={labelClasses}>Phone Number</label>
                            <PhoneNumberInput
                                buttonClass="!rounded-l-lg"
                                containerClass={'min-h-[40px] '}
                                inputClass={'min-h-[40px] !rounded-lg'}
                                name="phoneNumber" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="phoneNumber" />
                        </div>
                    </div>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Role</label>
                            <Field
                                disabled={initialValues?.role == "SUPER_ADMIN" ? true : false}
                                as='select' className={inputClasses} name="role" >
                                <option value="" disabled hidden>Select</option>
                                {roles?.map((each: any) => {
                                    return <option
                                        key={each}
                                        value={each?.toUpperCase()}>{each}</option>
                                })}
                            </Field>
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="role" />
                        </div>
                    </div>
                    {!isUpdateMode ?
                        <>
                            <div className={inputWrap}>
                                <div>
                                    <label className={labelClasses}>Password</label>
                                    <Field className={inputClasses} name="password" type="password" />
                                    <ErrorMessage className={errorMessageClasses} component={'p'} name="password" />
                                </div>
                                <div>
                                    <label className={labelClasses}>Confirm Password</label>
                                    <Field className={inputClasses} name="confirmPassword" type="password" />
                                    <ErrorMessage className={errorMessageClasses} component={'p'} name="confirmPassword" />
                                </div>
                            </div>
                        </>
                        : undefined
                    }

                    {props.isLoading && <Spinner />}
                    <AppButton
                        extraclass='mt-4'
                        type="submit" buttontype={'primary'}>
                        {isUpdateMode ? "Update" : "Add"}
                    </AppButton>
                </>
            </Form>
        </Formik >
    )
}

export default AddStaffForm