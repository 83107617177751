import moment from "moment";
import { userApi } from "./userApi";

export const invoiceApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoice: builder.query({
      query: ({ params }) => {
        return {
          url: "inventory/invoice/all-invoice",
          method: "GET",
          params: { ...params },
        };
      },
      providesTags: ["Invoice"],
    }),
    printInvoice: builder.mutation({
      query: ({ invoiceId }) => {
        return {
          url: "inventory/invoice/print/" + invoiceId,
          method: "GET",
          responseHandler: (response: any) => {
            const buffer = response.arrayBuffer();
            return buffer;
          },
        };
      },
    }),
    payTestInvoice: builder.mutation({
      query: (body) => {
        return {
          url: "inventory/invoice/add-new-invoice",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["TestsOnQueue", "Invoice", "Sale"],
    }),
    deleteInvoice: builder.mutation({
      query: ({ invoiceId }) => {
        return {
          url: "inventory/invoice/delete/" + invoiceId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Invoice"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllInvoiceQuery,
  usePrintInvoiceMutation,
  usePayTestInvoiceMutation,
  useDeleteInvoiceMutation,
} = invoiceApi;
