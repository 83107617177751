import * as React from "react";

import { Form, Field, useFormik, FormikProvider } from "formik";
import {
  useExportAdvancedMedReportMutation,
  useGetAdvancedMedicalReportQuery,
} from "../store/rtk-query/patientApi";
import { useNavigate, useParams } from "react-router-dom";
import Paginate from "../components/Paginate";
import moment from "moment";
import AppButton from "../components/UI/button";
import { toast } from "react-toastify";
import Spinner from "../components/UI/Spinner";
import { formatServerErrorMessage } from "../utils/functions";
import FilterHeader from "../components/filterHeader";
import FilterResultHeader from "../components/filterResultHeader";
import ExportMedicalReport from "../components/modals/exportMedicalReport";
import { RenderMedicalReportContent } from "../components/modals/exportMedicalContent";

const MedicalReporting = () => {
  const navigate = useNavigate();

  const { report_type } = useParams();

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const [exportData, exportDataState] = useExportAdvancedMedReportMutation();
  const [showExportModal, setShowExportModal] = React.useState<boolean>(false);

  const initialValues = {
    dateFrom: "",
    dateTo: "",
    searchPhrase: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema={formValidationSchema}
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
    },
  });

  const queryParams: any = {
    ...(formik.values.dateFrom && {
      periodFrom: moment(formik.values.dateFrom).format("YYYY-MM-DD"),
    }),
    ...(formik.values.dateTo && {
      periodTo: moment(formik.values.dateTo).format("YYYY-MM-DD"),
    }),
    ...(formik.values.searchPhrase && {
      searchPhrase: formik.values.searchPhrase,
    }),
  };

  const { data: medReportData, isLoading: medReportLoading } =
    useGetAdvancedMedicalReportQuery({
      queryParams: {
        ...queryParams,
        skip: itemOffset,
        take: perPage
      },
      type: report_type,
    });

  const onExportData = async () => {
    const res: any = await exportData({
      type: report_type,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    const blob = new Blob([res?.data], { type: "text/csv" }); // creates a Blob from the response data
    const url = window.URL.createObjectURL(blob); // creates a URL for the Blob
    const a = document.createElement("a"); // creates an <a> element to download the file
    a.href = url;
    a.download = `data_${moment().format('DD/MM/YYYY hh:mm A"')}.csv`;
    a.click();

    toast.success("Data exported successfully");
  };

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

  const renderHeading = () => {
    return (
      <div>
        <FormikProvider value={formik}>
          <FilterHeader title="Filter Medical Report" />
          <Form className="sm:max-w-[60%] md:w-full ">
            <div className="w-full  mx-auto mt-3 mb-2 border rounded-lg p-4 space-y-1">
              <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full mt-4">
                <div className="w-full ">
                  <label className={labelClasses}>
                    Enter Material collected, patient name, test name etc.
                  </label>
                  <Field
                    className={inputClasses}
                    name="searchPhrase"
                    placeholder="Enter material collected, patient name, test name etc."
                  />
                </div>
              </div>
              <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
                <div className="w-full sm:w-6/12">
                  <label className={labelClasses}>From</label>
                  <Field className={inputClasses} type="date" name="dateFrom" />
                </div>
                <div className="w-full sm:w-6/12">
                  <label className={labelClasses}>To</label>
                  <Field className={inputClasses} type="date" name="dateTo" />
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className="text-xl text-[#468847] text-center my-5">
          Currently showing: {perPage}
        </div>
        <div className="my-2 ml-[auto] flex !w-[fit-content]">
          <AppButton
            onClick={() => setShowExportModal(true)}
            title="Export Data"
            extraclass="!bg-[#2fade7] hover:!bg-[#3492c9]"
          />
          {exportDataState.isLoading && <Spinner />}
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full">
        <FilterResultHeader
          title="Medical Report Filter Result"
          isLoading={medReportLoading}
        />
        <div className="tableContainer relative  shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3">
                  View Result
                </th>
                <th scope="col" className="px-6 py-3">
                  Date Reported
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Material Collected
                </th>
                <th scope="col" className="px-6 py-3">
                  Diagnosis
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Patient Name
                </th>
                <th scope="col" className="px-6 py-3">
                  REG No
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Age
                </th>
                <th scope="col" className="px-6 py-3">
                  Sex
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Doctor
                </th>
                <th scope="col" className="px-6 py-3">
                  Lab No.
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Typed By
                </th>
              </tr>
            </thead>
            <tbody>
              {medReportData?.data?.map((report: any) => {
                return (
                  <tr key={report?.id} className="border-b border-gray-200 ">
                    <td className="px-6 py-4">
                      <AppButton
                        buttontype={"cyan"}
                        onClick={() =>
                          navigate("/patient-visits/update/" + report?.id)
                        }
                        title="View Result"
                      />
                    </td>
                    <td className="px-6 py-4">
                      {report?.dateReported &&
                        moment(report?.dateReported).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {report?.materialCollected}
                    </td>
                    <td className="px-6 py-4">{report?.diagnosis}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {report?.patientName}
                    </td>
                    <td className="px-6 py-4">{report?.patientRegNumber}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {report?.patientAge}
                    </td>
                    <td className="px-6 py-4">{report?.patientSex}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {report?.consultantPathologist}
                    </td>
                    <td className="px-6 py-4"></td>
                    <td className="px-6 py-4 bg-gray-50 "></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Advanced Medical Reporting
        </h3>
      </div>
      <div className="w-full p-3 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {renderHeading()}
        {renderResults()}
        {medReportData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={medReportData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
      </div>
      <ExportMedicalReport
        show={showExportModal}
        onClose={() => setShowExportModal(false)}
        content={() => {
          return <RenderMedicalReportContent />;
        }}
      />
    </div>
  );
};

export default MedicalReporting;
