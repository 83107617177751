import * as React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../components/Logo'
import { useGetPatientDetailsQuery, useGetPatientsQuery } from '../store/rtk-query/patientApi';
import { useSearchParams } from 'react-router-dom';
import Spinner from '../components/UI/Spinner';

type DetailItem = {
    label: string;
    value: string
}

const QrDetailsPage = () => {
    const [searchParams] = useSearchParams();

    let patientRegNo = searchParams.get('regNumber')

    const queryParams = {
        regNumber: patientRegNo
    }

    const { data: patientsData, isLoading: patientsLoading } =
        useGetPatientsQuery({
            queryParams,
            headers: {

            },
        }, { skip: !patientRegNo });

    const patientData = patientsData?.data?.[0]

    const detailsTable: DetailItem[] = [
        {
            label: 'Patient Name',
            value: patientData?.firstName + ' ' + patientData?.lastName
        },
        {
            label: 'Reg Number',
            value: patientData?.regNumber
        },
        {
            label: 'Phone Number',
            value: patientData?.phoneNumber
        },
        {
            label: 'Email Address',
            value: patientData?.email
        },
        {
            label: 'Age',
            value: patientData?.age
        },
        {
            label: 'Date of Birth',
            value: `${patientData?.dateOfBirth?.day}/${patientData?.dateOfBirth?.month}/${patientData?.dateOfBirth?.year}`
        },
        {
            label: 'Address',
            value: patientData?.address
        },
        {
            label: 'State',
            value: patientData?.state
        },
        {
            label: 'Country',
            value: patientData?.country
        },

    ]

    console.log('patient ', patientsData)

    const renderPatientDetails = () => {
        return (
            <div className='divider-y-1'>
                {detailsTable.map((detail) => {
                    return (
                        <div
                            key={detail?.label}
                            className='flex justify-between items-center h-[55px]'>
                            <div className='font-semibold'>{detail.label}</div>
                            <div className='text-sm'>
                                {detail.value}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className='space-y-4 py-7 bg-[#eee] min-h-[100vh] flex flex-col items-center'>
            <div className='w-[300px] '>
                <Logo />
            </div>
            <div className='rounded-md shadow-md bg-white p-4 w-full sm:w-[80%] max-w-[600px]'>
                {patientRegNo ? <>
                    <div className='flex justify-between items-center'>
                        <div>
                            <div className='font-bold text-lg'>
                                Patient Basic Information
                            </div>
                            <div className='text-sm'><span className='text-[#aaa] '>Patient Type: </span>
                                {' '}{patientData?.patientType}
                            </div>

                        </div>
                        <div>
                            <Link
                                target={'_blank'}
                                className='text-[#00a] underline text-sm'
                                to={'/patient-visits/' + patientData?.id}>
                                View Details
                            </Link>
                            {patientsLoading && <Spinner />}
                        </div>
                    </div>
                    {renderPatientDetails()}
                </> : undefined}
            </div>

        </div>
    )
}

export default QrDetailsPage