import * as React from "react";

import {
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";
import * as csc from "country-state-city";

import Spinner from "../components/UI/Spinner";
import AppButton from "../components/UI/button";

import FormikChildListener from "../utils/FormikChildListener";

import moment from "moment";
import { toast } from "react-toastify";
import {
  useAddNewHmoMutation,
  useGetHmoInfoQuery,
  useUpdateHmoMutation,
} from "../store/rtk-query/hmoApi";
import { useParams } from "react-router-dom";
import { updatedDiff } from "deep-object-diff";
import { formatServerErrorMessage } from "../utils/functions";
import PhoneNumberInput from "../components/PhoneTelInput";
import PageHeader from "../components/pageHeader";

interface FormValues {
  [key: string]: string;
}

const Page = () => {
  const [addHmo, addHmoState] = useAddNewHmoMutation();
  const [updateHmo, updateHmoState] = useUpdateHmoMutation();

  const [selectedCountry, setSelectedCountry] = React.useState<
    csc.ICountry | undefined
  >();
  const [countries, setCountries] = React.useState<Array<csc.ICountry>>([]);
  const [states, setStates] = React.useState<Array<csc.IState>>([]);

  const { hmo_id } = useParams();

  /**
   * determine if component is updating or creating
   */
  const isUpdateMode = hmo_id !== undefined;

  let initialValues = {
    hmoEmail: "",
    hmoName: "",
    hmoAddress: "",
    representativeName: "",
    phoneNumber: "",
    registration: "",
    consultation: "",
    city: "",
    state: "",
    country: "",
  };

  const { data: hmoData } = useGetHmoInfoQuery(
    {
      hmoId: hmo_id,
    },
    {
      skip: !isUpdateMode,
    }
  );

  if (isUpdateMode) {
    initialValues.hmoEmail = hmoData?.email;
    initialValues.hmoAddress = hmoData?.address;
    initialValues.hmoName = hmoData?.name;
    initialValues.city = hmoData?.city;
    initialValues.registration = hmoData?.registration;
    initialValues.consultation = hmoData?.consultation;
    initialValues.state = hmoData?.state;
    initialValues.phoneNumber = hmoData?.phoneNumber;
    initialValues.representativeName = hmoData?.representativeName;
    initialValues.country = hmoData?.country;
  }

  const formValidationSchema = Yup.object().shape({
    hmoEmail: Yup.string().email("Invalid Email").required("Required"),
    hmoName: Yup.string().required("Required"),
    hmoAddress: Yup.string().required("Required"),
    representativeName: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    registration: Yup.string().required("Required"),
    consultation: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
      onSubmit(values);
    },
    enableReinitialize: true,
  });

  React.useEffect(() => {
    //set nigeria initially
    const countries = csc.Country.getAllCountries();
    setCountries(countries);
    let nigeria = countries.find((country) => country.isoCode == "NGA");
    if (nigeria) setSelectedCountry(nigeria);
  }, []);

  React.useEffect(() => {
    if (!formik.values.country) return;

    let countryObj = countries.find(
      (country) => country.name == formik.values.country
    );

    if (countryObj) {
      const _states = csc.State.getStatesOfCountry(countryObj?.isoCode);
      setStates(_states);
    }
  }, [formik.values.country]);

  const onValueChange = (values: any) => {
    // if (!values.country) return;
    // if (values.country !== selectedCountry) {
    //   let countryObj = countries.find(
    //     (country) => country.hmoName == values.country
    //   );
    //   setSelectedCountry(countryObj);
    // }
  };

  const inputClasses =
    "bg-white border border-gray-300 text-gray-800 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";
  const inputWrap = "flex justify-between md:justify-start gap-8";

  const onSubmit = async (values: typeof initialValues) => {
    let data: any = {
      hmoName: values.hmoName,
      hmoAddress: values.hmoAddress,
      state: values.state,
      city: values.city,
      country: values.country,
      phoneNumber: values.phoneNumber,
      hmoEmail: values.hmoEmail,
      representativeName: values.representativeName,
      registration: values.registration,
      consultation: values.consultation,
    };

    if (isUpdateMode) {
      data = updatedDiff(initialValues, values);
    }

    let res: any;
    if (isUpdateMode) {
      res = await updateHmo({ body: data, hmoId: hmo_id });
    } else {
      res = await addHmo(data);
    }

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success(
      isUpdateMode ? "HMO Updated Successfully" : "HMO added successfully"
    );
  };

  const renderForm = () => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <>
            <PageHeader title={isUpdateMode ? "Update HMO" : "Add New HMO"} />
            <div className="ml-3 sm:ml-4 md:ml-6 mt-4">
              <div className={inputWrap}>
                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>Name</label>
                  <Field className={inputClasses} name="hmoName" />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="hmoName"
                  />
                </div>
                <div className="w-10/12 sm:w-3/12">
                  <label className={labelClasses}>Contact Address</label>
                  <Field
                    as="textarea"
                    className={inputClasses}
                    name="hmoAddress"
                  />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="hmoAddress"
                  />
                </div>
              </div>
              <div className={inputWrap}>
                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>Phone Number</label>
                  <PhoneNumberInput
                    buttonClass="!rounded-l-lg"
                    containerClass={"min-h-[40px]"}
                    inputClass={"min-h-[40px] !rounded-lg"}
                    name="phoneNumber"
                  />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="phoneNumber"
                  />
                </div>
                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>Representative</label>
                  <Field className={inputClasses} name="representativeName" />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="representativeName"
                  />
                </div>
              </div>
              <div className={inputWrap}>
                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>Email</label>
                  <Field className={inputClasses} name="hmoEmail" />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="hmoEmail"
                  />
                </div>

                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>City</label>
                  <Field className={inputClasses} name="city" />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="city"
                  />
                </div>
              </div>
              <div className={inputWrap}>
                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>Country</label>
                  <Field as="select" name="country" className={inputClasses}>
                    {countries.map((country) => {
                      return (
                        <option key={country.name} value={country.name}>
                          {country.name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="country"
                  />
                </div>
                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>State</label>
                  <Field as="select" name="state" className={inputClasses}>
                    {states.map((state) => {
                      return (
                        <option key={state.name} value={state.name}>
                          {state.name}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="state"
                  />
                </div>
              </div>
              <div className="font-semibold my-4">Billing Information</div>
              <div className={inputWrap}>
                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>Registration</label>
                  <Field className={inputClasses} name="registration" />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="registration"
                  />
                </div>
                <div className="w-full sm:w-3/12">
                  <label className={labelClasses}>Consultation</label>
                  <Field className={inputClasses} name="consultation" />
                  <ErrorMessage
                    className={errorMessageClasses}
                    component={"p"}
                    name="consultation"
                  />
                </div>
              </div>
            </div>
            <AppButton extraclass="mt-4" type="submit" buttontype={"primary"}>
              {isUpdateMode ? "Update" : "Create"}
            </AppButton>
            {(addHmoState.isLoading || updateHmoState.isLoading) && <Spinner />}
          </>
        </Form>
      </FormikProvider>
    );
  };

  return (
    <div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {renderForm()}
      </div>
    </div>
  );
};

export default Page;
