import * as React from "react";
import AppButton from "../../components/UI/button";

import {
  useFormikContext,
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";

import * as csc from "country-state-city";
import FormikChildListener from "../../utils/FormikChildListener";
import { useParams } from "react-router-dom";
import {
  useGetPatientDetailsQuery,
  useUpdatePatientInfoMutation,
} from "../../store/rtk-query/patientApi";
import moment from "moment";
import { toast } from "react-toastify";
import Spinner from "../../components/UI/Spinner";

import { updatedDiff } from 'deep-object-diff'
import { useGetAllCompaniesQuery } from "../../store/rtk-query/companyApi";
import { useGetAllHmosQuery } from "../../store/rtk-query/hmoApi";
import { formatServerErrorMessage } from "../../utils/functions";
import PhoneNumberInput from "../../components/PhoneTelInput";

interface FormValues {
  firstName: string;
  lastName: string;
  otherNames: string;
  phoneNo: string;
  dateOfBirth: string | Date;
  email: string;
  country: string;
  state: string;
  sex: string;
  address: string;
  patientType: string;
  hmoId: string;
  companyId: string
}

const Page = () => {
  const [selectedCountry, setSelectedCountry] = React.useState<
    csc.ICountry | undefined
  >();
  const [countries, setCountries] = React.useState<Array<csc.ICountry | any>>(
    []
  );
  const [states, setStates] = React.useState<Array<csc.IState | any>>([]);


  const [trackedFormVals, setTrackedVals] = React.useState<undefined | FormValues>(undefined)


  const { patient_id } = useParams();

  const { data: patientData, isLoading: patientLoading } =
    useGetPatientDetailsQuery({
      patientId: patient_id,
    });

  const [updatePx, updatePxState] = useUpdatePatientInfoMutation();

  React.useEffect(() => {
    //set nigeria initially
    const countries = csc.Country.getAllCountries();
    setCountries(countries);
    let nigeria = countries.find((country) => country.isoCode == "NG");

    if (nigeria) setSelectedCountry(nigeria);
  }, []);

  React.useEffect(() => {
    if (selectedCountry) {
      const _states = csc.State.getStatesOfCountry(selectedCountry?.isoCode);
      setStates(_states);
    }
  }, [selectedCountry]);

  const onValueChange = (values: any) => {
    if (!values.country) return;

    if (values.country !== selectedCountry) {
      let countryObj = countries.find(
        (country) => country.name == values.country
      );
      setSelectedCountry(countryObj);
    }
  };

  const patientTypes = ["FAMILY", "NHIS", "PRIVATE", "COMPANY", "HMO"];

  let initialValues: FormValues = {
    firstName: "",
    lastName: "",
    otherNames: "",
    phoneNo: "",
    dateOfBirth: "",
    email: "",
    country: "",
    state: "",
    sex: "",
    address: "",
    patientType: '',
    hmoId: '',
    companyId: ''
  };

  if (patientData) {
    initialValues.state = patientData?.state;
    initialValues.patientType = patientData?.patientType;
    initialValues.country = patientData?.country;
    initialValues.firstName = patientData?.firstName;
    initialValues.otherNames = patientData?.otherNames;
    initialValues.lastName = patientData?.lastName;
    initialValues.email = patientData?.email;
    initialValues.sex = patientData?.sex;
    initialValues.address = patientData?.address;
    initialValues.phoneNo = patientData?.phoneNumber;
    initialValues.dateOfBirth = moment(
      `${patientData?.dateOfBirth?.day}/${patientData?.dateOfBirth?.month}/${patientData?.dateOfBirth?.year}`,
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");

    initialValues.companyId = patientData?.companyId;
    initialValues.hmoId = patientData?.hMOId;

  }

  const formValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    // otherNames: Yup.string().required('Last name is Required'),
    lastName: Yup.string().required("Last name is required"),
    sex: Yup.string().required("Sex is required"),
    phoneNo: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    dateOfBirth: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    //
    ...(trackedFormVals?.patientType == 'HMO' && { hmoId: Yup.string().required("HMO must be selected for HMO patients") }),
    ...(trackedFormVals?.patientType == 'COMPANY' && { companyId: Yup.string().required("Company must be selected for Company patients") })
  });


  const formik = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
      submitForm(values);
    },
    enableReinitialize: true
  })


  const { data: hmoData, isLoading: hmoLoading } = useGetAllHmosQuery({
    headers: {
      X_pagination_take: 20,
    }
  })

  const { data: companyData, isLoading: companyLoading } =
    useGetAllCompaniesQuery({
      headers: {
       X_pagination_take: 20,
      }
    });


  React.useEffect(() => {
    setTrackedVals(formik.values)
    if (!formik.values.country) return;

    if (formik.values.country !== selectedCountry?.name) {
      let countryObj = countries.find(
        (country) => country.name == formik.values.country
      );
      setSelectedCountry(countryObj);
    }
  }, [formik.values])





  const submitForm = async (values: FormValues) => {

    const changedVals: any = updatedDiff(initialValues, values)

    const data = {
      ...changedVals,
      ...(changedVals?.dateOfBirth && { dateOfBirth: moment(values.dateOfBirth).format("MM/DD/YYYY") }),
    };


    if (Object.keys(data).length < 1) {
      return
    }

    const res: any = await updatePx({
      body: data,
      patientId: patient_id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Patient updated successfully");
  };

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";
  const inputWrap = "flex justify-between lg:justify-start gap-4";

  const renderPatientTypeInfo = () => {
    return (
      <div className="flex flex-wrap sm:flex-nowrap gap-5 my-3">
        <div className="w-full sm:w-1/3">
          <label className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Patient Type
          </label>

          <Field as="select" name="patientType" className={inputClasses}>
            <option value="" disabled hidden>Select Type</option>
            {patientTypes.map((type) => {
              return (
                <option key={type} value={type?.toUpperCase()}>
                  {type}
                </option>
              );
            })}
          </Field>
          <ErrorMessage
            className={errorMessageClasses}
            component={"p"}
            name="patientType"
          />
        </div>
        <div className="w-full sm:w-1/3">
          <label className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Reg No
          </label>
          <input
            disabled
            value={patientData?.regNumber || ""}
            className={inputClasses}
          />
        </div>
      </div>
    );
  };

  const renderCompanyAndHmoDropdown = () => {
    return <div>
      <div className={inputWrap}>
        {formik.values.patientType == 'HMO' &&
          <div className="w-full sm:w-1/3">
            <label className={labelClasses}>HMO</label>
            <Field className={inputClasses} as="select" name="hmoId" >
              <option value="" disabled hidden>Select HMO</option>
              {hmoData?.data?.map((hmo: any) => {
                return (
                  <option key={hmo?.id} value={hmo?.id}>
                    {hmo?.name}
                  </option>
                );
              })}
            </Field>
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="hmoId"
            />
          </div>
        }
        {
          formik.values.patientType == 'COMPANY' && <div className="w-full sm:w-1/3">
            <label className={labelClasses}>Company</label>
            <Field as="select" name="companyId" className={inputClasses}>
              <option value="" disabled hidden>Select Company</option>
              {companyData?.data?.map((company: any) => {
                return (
                  <option key={company?.id} value={company?.id}>
                    {company?.name}
                  </option>
                );
              })}
            </Field>
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="companyId"
            />
          </div>
        }
      </div>
    </div>
  }


  const renderPatientGeneralInfo = () => {
    return (
      <div className="mb-3">
        <div className="flex flex-wrap sm:flex-nowrap gap-4 justify-between lg:justify-start md:gap-6">
          <div className="w-full sm:w-1/3">
            <label className={labelClasses}>First Name</label>
            <Field className={inputClasses} name="firstName" />
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="firstName"
            />
          </div>
          <div className="w-full sm:w-1/3">
            <label className={labelClasses}>Surname</label>
            <Field className={inputClasses} name="lastName" />
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="lastName"
            />
          </div>
          <div className="w-full sm:w-1/3">
            <label className={labelClasses}>Other Name</label>
            <Field className={inputClasses} name="otherNames" />
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="otherNames"
            />
          </div>
        </div>
        <div>
          <div className={inputWrap}>
            <div>
              <label className={labelClasses}>Date of Birth</label>
              <Field className={inputClasses} type="date" name="dateOfBirth" />
            </div>
            <div>
              <label className={labelClasses}>Sex</label>
              <Field as="select" name="sex" className={inputClasses}>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Field>
            </div>
          </div>
        </div>

        <div>
          <div className={`${inputWrap} flex-wrap`}>
            <div>
              <label className={labelClasses}>Email</label>
              <Field className={inputClasses} name="email" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="email"
              />
            </div>
            <div>
              <label className={labelClasses}>Phone Number</label>

              <PhoneNumberInput
                buttonClass="!rounded-l-lg"
                containerClass={'min-h-[40px] '}
                inputClass={'min-h-[40px] !rounded-lg'}
                name="phoneNo" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="phoneNo"
              />
            </div>
          </div>
        </div>
        {renderCompanyAndHmoDropdown()}
        <div>
          <div className="flex justify-between lg:justify-start gap-4">
            <div>
              <label className={labelClasses}>Country</label>
              <Field as="select" name="country" className={inputClasses}>
                {countries.map((country) => {
                  return (
                    <option key={country.name} value={country.name}>
                      {country.name}
                    </option>
                  );
                })}
              </Field>
            </div>
            <div>
              <label className={labelClasses}>State</label>
              <Field as="select" name="state" className={inputClasses}>
                {states.map((state) => {
                  return (
                    <option key={state.name} value={state.name}>
                      {state.name}
                    </option>
                  );
                })}
              </Field>
            </div>
          </div>
        </div>
        <div className={inputWrap}>
          <div>
            <label className={labelClasses}>Address</label>
            <Field as="textarea" className={inputClasses} name="address" />
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="address"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Update Patient's Detail
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        <FormikProvider
          value={formik}
        >
          <Form>
            <>
              <div className="font-semibold my-4">
                Patient Type Information
              </div>
              {renderPatientTypeInfo()}
              <div className="font-semibold my-4">
                {" "}
                Patient's General Information
              </div>
              {renderPatientGeneralInfo()}
              {updatePxState.isLoading && <Spinner />}
              <div className="flex">
                <AppButton type="submit">Update</AppButton>
                <AppButton buttontype="danger">Cancel </AppButton>
              </div>
            </>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default Page;
