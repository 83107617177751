import { updatedDiff } from "deep-object-diff";
import { Spinner } from "flowbite-react";
import { Field, FormikProvider, useFormik } from "formik";
import * as React from "react";
import { toast } from "react-toastify";
import {
  LabTestsProviderValues,
  ResultTemplateType,
} from "../../../@types/app-types";
import { LabTestContext } from "../../../context/LabTestProvider";
import { usePrintResultMutation } from "../../../store/rtk-query/labResult";
import {
  formatServerErrorMessage,
  generateAndDownloadResultPdf,
} from "../../../utils/functions";
import AppButton from "../../UI/button";
import {
  convertGroupResultArrayToFormVals,
  convertObjectToGroupResultsArray,
  setValuesOfRefRangeNdUnits,
} from "./function";

import _ from "lodash";

interface Props {
  onChangeForm: any;
}

const RFT = (props: Props) => {
  const { originalTestResult, isUpdateMode, labTestResultId } =
    React.useContext<LabTestsProviderValues>(LabTestContext);

  const [printResult, printResultState] = usePrintResultMutation();

  const renalFunctionTestResult = originalTestResult?.renalFunctionTest;

  const prefix = "SEUC-";
  const tests = [
    {
      key: "Sodium",
      label: "Sodium",
      refRange: "135 - 155",
      unit: "mmol/L",
    },
    {
      key: "Potassium",
      label: "Potassium",
      refRange: "3.5 - 5.3",
      unit: "mmol/L",
    },
    {
      key: "Chloride",
      label: "Chloride",
      refRange: "95 - 110",
      unit: "mmol/L",
    },
    {
      key: "Bicarbonate",
      label: "Bicarbonate",
      refRange: "20 - 30",
      unit: "mmol/L",
    },
    {
      key: "TotalCalcium",
      label: "Total Calcium",
      refRange: "2.2 - 2.70",
      unit: "mmol/L",
    },
    {
      key: "Creatinine",
      label: "Creatinine",
      refRange: `M: 0.9 – 1.5;
            F: 0.7 – 1.3
            `,
      unit: "mg/dL",
    },
    {
      key: "BloodUrea",
      label: "Blood urea",
      refRange: "10 - 50",
      unit: "mg/dL",
    },
    {
      key: "i-calcium(iCa)",
      label: "i-calcium (iCa)",
      refRange: "1.05 - 1.35",
      unit: "mmol/L",
    },
    {
      key: "n-calcium(nCa)",
      label: "n-calcium (nCa)",
      refRange: "1.05 - 1.35",
      unit: "mmol/L",
    },
    {
      key: "pH",
      label: "pH",
      refRange: "",
      unit: "",
    },
  ];

  const formColumns = [
    //will become {testName}_o
    "result",
    //will become {testName}_h
    "refRange",
    //
    "flag",
    "units",
  ];

  let { values: initVals, formikKeys } = React.useMemo(() => {
    let formikKeys: string[] = [];
    //loop through test
    tests.forEach((test) => {
      let testKey = test.key;

      //loop through form columns and generate formik form keys;

      formColumns.forEach((_formCol) => {
        //example is {testName}_key
        let formikKey = `${testKey}_${_formCol}`;
        formikKeys.push(formikKey);
      });
    });

    //build object from formik keys
    let values: any = {};

    let newVals = setValuesOfRefRangeNdUnits(tests, formikKeys);

    formikKeys.forEach((_key) => {
      values[_key] = "";
    });

    values = { ...values, ...newVals };

    return { values, formikKeys };
  }, []);

  //build initialValues from tests and there respective colums
  let initialValues = React.useMemo(() => {
    //build object from formik keys
    let values: any = {};

    //set old result values
    const oldServerValues = convertGroupResultArrayToFormVals(
      renalFunctionTestResult
    );

    values = {
      ...initVals,
      ...values,
      ...oldServerValues,
    };

    return values;
  }, [renalFunctionTestResult]);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  React.useEffect(() => {
    /**
     * remove this form key from final result compilation if user
     * removes all changes
     */
    const updatedObj = updatedDiff(initVals, formik.values);
    if (Object.keys(updatedObj).length == 0) {
      props.onChangeForm(undefined, "renalFunctionTest");
      return;
    }

    const resultArray = convertObjectToGroupResultsArray(
      updatedObj,
      tests,
      formik.values
    );

    if (!resultArray[0]) {
      props.onChangeForm(undefined, "renalFunctionTest");
      return;
    }
    props.onChangeForm(resultArray, "renalFunctionTest");
  }, [formik.values]);

  const onPrintResult = async () => {
    const res: any = await printResult({
      labResultId: labTestResultId,
      queryParams: {
        templateType: ResultTemplateType.RENAL_FUNCTION_TEST_TEMPLATE,
      },
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadResultPdf(res?.data, "RftTest");

    toast.success("Result pdf generated successfully");
  };

  const inputClasses =
    "bg-white border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

  return (
    <FormikProvider value={formik}>
      <div className="mb-4">
        <div className="text-[#c09853] text-lg my-2">
          {" "}
          Key: L= Low, H = High, WNL= Within Normal Limits, *= Critical value
        </div>
        <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg">
          <table className="horiz-table w-full text-sm">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                  Test Name
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Test Result
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                  Ref. Range
                </th>
                <th scope="col" className="px-6 py-3">
                  Flag
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Units
                </th>
              </tr>
            </thead>
            <tbody>
              {tests?.map((test) => {
                return (
                  <tr key={test?.key} className="border-b border-gray-200 ">
                    <td className="px-6 py-3 bg-gray-50 ">
                      {prefix}
                      {test.label}
                    </td>
                    <td className="px-6 py-3 ">
                      <Field
                        name={`${test.key}_result`}
                        className={inputClasses}
                      />
                    </td>
                    <td className="px-6 py-3 bg-gray-50  ">
                      <Field
                        as="textarea"
                        name={`${test.key}_refRange`}
                        defaultValue={test.refRange}
                        className={inputClasses}
                      />
                    </td>
                    <td className="px-6 py-3 min-w-[120px]">
                      <Field
                        name={`${test.key}_flag`}
                        className={inputClasses}
                      />
                    </td>
                    <td className="px-6 py-3 bg-gray-50 min-w-[140px] ">
                      <Field
                        name={`${test.key}_units`}
                        className={inputClasses}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {isUpdateMode && !_.isEmpty(renalFunctionTestResult) && (
        <div className="flex gap-1 mt-8">
          <AppButton
            onClick={onPrintResult}
            buttontype={"cyan"}
            title="Generate Result"
            extraclass="!text-xs"
          />
          {printResultState.isLoading && <Spinner />}
        </div>
      )}
    </FormikProvider>
  );
};

export default RFT;
