import * as React from "react";
import { VscOutput } from "react-icons/vsc";
import Spinner from "./UI/Spinner";

const FilterResultHeader = (props: any) => {
  const { title, isLoading } = props;
  return (
    <div
      className="container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div
        className="w-full my-4 font-semibold bg-emerald-100 p-4 border border-sky-200 rounded-lg shadow-md"
        style={{ display: "flex", alignItems: "center" }}
      >
        <VscOutput size="1.5rem" style={{ marginRight: "0.5rem" }} />
        {title}
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export default FilterResultHeader;
