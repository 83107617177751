import { updatedDiff } from "deep-object-diff";
import { Field, FormikProvider, useFormik } from "formik";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";

import {
  LabTestsProviderValues,
  ResultTemplateType,
} from "../../../@types/app-types";
import { LabTestContext } from "../../../context/LabTestProvider";
import { usePrintResultMutation } from "../../../store/rtk-query/labResult";
import {
  formatServerErrorMessage,
  generateAndDownloadResultPdf,
} from "../../../utils/functions";
import LabNetQuillEditor from "../../LabNetQuillEditor";
import AppButton from "../../UI/button";
import Spinner from "../../UI/Spinner";

interface Props {
  onChangeForm: any;
}
const MolecularAssay = (props: Props) => {
  const { originalTestResult, isUpdateMode, labTestResultId } =
    React.useContext<LabTestsProviderValues>(LabTestContext);

  const [printResult, printResultState] = usePrintResultMutation();

  const molecularAssayResult = originalTestResult?.molecularAssay?.[0];

  let initVals = {
    notes: "",
    //
    specimen: "",
    testDescription: "",
    result: "",
    units: "",
    log: "",
    lod: "",
  };

  let initialValues = React.useMemo(() => {
    if (isUpdateMode) {
      if (molecularAssayResult) {
        initVals["testDescription"] = molecularAssayResult?.testDescription;
        initVals["specimen"] = molecularAssayResult?.specimen;
        initVals["result"] = molecularAssayResult?.result;
        initVals["notes"] = molecularAssayResult?.notes;
        initVals["units"] = molecularAssayResult?.units;
        initVals["log"] = molecularAssayResult?.log;
        initVals["lod"] = molecularAssayResult?.lod;
      }
    }

    return initVals;
  }, [isUpdateMode, originalTestResult]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  React.useEffect(() => {
    /**
     * remove this form key from final result compilation if user
     * removes all changes in this form file
     */
    const updatedObj = updatedDiff(initVals, formik.values);
    if (Object.keys(updatedObj).length == 0) {
      props.onChangeForm(undefined, "MolecularAssayResult");
      return;
    }

    props.onChangeForm(
      {
        ...formik.values,
        makeResultAvailable: true,
      },
      "MolecularAssayResult"
    );
  }, [formik.values]);

  const onPrintResult = async () => {
    const res: any = await printResult({
      labResultId: labTestResultId,
      queryParams: {
        templateType: ResultTemplateType.MCS_TEST_TEMPLATE,
      },
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadResultPdf(res?.data, "MolecularAssay");

    toast.success("Result pdf generated successfully");
  };

  const inputClasses =
    "bg-gray-50 border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm  block w-full md:w-full lg:w-full xl:w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

  return (
    <FormikProvider value={formik}>
      {isUpdateMode && molecularAssayResult && (
        <div className="text-[#c09853] text-lg mb-5">
          Updated on{" "}
          {moment(molecularAssayResult?.updatedAt).format("DD ddd MMM[,] YYYY")}
        </div>
      )}
      <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg mb-4">
        <table className="horiz-table w-full text-sm ">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="px-6 py-3 bg-gray-50 ">
                Specimen
              </th>
              <th scope="col" className="px-6 py-3 min-w-[300px]">
                Test Description
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50  min-w-[130px]">
                Result(copies/ml)
              </th>
              <th scope="col" className="px-6 py-3 ">
                Units(IU/ml)
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[140px] ">
                Log
              </th>
              <th scope="col" className="px-6 py-3 min-w-[140px] ">
                LOD (IU/ml)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="col" className="px-6 py-3 bg-gray-50 ">
                <Field name="specimen" className={inputClasses} />
              </td>
              <td scope="col" className="px-6 py-3 min-w-[300px]">
                <Field name="testDescription" className={inputClasses} />
              </td>
              <td scope="col" className="px-6 py-3 bg-gray-50  min-w-[130px]">
                <Field name="result" className={inputClasses} />
              </td>
              <td scope="col" className="px-6 py-3 ">
                <Field name="units" className={inputClasses} />
              </td>
              <td scope="col" className="px-6 py-3 bg-gray-50 ">
                <Field name="log" className={inputClasses} />
              </td>
              <td scope="col" className="px-6 py-3">
                <Field name="lod" className={inputClasses} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex gap-3 pb-[80px]">
        <label className={labelClasses}>Notes:</label>
        <LabNetQuillEditor
          theme="snow"
          value={formik.values.notes}
          onChange={(val: any) => formik.setFieldValue("notes", val)}
        />
      </div>
      {isUpdateMode && molecularAssayResult && (
        <div className="flex gap-1 mt-8">
          <AppButton
            onClick={onPrintResult}
            buttontype={"cyan"}
            title="Generate Result"
            extraclass="!text-xs"
          />
          {printResultState.isLoading && <Spinner />}
        </div>
      )}
    </FormikProvider>
  );
};

export default MolecularAssay;
