import { userApi } from "./userApi";

export const inventoryApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyInfo: builder.query({
      query: ({ companyId }) => {
        return {
          url: "company/info/" + companyId,
          method: "GET",
        };
      },
    }),
    getAllCompanies: builder.query({
      query: ({ headers, params }) => {
        return {
          url: "company/get-all-companies",
          method: "GET",
          headers: { ...headers },
          params: { ...params },
        };
      },
      providesTags: ["Company"],
    }),
    addNewCompany: builder.mutation({
      query: (body) => {
        return {
          url: "company/create-new-company",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Company"],
    }),
    updateCompany: builder.mutation({
      query: ({ body, companyId }) => {
        return {
          url: "company/edit/" + companyId,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Company"],
    }),
    deleteCompany: builder.mutation({
      query: ({ companyId }) => {
        return {
          url: "company/delete/" + companyId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Company"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompanyInfoQuery,
  useGetAllCompaniesQuery,
  useAddNewCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = inventoryApi;
