import * as React from "react";
import { useDispatch } from "react-redux";
import { Link, redirect } from "react-router-dom";
import { AppContextProviderValues } from "../@types/app-types";
import AvatarDefault from "../assets/images/avatarDefault.jpg";
import { AppContext } from "../context/AppContext";
import { logout } from "../store/reducers/userSlice";
import { useGetUserProfileQuery } from "../store/rtk-query/userApi";

const Header = () => {
  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = React.useState(false);

  const { data: profileData } = useGetUserProfileQuery({})

  const {
    hideSideBar, setHideSideBar
  } = React.useContext<AppContextProviderValues>(AppContext)


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };


  const onLogout = () => {
    dispatch(logout());
    redirect("/signin");
  };

  const joinClassNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <header className={
      joinClassNames(
        "w-screen fixed z-40 ml-16 md:ml-40",
        hideSideBar ? '!ml-16' : '!ml-40'
      )
    }>
      <nav className="border-gray-200 px-4 lg:px-6 py-2.5 bg-gray-800">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <div className=" items-center hidden sm:flex ">
            <div className="text-white text-xs sm:text-base md:text-md font-serif text-center font-semibold">
              Laboratory Information Management System
            </div>
          </div>

          <div className="relative ml-[auto]">
            <button
              onClick={toggleDropdown}
              className="flex items-center focus:outline-none px-4"
            >
              {/* <img
                src={AvatarDefault}
                alt="Profile Avatar"
                className="w-8 h-8 rounded-full"
              /> */}
              <span className="text-white text-sm ml-2">
                {profileData?.appUserData?.firstName} {profileData?.appUserData?.lastName}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-white ml-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path fillRule="evenodd" d="M10 13l5-5H5z" />
              </svg>
            </button>
            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                <Link
                  to="/update-profile"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Edit Profile
                </Link>
                <div
                  onClick={onLogout}
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
