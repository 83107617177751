import * as React from "react";

import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
//
import FBC from "./group/FBC";
import LFT from "./group/LFT";
import HormonalProfile from "./group/HormonalProfile";
import RFT from "./group/RFT";
import LipidProfile from "./group/LipidProfile";
import ClottingProfile from "./group/ClottingProfile";
import ThyroidFunction from "./group/ThyroidFunction";
import TumorMarkers from "./group/TumorMarkers";

interface Props {
  onChangeForm: any;
}

const GroupTests = (props: Props) => {
  const accordionData = [
    {
      label: "Full Blood Count",
      component: <FBC onChangeForm={props.onChangeForm} />,
    },
    {
      label: "Liver Function Test",
      component: <LFT onChangeForm={props.onChangeForm} />,
    },
    {
      label: "RFT",
      component: <RFT onChangeForm={props.onChangeForm} />,
    },
    {
      label: "Lipid Profile",
      component: <LipidProfile onChangeForm={props.onChangeForm} />,
    },
    {
      label: "Hormonal Profile",
      component: <HormonalProfile onChangeForm={props.onChangeForm} />,
    },
    {
      label: "Clotting Profile",
      component: <ClottingProfile onChangeForm={props.onChangeForm} />,
    },
    {
      label: "Thyroid Function Test",
      component: <ThyroidFunction onChangeForm={props.onChangeForm} />,
    },
    {
      label: "Tumor Markers",
      component: <TumorMarkers onChangeForm={props.onChangeForm} />,
    },
  ];

  return (
    <div>
      <div className="text-2xl font-semibold mb-2"> Group Tests</div>
      {accordionData?.map((data) => {
        return (
          <Disclosure key={data.label}>
            {({ open }) => (
              <>
                <Disclosure.Button className="mb-3 h-[50px] flex items-center w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span className="text-lg">{data?.label}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-gray-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel
                  unmount={false}
                  className="px-4 pt-4 pb-2 text-sm text-gray-500 "
                >
                  {data?.component}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        );
      })}
    </div>
  );
};

export default GroupTests;
