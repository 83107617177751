import { updatedDiff } from 'deep-object-diff';
import { Field, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { toast } from 'react-toastify';
import { LabTestsProviderValues, ResultTemplateType } from '../../../@types/app-types';
import { LabTestContext } from '../../../context/LabTestProvider';
import { usePrintResultMutation } from '../../../store/rtk-query/labResult';
import { formatServerErrorMessage, generateAndDownloadResultPdf } from '../../../utils/functions';
import AppButton from '../../UI/button';
import Spinner from '../../UI/Spinner';
import { convertGroupResultArrayToFormVals, convertObjectToGroupResultsArray, setValuesOfRefRangeNdUnits } from './function';

import _ from 'lodash'

interface Props {
    onChangeForm: any
}

const LFT = (props: Props) => {
    const {
        originalTestResult,
        isUpdateMode,
        labTestResultId
    } = React.useContext<LabTestsProviderValues>(LabTestContext)

    const [printResult, printResultState] = usePrintResultMutation()

    const lftResult = originalTestResult?.liverFunctionTest

    const prefix = 'LFT-'
    const tests = [
        {
            key: 'AST',
            label: 'AST',
            refRange: '<37',
            unit: 'U/L'
        },
        {
            key: 'ALT',
            label: 'ALT',
            refRange: '<41',
            unit: 'U/L'
        },
        {
            key: 'ALP',
            label: 'ALP',
            refRange: '<258',
            unit: 'U/L'
        },
        {
            key: 'Albumin',
            label: 'Albumin',
            refRange: '3.5 - 5.2',
            unit: 'g/dL'
        },
        {
            key: 'Bilirubin_T',
            label: 'Bilirubin(T)',
            refRange: '0.2 - 1.0',
            unit: 'mg/dl'
        },
        {
            key: 'Bilirubin_D',
            label: 'Bilirubin(D)',
            refRange: '0.0 – 0.3',
            unit: 'mg/dL'
        },
        {
            key: 'TotalProtein',
            label: 'Total Protein',
            refRange: '6.3 – 8.3',
            unit: 'g/dL'
        },
        {
            key: 'Albumin',
            label: 'Albumin',
            refRange: '',
            unit: 'g/dl'
        },
    ]

    const formColumns = [
        //will become {testName}_o
        'result',
        //will become {testName}_h
        'refRange',
        //
        'flag',
        'units',
    ]

    let { values: initVals, formikKeys } = React.useMemo(() => {
        let formikKeys: string[] = []
        //loop through test
        tests.forEach((test) => {
            let testKey = test.key;

            //loop through form columns and generate formik form keys;

            formColumns.forEach((_formCol) => {
                //example is {testName}_key
                let formikKey = `${testKey}_${_formCol}`
                formikKeys.push(formikKey)
            })
        })

        //build object from formik keys
        let values: any = {}

        let newVals = setValuesOfRefRangeNdUnits(tests, formikKeys)

        formikKeys.forEach((_key) => {
            values[_key] = '';
        })

        values = { ...values, ...newVals }


        return { values, formikKeys }
    }, [])

    //build initialValues from tests and there respective colums
    let initialValues = React.useMemo(() => {
        //build object from formik keys
        let values: any = {}


        //set old result values
        const oldServerValues = convertGroupResultArrayToFormVals(lftResult)

        values = {
            ...initVals,
            ...values,
            ...oldServerValues
        }

        return values
    }, [lftResult])

    const formik = useFormik({
        initialValues: {
            ...initialValues
        },
        onSubmit: values => {

        },
        enableReinitialize: true
    });

    React.useEffect(() => {
        /**
* remove this form key from final result compilation if user
* removes all changes
*/
        const updatedObj = updatedDiff(initVals, formik.values)
        if (Object.keys(updatedObj).length == 0) {
            props.onChangeForm(undefined, 'liverFunctionTestResult')
            return
        }

        const resultArray = convertObjectToGroupResultsArray(updatedObj, tests, formik.values)

        if (!resultArray[0]) {
            props.onChangeForm(undefined, 'liverFunctionTestResult')
            return
        }
        props.onChangeForm(resultArray, 'liverFunctionTestResult')
    }, [formik.values])

    const onPrintResult = async () => {
        const res: any = await printResult({
            labResultId: labTestResultId,
            queryParams: {
                templateType: ResultTemplateType.LIVER_FUNCTION_TEST_TEMPLATE
            }
        });

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }

        generateAndDownloadResultPdf(res?.data, 'LFTTest')

        toast.success("Result pdf generated successfully");
    };


    const inputClasses =
        "bg-white border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;


    return (
        <FormikProvider
            value={formik}
        >
            <div className='mb-4'>
                <div className='text-[#c09853] text-lg my-2'> Key: L= Low, H = High, WNL= Within Normal Limits, *= Critical value
                </div>
                <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg">
                    <table className="horiz-table w-full text-sm">
                        <thead className="text-xs text-gray-700 uppercase">
                            <tr>
                                <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                                    Test Name
                                </th>
                                <th scope="col" className="px-6 py-3 ">
                                    Test Result
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                                    Ref. Range
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Flag
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                                    Units
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {tests?.map((test) => {
                                return (
                                    <tr key={test?.key} className="border-b border-gray-200 ">
                                        <td className="px-6 py-3 bg-gray-50 ">
                                            {prefix}{test.label}
                                        </td>
                                        <td className="px-6 py-3 ">
                                            <Field name={`${test.key}_result`} className={inputClasses} />
                                        </td>
                                        <td className="px-6 py-3 bg-gray-50  ">
                                            <textarea
                                                name={`${test.key}_refRange`}
                                                defaultValue={test.refRange}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className={inputClasses}
                                            />
                                        </td>
                                        <td className="px-6 py-3 min-w-[120px]">
                                            <Field
                                                name={`${test.key}_flag`}
                                                className={inputClasses}
                                            />
                                        </td>
                                        <td className="px-6 py-3 bg-gray-50 min-w-[140px] ">
                                            <Field
                                                name={`${test.key}_units`}
                                                className={inputClasses}
                                            />
                                        </td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                isUpdateMode && !_.isEmpty(lftResult) && <div className='flex gap-1 mt-8'>
                    <AppButton
                        onClick={onPrintResult}
                        buttontype={'cyan'}
                        title='Generate Result'
                        extraclass='!text-xs'
                    />
                    {printResultState.isLoading && <Spinner />}
                </div>
            }
        </FormikProvider>
    )
}

export default LFT