import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "./customBaseQuery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Tests",
    "Inventory",
    "Birthdays",
    "Company",
    "HMO",
    "DocOrLabSci",
    "TestsOnQueue",
    "Profile",
    "Appointment",
    "Sale",
    "Purchase",
    "Debts",
    "PurchaseType",
    "Staff",
    "Invoice",
  ],
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => {
        return {
          url: "auth/me",
          method: "GET",
        };
      },
      providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (body) => {
        return {
          url: "user/update/info",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Profile", "Staff"],
    }),
    login: builder.mutation({
      query: (body) => {
        return {
          url: "auth/signin",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Profile"],
    }),
    changePassword: builder.mutation({
      query: (body) => {
        return {
          url: "user/update-password",
          method: "PUT",
          body,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (body) => {
        return {
          url: "auth/reset-password",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useGetUserProfileQuery,
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useResetPasswordMutation,
} = userApi;
