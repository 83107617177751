import * as React from "react";
import AppButton from "../../components/UI/button";

import {
  useFormikContext,
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  useFormik,
  FormikProvider,
} from "formik";
import * as Yup from "yup";
import {
  useAddNextVisitMutation,
  useGetPatientDetailsQuery,
  useGetPatientsQuery,
  useSearchPatientsQuery,
} from "../../store/rtk-query/patientApi";
import { MdNavigateNext, MdCancel } from "react-icons/md";

import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { BsInfoSquareFill } from "react-icons/bs";

import { Dropdown } from "flowbite-react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import Spinner from "../../components/UI/Spinner";
import moment from "moment";
import { useGetAllLabCategoriesQuery } from "../../store/rtk-query/labApi";
import PatientSearchBar from "../../components/PatientSearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import { formatServerErrorMessage } from "../../utils/functions";
import FilterHeader from "../../components/filterHeader";

interface FormValues {
  dateOfLabTest: string;
  clinicalDetails: string;
  labTestType: string;
  vitalSigns: string;
}

interface PatientSchema {
  id: number;
  firstName: string;
  lastName: string;
  otherNames: string;
  regNumber: string;
  age: string;
  sex: string;
  qrCodeUrl: string;
}

const initialValues: FormValues = {
  dateOfLabTest: "",
  clinicalDetails: "",
  labTestType: "",
  vitalSigns: "",
};

const formValidationSchema = Yup.object().shape({
  dateOfLabTest: Yup.string().required("Required"),
  // clinicalDetails: Yup.string().required("Required"),
  labTestType: Yup.string().required("Required"),
  // vitalSigns: Yup.string().required("Required"),
});

const Page = () => {
  const [search, setSearch] = React.useState("");
  const [selectedPx, setSelectedPx] = React.useState<
    PatientSchema | undefined
  >();
  // const [showDropdown, setShowDropdown] = React.useState<boolean>();

  const [addNextVisit, addNextVisitState] = useAddNextVisitMutation();

  const { data: labData } = useGetAllLabCategoriesQuery({});

  const navigate = useNavigate();

  const location = useLocation();
  const patientId = location?.state?.patientId;

  const { data: patientsData, isLoading } = useSearchPatientsQuery({
    searchPhrase: search ? search : "",
  });

  const { data: patientData } = useGetPatientDetailsQuery(
    {
      patientId,
    },
    {
      skip: !patientId,
    }
  );

  React.useEffect(() => {
    setSelectedPx(patientData);
  }, [patientData]);

  // React.useEffect(() => {
  //dont show if search is empty
  // if (search?.length == 0) return;
  //dont show if there are no results
  // if (patientsData?.data?.length < 1) return;

  // if (!showDropdown) {
  //   setShowDropdown(true);
  // }
  // }, [patientsData?.data?.length]);

  const dropdownRef = React.useRef();

  let testTypes = [
    "microbiology",
    "chemistry",
    "special stain",
    "conventional pap test",
    "liquid based pap test",
    "non gynae",
    "clinical",
    "ultrasound",
  ];

  if (labData) {
    testTypes = Object.keys(labData).map((key) => key);
  }

  const onSubmit = async (values: typeof initialValues) => {
    const data = {
      laboratoryTestTypes: values.labTestType,
      clinicalDetails: values.clinicalDetails,
      vitalSigns: values.vitalSigns,
      dateOfLabTest: moment(values.dateOfLabTest).format("MM/DD/YYYY"),
    };

    if (!selectedPx) {
      toast.error("Please select a patient before proceeding", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const res: any = await addNextVisit({
      body: data,
      patientId: selectedPx?.id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)


      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Next visit added successfully");

    const visitId = res?.data?.patientVisit?.id;
    const patientId = res?.data?.patient?.id;

    navigate("/add-to-queue/" + patientId, { state: { visitId } });
  };

  const buttonStyle =
    "flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600";

  const inputClasses =
    "bg-gray-50 border border-blue-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  const formik = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
      onSubmit(values);
    },
  });

  const renderForm = () => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <>
            <div className="w-10/12 mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
              <div
                className="container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className="w-full font-semibold bg-sky-200 p-4 border border-sky-200 rounded-lg shadow-md"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <BsInfoSquareFill
                    size="1.5rem"
                    style={{ marginRight: "0.5rem" }}
                  />
                  Patient Visit Information
                </div>
              </div>
              <div>
                <div className="flex justify-evenly">
                  <div className="w-full sm:w-1/3">
                    <label className={labelClasses}>Lab Test Types</label>
                    <Field
                      as="select"
                      name="labTestType"
                      className={inputClasses}
                    >
                      <option value="" disabled hidden>
                        Select LabTest Type
                      </option>

                      {testTypes.map((type) => {
                        return (
                          <option key={type} value={type?.toUpperCase()}>
                            {type?.toUpperCase()}
                          </option>
                        );
                      })}
                    </Field>

                    <ErrorMessage
                      className={errorMessageClasses}
                      component={"p"}
                      name="labTestType"
                    />
                  </div>
                  <div className="w-full sm:w-1/3">
                    <label className={labelClasses}>Clinical Details</label>
                    <Field
                      as="textarea"
                      className={inputClasses}
                      name="clinicalDetails"
                    />
                    <ErrorMessage
                      className={errorMessageClasses}
                      component={"p"}
                      name="clinicalDetails"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <div className="flex justify-evenly">
                  <div className="w-full sm:w-1/3">
                    <label className={labelClasses}>Vital Signs</label>
                    <Field
                      as="textarea"
                      className={inputClasses}
                      name="vitalSigns"
                    />
                    <ErrorMessage
                      className={errorMessageClasses}
                      component={"p"}
                      name="vitalSigns"
                    />
                  </div>
                  <div className="w-full sm:w-1/3">
                    <label className={labelClasses}>
                      Treatment/Lab Test date
                    </label>
                    <Field
                      className={inputClasses}
                      type="date"
                      name="dateOfLabTest"
                    />
                    <ErrorMessage
                      className={errorMessageClasses}
                      component={"p"}
                      name="dateOfLabTest"
                    />
                  </div>
                </div>
              </div>
              {addNextVisitState.isLoading && <Spinner />}
              <div className="flex items-center justify-center">
                <button
                  onClick={() => {
                    formik.resetForm();
                  }}
                  type="button"
                  className="flex flex-row items-center  justify-center focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-lg px-8 py-1 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                >
                  <MdCancel fill="white" fontSize={22} />
                  Cancel{" "}
                </button>
                <button
                  type="submit"
                  className="flex flex-row items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-8 py-1 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Next <MdNavigateNext fontSize={24} />
                </button>
              </div>
            </div>
          </>
        </Form>
      </FormikProvider>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Add Patient Next Visit
        </h3>
      </div>
      <div className="w-10/12 mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6 bg-slate-50">
       
       <FilterHeader title="Filter Patient" />
        <div className="w-full mx-auto">
          <PatientSearchBar
            onSelectPx={setSelectedPx}
            extraclass="w-6/12 flex justify-evenly"
          />
        </div>
        {selectedPx ? (
          <>
            <div className="w-10/12 mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
              <div
                className="container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className="w-full font-semibold bg-sky-200 p-4 border border-sky-200 rounded-lg shadow-md"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IoCheckmarkDoneCircle
                    size="1.5rem"
                    style={{ marginRight: "0.5rem" }}
                  />
                  Selected Patient
                </div>
              </div>
              <div className="flex justify-between">
                {/* First column: patient name */}
                <div className="mr-8">
                  <label className="block text-gray-600 text-sm font-bold mb-2">
                    Name
                  </label>
                  <div className="text-md">
                    {/* Show selected patient's name */}
                    {selectedPx?.firstName} {selectedPx?.lastName}{" "}
                    {selectedPx?.otherNames}
                  </div>
                </div>
                <div className="mr-8">
                  <label className="block text-gray-600 text-sm font-bold mb-2">
                    Age
                  </label>
                  <div className="text-md">
                    {/* Show selected patient's name */}
                    {selectedPx?.age}{" "}
                  </div>
                </div>
                <div className="mr-8">
                  <label className="block text-gray-600 text-sm font-bold mb-2">
                    Sex
                  </label>
                  <div className="text-md">
                    {/* Show selected patient's name */}
                    {selectedPx?.sex}{" "}
                  </div>
                </div>
                {/* Second column: patient registration number */}
                <div>
                  <label className="block text-gray-600 text-sm font-bold mb-2">
                    Reg No
                  </label>
                  <div className="text-md">
                    {/* Show selected patient's registration number */}
                    {selectedPx?.regNumber}
                  </div>
                </div>
                <div>
                  <label className="block text-gray-600 text-sm font-bold mb-2">
                    Qr Code
                  </label>
                  <div className="text-md">
                    <img src={selectedPx?.qrCodeUrl} alt="QR code" />

                  </div>
                </div>
              </div>
            </div>
          </>
        ) : undefined}
        {renderForm()}
      </div>
    </div>
  );
};

export default Page;
