import { userApi } from "./userApi";

export const staffApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStaff: builder.query({
      query: ({ params }) => {
        return {
          url: "user/all-staff",
          method: "GET",
          params: { ...params },
        };
      },
      providesTags: ["Staff"],
    }),
    getStaffInfo: builder.query({
      query: ({ uid }) => {
        return {
          url: "user/info/" + uid,
          method: "GET",
        };
      },
    }),
    addNewStaff: builder.mutation({
      query: (body) => {
        return {
          url: "user/setup-user",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Staff"],
    }),
    updateUser: builder.mutation({
      query: ({ body, staffId }) => {
        return {
          url: "user/update/staff/info/" + staffId,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Staff"],
    }),
    deleteStaff: builder.mutation({
      query: ({ staffId }) => {
        return {
          url: "user/delete/" + staffId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Staff"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllStaffQuery,
  useGetStaffInfoQuery,
  useAddNewStaffMutation,
  useUpdateUserMutation,
  useDeleteStaffMutation,
} = staffApi;
