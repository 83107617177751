import React from "react";
import {
  TestTableComponentProps,
  TestTableColumnItem,
} from "../@types/app-types";

const TestTable = (props: TestTableComponentProps) => {
  const { columns, data, extraStyles, extraRowClasses } = props;

  const createRows = (columns: TestTableColumnItem[], data: any[]) => {
    return data.map((item, index) => (
      <tr
        className={"border-b border-[#D9D9D9] font-dm-sans " + extraRowClasses}
        key={index}
      >
        {columns.map((column, columnIndex) => (
          <td key={columnIndex}>{item[column.key]}</td>
        ))}
      </tr>
    ));
  };

  return (
    <table className=" w-full" style={extraStyles}>
      <thead className="border-b border-[#D9D9D9] font-dm-sans ">
        <tr>
          {columns.map((column, index) => (
            <th
              key={index}
              className={`pr-5 text-left text-[18px] font-[600] ${column.customColumnClass}`}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{createRows(columns, data)}</tbody>
    </table>
  );
};

export default TestTable;
