import { updatedDiff } from 'deep-object-diff';
import { Field, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import * as React from 'react';

import { GynaecologicCytologyResultInterface, LabTestsProviderValues, ResultTemplateType } from '../../@types/app-types';

import _, { debounce } from 'lodash'
import { LabTestContext } from '../../context/LabTestProvider';
import AppButton from '../UI/button';
import LabNetQuillEditor from '../LabNetQuillEditor';
import { formatServerErrorMessage, generateAndDownloadResultPdf, objectIsNotEmpty } from '../../utils/functions';
import { usePrintResultMutation } from '../../store/rtk-query/labResult';
import { toast } from 'react-toastify';
import Spinner from '../UI/Spinner';

interface Props {
    onChangeForm: any
}

interface FormValues {
    "specimenAdequacy": string[];
    "otherFindings": string[];
    "recommendatation": {
        routineRecall: 'YES' | 'NO';
        repeatSmear: {
            whenToRepeatSmear: '';
            inHowManyMonths: number;
        };
        ensuringEndocervicalSampling: 'YES' | 'NO';
        colposcopyAndBiospy: 'YES' | 'NO';
        highRiskHPVTesting: 'YES' | 'NO';
    };
    "diagnosis": {
        NILM: 'YES' | 'NO';
        epithelialCellAbnormalities: 'YES' | 'NO';
        squamousCell: string[];
        glandularCell: string[];
    },
    //
    clinicalComment: string,
    comments: string,
    clinician: string,
    //
    "specimenSource": string,
    "LMP": string,
    "priorPapSmearDate": string,
    "priorPapSmearDiagnosis": string,
    "collectedMethod": string,
    "slideNumber": string,
    "transformationZone": string,
    "specimenInadequacyReason": string,
    "consultantPathologist": string,
}


const GynaeCytology = (props: Props) => {

    const [otherDiagnosis, setOtherDiagnosis] = React.useState<string>('')
    const [otherFindingsOutlier, setOtherFindingsOutlier] = React.useState<{ [key: string]: string }>({
        1: '',
        2: '',
        3: ''
    })

    const [pendingUpdatesCount, setPendingUpdateCount] = React.useState<number>(0)
    const pendingUpdatesRef = React.useRef<Array<{ key: any; value: any }>>([]);


    const {
        originalTestResult,
        isUpdateMode,
        labTestResultId
    } = React.useContext<LabTestsProviderValues>(LabTestContext)

    const [printResult, printResultState] = usePrintResultMutation()

    const gynaeResult = originalTestResult?.gynaecologicCytology

    const updateOutlier = (key: string, value: string) => {
        // setOtherFindingsOutlier(newObj)
        pendingUpdatesRef.current = [...pendingUpdatesRef.current, { key, value }];
        setPendingUpdateCount((prev: number) => prev + 1)
    }


    const callPendingUpdates = React.useCallback(async () => {

        const newObj = { ...otherFindingsOutlier };

        pendingUpdatesRef.current.forEach((update) => {
            const { key, value } = update;
            newObj[key] = value;
        });

        pendingUpdatesRef.current = [];

        setOtherFindingsOutlier(newObj)

        //reset count
        setPendingUpdateCount(0)
    }, []);

    const onPrintResult = async () => {
        const res: any = await printResult({
            labResultId: labTestResultId,
            queryParams: {
                templateType: ResultTemplateType.GYNAECOLOGY_TEST_TEMPLATE
            }
        });

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }

        generateAndDownloadResultPdf(res?.data, 'GynaeTest')

        toast.success("Result pdf generated successfully");
    };

    React.useEffect(() => {
        if (pendingUpdatesRef.current.length > 0) {
            const debouncedCallPendingUpdates = debounce(callPendingUpdates, 2000);
            debouncedCallPendingUpdates();
        }
    }, [pendingUpdatesCount]);

    let initVals: FormValues = {
        clinicalComment: '',
        comments: '',
        clinician: '',
        //
        "specimenSource": "",
        "LMP": "",
        "priorPapSmearDate": "",
        "priorPapSmearDiagnosis": "",
        "collectedMethod": "",
        "slideNumber": "",
        "transformationZone": "",
        "specimenAdequacy": [''],
        "specimenInadequacyReason": '',
        "otherFindings": [],
        "recommendatation": {
            routineRecall: 'NO',
            repeatSmear: {
                whenToRepeatSmear: '',
                inHowManyMonths: 0,
            },
            ensuringEndocervicalSampling: 'NO',
            colposcopyAndBiospy: 'NO',
            highRiskHPVTesting: 'NO',
        },
        "diagnosis": {
            "NILM": "NO",
            "epithelialCellAbnormalities": "NO",
            "squamousCell": [],
            "glandularCell": []
        },
        "consultantPathologist": 'Dr. Izu Achusi',
    }

    let initialValues = React.useMemo(() => {
        if (isUpdateMode) {

            if (gynaeResult && gynaeResult?.updatedAt) {
                initVals['clinicalComment'] = gynaeResult?.clinicalComment || ''
                initVals['clinician'] = gynaeResult?.clinician || ''
                initVals['slideNumber'] = gynaeResult?.slideNumber || ''
                initVals['collectedMethod'] = gynaeResult?.collectedMethod || ''
                initVals['comments'] = gynaeResult?.comments || ''
                initVals['consultantPathologist'] = gynaeResult?.consultantPathologist || ''

                //remove others_ identifier
                if (gynaeResult?.otherFindings && Array.isArray(gynaeResult?.otherFindings)) {
                    initVals['otherFindings'] = [...gynaeResult?.otherFindings].filter((finding: string) => {
                        if (finding?.includes('other1') || finding?.includes('other2') || finding?.includes('other3')) {
                            return false
                        }
                        return true
                    })
                }

                //update outliers
                if (gynaeResult?.otherFindings?.some((val: any) => val?.includes('other1'))) {
                    let value = gynaeResult?.otherFindings?.find((val: any) => val?.includes('other1'))
                    value = value?.replace('other1_', '')
                    if (value && value?.trim() !== '') {
                        updateOutlier('1', value)
                    }
                }
                if (gynaeResult?.otherFindings?.some((val: any) => val?.includes('other1'))) {
                    let value = gynaeResult?.otherFindings?.find((val: any) => val?.includes('other2'))
                    value = value?.replace('other2_', '')
                    console.log('value ', value)
                    if (value && value?.trim() !== '') {
                        updateOutlier('2', value)
                    }
                }
                if (gynaeResult?.otherFindings?.some((val: any) => val?.includes('other1'))) {
                    let value = gynaeResult?.otherFindings?.find((val: any) => val?.includes('other3'))
                    value = value?.replace('other3_', '')
                    if (value && value?.trim() !== '') {
                        updateOutlier('3', value)
                    }
                }

                if (gynaeResult?.priorPapSmearDate) {
                    initVals['priorPapSmearDate'] = moment(gynaeResult?.priorPapSmearDate).format('YYYY-MM-DD')
                }
                initVals['priorPapSmearDiagnosis'] = gynaeResult?.priorPapSmearDiagnosis || ''
                initVals['specimenAdequacy'] = gynaeResult?.specimenAdequacy || ''

                if (gynaeResult?.specimenAdequacy?.[1]) {
                    initVals['specimenInadequacyReason'] = gynaeResult?.specimenAdequacy?.[1]
                }

                initVals['specimenSource'] = gynaeResult?.specimenSource || ''
                initVals['transformationZone'] = gynaeResult?.transformationZone || ''
                initVals['LMP'] = gynaeResult?.LMP || ''

                //diagnosis
                initVals['diagnosis']['NILM'] = gynaeResult?.gynaecologicCytologyDiagnosis?.NILM || 'NO'
                initVals['diagnosis']['epithelialCellAbnormalities'] = gynaeResult?.gynaecologicCytologyDiagnosis?.epithelialCellAbnormalities || 'NO'

                if (gynaeResult?.gynaecologicCytologyDiagnosis?.squamousCell && Array.isArray(gynaeResult?.gynaecologicCytologyDiagnosis?.squamousCell)) {
                    initVals['diagnosis']['squamousCell'] = [...gynaeResult?.gynaecologicCytologyDiagnosis?.squamousCell].filter((diagnosis: string) => {
                        if (diagnosis?.includes('otherdiagnosis_')) {
                            return false
                        }
                        return true
                    })
                }

                if (gynaeResult?.gynaecologicCytologyDiagnosis?.squamousCell?.some((val: any) => val?.includes('otherdiagnosis_'))) {
                    let value = gynaeResult?.gynaecologicCytologyDiagnosis?.squamousCell?.find((val: any) => val?.includes('otherdiagnosis_'))
                    value = value?.replace('otherdiagnosis_', '')
                    setOtherDiagnosis(value)
                }

                initVals['diagnosis']['glandularCell'] = gynaeResult?.gynaecologicCytologyDiagnosis?.glandularCell || ''


                //recommendation
                initVals['recommendatation']['colposcopyAndBiospy'] = gynaeResult?.gynaecologicCytologyRecommendatation?.colposcopyAndBiospy || 'NO'
                initVals['recommendatation']['ensuringEndocervicalSampling'] = gynaeResult?.gynaecologicCytologyRecommendatation?.ensuringEndocervicalSampling || 'NO'
                initVals['recommendatation']['routineRecall'] = gynaeResult?.gynaecologicCytologyRecommendatation?.routineRecall || 'NO'
                initVals['recommendatation']['highRiskHPVTesting'] = gynaeResult?.gynaecologicCytologyRecommendatation?.highRiskHPVTesting || 'NO'
                initVals['recommendatation']['repeatSmear']['inHowManyMonths'] = gynaeResult?.gynaecologicCytologyRecommendatation?.repeatSmear?.inHowManyMonths || 0
                initVals['recommendatation']['repeatSmear']['whenToRepeatSmear'] = gynaeResult?.gynaecologicCytologyRecommendatation?.repeatSmear?.whenToRepeatSmear || 'IMMEDIATELY'

            }
        }

        return initVals
    }, [isUpdateMode, originalTestResult])


    const formik = useFormik({
        initialValues,
        onSubmit: values => {

        },
        enableReinitialize: true
    });


    React.useEffect(() => {
        /**
        * remove this form key from final result compilation if user
        * removes all changes
        */
        const updatedObj = updatedDiff(initVals, formik.values)

        if (Object.keys(updatedObj).length == 0) {
            props.onChangeForm(undefined, 'gynaecologicCytology')
            return
        }


        const dataToSubmit = convertFormikValsToGynaeResult(formik.values)
        props.onChangeForm(dataToSubmit, 'gynaecologicCytology')
    }, [formik.values])

    const convertFormikValsToGynaeResult = (values: typeof initialValues): GynaecologicCytologyResultInterface => {
        let result: any = {
            specimenSource: values.specimenSource,
            clinicalComment: values.clinicalComment,
            clinician: values.clinician,
            LMP: values.LMP,
            priorPapSmearDate: moment(values.priorPapSmearDate).format("MM/DD/YYYY"),
            priorPapSmearDiagnosis: values.priorPapSmearDiagnosis,
            collectedMethod: values.collectedMethod,
            slideNumber: values.slideNumber,
            // specimenAdequacy: values.specimenAdequacy,
            transformationZone: values.transformationZone,

            comments: values.comments,
            // recommendatation: values.recommendatation,
            consultantPathologist: values.consultantPathologist,
            // gynaecologicCytologyRecommendatationId: 0,
            //
            // specimenInadequacyReason:values.specimenInadequacyReason
        }

        //add diagnosis
        let diagnosis: any = {
            ...values.diagnosis,
        }

        //add recommendatation
        let recommendatation: any = {
            ...values.recommendatation
        };


        //add adequacy
        let specimenAdequacy: string[] = []
        if (values.specimenAdequacy) {
            specimenAdequacy = [
                ...values.specimenAdequacy,
            ]
        }

        if (values.specimenInadequacyReason && values.specimenAdequacy?.includes('Unsatisfactory')) {
            specimenAdequacy.push(values.specimenInadequacyReason)
        }

        //convert all true / false to string
        for (let key in diagnosis) {
            if (typeof diagnosis[key as any] == "boolean") {
                diagnosis[key as any] = JSON.stringify(diagnosis[key])
            }
        }

        for (let key in recommendatation) {
            if (typeof recommendatation[key as any] == "boolean") {
                recommendatation[key as any] = JSON.stringify(recommendatation[key])
            }
        }


        //add other diagnosis

        if (Array.isArray(diagnosis.squamousCell)) {
            if (otherDiagnosis) {
                diagnosis.squamousCell.push(`otherdiagnosis_${otherDiagnosis}`)
            }

            diagnosis.squamousCell = _.uniq(
                [...diagnosis.squamousCell]
            );
        }

        // add other findings
        let otherFindings: string[] = []
        if (values.otherFindings) {
            otherFindings = [
                ...values.otherFindings,
            ]
        }

        if (otherFindingsOutlier['1']) {
            otherFindings.push(`other1_${otherFindingsOutlier['1']}`)
        }
        if (otherFindingsOutlier['2']) {
            otherFindings.push(`other2_${otherFindingsOutlier['2']}`)
        }
        if (otherFindingsOutlier['3']) {
            otherFindings.push(`other3_${otherFindingsOutlier['3']}`)
        }

        result['diagnosis'] = diagnosis
        result['recommendatation'] = recommendatation
        result['otherFindings'] = otherFindings
        result['specimenAdequacy'] = specimenAdequacy

        return result
    }


    const inputClasses =
        "bg-white border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

    const values = formik.values

    return (
        <FormikProvider
            value={formik}
        >
            <div className='text-2xl font-semibold mb-2'> Gynaecologic Cytology</div>
            {isUpdateMode && objectIsNotEmpty(gynaeResult) && <div className='text-[#c09853] text-lg mb-5'>
                Updated on {moment(gynaeResult?.updatedAt).format('DD ddd MMM[,] YYYY')}
            </div>}
            <div className='grid grid-cols-2 gap-3'>
                <div className='flex'>
                    <label className={labelClasses}>Specimen Source:
                    </label>
                    <Field
                        as='textarea'
                        name='specimenSource'
                        className={inputClasses} />
                </div>
                <div className='flex'>
                    <label className={labelClasses}>Clinician:
                    </label>
                    <Field
                        name="clinician"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3 pb-[30px]'>
                    <label className={labelClasses}>Clinical Comment:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.clinicalComment}
                        onChange={(val: any) => formik.setFieldValue('clinicalComment', val)} />
                </div>
                <div className='flex'>
                    <label className={labelClasses}>L.M.P:
                    </label>
                    <Field
                        as="textarea"
                        name="LMP"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3 col-span-2'>
                    <label className={labelClasses}>Prior Pap Smear date /  Diagnosis:
                    </label>
                    <Field
                        name="priorPapSmearDate"
                        type="date" className={inputClasses} />/
                    <Field
                        name="priorPapSmearDiagnosis"
                        as="textarea"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>Collection Method:
                    </label>
                    <Field
                        name="collectedMethod"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>Slide No:
                    </label>
                    <Field
                        name="slideNumber"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3 col-span-2'>
                    <label className={labelClasses}>Specimen Adequacy:
                    </label>
                    <div className='text-sm'>
                        <div>Satisfactory</div>
                        <Field
                            name="specimenAdequacy[0]"
                            value="Satisfactory"
                            type="radio" />
                    </div>
                    <div className='text-sm'>
                        <div>Unsatisfactory</div>
                        <Field
                            name="specimenAdequacy[0]"
                            value="Unsatisfactory"
                            type="radio" />
                    </div>
                    <div className='text-sm'>Specify Reason</div>
                    <Field
                        as="textarea"
                        name="specimenInadequacyReason"
                        disabled={!formik.values?.specimenAdequacy?.includes('Unsatisfactory')}
                        className={inputClasses} />
                </div>
                <div className='flex gap-3 col-span-2'>
                    <label className={labelClasses}>Transformation Zone:
                    </label>
                    <div className='text-sm'>
                        <div>Present</div>
                        <Field
                            name="transformationZone"
                            value="PRESENT"
                            type="radio" />
                    </div>
                    <div className='text-sm'>
                        <div>Not Present</div>
                        <Field
                            name="transformationZone"
                            value="ABSENT"
                            type="radio" />
                    </div>
                </div>
                <div className='flex gap-3 col-span-2'>
                    <label className={labelClasses}>Diagnosis:
                    </label>
                    <div className='text-sm space-y-1'>
                        <div className='flex gap-1'>
                            <Field
                                name="diagnosis.NILM"
                                checked={values.diagnosis.NILM == "YES" ? true : false}
                                onChange={(e: any) => {
                                    formik.setFieldValue(
                                        "diagnosis.NILM",
                                        e.target.checked ? "YES" : "NO"
                                    );
                                }}
                                type="checkbox" />
                            <div>NILM</div>
                        </div>
                        <div className='flex gap-1'>
                            <Field
                                name="diagnosis.epithelialCellAbnormalities"
                                checked={values.diagnosis.epithelialCellAbnormalities == "YES" ? true : false}
                                onChange={(e: any) => {
                                    formik.setFieldValue(
                                        "diagnosis.epithelialCellAbnormalities",
                                        e.target.checked ? "YES" : "NO"
                                    );
                                }}
                                type="checkbox" />
                            <div> EPITHELIAL CELL ABNORMALITIES</div>
                        </div>
                        <div className='flex gap-1'>
                            <Field
                                name="diagnosis.squamousCell"
                                value="SQUAMOUS CELL"
                                type="checkbox" />
                            <div>SQUAMOUS CELL</div>

                        </div>
                        <div className='pl-4 flex gap-2' >
                            <div className='flex gap-1'>
                                <div>
                                    <Field
                                        name="diagnosis.squamousCell"
                                        value="ASC-US"
                                        type="checkbox" />
                                    <div>ASC-US</div>
                                </div>
                                <div>
                                    <Field
                                        name="diagnosis.squamousCell"
                                        value="ASC-H"
                                        type="checkbox" />
                                    <div>ASC-H</div>
                                </div>
                                <div>
                                    <Field
                                        name="diagnosis.squamousCell"
                                        value="LSIL"
                                        type="checkbox" />
                                    <div> LSIL  </div>
                                </div>
                                <div>
                                    <Field
                                        name="diagnosis.squamousCell"
                                        value="HSIL"
                                        type="checkbox" />
                                    <div>HSIL   </div>
                                </div>
                                <div>
                                    <Field
                                        name="diagnosis.squamousCell"
                                        value="SCC"
                                        type="checkbox" />
                                    <div>SCC</div>
                                </div>
                                <div>
                                    <input type="checkbox" />
                                    <div>Others:</div>
                                    <input
                                        value={otherDiagnosis}
                                        onChange={e => setOtherDiagnosis(e.target.value)}
                                        className={inputClasses} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-3 col-span-2'>
                    <label className={labelClasses}>Other Findings:
                    </label>
                    <div className='text-sm space-y-1'>
                        <div>
                            <div className='flex gap-1'>
                                <Field
                                    name="otherFindings"
                                    value="Organism: Tricomonas Vaginalis / Candida spp / Bacterial Vaginosis / Actinomyces spp / Herpes simplex virus / others"
                                    type="checkbox" />
                                <div>Organism: Tricomonas Vaginalis / Candida spp / Bacterial Vaginosis / Actinomyces spp / Herpes simplex virus / others
                                </div>
                            </div>
                            <input
                                value={otherFindingsOutlier['1']}
                                onChange={e => updateOutlier('1', e.target.value)}
                                className={`${inputClasses} !w-[200px]`} />
                        </div>
                        <div>
                            <div className='flex gap-1'>
                                <Field
                                    name="otherFindings"
                                    value="Reactive cellular change associated with Inflammation / Radiation / Intra Uterine Contraceptive Device / others"
                                    type="checkbox" />
                                <div>Reactive cellular change associated with Inflammation / Radiation / Intra Uterine Contraceptive Device / others
                                </div>
                            </div>
                            <input
                                value={otherFindingsOutlier['2']}
                                onChange={e => updateOutlier('2', e.target.value)}
                                className={`${inputClasses} !w-[200px]`} />
                        </div>
                        <div className='flex gap-1'>
                            <Field
                                name="otherFindings"
                                value="Glandular cells status post hysterectomy"
                                type="checkbox" />
                            <div> Glandular cells status post hysterectomy
                            </div>
                        </div>
                        <div className='flex gap-1'>
                            <Field
                                name="otherFindings"
                                value="Atrophy"
                                type="checkbox" />
                            <div> Atrophy
                            </div>
                        </div>
                        <div className='flex gap-1'>
                            <Field
                                name="otherFindings"
                                value="Endometrial cells woman > 40 years / woman < 40 years"
                                type="checkbox" />
                            <div> Endometrial cells woman {'>'} 40 years / woman {'<'} 40 years
                            </div>
                        </div>
                        <div className='flex gap-1'>
                            <input type="checkbox" />
                            <div> Others
                            </div>
                            <input
                                value={otherFindingsOutlier['3']}
                                onChange={e => updateOutlier('3', e.target.value)}
                                className={`${inputClasses} !w-[200px]`} />
                        </div>
                    </div>
                </div>
                <div className='flex gap-3 col-span-2 pb-[70px]'>
                    <label className={labelClasses}>Comments:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.comments}
                        onChange={(val: any) => formik.setFieldValue('comments', val)} />
                </div>
                <div className='flex gap-3 col-span-2'>
                    <label className={labelClasses}>recommendatation:
                    </label>
                    <div className='text-sm space-y-1'>
                        <div>
                            <div className='flex gap-1'>
                                <Field
                                    name="recommendatation.routineRecall"
                                    checked={values.recommendatation.routineRecall == "YES" ? true : false}
                                    onChange={(e: any) => {
                                        formik.setFieldValue(
                                            "recommendatation.routineRecall",
                                            e.target.checked ? "YES" : "NO"
                                        );
                                    }}
                                    type="checkbox" />
                                <div>Routine Recall</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex gap-1'>
                                <Field
                                    name="recommendatation"
                                    value="Repeat Smear"
                                    type="radio" />
                                <div>Repeat Smear:</div>
                            </div>
                            <div className='flex pl-3'>
                                <Field
                                    name="recommendatation.repeatSmear.whenToRepeatSmear"
                                    value="IMMEDIATELY"
                                    type="radio" />
                                <div className='mr-2'>Immediately</div>
                                <br />
                                <Field
                                    name="recommendatation.repeatSmear.whenToRepeatSmear"
                                    value="AFTER_THERAPY"
                                    type="radio" />
                                <div className='mr-2'>After Therapy</div>
                            </div>
                        </div>
                        <div className='flex gap-1'>
                            {/* <Field
                                name="recommendatation"
                                value="In"
                                type="checkbox" /> */}
                            <div>In</div>
                            <Field
                                name="recommendatation.repeatSmear.inHowManyMonths"
                                type="number"
                                placeholder='Enter number of months(s)'
                                className={`${inputClasses} !w-[230px]`} />
                            <div>months</div>
                            <br />
                            <Field
                                name="recommendatation.ensuringEndocervicalSampling"
                                checked={values.recommendatation.ensuringEndocervicalSampling == "YES" ? true : false}
                                onChange={(e: any) => {
                                    formik.setFieldValue(
                                        "recommendatation.ensuringEndocervicalSampling",
                                        e.target.checked ? "YES" : "NO"
                                    );
                                }}
                                type="checkbox" />
                            <div>Ensuring endocervical sampling</div>
                        </div>
                        <div className='flex gap-2'>
                            <div className='flex gap-1'>
                                <Field
                                    name="recommendatation.colposcopyAndBiospy"
                                    checked={values.recommendatation.colposcopyAndBiospy == "YES" ? true : false}
                                    onChange={(e: any) => {
                                        formik.setFieldValue(
                                            "recommendatation.colposcopyAndBiospy",
                                            e.target.checked ? "YES" : "NO"
                                        );
                                    }}
                                    type="checkbox" />
                                <div>Colposcopy and biopsy</div>
                            </div>
                            <div className='flex gap-1'>
                                <Field
                                    name="recommendatation.highRiskHPVTesting"
                                    type="checkbox"
                                    checked={values.recommendatation.highRiskHPVTesting == "YES" ? true : false}
                                    onChange={(e: any) => {
                                        formik.setFieldValue(
                                            "recommendatation.highRiskHPVTesting",
                                            e.target.checked ? "YES" : "NO"
                                        );
                                    }}
                                />
                                <div>High risk HPV testing</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-3 col-span-2'>
                    <label className={labelClasses}>Consultant Pathologist:
                    </label>
                    <Field
                        name="consultantPathologist"
                        className={inputClasses} />
                </div>
            </div>
            {
                isUpdateMode && objectIsNotEmpty(gynaeResult) && <div className='flex gap-1 mt-8'>
                    <AppButton
                        onClick={onPrintResult}
                        buttontype={'cyan'}
                        title='Generate Result'
                        extraclass='!text-xs'
                    />
                    {printResultState.isLoading && <Spinner />}
                </div>
            }
        </FormikProvider>
    )
}

export default GynaeCytology