import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../../components/UI/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../store/rtk-query/userApi";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";

// import { login } from '../../store/reducers/userSlice';

import { toast } from "react-toastify";
import Spinner from "../../components/UI/Spinner";

interface FormValues {
  [key: string]: string;
}

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [login, loginState] = useLoginMutation();

  const onLogin = async (values: FormValues) => {
    const data = {
      emailOrPhoneNumber: values.emailOrPhoneNumber,
      password: values.password,
    };

    const res: any = await login(data);

    if (res?.error) {
      toast.error(res?.error?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const origin = location.state?.from?.pathname || "/";

    navigate(origin);
  };

  const initialValues: FormValues = {
    emailOrPhoneNumber: "",
    password: "",
  };

  const formValidationSchema = Yup.object().shape({
    // email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";
  const inputWrap = "flex justify-between lg:justify-start gap-4";

  const renderForm = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values) => {
          // same shape as initial values
          onLogin(values);
        }}
      >
        <Form>
        <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-1">
          Email or Phone Number
        </label>
        <Field name="emailOrPhoneNumber" className={inputClasses} />
        <ErrorMessage
          className={errorMessageClasses}
          component={"p"}
          name="emailOrPhoneNumber"
        />
      </div>
      <div>
        <label className="block text-sm font-medium mb-1">Password</label>
        <div className="relative">
          <Field
            name="password"
            className={inputClasses}
            type={showPassword ? "text" : "password"}
          />
          <span
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={toggleShowPassword}
          >
            {showPassword ? (
              <RiEyeFill className="h-5 w-5 text-gray-500" />
            ) : (
              <RiEyeOffFill className="h-5 w-5 text-gray-500" />
            )}
          </span>
        </div>
        <ErrorMessage
          className={errorMessageClasses}
          component={"p"}
          name="password"
        />
      </div>
    </div>
          <div className="flex items-center justify-between mt-6">
            <AppButton type="submit" buttontype={"primary"}>
              Sign In
            </AppButton>
            {loginState.isLoading && <Spinner />}
            <div className="mr-1">
              <Link
                to="/reset-password"
                className="text-sm underline hover:no-underline"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </Form>
      </Formik>
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex-1">
        <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
          {/* Logo */}
          <a className="block" href="index.html">
            <img
              src="images/labnetwork_logo.svg"
              width={250}
              height={120}
              alt="Company Logo"
            />
          </a>
        </div>
      </div>
    );
  };

  return (
    <main className="bg-gray-200">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {renderHeader()}
            <div className="max-w-sm mx-auto px-8 py-8 bg-white rounded-lg shadow-md p-4">
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Welcome back! ✨
              </h1>
              {/* Form */}
              {renderForm()}
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-200"></div>
            </div>
          </div>
        </div>
        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src="images/pexels-cottonbro-studio-5721680.jpg"
            width={760}
            height={1024}
            alt="Authentication image"
          />
          <img
            className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block"
            src="images/auth-decoration.png"
            width={218}
            height={224}
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
};

export default SignIn;
