import * as React from "react";

import {
  Field,
  FormikProps,
  ErrorMessage,
  useFormik,
  FormikProvider,
} from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";

import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTestOnQueueDetailsQuery } from "../store/rtk-query/testQueueApi";
import Spinner from "../components/UI/Spinner";
import { usePayTestInvoiceMutation } from "../store/rtk-query/invoiceApi";
import { formatServerErrorMessage } from "../utils/functions";
import { AiOutlineArrowRight } from "react-icons/ai";

const PayTestInvoice = () => {
  const [submitted, setSubmitted] = React.useState(false);
  const navigate = useNavigate();

  const { queue_id } = useParams();

  const { data: testOnQueueData } = useGetTestOnQueueDetailsQuery({
    testId: queue_id,
  });

  const [payTestInvoice, payTestInvoiceState] = usePayTestInvoiceMutation();

  let initialValues = {
    discount: "",
    modeOfPay: "",
    totalAmount: "",
  };

  const formValidationSchema = Yup.object().shape({
    discount: Yup.string().required("Required"),
    modeOfPay: Yup.string().required("Required"),
    totalAmount: Yup.string().required("Required"),
  });

  const modesOfPay = ["Cash", "POS", "Transfer", "Debt"];

  const submitForm = async (values: typeof initialValues) => {
    let data = {
      amountPaid: values.totalAmount,
      balance: values.totalAmount,
      modeOfPayment: values.modeOfPay?.toUpperCase(),
      quantity: 1,
      discount: values.discount,
      testQueueId: queue_id,
      patientId: testOnQueueData?.patientId,
    };

    // return
    const res: any = await payTestInvoice(data);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Invoice created successfully");
    navigate("/reminders/waiting-queue");
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      console.log("values ", values);
      submitForm(values);
    },
    validationSchema: formValidationSchema,
  });

  const inputWrap = "flex justify-between md:justify-start gap-4";
  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[200px] p-2.5 disabled:bg-[#ddd]";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  const renderPatientInfo = () => {
    return (
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <div className="font-semibold text-lg">Patient Information</div>
        <div className={inputWrap}>
          <div>
            <label className={labelClasses}>Patient Name</label>
            <input
              className={inputClasses}
              disabled
              value={`${testOnQueueData?.patientName} `}
            />
          </div>
          <div>
            <label className={labelClasses}>Patient REG No</label>
            <input
              className={inputClasses}
              disabled
              defaultValue={testOnQueueData?.patientRegNumber}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderItems = () => {
    return (
      <div className="">
        <div className="font-semibold text-lg">Items</div>
        <div className="tableContainer relative shadow-md sm:rounded-lg my-8">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 "></th>
                <th scope="col" className="px-6 py-3">
                  Item
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Selling Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Amount Payable
                </th>
              </tr>
            </thead>
            <tbody>
              {testOnQueueData?.laboratoryTests?.map(
                (testOnQueue: any, index: number) => {
                  return (
                    <tr key={index} className="border-b border-gray-200 ">
                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                      >
                        #{index + 1}
                      </td>

                      <td className="px-6 py-4">{testOnQueue?.testName}</td>
                      <td className="px-6 py-4 bg-gray-50 ">
                        {testOnQueue?.amountPayable}
                      </td>
                      <td className="px-6 py-4">1</td>
                      <td className="px-6 py-4 bg-gray-50 ">
                        {testOnQueue?.amountPayable}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderFields = () => {
    return (
      <FormikProvider value={formik}>
        <div className="grid grid-cols-2 gap-2">
          <div>
            <div className="flex items-center space-x-4 p-2">
              <label className={labelClasses}>Grand Total:</label>
              <input
                className={inputClasses}
                disabled
                value={testOnQueueData?.grandTotal}
              />
            </div>
            <div className="flex items-center space-x-4 p-2">
              <label className={labelClasses}>Discount:</label>
              <Field name="discount" type="number" className={inputClasses} />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="discount"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center space-x-4 p-2">
              <label className={labelClasses}>Net Amount Payable:</label>
              <input
                className={inputClasses}
                disabled
                value={
                  isNaN(parseInt(formik.values.discount))
                    ? 0
                    : testOnQueueData?.netAmountPayable -
                      parseInt(formik.values.discount)
                }
              />
            </div>
            <div className="flex items-start space-x-4 p-2">
              <label className={labelClasses}>Total Amount Paid:</label>
              <Field
                name="totalAmount"
                className={inputClasses}
                type="number"
              />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="totalAmount"
              />
            </div>
            <div className="flex items-start space-x-4 p-2">
              <label className={labelClasses}>Mode of Pay:</label>
              <Field as="select" name="modeOfPay" className={inputClasses}>
                <option value="" disabled hidden>
                  Select
                </option>
                {modesOfPay.map((each) => {
                  return (
                    <option key={each} value={each}>
                      {each}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="modeOfPay"
              />
            </div>
          </div>
        </div>
      </FormikProvider>
    );
  };

  return (
    <div className="space-y-3">
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Pay Invoice
        </h3>
      </div>
      {renderPatientInfo()}
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        {renderItems()}
        <form>
          {renderFields()}

          <AppButton
            title="Continue"
            extraclass={"my-2"}
            onClick={formik.submitForm}
          />

          {payTestInvoiceState.isLoading && <Spinner />}
        </form>
      </div>
    </div>
  );
};

export default PayTestInvoice;
