import * as React from 'react'

import JSONInput from 'react-json-editor-ajrm';
import locale from './locale/en';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import Form from '@rjsf/core';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import fields from "react-jsonschema-form-extras";

interface JSONValid {
    valid: boolean,
    error?: undefined | string
}

const AddTemplate = () => {

    const [formJson, setFormJson] = React.useState<any>(null)
    const [uiJson, setUiJson] = React.useState<any>(null)

    const [formSchema, setFormSchema] = React.useState<undefined | any>({})
    const [uiSchema, setUiSchema] = React.useState<undefined | any>({})

    const [formJsonValid, setFormJsonValid] = React.useState<JSONValid>({ valid: true })
    const [uiJsonValid, setUiJsonValid] = React.useState<JSONValid>({
        valid: true
    })

    const formEditorRef = React.useRef<any>(null);
    const uiEditorRef = React.useRef<any>(null);

    // React.useEffect(() => {
    // if (formEditorRef.current) {
    //     const editor = formEditorRef.current.editor;
    //     editor.setOptions({
    //         tabSize: 2,
    //         useSoftTabs: true,
    //     });
    // }
    // }, []);

    const isJsonValid = (jsonString: string) => {
        try {
            JSON.parse(jsonString);
            return { valid: true };
        } catch (e: any) {
            console.log('e ', e)
            return { valid: false, error: e?.message };
        }
    }

    const formatJsonEntry = (jsonString: string) => {
        try {
            const obj = JSON.parse(jsonString);
            return JSON.stringify(obj, null, 2);
        } catch (e) {
            return jsonString;
        }
    };

    const renderFormSchemaCanvas = () => {
        return <div className='w-full'>
            <div className='font-bold'>Form Schema</div>
            <AceEditor
                ref={formEditorRef}
                mode="json"
                theme="github"
                value={formJson}
                onChange={(value: string) => {
                    const check = isJsonValid(value)

                    setFormJson(formatJsonEntry(value))
                    if (check.valid) {
                        setFormJsonValid({
                            valid: true
                        })
                        setFormSchema(JSON.parse(value))
                    } else {
                        setFormJsonValid({
                            valid: false,
                            error: check?.error
                        })
                    }
                }}
                name="form_schema"
                editorProps={{ $blockScrolling: true }}
                className='!w-full  !h-[300px]'
            />
            {!formJsonValid.valid && <div className='text-red-400 text-sm'>Invalid JSON <br /> {formJsonValid?.error} </div>}
        </div>
    }

    console.log('rrrr ', formJsonValid)

    const renderUISchemaCanvas = () => {
        return <div className='w-full'>
            <div className='font-bold'>UI Schema</div>
            <AceEditor
                mode="json"
                theme="github"
                onChange={(value: string) => {
                    const check = isJsonValid(value)

                    setUiJson(formatJsonEntry(value))
                    if (check.valid) {
                        setUiJsonValid({
                            valid: true
                        })
                        setUiSchema(JSON.parse(value))
                    } else {
                        setUiJsonValid({
                            valid: false,
                            error: check.error
                        })
                    }
                    // setFormSchema(value)
                }}
                name="ui_schema"
                editorProps={{ $blockScrolling: true }}
                className='!w-full !h-[300px] !mr-4'
            />
            {!uiJsonValid.valid && <div className='text-red-400 text-sm'>Invalid JSON <br /> {uiJsonValid?.error} </div>}
        </div>
    }

    return (
        <div>
            <div className="w-full z-30 bg-white shadow-md mb-1">
                <h3 className="text-lg my-3 p-3  font-semibold font-sans">
                    Add Template
                </h3>
            </div>
            <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
                <div className='flex flex-wrap sm:flex-nowrap w-full gap-2'>
                    {renderFormSchemaCanvas()}
                    {renderUISchemaCanvas()}
                </div>
                <div className='mt-3'>
                    <div className='font-semibold'>Form Preview</div>
                    <Form
                        schema={{
                            ...formSchema
                        }}
                        uiSchema={uiSchema}
                        validator={validator}
                    />

                </div>
            </div>
        </div>
    )
}

export default AddTemplate