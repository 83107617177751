import * as React from "react";
import { MdModeEditOutline, MdOutlineClose } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CompanyDelete from "../../components/modals/CompanyDelete";
import Paginate from "../../components/Paginate";
import AppButton from "../../components/UI/button";
import Spinner from "../../components/UI/Spinner";
import {
  useDeleteCompanyMutation,
  useGetAllCompaniesQuery,
} from "../../store/rtk-query/companyApi";

import {
  useFormikContext,
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  FormikProvider,
  useFormik,
} from "formik";
import { formatServerErrorMessage } from "../../utils/functions";

const Page = () => {
  const navigate = useNavigate();

  const [perPage] = React.useState<number>(10)
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const initialValues = {
    name: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
    }
  })

  const { data: companyData, isLoading: companyLoading } =
    useGetAllCompaniesQuery({
      params: {
        ...(formik.values.name && { searchPhrase: formik.values.name }),
        take: perPage,
        skip: itemOffset
      }
    });

  const [deleteCompany, deleteCompanyState] = useDeleteCompanyMutation();

  const [selectedItem, setSelected] = React.useState<any>();
  const [showDeleteConfirm, setShowDelete] = React.useState(false);

  const onDeleteItem = async () => {
    if (!selectedItem) {
      return;
    }

    const res: any = await deleteCompany({ companyId: selectedItem?.id });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Company deleted successfully");
    setShowDelete(false);
  };


  const renderSearchBar = () => {
    return (
      <div className="flex  w-full md:w-[350px]">
        <div className="relative w-full">
          <Field
            name="name"
            type="text"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                        "
            placeholder="Search"
            required
          />
        </div>
      </div>
    );
  };

  const renderHeading = () => {
    return (
      <div className="flex justify-between w-full mb-5">
        <FormikProvider
          value={formik}
        >
          <Form>
            {renderSearchBar()}
          </Form>
        </FormikProvider>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full">
        <div className="font-semibold my-2 flex gap-2 items-center">
          Results
          {companyLoading && <Spinner />}
        </div>
        <div className="tableContainer relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th></th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Contact
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Representative
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Registration
                </th>
                <th scope="col" className="px-6 py-3">
                  Consultation
                </th>
              </tr>
            </thead>
            <tbody>
              {companyData?.data?.map((item: any) => {
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td>
                      <div className="flex gap-2 mx-2">
                        <div
                          onClick={() => navigate('/update-company/' + item?.id)}
                          className="p-1 h-[fit-content] bg-[#2fade7] text-white rounded-md cursor-pointer"
                        >
                          <MdModeEditOutline />
                        </div>
                        <div
                          onClick={() => {
                            setSelected(item);
                            setShowDelete(true);
                          }}
                          className="p-1 h-[fit-content] bg-[#dc5d3a] text-white rounded-md cursor-pointer"
                        >
                          <MdOutlineClose />
                        </div>
                      </div>
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      {item?.id}
                    </th>
                    <td className="px-6 py-4">{item?.name}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.phoneNumber}
                    </td>
                    <td className="px-6 py-4">{item?.phoneNumber}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.email}</td>
                    <td className="px-6 py-4"></td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.regNumber}</td>
                    <td className="px-6 py-4 flex gap-x-2 gap-y-1 flex-wrap"></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Patient's Company</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">

        {renderHeading()}
        <AppButton
          buttontype={"cyan"}
          title="Add New Patient's Company"
          onClick={() => navigate("/add-company")}
        />
        {renderResults()}
        {companyData && <Paginate
          onPageChange={(data) => {
            setItemOffset(data?.selected * perPage)
          }}
          pageCount={companyData?.totalPage || 1}
          itemsPerPage={perPage}
        />}
        <CompanyDelete
          show={showDeleteConfirm}
          onClose={() => setShowDelete(false)}
          onDelete={onDeleteItem}
          isLoading={deleteCompanyState.isLoading}
        />
      </div>
    </div>
  );
};

export default Page;
