import * as React from "react";

import {
  Form,
  Field,
  useFormik,
  FormikProvider,
} from "formik";
import { useGetAllUpcomingBdaysQuery } from "../../store/rtk-query/birthdayApi";
import moment from "moment";
import Paginate from "../../components/Paginate";
import FilterResultHeader from "../../components/filterResultHeader";

const Page = () => {
  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const { data: bdayData, isLoading: bdayLoading } =
    useGetAllUpcomingBdaysQuery({
      queryParams: {
        skip: itemOffset,
        take: perPage,
      },
    });

  const initialValues: any = {
    filter: "",
  };

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 w-full`;

  const filters = ["patients", "staffs"];

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
    },
  });

  const renderFilters = () => {
    return (
      <div>
        <FormikProvider value={formik}>
          <Form>
            <div className="flex max-w-[300px]">
              <label className={labelClasses}>Filter by: </label>
              <Field className={inputClasses} name="filter" as="select">
                {filters.map((each) => {
                  return (
                    <option key={each} value={each}>
                      {each}
                    </option>
                  );
                })}
              </Field>
            </div>
          </Form>
        </FormikProvider>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full">
        <FilterResultHeader
          title="All Upcoming Birthdays"
          isLoading={bdayLoading}
        />
        <div className="tableContainer relative  shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 w-[40px]"></th>
                <th scope="col" className="px-6 py-3">
                  Patient
                  <br /> Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Birth Date
                </th>
                <th scope="col" className="px-6 py-3">
                  phoneNumber
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 "></th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {bdayData?.data.map((each: any) => {
                const patientData = each;
                return (
                  <tr key={each?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    ></td>
                    <td className="px-6 py-4">
                      {each?.firstName} {each?.lastName} {each?.otherNames}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {moment(
                        `${patientData?.dateOfBirth?.day}/${patientData?.dateOfBirth?.month}/${patientData?.dateOfBirth?.year}`,
                        "DD/MM/YYYY"
                      ).format("ddd DD[,] MMM YYYY")}
                    </td>
                    <td className="px-6 py-4">{each?.phoneNumber}</td>
                    <td className="px-6 py-4 bg-gray-50 "></td>
                    <td className="px-6 py-4"></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Birthdays</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {/* {renderFilters()} */}
        {renderResults()}
        {bdayData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={bdayData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
      </div>
    </div>
  );
};

export default Page;
