import React, { useState } from "react";
import { AiFillDownCircle } from "react-icons/ai";

interface Props {
  onSelect: (any: string) => void
}

export default function TimeFrameSelector(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select Timeframe");

  const handleOptionClick = (option: React.SetStateAction<string>) => {
    setSelectedOption(option);
    setIsOpen(false);
    props.onSelect(option as string)
  };

  const options = ['Week', 'Month', 'Year']

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          {!selectedOption?.includes('Select') && 'This'} {selectedOption}
          <AiFillDownCircle className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            {options.map((option) => {
              return <a
                key={option}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                onClick={() => handleOptionClick(option)}
              >
                This {option}
              </a>
            })}
          </div>
        </div>
      )}
    </div>
  );
}
