import * as React from 'react';

import { Dialog, Transition } from '@headlessui/react'

import AppButton from '../UI/button';
import { useGetDashboardLabInfoQuery } from '../../store/rtk-query/dashboardApi';
import { useNavigate } from 'react-router-dom';

interface Props {
    onClose: () => void;
    show: boolean
}

const Notifications = (props: Props) => {
    const { onClose, show } = props;
    const navigate = useNavigate()

    const { data: labInfoData, isLoading: labInfoLoading } = useGetDashboardLabInfoQuery({})

    return (
        <Transition.Root show={show} as={React.Fragment}>
            <Dialog as="div" className="relative z-10"
                onClose={onClose}
            >
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-30"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex
                    sm:ml-40
                    min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h2" className="text-lg font-medium leading-6 text-gray-900">
                                            Notifications
                                        </Dialog.Title>

                                    </div>
                                </div>
                                <div className='w-full p-4 flex flex-col items-center space-y-2'>
                                    <div className='text-xl text-[#468847]'>Waiting Queue</div>
                                    <div>
                                        {labInfoData?.totalTestOnQueue} test(s) on queue
                                    </div>
                                    <AppButton
                                        onClick={() => {
                                            onClose()
                                            navigate('reminders/waiting-queue')
                                        }}
                                        title='Go to queue now!'
                                        extraclass='!bg-color-[#dc5d3a]'
                                    />
                                </div>
                                <div className='w-full flex items-center justify-between p-2'>
                                    <div className='flex-1 w-1/2 p-3 flex flex-col items-center space-y-2'>
                                        <div className='text-xl text-[#b94a48]'>Appointments</div>
                                        <div>
                                            {labInfoData?.totalNumberOfAppointmentToday} Appointment(s) for today
                                        </div>
                                        <AppButton
                                            onClick={() => {
                                                onClose()
                                                navigate('reminders/appointments')
                                            }}
                                            title='See Detail'
                                            extraclass='!bg-color-[#fb9800]'
                                        />
                                    </div>
                                    <div className='flex-1 w-1/2 p3 flex flex-col items-center space-y-2'>
                                        <div className='text-xl text-[#468847]'>Birthdays</div>
                                        <div>
                                            {labInfoData?.totalNumberOfBirthdayToday} Birthday(s) for today
                                        </div>
                                        <AppButton
                                            onClick={() => {
                                                onClose()
                                                navigate('reminders/birthday')
                                            }}
                                            title='See Detail'
                                            buttontype={'cyan'}
                                        />
                                    </div>
                                </div>
                                <div className="w-full flex justify-end ">
                                    <AppButton
                                        onClick={() => onClose()}
                                        title='Cancel'
                                        buttontype={'danger'}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default Notifications