import * as React from 'react'

const Page = () => {

    return (
        <div>
            <h3 className='text-3xl my-3' >Staff Evaluation</h3>
            <div className='p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md '>
                <div></div>
            </div>
        </div>
    )
}

export default Page