import { updatedDiff } from 'deep-object-diff';
import { Field, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { toast } from 'react-toastify';
import { LabTestsProviderValues, ResultTemplateType } from '../../../@types/app-types';
import { LabTestContext } from '../../../context/LabTestProvider';
import { usePrintResultMutation } from '../../../store/rtk-query/labResult';
import { formatServerErrorMessage, generateAndDownloadResultPdf } from '../../../utils/functions';
import AppButton from '../../UI/button';
import Spinner from '../../UI/Spinner';
import { convertGroupResultArrayToFormVals, convertObjectToGroupResultsArray, setValuesOfRefRangeNdUnits } from './function';

import _ from 'lodash'

interface Props {
    onChangeForm: any
}

const FBC = (props: Props) => {
    const {
        originalTestResult,
        isUpdateMode,
        allFormValues,
        labTestResultId
    } = React.useContext<LabTestsProviderValues>(LabTestContext)

    const [printResult, printResultState] = usePrintResultMutation()

    const fbcResult = originalTestResult?.fullBloodCount

    const prefix = 'FBC-'
    const tests = [
        {
            key: 'RBC',
            label: 'RBC',
            refRange: '3.50 - 5.50',
            unit: '10^12/l'
        },
        {
            key: 'MCV',
            label: 'MCV',
            refRange: '75.0 - 100.0',
            unit: 'fl'
        },
        {
            key: 'RDW%',
            label: 'RDW%',
            refRange: '11.0 - 16.0',
            unit: '%'
        },

        {
            key: 'RDWa',
            label: 'RDWa',
            refRange: '30.0 - 150.0',
            unit: 'fl'
        },
        {
            key: 'HCT',
            label: 'HCT',
            refRange: '35.0 - 55.0',
            unit: '%'
        },
        {
            key: 'PLT',
            label: 'PLT',
            refRange: '100.0 - 400',
            unit: '10^9/1'
        },
        {
            key: 'MPV',
            label: 'MPV',
            refRange: '8.0 - 11.0',
            unit: 'fl'
        },
        {
            key: 'PDW',
            label: 'PDW',
            refRange: '0.1 - 99.9',
            unit: 'fl'
        },
        {
            key: 'PCT',
            label: 'PCT',
            refRange: '0.01 - 9.99',
            unit: '%'
        },
        {
            key: 'LCPR',
            label: 'LCPR',
            refRange: '0.1 - 99.9',
            unit: '%'
        },
        {
            key: 'WBC',
            label: 'WBC',
            refRange: '3.5 - 10.0',
            unit: '10^9/1'
        },
        {
            key: 'HGB',
            label: 'HGB',
            refRange: '11.5 - 16.5',
            unit: 'g/dl'
        },
        {
            key: 'MCH',
            label: 'MCH',
            refRange: '25.0 - 35.0',
            unit: 'pg'
        },
        {
            key: 'MCHC',
            label: 'MCHC',
            refRange: '31.0 - 38.0',
            unit: 'g/dl'
        },
        {
            key: 'LYM',
            label: 'LYM',
            refRange: '0.5 - 5.0',
            unit: '10^9/l'
        },
        {
            key: 'GRAN',
            label: 'GRAN',
            refRange: '1.2 - 8.0',
            unit: '10^9/l'
        },
        {
            key: 'MID',
            label: 'MID',
            refRange: '0.1 - 1.5',
            unit: '10^9/l'
        },
        {
            key: 'LYM%',
            label: 'LYM%',
            refRange: '15.0 - 50.0',
            unit: '%'
        },
        {
            key: 'GRA%',
            label: 'GRA%',
            refRange: '35.0 - 80.0',
            unit: '%'
        },
        {
            key: 'MID%',
            label: 'MID%',
            refRange: '2.0 - 15.0',
            unit: '%'
        },
    ]

    const formColumns = [
        //will become {testName}_o
        'result',
        //will become {testName}_h
        'refRange',
        //
        'flag',
        'units',
    ]


    let { values: initVals, formikKeys } = React.useMemo(() => {
        let formikKeys: string[] = []
        //loop through test
        tests.forEach((test) => {
            let testKey = test.key;

            //loop through form columns and generate formik form keys;

            formColumns.forEach((_formCol) => {
                //example is {testName}_key
                let formikKey = `${testKey}_${_formCol}`
                formikKeys.push(formikKey)
            })
        })

        //build object from formik keys
        let values: any = {}

        let newVals = setValuesOfRefRangeNdUnits(tests, formikKeys)

        formikKeys.forEach((_key) => {
            values[_key] = '';
        })

        values = { ...values, ...newVals }


        return { values, formikKeys }
    }, [])

    //build initialValues from tests and there respective colums
    let initialValues = React.useMemo(() => {
        //build object from formik keys
        let values: any = {}

        //set old result values
        const oldServerValues = convertGroupResultArrayToFormVals(fbcResult)

        values = {
            ...initVals,
            ...values,
            ...oldServerValues
        }

        return values
    }, [fbcResult])


    const formik = useFormik({
        initialValues: {
            ...initialValues
        },
        onSubmit: values => {

        },
        enableReinitialize: true
    });

    React.useEffect(() => {
        /**
 * remove this form key from final result compilation if user
 * removes all changes
 */
        const updatedObj = updatedDiff(initVals, formik.values)

        if (Object.keys(updatedObj).length == 0) {
            props.onChangeForm(undefined, 'fullBloodCountResult')
            return
        }

        const resultArray = convertObjectToGroupResultsArray(updatedObj, tests, formik.values)

        if (!resultArray[0]) {
            props.onChangeForm(undefined, 'fullBloodCountResult')
            return
        }
        props.onChangeForm(resultArray, 'fullBloodCountResult')
    }, [formik.values])

    const onPrintResult = async () => {
        const res: any = await printResult({
            labResultId: labTestResultId,
            queryParams: {
                templateType: ResultTemplateType.FULL_BLOOD_COUNT_TEMPLATE
            }
        });

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }

        generateAndDownloadResultPdf(res?.data, 'FBCTest')

        toast.success("Result pdf generated successfully");
    };


    const inputClasses =
        "bg-white border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

    return (
        <FormikProvider
            value={formik}
        >
            <div className='text-[#c09853] text-lg my-2'> Key: L= Low, H = High, WNL= Within Normal Limits, *= Critical value
            </div>
            <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg">
                <table className="horiz-table w-full text-sm">
                    <thead className="text-xs text-gray-700 uppercase">
                        <tr>
                            <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                                Test Name
                            </th>
                            <th scope="col" className="px-6 py-3 ">
                                Test Result
                            </th>
                            <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                                Ref. Range
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Flag
                            </th>
                            <th scope="col" className="px-6 py-3 bg-gray-50 ">
                                Units
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {tests?.map((test) => {
                            return (
                                <tr key={test?.key} className="border-b border-gray-200 ">
                                    <td className="px-6 py-3 bg-gray-50 ">
                                        {prefix}{test.label}
                                    </td>
                                    <td className="px-6 py-3 ">
                                        <Field
                                            name={`${test.key}_result`}
                                            className={inputClasses} />
                                    </td>
                                    <td className="px-6 py-3 bg-gray-50  ">
                                        <Field
                                            as='textarea'
                                            name={`${test.key}_refRange`}
                                            // defaultValue={test.refRange}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={inputClasses}
                                        />
                                    </td>
                                    <td className="px-6 py-3 min-w-[120px]">
                                        <Field
                                            name={`${test.key}_flag`}
                                            className={inputClasses}
                                        />
                                    </td>
                                    <td className="px-6 py-3 bg-gray-50 min-w-[140px] ">
                                        <Field
                                            name={`${test.key}_units`}
                                            className={inputClasses}
                                        />
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {
                isUpdateMode && !_.isEmpty(fbcResult) && <div className='flex gap-1 mt-8'>
                    <AppButton
                        onClick={onPrintResult}
                        buttontype={'cyan'}
                        title='Generate Result'
                        extraclass='!text-xs'
                    />
                    {printResultState.isLoading && <Spinner />}
                </div>
            }
        </FormikProvider>
    )
}

export default FBC