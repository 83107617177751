import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import TimeFrameSelector from "../TimeFrameSelector";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const scores = [6, 5, 5, 5, 3, 4, 6, 4, 5];
const labels = [100, 200, 300, 400, 500, 600, 700];

export const options = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  // height: 400 // set the desired height here
};

interface Props {
  data: any
}


export default function BarChart(props: Props) {
  const [timeFrame, setTimeFrame] = React.useState<string | undefined>('Week')

  const formatData = () => {
    let formattedData: number[] = []
    let labels: string[] = []

    const filterKey = `${timeFrame?.toLowerCase()}ly`

    const filteredData = props.data?.[filterKey]

    formattedData = filteredData?.map((item: any) => item?.count)
    labels = filteredData?.map((item: any) => {
      if (item?.date) {
        if (timeFrame?.toLowerCase() == "week") {
          return moment(item?.date).format('ddd')
        }
        if (timeFrame?.toLowerCase() == "month") {
          return moment(item?.date).week()
        }
        if (timeFrame?.toLowerCase() == "year") {
          return moment(item?.date).format('MMM')
        }
        return moment(item?.date).format('DD/MM/YYYY')
      } else {
        return "No date"
      }
    })

    return {
      formattedData,
      labels
    }
  }

  const data = useMemo(function () {
    const { formattedData, labels } = formatData()
    return {
      datasets: [
        {
          label: "data",
          tension: 0.3,
          data: formattedData,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.3)",
        },

      ],
      labels,
    };
  }, [props.data, timeFrame]);

  return (
    <div className="h-full w-full pb-20">
      <TimeFrameSelector
        onSelect={(val) => setTimeFrame(val)}
      />
      <div className="h-full w-full px-1">
        <Bar data={data} options={options} />
      </div>
      <p className="text-gray-600 text-sm text-center">Number</p>
    </div>
  );
}