import { updatedDiff } from "deep-object-diff";
import { Field, FormikProvider, useFormik } from "formik";
import * as React from "react";
import {
  LabTestsProviderValues,
  ResultTemplateType,
} from "../../../@types/app-types";
import { LabTestContext } from "../../../context/LabTestProvider";
import {
  convertGroupResultArrayToFormVals,
  convertObjectToGroupResultsArray,
  setValuesOfRefRangeNdUnits,
} from "./function";
import {
  formatServerErrorMessage,
  generateAndDownloadResultPdf,
} from "../../../utils/functions";
import { toast } from "react-toastify";
import { usePrintResultMutation } from "../../../store/rtk-query/labResult";
import AppButton from "../../UI/button";
import Spinner from "../../UI/Spinner";
import _ from "lodash";

interface Props {
  onChangeForm: any;
}

const HormonalProfile = (props: Props) => {
  const { originalTestResult, isUpdateMode, labTestResultId } =
    React.useContext<LabTestsProviderValues>(LabTestContext);

  const [printResult, printResultState] = usePrintResultMutation();

  const hormonalProfileResult = originalTestResult?.hormonalProfile;

  const tests = [
    {
      key: "PROGESTERONE",
      label: "PROGESTERONE",
      refRange: `Females:                       
      Follicular phase = 0.15 – 0.70
      Luteal phase = 2.0 – 25.0
      Post-Menopausal = 0.06 – 1.60  
      Men = 0.13 – 0.97 
      Children = 0.70 – 0.52`,
      unit: "ng/mL",
    },
    {
      key: "LH",
      label: "LH",
      refRange: `Follicular phase = 0.5 – 10.5   
      Midcycle  = 18.4 – 61.2   
      Luteal phase = 0.5 – 10.5   
      Menopausal = 8.2 – 40.8   
      Men = 0.7 – 7.4  `,
      unit: "MIu/mL ",
    },
    {
      key: "FSH",
      label: "FSH",
      refRange: `Follicular phase = 2.0 – 10.0   
      Midcycle = 8.0–22.0  
      Luteal phase = 2.0 – 12.0   
      Post-menopausal = 35.0 – 151.0   
      Male = 1.0 – 14.0`,
      unit: "Mlu/mL",
    },
    {
      key: "ESTRADIOL",
      label: "ESTRADIOL",
      refRange: `Follicular phase = 30 - 120   
      Ovulation peak = 150 – 350   
      Luteal Phase = 50 – 180   
      Menopausal = < 60  
      Men = <40    
      Children = < 60`,
      unit: "Pg/ml",
    },
    {
      key: "PROLACTIN",
      label: "PROLACTIN",
      refRange: `Female: Adult = 1.2 – 19.5 
        Postmenopausal = 1.5 – 18.5 
        Male = 1.8 – 17.0 `,
      unit: "ng/mL",
    },
    {
      key: "TESTOSTERONE",
      label: "TESTOSTERONE",
      refRange: `Women = 0.2 – 0.95 
      Children = 0.1 – 3.7 
      Men = 3.0 – 10.0 `,
      unit: "ng/mL",
    },
  ];

  const formColumns = [
    //will become {testName}_o
    "result",
    //will become {testName}_h
    "refRange",
    //
    "flag",
    "units",
  ];

  let { values: initVals, formikKeys } = React.useMemo(() => {
    let formikKeys: string[] = [];
    //loop through test
    tests.forEach((test) => {
      let testKey = test.key;

      //loop through form columns and generate formik form keys;

      formColumns.forEach((_formCol) => {
        //example is {testName}_key
        let formikKey = `${testKey}_${_formCol}`;
        formikKeys.push(formikKey);
      });
    });

    //build object from formik keys
    let values: any = {};

    let newVals = setValuesOfRefRangeNdUnits(tests, formikKeys);

    formikKeys.forEach((_key) => {
      values[_key] = "";
    });

    values = { ...values, ...newVals };

    return { values, formikKeys };
  }, []);

  //build initialValues from tests and there respective columns
  let initialValues = React.useMemo(() => {
    //build object from formik keys
    let values: any = {};

    //set old result values
    const oldServerValues = convertGroupResultArrayToFormVals(
      hormonalProfileResult
    );

    values = {
      ...initVals,
      ...values,
      ...oldServerValues,
    };

    return values;
  }, [hormonalProfileResult]);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  React.useEffect(() => {
    /**
     * remove this form key from final result compilation if user
     * removes all changes
     */
    const updatedObj = updatedDiff(initVals, formik.values);
    if (Object.keys(updatedObj).length == 0) {
      props.onChangeForm(undefined, "hormonalProfile");
      return;
    }

    const resultArray = convertObjectToGroupResultsArray(
      updatedObj,
      tests,
      formik.values
    );

    if (!resultArray[0]) {
      props.onChangeForm(undefined, "hormonalProfile");
      return;
    }
    props.onChangeForm(resultArray, "hormonalProfile");
  }, [formik.values]);

  const onPrintResult = async () => {
    const res: any = await printResult({
      labResultId: labTestResultId,
      queryParams: {
        templateType: ResultTemplateType.HORMONAL_PROFILE_TEST_TEMPLATE,
      },
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadResultPdf(res?.data, "hormonalProfileTest");

    toast.success("Result pdf generated successfully");
  };

  const inputClasses =
    "bg-white border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-10/12 lg:w-10/12 xl:w-10/12 p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

  return (
    <FormikProvider value={formik}>
      <div className="mb-4">
        <div className="text-[#c09853] text-lg my-2">
          {" "}
          Key: L= Low, H = High, WNL= Within Normal Limits, *= Critical value
        </div>
        <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg">
          <table className="horiz-table w-full text-sm">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                  Test Name
                </th>
                <th scope="col" className="px-6 py-3 ">
                  Test Result
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                  Ref. Range
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Units
                </th>
              </tr>
            </thead>
            <tbody>
              {tests?.map((test) => {
                return (
                  <tr key={test?.key} className="border-b border-gray-200 ">
                    <td className="px-6 py-3 bg-gray-50 ">{test.label}</td>
                    <td className="px-6 py-3 ">
                      <Field
                        name={`${test.key}_result`}
                        className={inputClasses}
                      />
                    </td>
                    <td className="px-6 py-3 bg-gray-50  ">
                      <ul className="list-disc list-inside">
                        {test.refRange.split("\n").map((item, index) => (
                          <li key={index}>{item.trim()}</li>
                        ))}
                      </ul>
                    </td>
                    <td className="px-6 py-3 bg-white min-w-[140px]">
                      <Field
                        name={`${test.key}_units`}
                        className={inputClasses}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {isUpdateMode && !_.isEmpty(hormonalProfileResult) && (
        <div className="flex gap-1 mt-8">
          <AppButton
            onClick={onPrintResult}
            buttontype={"cyan"}
            title="Generate Result"
            extraclass="!text-xs"
          />
          {printResultState.isLoading && <Spinner />}
        </div>
      )}
    </FormikProvider>
  );
};

export default HormonalProfile;
