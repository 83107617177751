import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";
import ItemsForm from "../components/forms/ItemsForm";
import { toast } from "react-toastify";
import moment from "moment";

import { useAddNewDocOrLabSciMutation } from "../store/rtk-query/docOrLabsciApi";

import AddAppointmentForm from "../components/forms/AddAppointmentForm";
import { useCreateNewAppointmentMutation, useEditAppointmentMutation, useGetAppointmentInfoQuery } from "../store/rtk-query/appointmentApi";
import { useParams } from "react-router-dom";
import { formatServerErrorMessage } from "../utils/functions";

const AddAppointment = () => {
  const [addAppointment, addAppointmentState] =
    useCreateNewAppointmentMutation();
  const [updateAppointment, updateAppointmentState] = useEditAppointmentMutation()

  let { appointment_id } = useParams()

  const { data: appointmentData } = useGetAppointmentInfoQuery({
    appointmentId: appointment_id
  }, {
    skip: !appointment_id
  })

  console.log('appointmentData ', appointmentData)

  let initialValues = {
    patientRegNumber: "",
    remark: "",
    appointmentDate: "",
    appointmentWithUserId: "",
  };

  if (appointmentData) {
    initialValues.patientRegNumber = appointmentData.patientRegNumber
    initialValues.remark = appointmentData.remark
    initialValues.appointmentDate = moment(appointmentData.appointmentDate).format('YYYY-MM-DD')
  }

  const onSubmit = async (values: typeof initialValues) => {
    let data = {
      patientRegNumber: values.patientRegNumber,
      remark: values.remark,
      appointmentDate: moment(values.appointmentDate).format("MM/DD/YYYY"),
      appointmentTime: moment(values.appointmentDate).format("hhA"),
      appointmentWithUserId: values.appointmentWithUserId,
      status: "AWAITING",
    };

    let res: any

    //if its adding new
    if (!appointmentData) {
      res = await addAppointment(data);
    }

    if (appointmentData) {
      res = await updateAppointment({
        appointmentId: appointment_id,
        body: data
      });

    }

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    if (appointmentData) {
      toast.success("Appointment updated successfully");
    } else {
      toast.success("Appointment added successfully");
    }
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          {appointmentData ? "Update" : "Add"} Appointment
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <AddAppointmentForm
          onSubmit={onSubmit}
          initialValues={appointmentData ? initialValues : undefined}
          isLoading={addAppointmentState.isLoading || updateAppointmentState.isLoading}
        />
      </div>
    </div>
  );
};

export default AddAppointment;
