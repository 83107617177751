import * as React from 'react'

import { Formik, Form, Field, FormikProps, ErrorMessage, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import AppButton from '../UI/button';
import { useGetAllLabCategoriesQuery } from '../../store/rtk-query/labApi';
import Spinner from '../UI/Spinner';
import { useGetAllPurchaseTypesQuery } from '../../store/rtk-query/purchaseApi';


interface FormValues {
    [key: string]: string
}

let initialValues = {
    "date": "",
    "name": "",
    "itemCategoryId": "",
    "supplierName": "",
    "supplierAddress": "",
    "supplierPhone": "",
    "quantity": "",
    "price": "",
}

interface Props {
    onSubmit: any;
    initialValues?: typeof initialValues;
    isLoading: boolean
}

const AddPurchaseForm = (props: Props) => {

    const { data: purchaseTypeData } = useGetAllPurchaseTypesQuery({})

    if (props.initialValues) {
        initialValues = {
            ...initialValues,
            ...props.initialValues
        }
    }

    const formValidationSchema = Yup.object().shape({
        date: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        itemCategoryId: Yup.string().required('Required'),
        supplierName: Yup.string().required('Required'),
        supplierAddress: Yup.string().required('Required'),
        supplierPhone: Yup.string().required('Required'),
        quantity: Yup.string().required('Required'),
        price: Yup.string().required('Required'),
    })

    const inputClasses = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'
    const inputWrap = 'flex justify-between md:justify-start gap-4 mb-3'

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formValidationSchema,
        onSubmit: values => {
            // same shape as initial values
            console.log(values);
            props.onSubmit(values)
        },
        enableReinitialize: true
    })


    return (
        <FormikProvider
            value={formik}
        >
            <Form>
                <>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Date</label>
                            <Field className={inputClasses} type="date" name="date" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="date" />
                        </div>
                        <div>
                            <label className={labelClasses}>Item Name</label>
                            <Field className={inputClasses} name="name" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="name" />
                        </div>
                    </div>
                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Purchase Type</label>
                            <Field as='select'
                                name="itemCategoryId"
                                className={inputClasses}>
                                <option value="" disabled hidden>Select</option>
                                {purchaseTypeData?.map((each: any) => {
                                    return <option
                                        key={each?.id}
                                        value={each?.id}>{each?.itemCategory}</option>
                                })}
                            </Field>
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="purchaseType" />
                        </div>
                        <div>
                            <label className={labelClasses}>Supplier Name</label>
                            <Field className={inputClasses} name="supplierName" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="supplierName" />
                        </div>
                    </div>


                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Supplier Address</label>
                            <Field as="textarea" className={inputClasses} name="supplierAddress" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="supplierAddress" />
                        </div>
                        <div>
                            <label className={labelClasses}>Supplier Phone Number</label>
                            <Field className={inputClasses} name="supplierPhone" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="supplierPhone" />
                        </div>
                    </div>

                    <div className={inputWrap}>
                        <div>
                            <label className={labelClasses}>Quantity</label>
                            <Field type="number" className={inputClasses} name="quantity" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="quantity" />
                        </div>
                        <div>
                            <label className={labelClasses}>Price</label>
                            <Field type="number" className={inputClasses} name="price" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="price" />
                        </div>
                    </div>

                    {props.isLoading && <Spinner />}
                    <AppButton
                        extraclass='mt-4'
                        type="submit" buttontype={'primary'}>Add</AppButton>
                </>
            </Form>
        </FormikProvider >
    )
}

export default AddPurchaseForm