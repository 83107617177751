import { userApi } from "./userApi";

export const debtApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getDebtors: builder.query({
      query: ({ params }) => {
        return {
          url: "inventory/debtors",
          method: "GET",
          params: { ...params },
        };
      },
      providesTags: ["Debts"],
    }),
    exportDebtsData: builder.query({
      query: ({ queryParams }) => {
        return {
          url: "inventory/debt/export-data",
          method: "GET",
          params: { ...queryParams },
          responseHandler: (response: any) => {
            const text = response.text();
            return text;
          },
        };
      },
    }),
    payDebt: builder.mutation({
      query: ({ salesReceiptId, body }) => {
        return {
          url: "inventory/debt/pay/" + salesReceiptId,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Debts"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDebtorsQuery,
  useExportDebtsDataQuery,
  usePayDebtMutation,
} = debtApi;
