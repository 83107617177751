import * as React from 'react';

import { Dialog, Transition } from '@headlessui/react'

import AppButton from '../UI/button';

import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Spinner from '../UI/Spinner';

interface FormValues {
    [key: string]: string
}


interface Props {
    onClose: any;
    onSubmit: any;
    show: boolean;
    debtData: any;
    isLoading: boolean
}

const PayDebt = (props: Props) => {
    const { onClose, onSubmit, show, debtData } = props;

    const modesOfPay = ["Cash", "POS", "Transfer", "PAYMENT_LINK", "Bank", "Debt"];

    const initialValues: FormValues = {
        modeOfPay: '',
        amountPaid: ''
    }

    const formValidationSchema = Yup.object().shape({
        modeOfPay: Yup.string().required('Required'),
        amountPaid: Yup.string().required('Required')
    })

    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'
    const inputClasses = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"

    return (
        <Transition.Root show={show} as={React.Fragment}>
            <Dialog as="div" className="relative z-10"
                onClose={onClose}
            >
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-30"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex
                    sm:ml-60
                    min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">

                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Pay Debt
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={formValidationSchema}
                                                    onSubmit={values => {
                                                        // same shape as initial values
                                                        console.log(values);
                                                        onSubmit(values)
                                                    }}
                                                >
                                                    <Form>
                                                        <label className={labelClasses}>Customer Name</label>
                                                        <input className={inputClasses}
                                                            value={debtData?.customerName}
                                                            disabled
                                                        >

                                                        </input>
                                                        <label className={labelClasses}>Total Debt</label>
                                                        <input className={inputClasses}
                                                            value={debtData?.totalDebt}
                                                            disabled
                                                        ></input>
                                                        <label className={labelClasses}>Amount Paid</label>
                                                        <Field
                                                            type="number"
                                                            name="amountPaid"
                                                            className={inputClasses}

                                                        />
                                                        <ErrorMessage
                                                            className={errorMessageClasses}
                                                            component={"p"}
                                                            name="amountPaid" />
                                                        <label className={labelClasses}>Mode of Payment</label>
                                                        <Field
                                                            as="select"
                                                            name="modeOfPay"
                                                            className={inputClasses}
                                                        >
                                                            <option value="" disabled hidden>Select</option>
                                                            {modesOfPay.map((each) => {
                                                                return (
                                                                    <option key={each} value={each}>
                                                                        {each}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <ErrorMessage
                                                            className={errorMessageClasses}
                                                            component={"p"}
                                                            name="modeOfPay" />
                                                        <div className="mt-3 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                                            <AppButton
                                                                title='Pay'
                                                                type='submit'
                                                                buttontype={'primary'}
                                                            />

                                                            <AppButton
                                                                onClick={() => onClose()}
                                                                title='Cancel'
                                                                buttontype={'danger'}
                                                            />
                                                        </div>
                                                        {props.isLoading && <Spinner />}
                                                    </Form>
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default PayDebt