import moment from "moment";

export const objectIsNotEmpty = (obj: object) => {
  if (!obj) return false;

  if (Object.keys(obj).length < 1) return false;

  return true;
};

export const formatServerErrorMessage = (
  _message: Array<string> | string
): string => {
  let message = "";
  if (_message) {
    if (Array.isArray(_message)) {
      message = _message.join("\n");
    } else if (typeof _message == "string") {
      message = _message;
    }
  }

  return message;
};

export const addClassNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export const generateAndDownloadInvoicePdf = (
  arrayBuffer: ArrayBuffer,
  invoiceData: any
) => {
  // Create a PDF Blob from the array buffer
  const blob = new Blob([arrayBuffer], { type: "application/pdf" });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create an <a> element to download the file
  const a = document.createElement("a");
  a.href = url;

  // Open the PDF in a new browser tab
  window.open(url, "_blank");

  //also download file
  a.download = `invoice#${invoiceData?.id} on ${moment().format(
    "DD/MM/YYYY hh:mm a"
  )}.pdf`;
  a.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);
};

export const generateAndDownloadResultPdf = (
  arrayBuffer: ArrayBuffer,
  testName: string
) => {
  // Create a PDF Blob from the array buffer
  const blob = new Blob([arrayBuffer], { type: "application/pdf" });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create an <a> element to download the file
  const a = document.createElement("a");
  a.href = url;

  // Open the PDF in a new browser tab
  window.open(url, "_blank");

  //also download file
  a.download = `result# for ${testName} ${moment().format(
    "DD/MM/YYYY hh:mm a"
  )}.pdf`;
  a.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);
};
