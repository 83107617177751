import React, { SVGProps } from "react";

export interface GroupTestResultItem {
  testName: string;
  testResult: string;
  referenceRange: string;
  key: string;
  units: string;
}

/**svg */
export interface TsSvgProps extends SVGProps<SVGSVGElement> {
  strokecolor?: string;
  fillColor?: string;
  scale?: number;
}
export interface FormValues {
  [key: string]: string;
}

export interface DashboardCardProps {
  theme: string;
  title: string;
  value: string;
}

export interface ModalProps {
  show: boolean;
  onClose: (val: boolean) => void;
  children?: React.ReactNode;
  backdropClose?: boolean;
  dialogPanelExtraClass?: string;
}

export interface ExportDataModalProps {
  show: boolean;
  onClose: () => void;
  content: () => JSX.Element;
}

export interface LabModalProps {
  show: boolean;
  onClose: () => void;
  content: () => JSX.Element;
}

export interface WidalResultItem {
  testName: string;
  testCategory: string;
  O: string;
  H: string;
  key: string;
  makeResultAvailable: boolean;
}

export interface SerologyItem {
  testName: string;
  testFindings: string;
  makeResultAvailable: boolean;
}

export interface GenotypeItem {
  testName: string;
  testResult: string;
}

export interface GeneralTestResultItem {
  testName: string;
  testResult: string;
  referenceRange: string;
  key: string;
  units: string;
  makeResultAvailable: boolean;
  testCategory: string;
}

export interface TestTableColumnItem {
  label: string;
  key: string;
  customColumnClass?: string;
}

export interface TestTableComponentProps {
  columns: TestTableColumnItem[];
  data: any[];
  extraStyles?: React.CSSProperties;
  extraRowClasses?: string;
}

export interface MCSResultInterface {
  testName: string;
  appearance: string;
  pusCells: string;
  yeastCells: string;
  sensitivity: string;
  micro: string;
  epithelialCells: string;
  bacterialCells: string;
  culture: string;
  resistantTo: string;
  others?: string;
  comments?: string;
}

export interface ECSResultInterface {
  testName: string;
  wetPrep: string;
  epithelialCells: string;
  bacterialCells: string;
  culture: string;
  resistantTo: string;
  pusCells: string;
  yeastCells: string;
  others: string;
  sensitivity: string;
  comment: string;
  makeResultAvailable: boolean;
}

export interface MolecularAssayResultInterface {
  specimen: string;
  testDescription: string;
  result: string;
  units: string;
  log: string;
  lod: string;
  notes: string;
  makeResultAvailable: boolean;
}

export interface SemenAnalysisResultInterface {
  testResult: string;
  makeResultAvailable: boolean;
}

export interface HistologyTestResultInterface {
  materialCollected: string;
  clinician: string;
  slideNumber: string;
  macroscopy: string;
  diagnosis: string;
  testName: string;
  clinicalSummaryOrDiagnosis: string;
  microscopy: string;
  comments: string;
  consultantPathologist: string;
  makeResultAvailable: boolean;
}

export interface GynaecologicCytologyResultInterface {
  specimenSource: string;
  clinicalComment: string;
  clinician: string;
  LMP: string;
  priorPapSmearDate: string;
  priorPapSmearDiagnosis: string;
  collectedMethod: string;
  slideNumber: string;
  specimenAdequacy: string[];
  transformationZone: string;
  diagnosis: {
    NILM: "YES" | "NO";
    epithelialCellAbnormalities: "YES" | "NO";
    squamousCell: string[];
    glandularCell: string[];
  };
  otherFindings: string[];
  comments: string;
  recommendatation: {
    routineRecall: "YES" | "NO";
    repeatSmear: {
      whenToRepeatSmear: string;
      inHowManyMonths: number;
    };
    ensuringEndocervicalSampling: "YES" | "NO";
    colposcopyAndBiospy: "YES" | "NO";
    highRiskHPVTesting: "YES" | "NO";
  };
  consultantPathologist: string;
}

export interface NonGynaecologicCytologyResultInterface {
  materialCollected: string;
  clinician: string;
  slideNumber: string;
  macroscopy: string;
  diagnosis: string;
  testName: string;
  clinicalSummaryOrDiagnosis: string;
  microscopy: string;
  comments: string;
  consultantPathologist: string;
  makeResultAvailable: boolean;
}

export interface LabAllFormValuesInterface {
  generalTestResult?: GeneralTestResultItem[];
  widalScreeningResult?: WidalResultItem[];
  MCSResult?: MCSResultInterface;
  ECSResult?: ECSResultInterface;
  MolecularAssayResult?: MolecularAssayResultInterface;
  semenAnalysisResult?: SemenAnalysisResultInterface;
  //
  fullBloodCountResult?: GroupTestResultItem[];
  liverFunctionTestResult?: GroupTestResultItem[];
  renalFunctionTest?: GroupTestResultItem[];
  lipidProfile?: GroupTestResultItem[];
  histologyTest?: HistologyTestResultInterface;
  //
  gynaecologicCytology?: GynaecologicCytologyResultInterface;
  nongynaecologicCytology?: NonGynaecologicCytologyResultInterface;
}

export interface LabTestsProviderValues {
  isUpdateMode?: boolean;
  setIsUpdateMode?: React.Dispatch<React.SetStateAction<boolean>>;
  originalTestResult?: any;
  setOriginalTestResult?: React.Dispatch<React.SetStateAction<boolean>>;
  allFormValues?: LabAllFormValuesInterface | undefined;
  setAllFormValues?: any;
  labTestResultId?: string | undefined;
  setLabTestResultId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface AppContextProviderValues {
  hideSideBar?: boolean;
  setHideSideBar?: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum ResultTemplateType {
  FULL_BLOOD_COUNT_TEMPLATE = 'FULL_BLOOD_COUNT_TEMPLATE',
  ECS_TEMPLATE = 'ECS_TEMPLATE',
  GENERAL_TEST_TEMPLATE = 'GENERAL_TEST_TEMPLATE',
  HISTOLOGY_TEST_TEMPLATE = 'HISTOLOGY_TEST_TEMPLATE',
  LIVER_FUNCTION_TEST_TEMPLATE = 'LIVER_FUNCTION_TEST_TEMPLATE',
  RENAL_FUNCTION_TEST_TEMPLATE = 'RENAL_FUNCTION_TEST_TEMPLATE',
  MCS_TEST_TEMPLATE = 'MCS_TEST_TEMPLATE',
  SEROLOGY_TEST_TEMPLATE = 'SEROLOGY_TEST_TEMPLATE',
  NON_GYNAECOLOGY_TEST_TEMPLATE = 'NON_GYNAECOLOGY_TEST_TEMPLATE',
  SEMEN_ANALYSIS_TEST_TEMPLATE = 'SEMEN_ANALYSIS_TEST_TEMPLATE',
  WIDAL_SCREENING_TEST_TEMPLATE = 'WIDAL_SCREENING_TEST_TEMPLATE',
  LIPID_PROFILE_TEST_TEMPLATE = 'LIPID_PROFILE_TEST_TEMPLATE',
  GYNAECOLOGY_TEST_TEMPLATE = 'GYNAECOLOGY_TEST_TEMPLATE',
  MOLECULAR_ASSAY_TEST_TEMPLATE = 'MOLECULAR_ASSAY_TEST_TEMPLATE',
  HIV_HPYLORI_HBSAG_TEST_TEMPLATE = 'HIV_HPYLORI_HBSAG_TEST_TEMPLATE',
  HORMONAL_PROFILE_TEST_TEMPLATE = 'HORMONAL_PROFILE_TEST_TEMPLATE',
  CLOTTING_PROFILE_TEST_TEMPLATE = 'CLOTTING_PROFILE_TEST_TEMPLATE',
  THYROID_FUNCTION_TEST_TEMPLATE = 'THYROID_FUNCTION_TEST_TEMPLATE',
  TUMOR_MARKERS_TEST_TEMPLATE = 'TUMOR_MARKERS_TEST_TEMPLATE',
  BLOOD_GROUP_TEST_TEMPLATE = 'BLOOD_GROUP_TEST_TEMPLATE',
  GENOTYPE_TEST_TEMPLATE = 'GENOTYPE_TEST_TEMPLATE',
  MALARIA_PARASITE_TEST_TEMPLATE = 'MALARIA_PARASITE_TEST_TEMPLATE',

}
