import * as React from "react";
import { GrClose } from "react-icons/gr";

import LabModal from "./LabModal";

import { LabModalProps } from "../../@types/app-types";
import moment from "moment";

const ExportMedicalReport = (props: LabModalProps) => {
  const renderHeader = () => {
    return (
      <>
        <div
          onClick={props.onClose}
          className="ml-[auto] w-[23px] cursor-pointer mb-[20px]"
        >
          <GrClose />
        </div>
        <div className="flex items-center justify-between">
          <div className="">
            <div className="font-[500] text-[13px] mb-[7px]">
              {moment().format("Do MMMM YYYY")}
            </div>
            <div className="text-[#717171] text-[12px]">
              <h2>Export Medical Report</h2>
            </div>
          </div>
        </div>

        <div className="w-full h-[2px] bg-[#4A9DFF29] my-[20px]"></div>
      </>
    );
  };

  return (
    <>
      <LabModal show={props.show} onClose={props.onClose}>
        {renderHeader()}
        {props.content()}
      </LabModal>
    </>
  );
};

export default ExportMedicalReport;
