import moment from "moment";
import * as React from "react";
import { useParams } from "react-router-dom";
import { VscOutput } from "react-icons/vsc";

import TestDelete from "../../components/modals/TestDelete";
import UpdateLabTest from "../../components/modals/UpdateLabTest";
import AppButton from "../../components/UI/button";
// import {  } from '../../store/rtk-query/labApi'

import { MdModeEditOutline, MdOutlineClose } from "react-icons/md";
import { toast } from "react-toastify";
import Spinner from "../../components/UI/Spinner";
import { useGetAllInventoryItemsQuery } from "../../store/rtk-query/inventoryApi";
import Paginate from "../../components/Paginate";

const Page = () => {
  const [showDeleteConfirm, setShowDelete] = React.useState(false);
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [selectedTest, setSelected] = React.useState<any>();

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  let { labType } = useParams();
  //format lab types with real slash
  labType = labType?.replaceAll("_slash_", "/");

  const itemType = labType?.toUpperCase();

  const { data: itemsData, isLoading: itemsLoading } =
    useGetAllInventoryItemsQuery({
      params: {
        ...(itemType && { itemType: itemType }),
        skip: itemOffset,
        take: perPage,
      },
    });

  const renderResults = () => {
    return (
      <div className="w-full">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            className="w-full my-4 font-semibold bg-emerald-100 p-4 border border-sky-200 rounded-lg shadow-md"
            style={{ display: "flex", alignItems: "center" }}
          >
            <VscOutput size="1.5rem" style={{ marginRight: "0.5rem" }} />
            Results
            {itemsLoading && <Spinner />}
          </div>
        </div>
        <div className="tableContainer relative  shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3"></th>
                <th scope="col" className="px-6 py-3 bg-gray-50 max-w-[50px]">
                  Test Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Test Category
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Reference Range
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Units
                </th>
                <th scope="col" className="px-6 py-3">
                  Date Added
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Added by
                </th>
                <th scope="col" className="px-6 py-3 bg-white">
                  Updated Last on
                </th>
                <th scope="col" className="px-6 py-3  bg-gray-50 ">
                  Updated by
                </th>
              </tr>
            </thead>
            <tbody>
              {itemsData?.data?.map((test: any) => {
                return (
                  <tr key={test?.id} className="border-b border-gray-200 ">
                    <td className="px-6 py-4">#{test?.id}</td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      {test?.itemName}
                    </th>
                    <td className="px-6 py-4">{test?.itemType}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{test?.cost}</td>
                    <td className="px-6 py-4"></td>
                    <td className="px-6 py-4 bg-gray-50 "></td>
                    <td className="px-6 py-4">
                      {moment(test?.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">{test?.addedBy}</td>
                    <td className="px-6 py-4 flex gap-x-2 gap-y-1 flex-wrap bg-white ">
                      {moment(test?.updatedAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 "></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-2">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          {labType ? labType : "All"} Laboratory Tests
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-slate-50 border border-gray-200 rounded-lg shadow-md ">
        {renderResults()}
        {itemsData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={itemsData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
        {/* {
                    deleteTestState.isLoading || updateTestState.isLoading && <Spinner />
                } */}
        {/* <TestDelete
                    show={showDeleteConfirm}
                    onClose={() => {
                        setShowDelete(false)
                        setSelected(undefined)
                    }}
                    onDelete={onDeleteLabTest}
                    data={selectedTest}
                />
                <UpdateLabTest
                    onSubmit={onUpdateLabTest}
                    show={showUpdate}
                    onClose={() => {
                        setShowUpdate(false)
                        setSelected(undefined)
                    }}
                    data={selectedTest}
                /> */}
      </div>
    </div>
  );
};

export default Page;
