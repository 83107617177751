import * as React from 'react';

import logoImage from "../assets/images/labnetwork_logo.svg";

const Logo = () => {

    return (<a className="flex items-center  px-3 mt-3" href="#">
        <img
            src={logoImage}
            className="hidden md:inline mr-3 h-full  w-full "
            alt="Labnetwork Logo"
        />
    </a>)
}

export default Logo