import { Field, FormikProvider, useFormik } from "formik";
import * as React from "react";

import _ from "lodash";
import {
  GeneralTestResultItem,
  LabTestsProviderValues,
  ResultTemplateType,
} from "../../@types/app-types";
import { updatedDiff } from "deep-object-diff";
import LabTestProvider, { LabTestContext } from "../../context/LabTestProvider";
import AppButton from "../UI/button";
import moment from "moment";
import LabNetQuillEditor from "../LabNetQuillEditor";
import { usePrintResultMutation } from "../../store/rtk-query/labResult";
import {
  formatServerErrorMessage,
  generateAndDownloadResultPdf,
} from "../../utils/functions";
import { toast } from "react-toastify";
import Spinner from "../UI/Spinner";

interface Props {
  onChangeForm: any;
  laboratoryTests: any[] | undefined;
}

interface TestItem {
  label: string;
  key: string;
  testCategory: string;
  resultObj?: any;
}

const GeneralTest = (props: Props) => {
  const { originalTestResult, isUpdateMode, allFormValues, labTestResultId } =
    React.useContext<LabTestsProviderValues>(LabTestContext);

  const [printResult, printResultState] = usePrintResultMutation();

  let tests: TestItem[] = [];

  const origGeneralTestResult = originalTestResult?.generalTest;

  // console.log('In gen allFormValues ', allFormValues)

  //build default test items
  if (props.laboratoryTests) {
    tests = props.laboratoryTests.map((test: any) => {
      const item: TestItem = {
        label: test?.testName,
        key: test?.testName?.replace(/\s/g, ""),
        testCategory: test?.testCategory,
      };
      return item;
    });
  }

  //if its update mode then rebuild whole forms with original test object
  if (isUpdateMode && originalTestResult?.generalTest) {
    tests = originalTestResult?.generalTest.map((test: any) => {
      const item: TestItem = {
        label: test?.testName,
        key: test?.testName?.replace(/\s/g, ""),
        testCategory: test?.testCategory,
        resultObj: test,
      };
      return item;
    });
  }

  const formColumns = [
    //will become {testName}_result
    "comments",
    "result",
    //will become {testName}_refRange
    "refRange",
    //
    "key",
    "units",
    "makeAvailable",
  ];

  //build initialValues from tests and there respective colums
  let initialValues = React.useMemo(() => {
    let formikKeys: string[] = [];
    //loop through test
    tests.forEach((test) => {
      let testKey = test.key;

      //loop through form columns and generate formik form keys;

      formColumns.forEach((_formCol) => {
        //example is urineMcs_result
        let formikKey = `${testKey}_${_formCol}`;
        formikKeys.push(formikKey);
      });
    });

    //build object from formik keys
    let values: any = {};

    formikKeys.forEach((_key) => {
      const testNameKey = _key?.split("_")[0]; //e.g StoolMCS
      const rawFieldKey = _key?.split("_")[1]; //e.g refRange

      //in create mode, run this
        if (testNameKey === "RandomBloodSugar") {
          values[`${testNameKey}_result`] = "";
          values[`${testNameKey}_refRange`] = "70 - 115";
          values[`${testNameKey}_units`] = "mg/dL";
          values[`${testNameKey}_comments`] =
            "RBS and OGTT values of ≥160mg/dl and ≥200mg/dl respectively on two different testing occasions are considered diabetic.";
        }

        if (testNameKey === "FastingBloodSugar") {
          values[`${testNameKey}_result`] = "";
          values[`${testNameKey}_refRange`] = "70 - 115";
          values[`${testNameKey}_units`] = "mg/dL";
          values[`${testNameKey}_comments`] =
            "FBS and OGTT values of ≥126mg/dl and ≥200mg/dl respectively on two different testing occasions are considered diabetic.";
        }

        if (testNameKey === "TotalPSA") {
          values[`${testNameKey}_result`] = "";
          values[`${testNameKey}_refRange`] = "0.0 – 4.0";
          values[`${testNameKey}_units`] = "ng/ml";
          values[`${testNameKey}_comments`] =
            "PSA results between 4.0 and 10.0 ng/ml may indicate benign prostatic hypertrophy. Results greater than 10.0 ng/ml are more suggestive of prostatic cancer and should be evaluated by follow up prostatic biopsy.";
        }

        if (testNameKey === "GlycatedHB(HbA1C)") {
          values[`${testNameKey}_result`] = "";
          values[
            `${testNameKey}_refRange`
          ] = `4.0-6.0% Normal <5.7% Prediabetes;
          5.7% to 6.4% Diabetes 6.5% and above Target of therapy <7.0% Values; >8.0% indicate poor glycemic control and predispose to micro – vascular complications, e.g., Renal failure and blindness
          `;
          values[`${testNameKey}_units`] = "%";
          values[`${testNameKey}_comments`] =
            "Estimated Average Glucose: 154.2mg/dL as calculated from the HbA1c value";
        }

        if (testNameKey === "TotalCalcium") {
          values[`${testNameKey}_result`] = "";
          values[
            `${testNameKey}_refRange`
          ] = `2.2 - 2.70`;
          values[`${testNameKey}_units`] = "mmol/L";            
        }

        if (testNameKey === "Phosphorus") {
            values[`${testNameKey}_result`] = "";
            values[
              `${testNameKey}_refRange`
            ] = `0.8 - 1.5`;
            values[`${testNameKey}_units`] = "mmol/L";            
          }

          if (testNameKey === "UricAcid") {
            values[`${testNameKey}_result`] = "";
            values[
              `${testNameKey}_refRange`
            ] = `3.5 – 7.2`;
            values[`${testNameKey}_units`] = "mg/dL";            
          }

          if (testNameKey === "UricAcid") {
            values[`${testNameKey}_result`] = "";
            values[
              `${testNameKey}_refRange`
            ] = `3.5 – 7.2`;
            values[`${testNameKey}_units`] = "mg/dL";            
          }
          
        //initialize as boolean instead
        if (_key?.includes("makeAvailable")) {
          values[_key] = false;
          return;
        }

        values[_key] = "";

      if (isUpdateMode) {
        //find test
        const test = tests.find((test) => test.key == testNameKey);
        values[_key] = "";

        //ignore if result doesnt exist
        if (!test || !test?.resultObj) return;

        values[`${testNameKey}_result`] = test?.resultObj?.testResult || "";
        values[`${testNameKey}_refRange`] =
          test?.resultObj?.referenceRange || "";
        values[`${testNameKey}_units`] = test?.resultObj?.units || "";
        values[`${testNameKey}_key`] = test?.resultObj?.key || "";
        values[`${testNameKey}_comments`] = test?.resultObj?.comments || "";
        values[`${testNameKey}_makeAvailable`] =
          test?.resultObj?.makeResultAvailable || false;
      }
    });
    return values;
  }, [props.laboratoryTests, originalTestResult]);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  React.useEffect(() => {
    console.log("formit", formik.values);
    if (Object.keys(formik.values).length == 0) return;

    /**
     * remove this form key from final result compilation if user
     * removes all changes
     */
    const updatedObj = updatedDiff(initialValues, formik.values);

    if (!isUpdateMode) {
      if (Object.keys(updatedObj).length == 0) {
        props.onChangeForm(undefined, "generalTestResult");
        return;
      }
    }

    //if updating result, then all fields do not need updating
    const array = convertResultToArray(formik.values);

    props.onChangeForm(array, "generalTestResult");
  }, [formik.values]);

  const onPrintResult = async () => {
    const res: any = await printResult({
      labResultId: labTestResultId,
      queryParams: {
        templateType: ResultTemplateType.GENERAL_TEST_TEMPLATE,
      },
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadResultPdf(res?.data, "GeneralTests");

    toast.success("Result pdf generated successfully");
  };

  function convertResultToArray(inputObj: {
    [key: string]: any;
  }): GeneralTestResultItem[] {
    const resultArray: GeneralTestResultItem[] = [];
    const addedTestNames: { [key: string]: boolean } = {};

    _.forEach(inputObj, (value, key) => {
      const testName = key.split("_")[0];
      const testLabelName = tests.find((test) => test?.key == testName)
        ?.label as string;
      const testCategory = tests.find((test) => test?.key == testName)
        ?.testCategory as string;

      if (!addedTestNames[testName]) {
        // const otherProps = _.omit(inputObj, `${testName}_result`, `${testName}_refRange`, `${testName}_key`, `${testName}_units`, `${testName}_makeAvailable`);

        const testObj: GeneralTestResultItem = {
          testName: testLabelName,
          testResult: inputObj[`${testName}_result`],
          referenceRange: inputObj[`${testName}_refRange`],
          key:
            inputObj[`${testName}_key`]?.toString() == "on" ? "true" : "false",
          units: inputObj[`${testName}_units`],
          makeResultAvailable: inputObj[`${testName}_makeAvailable`],
          testCategory: testCategory,
        };

        resultArray.push(testObj);
        addedTestNames[testName] = true;
      }
    });

    return resultArray;
  }

  const inputClasses =
    "bg-white border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

  return (
    <FormikProvider value={formik}>
      <div className="text-2xl font-semibold mb-2"> General Test</div>
      {isUpdateMode && origGeneralTestResult?.[0] && (
        <div className="text-[#c09853] text-lg mb-5">
          Updated on{" "}
          {moment(origGeneralTestResult?.[0]?.updatedAt).format(
            "DD ddd MMM[,] YYYY"
          )}
        </div>
      )}
      <div className="text-[#c09853] text-md">
        {" "}
        Key: L= Low, H = High, WNL= Within Normal Limits, *= Critical value
      </div>
      <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg">
        <table className="horiz-table w-full text-sm">
          <thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="px-6 py-3 bg-gray-50 ">
                Test Name
              </th>
              <th scope="col" className="px-6 py-3 min-w-[300px]">
                Test Result
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 min-w-[200px] ">
                Ref Range
              </th>
              <th scope="col" className="px-6 py-3 min-w-[100px]">
                Key
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 ">
                Units
              </th>
              <th scope="col" className="px-6 py-3">
                Results typed by
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 ">
                Make result available on client portal?
              </th>
            </tr>
          </thead>
          <tbody>
            {tests?.map((test) => {
              return (
                <tr key={test?.key} className="border-b border-gray-200 ">
                  <td className="px-6 py-3 bg-gray-50 ">{test.label}</td>
                  <td className="px-6 py-3 min-w-[300px]">
                    <LabNetQuillEditor
                      theme="snow"
                      value={formik.values[`${test.key}_result`]}
                      onChange={(val: any) =>
                        formik.setFieldValue(`${test.key}_result`, val)
                      }
                    />
                  </td>
                  <td className="px-6 py-3 bg-gray-50 min-w-[120px] ">
                    <Field
                      as="textarea"
                      name={`${test.key}_refRange`}
                      className={inputClasses}
                    ></Field>
                  </td>
                  <td className="px-6 py-3">
                    <Field name={`${test.key}_key`} className={inputClasses} />
                  </td>
                  <td className="px-6 py-3 bg-gray-50 ">
                    <Field
                      name={`${test.key}_units`}
                      className={inputClasses}
                    />
                  </td>
                  <td className="px-6 py-3"></td>
                  <td className="px-6 py-3 bg-gray-50 ">
                    <Field type="checkbox" name={`${test.key}_makeAvailable`} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex gap-3 pb-[80px] mt-4">
        {tests?.map((test) => {
          return (
            <>
              <label
                className={labelClasses}
              >{`${test.label} Comments:`}</label>
              <LabNetQuillEditor
                theme="snow"
                value={formik.values[test.key + "_comments"]}
                onChange={(val: any) =>
                  formik.setFieldValue(`${test.key}_comments`, val)
                }
              />
            </>
          );
        })}
      </div>
      {isUpdateMode && origGeneralTestResult?.[0] && (
        <div className="flex gap-1 mt-8">
          {/* <AppButton
                        buttontype={'danger'}
                        title='Generate Report without letter head'
                        extraclass='!text-xs'
                    /> */}
          <AppButton
            onClick={onPrintResult}
            buttontype={"cyan"}
            title="Generate Result"
            extraclass="!text-xs"
          />
          {printResultState.isLoading && <Spinner />}
        </div>
      )}
    </FormikProvider>
  );
};

export default GeneralTest;
