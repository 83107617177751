import { updatedDiff } from 'deep-object-diff';
import { Field, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { toast } from 'react-toastify';

import { LabTestsProviderValues, ResultTemplateType } from '../../../@types/app-types';
import { LabTestContext } from '../../../context/LabTestProvider';
import { usePrintResultMutation } from '../../../store/rtk-query/labResult';
import { formatServerErrorMessage, generateAndDownloadResultPdf } from '../../../utils/functions';
import LabNetQuillEditor from '../../LabNetQuillEditor';
import AppButton from '../../UI/button';
import Spinner from '../../UI/Spinner';

interface Props {
    onChangeForm: any
}
const MCS = (props: Props) => {
    const {
        originalTestResult,
        isUpdateMode,
        labTestResultId
    } = React.useContext<LabTestsProviderValues>(LabTestContext)

    const [printResult, printResultState] = usePrintResultMutation()


    const mcsResult = originalTestResult?.MCS?.[0]
    let initVals = {
        appearance: '',
        pusCells: '',
        yeastCells: '',
        micro: '',
        epithelialCells: '',
        bacterialCells: '',
        testName: '',
        //
        comments: '',
        //
        culture: '',
        sensitivity: '',
        resistantTo: '',
        others: ''
    }

    let initialValues = React.useMemo(() => {


        if (isUpdateMode) {

            if (mcsResult) {
                initVals['bacterialCells'] = mcsResult?.bacterialCells
                initVals['epithelialCells'] = mcsResult?.epithelialCells
                initVals['appearance'] = mcsResult?.appearance
                initVals['culture'] = mcsResult?.culture
                initVals['micro'] = mcsResult?.micro
                initVals['comments'] = mcsResult?.comment
                initVals['testName'] = mcsResult?.testName
                initVals['others'] = mcsResult?.others
                initVals['pusCells'] = mcsResult?.pusCells
                initVals['resistantTo'] = mcsResult?.resistantTo
                initVals['sensitivity'] = mcsResult?.sensitivity
                initVals['yeastCells'] = mcsResult?.yeastCells
            }
        }

        return initVals
    }, [isUpdateMode, originalTestResult])

    const formik = useFormik({
        initialValues
        ,
        onSubmit: values => {

        },
        enableReinitialize: true
    });

    React.useEffect(() => {
        /**
      * remove this form key from final result compilation if user
      * removes all changes
      */
        const updatedObj = updatedDiff(initVals, formik.values)
        if (Object.keys(updatedObj).length == 0) {
            props.onChangeForm(undefined, 'MCSResult')
            return
        }

        props.onChangeForm(
            {
                ...formik.values,
                makeResultAvailable: true
            }, 'MCSResult')
    }, [formik.values])

    const onPrintResult = async () => {
        const res: any = await printResult({
            labResultId: labTestResultId,
            queryParams: {
                templateType: ResultTemplateType.MCS_TEST_TEMPLATE
            }
        });

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }

        generateAndDownloadResultPdf(res?.data, 'MCSTest')

        toast.success("Result pdf generated successfully");
    };

    const inputClasses =
        "bg-gray-50 border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

    return (
        <FormikProvider
            value={formik}
        >
            {isUpdateMode && mcsResult && <div className='text-[#c09853] text-lg mb-5'>
                Updated on {moment(mcsResult?.updatedAt).format('DD ddd MMM[,] YYYY')}
            </div>}
            <div className='grid grid-cols-2 gap-3'>
                <div className='flex'>
                    <label className={labelClasses}>APPEARANCE:
                    </label>
                    <Field
                        name="appearance"
                        as='textarea'
                        className={inputClasses} />
                </div>
                <div className='flex'>
                    <label className={labelClasses}>MICRO:
                    </label>
                    <Field name="micro" className={inputClasses} />
                </div>
                <div className='flex'>
                    <label className={labelClasses}>PUS CELLS:
                    </label>
                    <Field name="pusCells" className={inputClasses} />
                </div>
                <div className='flex'>
                    <label className={labelClasses}>EPITEHLIAL CELLS:
                    </label>
                    <Field
                        name="epithelialCells"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>YEAST CELLS:
                    </label>
                    <Field
                        name="yeastCells"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>BACTERIA CELLS:
                    </label>
                    <Field
                        name="bacterialCells"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>OTHERS:
                    </label>
                    <Field name="others" className={inputClasses} />
                </div>
                <div className='flex gap-3 pb-[80px]'>
                    <label className={labelClasses}>Culture:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.culture}
                        onChange={(val: any) => formik.setFieldValue('culture', val)} />
                </div>
                <div className='flex gap-3 pb-[80px]'>
                    <label className={labelClasses}>Sensitivity:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.sensitivity}
                        onChange={(val: any) => formik.setFieldValue('sensitivity', val)} />
                </div>
                <div className='flex gap-3 pb-[80px]'>
                    <label className={labelClasses}>Resistant To:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.resistantTo}
                        onChange={(val: any) => formik.setFieldValue('resistantTo', val)} />
                </div>
                <div className='flex gap-3 pb-[80px] cols-span-2'>
                    <label className={labelClasses}>Comments:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.comments}
                        onChange={(val: any) => formik.setFieldValue('comments', val)} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>Test Name:
                    </label>
                    <Field name="testName" className={inputClasses} />
                </div>
            </div>
            {
                isUpdateMode && mcsResult && <div className='flex gap-1 mt-8'>
                    <AppButton
                        onClick={onPrintResult}
                        buttontype={'cyan'}
                        title='Generate Result'
                        extraclass='!text-xs'
                    />
                    {printResultState.isLoading && <Spinner />}
                </div>
            }
        </FormikProvider>
    )
}

export default MCS