import * as React from "react";

import AppButton from "../../components/UI/button";
import { Link, useNavigate } from "react-router-dom";

import {
  useFormikContext,
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  FormikProvider,
  useFormik,
} from "formik";
import FormikChildListener from "../../utils/FormikChildListener";
import {
  useDeleteTestOnQueueMutation,
  useGetAllTestOnQueueQuery,
} from "../../store/rtk-query/testQueueApi";
import moment from "moment";
import TestQueueDelete from "../../components/modals/TestQueueDelete";
import { toast } from "react-toastify";
import Spinner from "../../components/UI/Spinner";
import Paginate from "../../components/Paginate";

import { usePrintInvoiceMutation } from "../../store/rtk-query/invoiceApi";
import {
  formatServerErrorMessage,
  generateAndDownloadInvoicePdf,
} from "../../utils/functions";
import FilterResultHeader from "../../components/filterResultHeader";

enum TestPriorityLevel {
  ROUTINE = "ROUTINE",
  EMERGENCY = "EMERGENCY",
  BATCH = "BATCH",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

interface Item {
  priority: TestPriorityLevel,
  [key: string]: any
}

const Page = () => {
  const [showDeleteConfirm, setShowDelete] = React.useState(false);
  const [selectedItem, setSelected] = React.useState<any>();

  const navigate = useNavigate();

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const initialValues = {
    patientName: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const { data: testsOnQueueData, isLoading: testsOnQueueLoading } =
    useGetAllTestOnQueueQuery({
      params: {
        ...(formik.values.patientName && {
          searchPhrase: formik.values.patientName,
        }),
        skip: itemOffset,
        take: perPage,
      },
    });

  const [printInvoice, printInvoiceState] = usePrintInvoiceMutation();

  const [deleteFromQueue, deleteState] = useDeleteTestOnQueueMutation();

  const onDeleteItem = async () => {
    if (!selectedItem) {
      return;
    }

    const res: any = await deleteFromQueue({ testId: selectedItem?.id });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Queue item deleted successfully");
    setShowDelete(false);
    setSelected(undefined);
  };

  const onPrintInvoice = async (selectedItem: any) => {
    if (!selectedItem) {
      return;
    }

    const res: any = await printInvoice({ invoiceId: selectedItem?.invoiceId });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadInvoicePdf(res?.data, selectedItem);

    toast.success("Invoice pdf generated successfully");
  };

  
  type Priority = keyof typeof TestPriorityLevel;
  
  function assignColorToEnum(enumObject: Record<Priority, string>) {
    const colors = [
      "text-green-600",
      "text-red-600",
      "text-blue-600",
      "text-red-800",
      "text-yellow-600",
      "text-fuchsia-900",
    ] as const;
    const colorMap: Record<Priority, string> = {} as Record<Priority, string>;
  
    const enumKeys = Object.keys(enumObject) as Priority[];
  
    enumKeys.forEach((key, index) => {
      const colorIndex = index % colors.length;
      colorMap[key] = colors[colorIndex];
    });
  
    return colorMap;
  }
  
  const colorMap = assignColorToEnum(TestPriorityLevel);
  
  

  const renderSearchBar = () => {
    
    return (
      <div className="flex  w-full md:w-[350px]">
        <div className="relative w-full">
          <Field
            name="patientName"
            type="text"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                        "
            placeholder="Search Patient"
            required
          />
          <button
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </div>
    );
  };

  const renderHeading = () => {
    return (
      <div className="flex justify-between w-full">
        <FormikProvider value={formik}>
          <Form>{renderSearchBar()}</Form>
        </FormikProvider>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full">
        <FilterResultHeader
          title="All tests on Queue"
          isLoading={testsOnQueueLoading}
        />
        <div className="tableContainer relative  shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 "></th>
                <th scope="col" className="px-6 py-3">
                  Patient Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Doctor Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Test Priority
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Payment Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Created at
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Added by
                </th>
              </tr>
            </thead>
            <tbody>
              {testsOnQueueData?.data?.map((item: Item) => {
                 const priorityColor = colorMap[item?.priority];
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <AppButton
                        buttontype={"cyan"}
                        onClick={() =>
                          navigate("/attend-to-patient/" + item?.id)
                        }
                        title="Attend to Patient"
                      />
                      <br />
                      {item?.paymentStatus == "UNPAID" && (
                        <AppButton
                          buttontype={"green"}
                          onClick={() => navigate("/pay-invoice/" + item?.id)}
                          title="Pay Now"
                        />
                      )}
                      {item?.paymentStatus == "COMPLETED" && (
                        <AppButton
                          buttontype={"green"}
                          onClick={() => {
                            onPrintInvoice(item);
                          }}
                          title="Print invoice"
                        />
                      )}
                      <AppButton
                        title="Delete"
                        buttontype={"danger"}
                        onClick={() => {
                          setShowDelete(true);
                          setSelected(item);
                        }}
                      />
                    </td>
                    <td className="px-6 py-4">{item?.patientName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.orderingDoctorName}
                    </td>
                    <td className={`px-6 PY-4 ${priorityColor} font-bold`}>{item?.priority}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.status}</td>
                    <td className="px-6 py-4">{item?.paymentStatus}</td>
                    <td className="px-6 py-4">
                      {moment(item?.createdAt).format("DD-MM-YYYY  hh:mm A")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.addedBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Waiting Queue
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {renderHeading()}
        {renderResults()}
        {testsOnQueueData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={testsOnQueueData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
      </div>
      <TestQueueDelete
        show={showDeleteConfirm}
        onClose={() => {
          setSelected(undefined);
          setShowDelete(false);
        }}
        onDelete={onDeleteItem}
        data={selectedItem}
      />
    </div>
  );
};

export default Page;
