import { userApi } from "./userApi";

export const testQueueApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTestOnQueue: builder.query({
      query: ({ headers, params }) => {
        return {
          url: "test-queue/",
          method: "GET",
          params: { ...params },
        };
      },
      providesTags: ["TestsOnQueue"],
    }),
    getTestOnQueueDetails: builder.query({
      query: ({ testId }) => {
        return {
          url: "test-queue/info/" + testId,
          method: "GET",
        };
      },
    }),
    addTestToQueue: builder.mutation({
      query: ({ body, patientId }) => {
        return {
          url: "test-queue/add-test-to-queue/" + patientId,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["TestsOnQueue"],
    }),

    updateTestOnQueue: builder.mutation({
      query: ({ body, testId }) => {
        return {
          url: "test-queue/update/" + testId,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["TestsOnQueue"],
    }),
    deleteTestOnQueue: builder.mutation({
      query: ({ testId }) => {
        return {
          url: "test-queue/delete/" + testId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["TestsOnQueue"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTestOnQueueQuery,
  useGetTestOnQueueDetailsQuery,
  useAddTestToQueueMutation,
  useUpdateTestOnQueueMutation,
  useDeleteTestOnQueueMutation,
} = testQueueApi;
