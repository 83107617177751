import * as React from "react";
import _ from "lodash";

import { Form, Field, ErrorMessage, FormikProvider, useFormik } from "formik";

import AppButton from "../../components/UI/button";
import { formatServerErrorMessage } from "../../utils/functions";
import { toast } from "react-toastify";
import FilterHeader from "../filterHeader";
import { AiOutlineArrowRight } from "react-icons/ai";
import Spinner from "../UI/Spinner";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useExportMedReportQuery } from "../../store/rtk-query/patientApi";

const inputClasses =
  "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
const labelClasses = `block mt-3 mb-1 text-sm font-medium text-gray-900 `;
const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

export const RenderMedicalReportContent = () => {
  const { report_type } = useParams();
  const [isExporting, setIsExporting] = React.useState<boolean>(false);


  const initialValues = {
    dateFrom: "",
    dateTo: "",
    searchPhrase: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema={formValidationSchema}
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
    },
  });

  let queryParams: any = {
    ...(formik.values.dateFrom && {
      periodFrom: moment(formik.values.dateFrom).format("YYYY-MM-DD"),
    }),
    ...(formik.values.dateTo && {
      periodTo: moment(formik.values.dateTo).format("YYYY-MM-DD"),
    }),
    ...(formik.values.searchPhrase && {
      searchPhrase: formik.values.searchPhrase,
    }),
  };
  
  queryParams = _.omitBy(queryParams, (val) => {
    return val === undefined || val?.toString()?.trim() === "";
  });

  const { data, isLoading, isError } = useExportMedReportQuery({ queryParams, type: report_type });

  const onExportData = async () => {
    if (isLoading || isExporting) {
      return;
    }

    setIsExporting(true);

    try {
      if (isError) {
        let message = formatServerErrorMessage("An error occured.");

        if (message.length > 0) {
          toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        return;
      }

      const blob = new Blob([data], { type: "text/csv" }); // creates a Blob from the response data
      const url = window.URL.createObjectURL(blob); // creates a URL for the Blob
      const a = document.createElement("a"); // creates an <a> element to download the file
      a.href = url;
      a.download = `data_${moment().format('DD/MM/YYYY hh:mm A"')}.csv`;
      a.click();

      toast.success("Data exported successfully");
    } catch (error) {
      console.error("Export error:", error);
    } finally {
      setIsExporting(false);
    }
  };

  const renderSearchBar = () => {
    return (
      <div className="flex">
        <div className="relative w-full">
          <Field
            name="searchPhrase"
            type="text"
            id="search-dropdown"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg   border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                        "
            placeholder="Test name, patient, materials etc.
            "
          />
          <button
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">
            </span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
          <FilterHeader title={`Filter ${report_type} report`} />
          {renderSearchBar()}
          <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
                <div className="w-full sm:w-6/12">
                  <label className={labelClasses}>From</label>
                  <Field className={inputClasses} type="date" name="dateFrom" />
                </div>
                <div className="w-full sm:w-6/12">
                  <label className={labelClasses}>To</label>
                  <Field className={inputClasses} type="date" name="dateTo" />
                </div>
              </div>
            </div>
          <div className="flex justify-center pt-3">
            <AppButton onClick={onExportData}>
              <div className="flex justify-center gap-1">
                <p>Export Data</p> <AiOutlineArrowRight fontSize={20} />
              </div>
            </AppButton>
            {isLoading && <Spinner />}
          </div>
      </Form>
    </FormikProvider>
  );
};
