import * as React from "react";

import {
  useFormikContext,
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  useFormik,
  FormikProvider,
} from "formik";

import { VscOutput } from "react-icons/vsc";
import { useGetPatientDetailsQuery } from "../store/rtk-query/patientApi";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import FormikChildListener from "../utils/FormikChildListener";
import { useViewPatientsVisitsQuery } from "../store/rtk-query/patientApi";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import moment from "moment";
import Spinner from "../components/UI/Spinner";
import AppButton from "../components/UI/button";
import Paginate from "../components/Paginate";

interface PatientSchema {
  id: number;
  firstName: string;
  lastName: string;
  otherNames: string;
  regNumber: string;
  age: string;
  sex: string;
  qrCodeUrl: string;
}

const Page = () => {
  const navigate = useNavigate();
  const { patient_id } = useParams();

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);
  const [selectedPx, setSelectedPx] = React.useState<
    PatientSchema | undefined
  >();

  const patientId = patient_id;
  const { data: patientData } = useGetPatientDetailsQuery(
    {
      patientId,
    },
    {
      skip: !patientId,
    }
  );

  React.useEffect(() => {
    setSelectedPx(patientData);
  }, [patientData]);

  const initialValues = {
    //
    periodMonth: "",
    periodYear: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const queryParams: any = {
    ...(formik.values.periodMonth && { month: formik.values.periodMonth }),
    ...(formik.values.periodYear && { year: formik.values.periodYear }),
  };

  const { data: visitsData, isLoading: visitsLoading } =
    useViewPatientsVisitsQuery({
      patientId: patient_id,
      params: {
        ...queryParams,
        skip: itemOffset,
        take: perPage,
      },
    });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

  const renderHeading = () => {
    return (
      <div
        className="flex
            flex-wrap
            sm:flex-nowrap
            gap-2
            justify-between w-full"
      >
        <FormikProvider value={formik}>
          <Form>
            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <label className={labelClasses}>Filter by Period</label>
              <div className="flex gap-3">
                <Field className={inputClasses} as="select" name="periodMonth">
                  <option value="" disabled hidden>
                    Select Month
                  </option>

                  {months.map((month) => {
                    return (
                      <option value={month} key={month}>
                        {month}
                      </option>
                    );
                  })}
                </Field>
                <Field
                  className={inputClasses}
                  placeholder="year"
                  type="number"
                  name="periodYear"
                />
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full">
        <div className="flex gap-4 items-center">
          <div className="font-semibold my-2">Results</div>
          {visitsLoading && <Spinner />}
        </div>
        <div className="tableContainer relative  shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 w-[40px]"></th>
                <th scope="col" className="px-6 py-3">
                  Visit
                  <br /> Number
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Date of
                  <br /> Treatment
                </th>
                <th scope="col" className="px-6 py-3">
                  Month
                  <br />
                  Visited
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Year
                  <br />
                  visited
                </th>
                <th scope="col" className="px-6 py-3">
                  Remark
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Consultant/Physician/
                  <br />
                  Lab Scientist
                </th>
              </tr>
            </thead>
            <tbody>
              {visitsData?.data?.map((visit: any, index: number) => {
                return (
                  <tr key={visit?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <AppButton
                        title="Visit Visit"
                        onClick={() =>
                          navigate("/patient-visits/update/" + visit?.id)
                        }
                        buttontype="green"
                      />
                    </td>
                    <td className="px-6 py-4">{index + 1}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {moment(visit?.dateOfTreatment).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">{visit?.monthVisited}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {visit?.yearVisited}
                    </td>
                    <td className="px-6 py-4">{visit?.remark}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {visit?.doctorOrLabScientistName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Patients Visits
        </h3>
      </div>
      {selectedPx ? (
        <>
          <div className="w-11/12 mx-auto mt-5 mb-5 border bg-slate-50 rounded-lg p-6 space-y-6">
            <div
              className="container"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                className="w-full font-semibold bg-sky-200 p-4 border border-sky-200 rounded-lg shadow-md"
                style={{ display: "flex", alignItems: "center" }}
              >
                <IoCheckmarkDoneCircle
                  size="1.5rem"
                  style={{ marginRight: "0.5rem" }}
                />
                Selected Patient
              </div>
            </div>
            <div className="flex justify-between">
              {/* First column: patient name */}
              <div className="mr-8">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Name
                </label>
                <div className="text-md">
                  {/* Show selected patient's name */}
                  {selectedPx?.firstName} {selectedPx?.lastName}{" "}
                  {selectedPx?.otherNames}
                </div>
              </div>
              <div className="mr-8">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Age
                </label>
                <div className="text-md">
                  {/* Show selected patient's name */}
                  {selectedPx?.age}{" "}
                </div>
              </div>
              <div className="mr-8">
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Sex
                </label>
                <div className="text-md">
                  {/* Show selected patient's name */}
                  {selectedPx?.sex}{" "}
                </div>
              </div>
              {/* Second column: patient registration number */}
              <div>
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Reg No
                </label>
                <div className="text-md">
                  {/* Show selected patient's registration number */}
                  {selectedPx?.regNumber}
                </div>
              </div>
              <div>
                <label className="block text-gray-600 text-sm font-bold mb-2">
                  Qr Code
                </label>
                <div className="text-md">
                  <img src={selectedPx?.qrCodeUrl} alt="QR code" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : undefined}
      <div className="w-11/12 mx-auto mt-5 mb-5 p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            className="w-full my-4 font-semibold bg-emerald-100 p-4 border border-sky-200 rounded-lg shadow-md"
            style={{ display: "flex", alignItems: "center" }}
          >
            <VscOutput size="1.5rem" style={{ marginRight: "0.5rem" }} />
            Patient Visit Records
          </div>
        </div>
        {renderHeading()}
        {renderResults()}
        {visitsData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={visitsData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
      </div>
    </div>
  );
};

export default Page;
