import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";
import ItemsForm from "../components/forms/ItemsForm";
import { toast } from "react-toastify";
import moment from "moment";

import { useAddNewDocOrLabSciMutation } from "../store/rtk-query/docOrLabsciApi";

import AddSaleForm from "../components/forms/AddSaleForm";
import { useAddNewSaleMutation } from "../store/rtk-query/saleApi";
import { formatServerErrorMessage } from "../utils/functions";

interface AddSaleValues {
  patientId: undefined | number;
  itemId: undefined | number;
  quantity: undefined | number;
  amountPayable: undefined | number;
  modeOfPayment: string;
  transactionDate: string;
}

const AddSale = () => {
  const [addSale, addSaleState] = useAddNewSaleMutation();

  let initialValues: AddSaleValues = {
    patientId: undefined,
    itemId: undefined,
    quantity: undefined,
    amountPayable: undefined,
    modeOfPayment: "",
    transactionDate: "",
  };

  const onSubmit = async (values: typeof initialValues) => {
    let data = {
      patientId: values.patientId,
      itemId: values.itemId,
      quantity: values.quantity,
      amountPayable: values.amountPayable,
      modeOfPayment: values.modeOfPayment,
      transactionDate: moment(values.transactionDate).format("MM/DD/YYYY"),
    };

    const res: any = await addSale(data);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Sale added successfully");
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Add Sale</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <AddSaleForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          isLoading={addSaleState.isLoading}
        />
      </div>
    </div>
  );
};

export default AddSale;
