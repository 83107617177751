import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";
import ItemsForm from "../components/forms/ItemsForm";
import { toast } from "react-toastify";
import moment from "moment";

import { useAddNewDocOrLabSciMutation } from "../store/rtk-query/docOrLabsciApi";

import AddStaffForm from "../components/forms/AddStaffForm";
import { useAddNewStaffMutation, useGetStaffInfoQuery, useUpdateUserMutation } from "../store/rtk-query/staffApi";
import { useParams } from "react-router-dom";
import { formatServerErrorMessage } from "../utils/functions";

const AddStaff = () => {
  const [addStaff, addStaffState] = useAddNewStaffMutation();
  const [updateStaff, updateStaffState] = useUpdateUserMutation();

  const { staff_id } = useParams()

  const { data: staffData } = useGetStaffInfoQuery({
    uid: staff_id
  }, {
    skip: !staff_id
  })

  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: ''
  };

  console.log('staffData ', staffData)

  if (staffData) {
    initialValues.firstName = staffData.appUserData?.firstName
    initialValues.lastName = staffData.appUserData?.lastName
    initialValues.email = staffData.appUserData?.email
    initialValues.phoneNumber = staffData.firebaseUserData?.phoneNumber
    initialValues.role = staffData.appUserData?.accountCategory
  }

  const isUpdateMode = staffData != undefined

  const onSubmit = async (values: typeof initialValues) => {
    let data: any = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      password: values.password,
      role: values.role
    };

    console.log('data ', data)

    let res: any

    if (isUpdateMode) {
      delete data['password'];
      res = await updateStaff({ body: data, staffId: staff_id });
    } else {
      res = await addStaff(data);
    }

    console.log('res ', res)

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    if (isUpdateMode) {
      toast.success("Staff updated successfully");
    } else {
      toast.success("Staff added successfully");
    }
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">{staffData ? "Update" : "Add"} Staff</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <AddStaffForm
          onSubmit={onSubmit}
          initialValues={staffData ? initialValues : undefined}
          isLoading={addStaffState.isLoading || updateStaffState.isLoading}
        />
      </div>
    </div>
  );
};

export default AddStaff;
