import * as React from 'react'

import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AppButton from '../../components/UI/button';
import { toast } from 'react-toastify';
import { useCreateNewLabTestMutation, useGetAllLabCategoriesQuery } from '../../store/rtk-query/labApi';
import Spinner from '../../components/UI/Spinner';
import { formatServerErrorMessage } from '../../utils/functions';


interface FormValues {
    [key: string]: string
}

const Page = () => {
    const [createTest, createTestState] = useCreateNewLabTestMutation();
    const { data: labData } = useGetAllLabCategoriesQuery({})

    const initialValues = {
        testName: '',
        price: '',
        labCategory: ''
    }

    let laboratories: string[] = []

    if (labData) {
        Object.keys(labData).forEach((key) => laboratories.push(key))
    }

    const formValidationSchema = Yup.object().shape({
        testName: Yup.string().required('Required'),
        price: Yup.string().required('Required'),
        labCategory: Yup.string().required('Required'),
    })

    const inputClasses = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'
    const inputWrap = 'flex justify-between md:justify-start gap-4'

    const onSubmit = async (values: typeof initialValues) => {
        const data = {
            "laboratoryCategory": values.labCategory,
            "testName": values.testName,
            "price": values.price
        }


        const res: any = await createTest(data)

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return
        }

        toast.success('Test created successfully')
    }


    const renderForm = () => {
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={formValidationSchema}
                onSubmit={values => {
                    // same shape as initial values
                    console.log(values);
                    onSubmit(values)
                }}
            >
                <Form>
                    <>
                        <div className={inputWrap}>
                            <div>
                                <label className={labelClasses}>Test Name</label>
                                <Field className={inputClasses} name="testName" />
                                <ErrorMessage className={errorMessageClasses} component={'p'} name="testName" />
                            </div>
                        </div>
                        <div className={inputWrap}>
                            <div>
                                <label className={labelClasses}>Price</label>
                                <Field type="number" className={inputClasses} name="price" />
                                <ErrorMessage className={errorMessageClasses} component={'p'} name="price" />
                            </div>
                        </div>
                        <div className={inputWrap}>

                            <div>
                                <label className={labelClasses}>Laboratory category</label>
                                <Field className={inputClasses} as="select" name="labCategory" >
                                    {laboratories.map((lab: any) => {
                                        return <option key={lab} value={lab?.toUpperCase()}>{lab}</option>
                                    })}
                                </Field>
                                <ErrorMessage className={errorMessageClasses} component={'p'} name="labCategory" />
                            </div>
                        </div>
                        {createTestState.isLoading && <Spinner />}
                        <AppButton
                            extraclass='mt-4'
                            type="submit" buttontype={'primary'}>Add</AppButton>
                    </>
                </Form>
            </Formik >
        )
    }

    return (
        <div>
            <h3 className='text-3xl my-3' >Add New Test</h3>
            <div className='p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md'>
                {
                    renderForm()
                }
            </div>
        </div>
    )
}

export default Page