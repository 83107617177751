import { updatedDiff } from "deep-object-diff";
import { Field, FormikProvider, useFormik, useFormikContext } from "formik";
import moment from "moment";
import * as React from "react";

import {
  LabTestsProviderValues,
  ResultTemplateType,
} from "../../@types/app-types";
import { LabTestContext } from "../../context/LabTestProvider";
import {
  formatServerErrorMessage,
  generateAndDownloadInvoicePdf,
  objectIsNotEmpty,
} from "../../utils/functions";
import LabNetQuillEditor from "../LabNetQuillEditor";
import AppButton from "../UI/button";
import HistologyTemplateData from "../../data/histologyTemplate.json";
import ImmunoHistoChemistryTemplateData from "../../data/immunologyMicroTemplate.json";
import { toast } from "react-toastify";
import { usePrintResultMutation } from "../../store/rtk-query/labResult";
import Spinner from "../UI/Spinner";

interface Props {
  onChangeForm: any;
}

type HistologyTestTemplate = {
  [key: string]: TemplateItem | TemplateSubItem;
};

type TemplateItem = {
  DIAGNOSIS: string;
  RESULT: string;
};

type TemplateSubItem = {
  [key: string]: TemplateItem;
};

const HistoTest = (props: Props) => {
  const { originalTestResult, isUpdateMode, labTestResultId } =
    React.useContext<LabTestsProviderValues>(LabTestContext);

  const [printResult, printResultState] = usePrintResultMutation();

  const histoResult = originalTestResult?.histologyResult;

  let initVals = {
    materialCollected: "",
    clinician: "",
    slideNumber: "",
    testName: "",
    //
    clinicalSummaryOrDiagnosis: "",
    macroscopy: "",
    microscopy: "",
    diagnosis: "",
    comments: "",
    hospitalNumber: "",
    consultantPathologist: "Dr. Izu Achusi",
    //
    testTemplate: "",
    immunologyTestTemplate: ""
  };

  let initialValues = React.useMemo(() => {
    if (isUpdateMode) {
      if (histoResult) {
        initVals["materialCollected"] = histoResult?.materialCollected || "";
        initVals["clinician"] = histoResult?.clinician || "";
        initVals["slideNumber"] = histoResult?.slideNumber || "";
        initVals["testName"] = histoResult?.testName || "";
        initVals["clinicalSummaryOrDiagnosis"] =
          histoResult?.clinicalSummaryOrDiagnosis || "";
        initVals["macroscopy"] = histoResult?.macroscopy || "";
        initVals["hospitalNumber"] = histoResult?.hospitalNumber || "";
        initVals["microscopy"] = histoResult?.microscopy || "";
        initVals["diagnosis"] = histoResult?.diagnosis || "";
        initVals["comments"] = histoResult?.comments || "";
        initVals["consultantPathologist"] =
          histoResult?.consultantPathologist || "Dr. Izu Achusi";
      }
    }

    return initVals;
  }, [isUpdateMode, originalTestResult]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  React.useEffect(() => {
    /**
     * remove this form key from final result compilation if user
     * removes all changes
     */
    let formikValues: any = { ...formik.values };
    //remove testTemplate key before storing histology results
    delete formikValues["testTemplate"];
    delete formikValues["immunologyTestTemplate"]

    const updatedObj = updatedDiff(initVals, formikValues);
    if (Object.keys(updatedObj).length == 0) {
      props.onChangeForm(undefined, "histologyTest");
      return;
    }

    props.onChangeForm(
      {
        ...formikValues,
        makeResultAvailable: true,
      },
      "histologyTest"
    );
  }, [formik.values]);

  const inputClasses =
    "bg-gray-50 border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const templateClasses = `block mb-1 text-sm font-medium text-red-600 `;

  const flattenHistologyData = (
    data: HistologyTestTemplate
  ): TemplateItem[] => {
    const flattenedData: TemplateItem[] = [];

    const processItem = (item: TemplateItem) => {
      flattenedData.push(item);
    };

    const processSubItem = (subItem: TemplateSubItem) => {
      Object.values(subItem).forEach((value) => {
        if ("DIAGNOSIS" in value && "RESULT" in value) {
          processItem(value);
        } else {
          processSubItem(value);
        }
      });
    };

    Object.values(data).forEach((value) => {
      if ("DIAGNOSIS" in value && "RESULT" in value) {
        processItem(value as TemplateItem);
      } else {
        processSubItem(value);
      }
    });

    return flattenedData;
  };

  React.useEffect(() => {
    const flattenedTemplateData = flattenHistologyData(HistologyTemplateData);

    const selectedData = flattenedTemplateData.find(
      (item) => item.DIAGNOSIS == formik.values.testTemplate
    );

    formik.setFieldValue("diagnosis", selectedData?.DIAGNOSIS);
    formik.setFieldValue("microscopy", selectedData?.RESULT);
    formik.setFieldValue("testName", "HISTOLOGY");
  }, [formik.values.testTemplate]);

  React.useEffect(() => {
    const flattenedTemplateData = flattenHistologyData(
      ImmunoHistoChemistryTemplateData
    );


    const selectedData = flattenedTemplateData.find(
      (item) => item.RESULT === formik.values.immunologyTestTemplate
    );

    formik.setFieldValue("diagnosis", selectedData?.DIAGNOSIS);
    formik.setFieldValue("microscopy", selectedData?.RESULT);
    formik.setFieldValue("testName", "IMMUNOHISTOCHEMISTRY");
  }, [formik.values.immunologyTestTemplate]);

  const onPrintResult = async () => {
    const res: any = await printResult({
      labResultId: labTestResultId,
      queryParams: {
        templateType: ResultTemplateType.HISTOLOGY_TEST_TEMPLATE,
      },
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadInvoicePdf(res?.data, "HistologyTests");

    toast.success("Result pdf generated successfully");
  };

  const histoTemplateOptions = React.useMemo(() => {
    const flattenedTemplateData = flattenHistologyData(HistologyTemplateData);

    return flattenedTemplateData.map((item, index) => (
      <option key={index} value={item.DIAGNOSIS}>
        {item.DIAGNOSIS}
      </option>
    ));
  }, []);

  const immunoTemplateOptions = React.useMemo(() => {
    const flattenedTemplateData = flattenHistologyData(
      ImmunoHistoChemistryTemplateData
    );

    return flattenedTemplateData.map((item, index) => (
      <option key={index} value={item.RESULT}>
        {item.RESULT}
      </option>
    ));
  }, []);

  return (
    <FormikProvider value={formik}>
      <div className="text-2xl font-semibold mb-4"> Histology</div>
      <div className="flex items-center justify-between mb-10">
        {isUpdateMode && objectIsNotEmpty(histoResult) && (
          <div className="text-[#c09853] text-lg mb-5">
            Updated on{" "}
            {moment(histoResult?.updatedAt).format("DD ddd MMM[,] YYYY")}
          </div>
        )}

        <div className="w-full sm:w-1/3 ">
          <label className={templateClasses}>
            Use Histology Predefined Template:
          </label>
          <Field as="select" name="testTemplate" className={inputClasses}>
            <option disabled hidden value="">
              Select Template
            </option>
            {histoTemplateOptions}
          </Field>
        </div>
        <div className="w-full sm:w-1/3 ">
          <label className={templateClasses}>
            Use Immunohistochemistry Predefined Template:
          </label>
          <Field as="select" name="immunologyTestTemplate" className={inputClasses}>
            <option disabled hidden value="">
              Select Template
            </option>
            {immunoTemplateOptions}
          </Field>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <div className="flex">
          <label className={labelClasses}>Material Collected:</label>
          <Field
            name="materialCollected"
            as="textarea"
            className={inputClasses}
          />
        </div>
        <div className="flex">
          <label className={labelClasses}>Slide No:</label>
          <Field name="slideNumber" className={inputClasses} />
        </div>
        <div className="flex gap-3">
          <label className={labelClasses}>Test Name:</label>
          <Field as="select" name="testName" className={inputClasses}>
            <option disabled hidden value="">
              Select Test Name
            </option>
            <option value={"HISTOLOGY"} label="HISTOLOGY"></option>
            <option value={"SPECIAL STAIN"} label="SPECIAL STAIN"></option>
            <option
              value={"IMMUNOHISTOCHEMISTRY"}
              label="IMMUNOHISTOCHEMISTRY"
            ></option>
          </Field>
        </div>
        <div className="flex gap-3">
          <label className={labelClasses}>Clinician:</label>
          <Field name="clinician" className={inputClasses} />
        </div>
        <div className="flex gap-3">
          <label className={labelClasses}>Hospital Number:</label>
          <Field name="hospitalNumber" className={inputClasses} />
        </div>
        <div className="flex gap-3 pb-[20px]">
          <label className={labelClasses}>Clinical Summary:</label>
          <LabNetQuillEditor
            theme="snow"
            value={formik.values.clinicalSummaryOrDiagnosis}
            onChange={(val: any) =>
              formik.setFieldValue("clinicalSummaryOrDiagnosis", val)
            }
          />
        </div>
       
  

        <div className="flex gap-3 pb-[20px]">
          <label className={labelClasses}>Macroscopy:</label>
          <LabNetQuillEditor
            theme="snow"
            value={formik.values.macroscopy}
            onChange={(val: any) => formik.setFieldValue("macroscopy", val)}
          />
        </div>
        <div className="flex gap-3 pb-[20px]">
          <label className={labelClasses}>Microscopy:</label>
          <LabNetQuillEditor
            theme="snow"
            value={formik.values.microscopy}
            onChange={(val: any) => formik.setFieldValue("microscopy", val)}
          />
        </div>
        <div className="flex gap-3 pb-[20px]">
          <label className={labelClasses}>Diagnosis:</label>
          <LabNetQuillEditor
            theme="snow"
            value={formik.values.diagnosis}
            onChange={(val: any) => formik.setFieldValue("diagnosis", val)}
          />
        </div>
        <div className="flex gap-3 pb-[2px]">
          <label className={labelClasses}>Comments:</label>
          <LabNetQuillEditor
            theme="snow"
            value={formik.values.comments}
            onChange={(val: any) => formik.setFieldValue("comments", val)}
          />
        </div>
        

        <div className="flex gap-3">
          <label className={labelClasses}>Consultant Pathologist:</label>
          <Field name="consultantPathologist" className={inputClasses} />
        </div>
      </div>
      {isUpdateMode && objectIsNotEmpty(histoResult) && (
        <div className="flex gap-1 mt-8">
          {/* <AppButton
            buttontype={"danger"}
            title="Generate Report without letter head"
            extraclass="!text-xs"
          /> */}
          <AppButton
            onClick={onPrintResult}
            buttontype={"cyan"}
            title="Generate Histology Result"
            extraclass="!text-xs"
          />
          {printResultState.isLoading && <Spinner />}
        </div>
      )}
    </FormikProvider>
  );
};

export default HistoTest;
