import { userApi } from "./userApi";

export const inventoryApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDocOrLabSci: builder.query({
      query: ({ params }) => {
        return {
          url: "doctor-or-lab-scientist/get-all",
          method: "GET",
          params: { ...params },
        };
      },
      providesTags: ["DocOrLabSci"],
    }),
    getAllDocOrLabSciInfo: builder.query({
      query: ({ docOrSciId }) => {
        return {
          url: "doctor-or-lab-scientist/info/" + docOrSciId,
          method: "GET",
        };
      },
    }),
    addNewDocOrLabSci: builder.mutation({
      query: (body) => {
        return {
          url: "doctor-or-lab-scientist/create-new-doctor-or-lab-scientist",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["DocOrLabSci"],
    }),
    updateDocOrLabSci: builder.mutation({
      query: ({ body, docOrSciId }) => {
        return {
          url: "doctor-or-lab-scientist/edit/" + docOrSciId,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["DocOrLabSci"],
    }),
    deleteDocOrLabSci: builder.mutation({
      query: ({ docOrSciId }) => {
        return {
          url: "doctor-or-lab-scientist/delete/" + docOrSciId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DocOrLabSci"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllDocOrLabSciQuery,
  useGetAllDocOrLabSciInfoQuery,
  useAddNewDocOrLabSciMutation,
  useUpdateDocOrLabSciMutation,
  useDeleteDocOrLabSciMutation,
} = inventoryApi;
