import React, { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import TimeFrameSelector from "../TimeFrameSelector";

import chroma from 'chroma-js'

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  height: 400 // set the desired height here
};

interface Props {
  data: any
}


export default function DoughnutChart(props: Props) {
  const [timeFrame, setTimeFrame] = React.useState<string | undefined>('Week')

  const formatData = () => {
    let formattedData: number[] = []
    let labels: string[] = []

    const filterKey = `${timeFrame?.toLowerCase()}ly`

    const filteredData = props.data?.[filterKey]

    formattedData = filteredData?.map((item: any) => {
      return item?.count
    })
    labels = filteredData?.map((item: any) => {
      if (item?.category) {
        return item?.category
      } else {
        return "No date"
      }
    })

    return {
      formattedData,
      labels
    }
  }

  // console.log('data ', props.data)

  const data = useMemo(function () {
    const { formattedData, labels } = formatData()

    const colors = ["Red", "Blue", "Yellow", "Green", "Purple", "Orange", "Cyan", "Magenta", "Lime", "Pink", "Teal", "Silver", "Indigo"];
    const backgroundColors: string[] = [];
    const borderColors: string[] = [];


    for (let i = 0; i < colors.length; i++) {
      // Generate a color based on the label name
      const color = chroma(colors[i]);

      // Create the background color
      const backgroundColor = color.alpha(0.2).css();
      backgroundColors.push(backgroundColor);

      // Create the border color
      const borderColor = color.darken(1).css();
      borderColors.push(borderColor);
    }

    return {
      labels: labels,
      datasets: [
        {
          label: "No of Tests",
          data: formattedData,
          // data: [12, 19, 3, 5, 2, 3, 19, 3, 5, 2, 3, 6, 8],
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };
  }, [props.data, timeFrame]);

  return (
    <div className="h-full w-full pb-10">
      <TimeFrameSelector
        onSelect={(val) => setTimeFrame(val)}
      />
      <div className="h-full w-full px-1">
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
}
