import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";
import ItemsForm from "../components/forms/ItemsForm";
import { toast } from "react-toastify";
import moment from "moment";
import { useAddNewInventoryItemMutation } from "../store/rtk-query/inventoryApi";
import { formatServerErrorMessage } from "../utils/functions";

const Page = () => {
  const [addItem, addItemState] = useAddNewInventoryItemMutation();

  let initialValues = {
    itemName: "",
    itemNumber: "",
    itemType: "",
    quantityPerUnit: "",
    quantity: "",
    location: "",
    cost: "",
    expirationDate: "",
    isLabTest: "",
    refRange: "",
    units: "",
    generalPrice: "",
  };

  const onSubmit = async (values: any) => {
    let data: any = {
      itemName: values.itemName,
      cost: values.cost,
      itemNumber: values.itemNumber,
      itemType: values.itemType,
      quantity: values.quantity,
      quantityPerUnit: values.quantityPerUnit,
      expirationDate: moment(values.expirationDate).format("MM/DD/YYYY"),
      //
      generalPrice: values.generalPrice,
      isLaboratoryTest: values.isLaboratoryTest,
      location: values.location,
      units: values.units,
      referenceRange: values.refRange,
      useGeneralPriceForCompanyAndHMO: values.useGeneralPriceForCompanyAndHMO,
    };

    if (values?.hmoPrice) {
      data = {
        ...data,
        hmoPrice: [...values.hmoPrice
        ],
      };
    }

    if (values?.companyPrice) {
      data = {
        ...data,
        companyPrice: [
          ...values.companyPrice
        ],
      };
    }

    const res: any = await addItem(data);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)


      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Item added successfully");
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Add New Item
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <ItemsForm
          onSubmit={onSubmit}
          // initialValues={initialValues}
          isLoading={addItemState.isLoading}
        />
      </div>
    </div>
  );
};

export default Page;
