import * as React from "react";

import { Field } from "formik";

import AppButton from "../../components/UI/button";
import { Link, useNavigate } from "react-router-dom";
import {
  useDeleteDocOrLabSciMutation,
  useGetAllDocOrLabSciQuery,
} from "../../store/rtk-query/docOrLabsciApi";
import { MdModeEditOutline, MdOutlineClose } from "react-icons/md";
import { toast } from "react-toastify";
import DocOrSciDelete from "../../components/modals/DocOrSciDelete";
import Paginate from "../../components/Paginate";
import {
  useDeleteStaffMutation,
  useGetAllStaffQuery,
} from "../../store/rtk-query/staffApi";
import StaffDelete from "../../components/modals/StaffDelete";
import { useGetUserProfileQuery } from "../../store/rtk-query/userApi";
import { formatServerErrorMessage } from "../../utils/functions";
import PageHeader from "../../components/pageHeader";
import FilterResultHeader from "../../components/filterResultHeader";

interface Selected {
  type: string;
  item: any;
}

const Page = () => {
  const navigate = useNavigate();
  const { data: profileData } = useGetUserProfileQuery({});

  const [showSciDeleteConfirm, setSciShowDelete] = React.useState(false);
  const [showStaffDelete, setShowStaffDelete] = React.useState(false);
  const [selectedItem, setSelected] = React.useState<undefined | Selected>(
    undefined
  );

  const [deleteDocOrSci, deleteDocOrSciState] = useDeleteDocOrLabSciMutation();
  const [deleteStaff, deleteStaffState] = useDeleteStaffMutation();

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);
  const [staffsOffset, setStaffsOffset] = React.useState<number>(0);

  const { data: scientistsData, isLoading: sciLoading } =
    useGetAllDocOrLabSciQuery({
      params: {
        skip: itemOffset,
        take: perPage,
      },
    });

  const { data: staffData, isLoading: staffLoading } = useGetAllStaffQuery({
    params: {
      skip: staffsOffset,
      take: perPage,
    },
  });

  React.useEffect(() => {
    const administrationRoles = ["admin", "super_admin"];
    const accountCategory: string = profileData?.appUserData?.accountCategory;

    //if user is not admin, navigate back
    if (
      !administrationRoles?.some(
        (role) => role?.toLowerCase() == accountCategory?.toLowerCase()
      )
    ) {
      navigate(-1);
    }
  }, [profileData]);

  const onValueChange = () => {};

  const initialValues = {
    name: "",
  };

  const onDeleteSci = async () => {
    if (!selectedItem) {
      return;
    }
    if (selectedItem?.type !== "scientist") return;

    const res: any = await deleteDocOrSci({
      docOrSciId: selectedItem?.item?.id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Doc/Lab sci deleted successfully");
    setSciShowDelete(false);
    setSelected(undefined);
  };

  const onDeleteStaff = async () => {
    if (!selectedItem) {
      return;
    }
    if (selectedItem?.type !== "staff") return;

    const res: any = await deleteStaff({ staffId: selectedItem?.item?.id });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Staff deleted successfully");
    setSciShowDelete(false);
    setSelected(undefined);
  };

  const renderSearchBar = () => {
    return (
      <div className="flex w-full md:w-[350px]">
        <div className="relative w-full">
          <Field
            name="name"
            type="text"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                        "
            placeholder="Search Patient"
            required
          />
          <button
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </div>
    );
  };

  const renderStaffHeading = () => {
    return (
      <div className="flex flex-row-reverse w-full mt-6">
        <div className="flex gap-4 items-center">
          <AppButton
            onClick={() => navigate("/add-staff")}
            extraclass="!bg-[#22c0cb]"
            title="Add New Staff"
          />
        </div>
      </div>
    );
  };

  const renderDoctorsHeading = () => {
    return (
      <div className="flex flex-row-reverse w-full mt-6">
        <div className="flex gap-4 items-center">
          <AppButton
            onClick={() => navigate("/add-scientist")}
            title="Add Doctor or Scientist"
          />
        </div>
      </div>
    );
  };

  const renderScientistsTable = () => {
    return (
      <div className="w-full mt-10">
        <FilterResultHeader
          isLoading={sciLoading}
          title="All Doctors/Scientist"
        />
        <div className="tableContainer relative  shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 w-[50px] "></th>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  First Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Last Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone Number
                </th>
              </tr>
            </thead>
            <tbody>
              {scientistsData?.map((item: any) => {
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <div className="flex gap-2">
                        <Link to={"/update-scientist/" + item?.id}>
                          <div className="p-2 bg-[#2fade7] text-white rounded-md">
                            <MdModeEditOutline />
                          </div>
                        </Link>

                        <div
                          onClick={() => {
                            setSciShowDelete(true);
                            setSelected({ item, type: "scientist" });
                          }}
                          className="p-2 bg-[#dc5d3a] text-white rounded-md"
                        >
                          <MdOutlineClose />
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4">{item?.id}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.firstName}</td>
                    <td className="px-6 py-4">{item?.lastName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.email}</td>
                    <td className="px-6 py-4">{item?.phoneNumber}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderStaffsTable = () => {
    return (
      <div className="w-full">
        <FilterResultHeader title="Staff" isLoading={staffLoading} />
        <div className="tableContainer relative  shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 w-[50px] "></th>
                <th scope="col" className="px-6 py-3">
                  Staff ID
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  First Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Last Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Phone
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {staffData?.data?.map((item: any) => {
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <div className="flex gap-2">
                        <Link to={"/update-staff/" + item?.id}>
                          <div className="p-2 bg-[#2fade7] text-white rounded-md">
                            <MdModeEditOutline />
                          </div>
                        </Link>
                        {item?.accountCategory !== "SUPER_ADMIN" ? (
                          <div
                            onClick={() => {
                              setShowStaffDelete(true);
                              setSelected({ item, type: "staff" });
                            }}
                            className="p-2 bg-[#dc5d3a] text-white rounded-md"
                          >
                            <MdOutlineClose />
                          </div>
                        ) : undefined}
                      </div>
                    </td>

                    <td className="px-6 py-4">{item?.id}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.firstName}</td>
                    <td className="px-6 py-4">{item?.lastName}</td>
                    <td className="px-6 py-4 bg-gray-50 "></td>
                    <td className="px-6 py-4">{item?.email}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.accountCategory}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-3">
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Staff Management
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        <PageHeader title="All Staff" />
        {renderStaffHeading()}
        {renderStaffsTable()}
        {staffData && (
          <Paginate
            onPageChange={(data) => {
              setStaffsOffset(data?.selected * perPage);
            }}
            pageCount={staffData?.totalPage || 1}
            itemsPerPage={perPage}
          />
        )}
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        <PageHeader title="All Doctors/Lab Scientists" />
        {renderDoctorsHeading()}
        {renderScientistsTable()}
        {scientistsData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={scientistsData?.totalPage || 1}
            itemsPerPage={perPage}
          />
        )}
      </div>
      <StaffDelete
        show={showStaffDelete}
        onDelete={onDeleteStaff}
        onClose={() => setShowStaffDelete(false)}
      />
      <DocOrSciDelete
        show={showSciDeleteConfirm}
        onDelete={onDeleteSci}
        onClose={() => setSciShowDelete(false)}
      />
    </div>
  );
};

export default Page;
