import * as React from "react";

import { Form, Field, ErrorMessage, useFormik, FormikProvider } from "formik";

import AppButton from "../../components/UI/button";
import PatientDelete from "../../components/modals/PatientDelete";

import { Link, useNavigate } from "react-router-dom";
import {
  useDeletePatientInfoMutation,
  useGetPatientsQuery,
} from "../../store/rtk-query/patientApi";

import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import moment from "moment";
import _ from "lodash";
import { useGetAllCompaniesQuery } from "../../store/rtk-query/companyApi";
import { useGetAllHmosQuery } from "../../store/rtk-query/hmoApi";
import { toast } from "react-toastify";
import { useGetAllInventoryItemsQuery } from "../../store/rtk-query/inventoryApi";
import { useGetAllLabCategoriesQuery } from "../../store/rtk-query/labApi";
import Paginate from "../../components/Paginate";
import { useGetDashboardLabInfoQuery } from "../../store/rtk-query/dashboardApi";
import { formatServerErrorMessage } from "../../utils/functions";
import FilterHeader from "../../components/filterHeader";
import FilterResultHeader from "../../components/filterResultHeader";
import ExportDataModal from "../../components/modals/exportData";
import { months } from "../../utils/consts";
import { FormValues } from "../../@types/app-types";
import { RenderExportDataContent } from "../../components/modals/exportPatientRecord";

const Page = () => {
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDelete] = React.useState(false);
  const [selectedItem, setSelected] = React.useState<undefined | any>(
    undefined
  );

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);
  const [deletePatientInfoRecord, deletePatientInfoRecordState] =
    useDeletePatientInfoMutation();

  const { data: companyData, isLoading: companyLoading } =
    useGetAllCompaniesQuery({});

  const { data: hmoData, isLoading: hmoLoading } = useGetAllHmosQuery({});

  const { data: itemsData, isLoading: itemsLoading } =
    useGetAllInventoryItemsQuery({});
  const { data: labCategoryData } = useGetAllLabCategoriesQuery({});

  const { data: labInfoData, isLoading: labInfoLoading } =
    useGetDashboardLabInfoQuery({});

  // const [exportData, exportDataState] = useExportPatientDataMutation({});
  const [showExportModal, setShowExportModal] = React.useState<boolean>(false);

  let labCategories: string[] = [];

  if (labCategoryData) {
    labCategories = Object.keys(labCategoryData);
  }

  const initialValues: FormValues = {
    dateFrom: "",
    dateTo: "",
    patientName: "",
    hospitalNo: "",
    referral: "",
    hmo: "",
    company: "",
    periodMonth: "",
    periodYear: "",
    dateVisited: "",
    //
    testCategory: "",
    testCategoryFrom: "",
    testCategoryTo: "",
    //
    testName: "",
    testNameFrom: "",
    testNameTo: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
      //
    },
  });

  let queryParams: any = {
    searchParams: formik.values.patientName
      ? formik.values.patientName
      : undefined,
    regNumber: formik.values.hospitalNo,
    hmoId: formik.values.hmo,
    companyId: formik.values.company,
    periodMonth: formik.values.periodMonth,
    periodYear: formik.values.periodYear,
    testName: formik.values.testName,
    testCategory: formik.values.testCategory,
    //dates
    ...(formik.values.dateFrom && {
      dateCreatedFrom: moment(formik.values.dateFrom).format("YYYY-MM-DD"),
    }),
    ...(formik.values.dateTo && {
      dateCreatedTo: moment(formik.values.dateTo).format("YYYY-MM-DD"),
    }),
    ...(formik.values.testCategoryFrom && {
      testCategoryFrom: moment(formik.values.testCategoryFrom).format(
        "YYYY-MM-DD"
      ),
    }),
    ...(formik.values.testCategoryTo && {
      testCategoryTo: moment(formik.values.testCategoryTo).format("YYYY-MM-DD"),
    }),
    ...(formik.values.testNameFrom && {
      testNameFrom: moment(formik.values.testNameFrom).format("YYYY-MM-DD"),
    }),
    ...(formik.values.testNameTo && {
      testNameTo: moment(formik.values.testNameTo).format("YYYY-MM-DD"),
    }),
    ...(formik.values.dateVisited && {
      "date-visited": moment(formik.values.dateVisited).format("YYYY-MM-DD"),
    }),
  };

  queryParams = _.omitBy(queryParams, (val) => {
    return val === undefined || val?.toString()?.trim() == "";
  });

  const { data: patientsData, isLoading: patientsLoading } =
    useGetPatientsQuery({
      queryParams: {
        ...queryParams,
        take: perPage,
        skip: itemOffset
      }
    });

  const onDeletePatient = async () => {
    if (!selectedItem) {
      return;
    }
    const res: any = await deletePatientInfoRecord({
      patientId: selectedItem?.patient?.id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Doc/Lab sci deleted successfully");
    setSelected(undefined);
    setShowDelete(false);
    navigate('/patient/records'); 
  };

  const renderSearchBar = () => {
    return (
      <div className="flex">
        <div className="relative w-full">
          <Field
            name="patientName"
            type="text"
            id="search-dropdown"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg   border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                        "
            placeholder="Search Patient"
          />
          <button
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </div>
    );
  };

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mt-3 mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  const reportLinks = [
    {
      label: "Histology",
      link: "/medical-reports/histology",
    },
    {
      label: "Cytology",
      link: "/medical-reports/cytology",
    },
    {
      label: "Non Gynae",
      link: "/medical-reports/non-gynae",
    },
    {
      label: "Clinical",
      link: "/medical-reports/clinical",
    },
  ];

  const renderFilters = () => {
    return (
      <FormikProvider value={formik}>
        <FilterHeader title="Filter Patient Records" />

        <Form>
          <div className="w-full  mx-auto mt-3 mb-2 border rounded-lg p-4 space-y-1">
            {renderSearchBar()}
            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period</label>
                <div className="flex gap-3">
                  <Field
                    className={inputClasses}
                    as="select"
                    name="periodMonth"
                  >
                    <option value="" disabled hidden>
                      Select Month
                    </option>

                    {months.map((month) => {
                      return (
                        <option value={month} key={month}>
                          {month}
                        </option>
                      );
                    })}
                  </Field>
                  <Field
                    className={inputClasses}
                    placeholder="year"
                    type="number"
                    name="periodYear"
                  />
                </div>
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Date Visited</label>
                <Field
                  className={inputClasses}
                  type="date"
                  name="dateVisited"
                />
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>From</label>
                <Field className={inputClasses} type="date" name="dateFrom" />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>To</label>
                <Field className={inputClasses} type="date" name="dateTo" />
              </div>
            </div>
            <div>
              <label className={labelClasses}>Test Category and Period</label>
              <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
                <div className="w-full sm:w-1/3 ">
                  <Field
                    className={inputClasses}
                    as="select"
                    name="testCategory"
                  >
                    <option value="" disabled hidden>
                      Select Test Category
                    </option>

                    {labCategories.map((month) => {
                      return (
                        <option value={month} key={month}>
                          {month}
                        </option>
                      );
                    })}
                  </Field>
                </div>
                <div className="w-full sm:w-1/3">
                  <Field
                    className={inputClasses}
                    type="date"
                    name="testCategoryFrom"
                  />
                </div>
                <div className="self-center">⇒</div>
                <div className="w-full sm:w-1/3">
                  <Field
                    className={inputClasses}
                    type="date"
                    name="testCategoryTo"
                  />
                </div>
              </div>
            </div>
            <div>
              <label className={labelClasses}>Test Name and Period</label>
              <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
                <div className="w-full sm:w-1/3 ">
                  <Field className={inputClasses} as="select" name="testName">
                    <option value="" disabled hidden>
                      Select Test Name
                    </option>

                    {itemsData?.data?.map((item: any) => {
                      return (
                        <option value={item?.itemName} key={item?.id}>
                          #{item?.id} {item?.itemName}
                        </option>
                      );
                    })}
                  </Field>
                </div>
                <div className="w-full sm:w-1/3">
                  <Field
                    className={inputClasses}
                    type="date"
                    name="testNameFrom"
                  />
                </div>
                <div className="self-center">⇒</div>
                <div className="w-full sm:w-1/3">
                  <Field
                    className={inputClasses}
                    type="date"
                    name="testNameTo"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div>
                <label className={labelClasses}>Reg Number</label>
                <Field className={inputClasses} name="hospitalNo" />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="hospitalNo"
                />
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div>
                <label className={labelClasses}>HMO</label>
                <Field className={inputClasses} name="hmo" as="select">
                  <option value="">Choose an HMO</option>
                  {hmoData?.data?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div>
                <label className={labelClasses}>Company</label>
                <Field className={inputClasses} name="company" as="select">
                  <option value="">Choose a Company</option>
                  {companyData?.data?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
            <div className="text-xl text-[#468847] text-center my-2">
              Total Number of patients: {labInfoData?.totalRegisteredPatient}
            </div>
            <div className="my-2 ml-[auto] flex !w-[fit-content]">
              <AppButton
                // onClick={onExportData}
                onClick={() => setShowExportModal(true)}
                title="Export Data"
                extraclass="!bg-[#2fade7] hover:!bg-[#3492c9]"
              />
              {/* {exportDataState.isLoading && <Spinner />} */}
              <Menu as="div">
                <Menu.Button as="div">
                  <AppButton buttontype={"green"} extraclass="!flex">
                    <div className="flex items-center">
                      <span>Advanced Medical Reporting</span>
                      <ChevronDownIcon
                        className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                        aria-hidden="true"
                      />
                    </div>
                  </AppButton>
                </Menu.Button>
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-[100] right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {reportLinks.map((report) => {
                      return (
                        <Link key={report.label} to={report.link}>
                          <Menu.Item
                            className={
                              "h-[30px] flex flex-col justify-center p-2"
                            }
                            as="div"
                          >
                            {report.label}
                          </Menu.Item>
                        </Link>
                      );
                    })}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </Form>
      </FormikProvider>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full">
        <FilterResultHeader
          title="Patient Record Filter Result"
          isLoading={patientsLoading}
        />
        <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg">
          <table className="horiz-table w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Reg No
                </th>
                <th scope="col" className="px-6 py-3">
                  FirstName
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  LastName
                </th>
                <th scope="col" className="px-6 py-3">
                  Other Names
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Age
                </th>
                <th scope="col" className="px-6 py-3">
                  Sex
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Type
                </th>
                <th scope="col" className="px-6 py-3 min-w-[300px] bg-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {patientsData?.data?.map((patient: any) => {
                return (
                  <tr key={patient?.id} className="border-b border-gray-200 ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      {patient?.regNumber}
                    </th>
                    <td className="px-6 py-4">{patient?.firstName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {patient?.lastName}
                    </td>
                    <td className="px-6 py-4">{patient?.otherNames}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{patient?.age}</td>
                    <td className="px-6 py-4">{patient?.sex?.toLowerCase()}</td>
                    <td className="px-6 py-4 bg-gray-50 "></td>
                    <td className="px-6 py-4 flex gap-x-2 gap-y-1 flex-wrap bg-white ">
                      <AppButton
                        buttontype={"cyan"}
                        onClick={() =>
                          navigate("/patient-visits/" + patient?.id)
                        }
                        title="View Visits"
                      />
                      <AppButton
                        buttontype={"green"}
                        title="Edit"
                        onClick={() =>
                          navigate("/patient/update/" + patient?.id)
                        }
                      />
                      <AppButton
                        title="Delete"
                        buttontype={"danger"}
                        onClick={() => {
                          setShowDelete(true);
                          setSelected({ patient });
                        }}
                      />
                      <AppButton
                        buttontype={"cyan"}
                        title="Add Next Visit"
                        onClick={() =>
                          navigate("/patient/next-visit", {
                            state: { patientId: patient?.id },
                          })
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          All Patients Record
        </h3>
      </div>
      <div className="w-full p-3 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {renderFilters()}
        {renderResults()}
        {patientsData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={patientsData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
      </div>
      <PatientDelete
        show={showDeleteConfirm}
        onDelete={onDeletePatient}
        onClose={() => setShowDelete(false)}
      />
      <ExportDataModal
        show={showExportModal}
        onClose={() => setShowExportModal(false)}
        content={() => {
          return (
            <RenderExportDataContent
              companyData={companyData}
              hmoData={hmoData}
              itemsData={itemsData}
              labCategoryData={labCategoryData}
            />
          );
        }}
      />
    </div>
  );
};

export default Page;
