import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";
import ItemsForm from "../components/forms/ItemsForm";
import { toast } from "react-toastify";
import moment from "moment";

import { useAddNewDocOrLabSciMutation, useGetAllDocOrLabSciInfoQuery, useUpdateDocOrLabSciMutation } from "../store/rtk-query/docOrLabsciApi";
import AddDocOrSciForm from "../components/forms/AddDocForm";
import { useParams } from "react-router-dom";
import { formatServerErrorMessage } from "../utils/functions";

const AddDocOrSci = () => {
  const [addDocOrSci, addDocOrSciState] = useAddNewDocOrLabSciMutation();
  const [updateDocOrSci, updateDocOrSciState] = useUpdateDocOrLabSciMutation();

  const { scientist_id } = useParams();

  const { data: docOrSciData } = useGetAllDocOrLabSciInfoQuery({
    docOrSciId: scientist_id
  }, { skip: !scientist_id })

  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  console.log('docOrSciData ', docOrSciData)
  if (docOrSciData) {
    initialValues.firstName = docOrSciData.firstName
    initialValues.lastName = docOrSciData.lastName
    initialValues.email = docOrSciData.email
    initialValues.phoneNumber = docOrSciData.phoneNumber
  }

  const onSubmit = async (values: typeof initialValues) => {
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
    };

    let res: any
    if (docOrSciData) {
      res = await updateDocOrSci({
        docOrSciId: scientist_id,
        body: data
      })
    } else {
      res = await addDocOrSci(data);
    }

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    if (docOrSciData) {
      toast.success("Doctor/Lab Scientist updated successfully");
    } else {
      toast.success("Doctor/Lab Scientist added successfully");
    }
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          {docOrSciData ? "Update" : "Add"} Doctor or Lab Scientist
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <AddDocOrSciForm
          onSubmit={onSubmit}
          initialValues={docOrSciData ? initialValues : undefined}
          isLoading={addDocOrSciState.isLoading || updateDocOrSciState.isLoading}
        />
      </div>
    </div>
  );
};

export default AddDocOrSci;
