// @ts-nocheck
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { updateToken } from "../reducers/userSlice";

// const BASE_URL = "http://localhost:5000/";
const BASE_URL = "https://api.labnetworkpathology.com/";

// const BASE_URL = "https://labnetwork-server.onrender.com/";

const logoutSuccess = () => {
  return {
    type: "user/logout",
  };
};

const refreshToken = async (refreshToken: string) => {
  const data = {
    refreshToken,
  };
  let res = await axios
    .post(`${BASE_URL}auth/refresh-token`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);

  if (res?.id_token) {
    return res;
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const userState = getState().user;
    const idToken = userState?.idToken;

    if (idToken) {
      headers.set("Authorization", `Bearer ${idToken}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  const { dispatch, getState } = api;

  if (result.error && result.error.status === 401) {
    const refToken = getState().user?.refreshToken;
    const idToken = (await refreshToken(refToken))?.id_token;

    if (idToken) {
      dispatch(
        updateToken({
          idToken: idToken,
        })
      );

      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      dispatch(logoutSuccess());
    }
  }
  return result;
};
