import { userApi } from "./userApi";

export const inventoryApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLabItems: builder.query({
      query: ({ headers }) => {
        return {
          url: "inventory/all-tests",
          method: "GET",
          headers: { ...headers },
        };
      },
      providesTags: ["Inventory"],
    }),
    getAllInventoryItems: builder.query({
      query: ({  params }) => {
        return {
          url: "inventory",
          method: "GET",
          params: { ...params },
        };
      },
      providesTags: ["Inventory"],
    }),
    addNewInventoryItem: builder.mutation({
      query: (body) => {
        return {
          url: "inventory/add-new-item",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Inventory"],
    }),
    updateInventoryItem: builder.mutation({
      query: ({ body, inventoryItemId }) => {
        return {
          url: "inventory/edit/" + inventoryItemId,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Inventory"],
    }),
    deleteInventoryItem: builder.mutation({
      query: ({ inventoryItemId }) => {
        return {
          url: "inventory/delete/" + inventoryItemId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Inventory"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllLabItemsQuery,
  useGetAllInventoryItemsQuery,
  useAddNewInventoryItemMutation,
  useUpdateInventoryItemMutation,
  useDeleteInventoryItemMutation,
} = inventoryApi;
