import * as React from "react";

import AppButton from "../../components/UI/button";
import { Link, useNavigate } from "react-router-dom";

import {
  useFormikContext,
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  useFormik,
  FormikProvider,
} from "formik";
import { BsFilterSquareFill } from "react-icons/bs";
import { VscOutput } from "react-icons/vsc";
import FormikChildListener from "../../utils/FormikChildListener";

import AddItemType from "../../components/modals/AddItemType";
import ItemDelete from "../../components/modals/ItemDelete";

import { MdModeEditOutline, MdOutlineClose } from "react-icons/md";
import {
  useDeleteInventoryItemMutation,
  useGetAllInventoryItemsQuery,
} from "../../store/rtk-query/inventoryApi";
//
import moment from "moment";
import { toast } from "react-toastify";
import Spinner from "../../components/UI/Spinner";
import Paginate from "../../components/Paginate";
import { useGetAllLabCategoriesQuery } from "../../store/rtk-query/labApi";
import { formatServerErrorMessage } from "../../utils/functions";

interface FormValues {
  [key: string]: string;
}

const Page = () => {
  const [showDeleteConfirm, setShowDelete] = React.useState(false);
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [selectedItem, setSelected] = React.useState<any>();

  const [showTypeForm, setShowTypeForm] = React.useState(false);

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const initialValues: FormValues = {
    itemType: "",
    // item: '',
    itemName: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
    },
  });

  const { data: itemsData, isLoading: itemsLoading } =
    useGetAllInventoryItemsQuery({
      params: {
        ...(formik.values.itemType && { itemType: formik.values.itemType }),
        ...(formik.values.itemName && { itemName: formik.values.itemName }),
        skip: itemOffset,
        take: perPage,
      },
    });

  const { data: labData } = useGetAllLabCategoriesQuery({});
  let itemTypes: string[] = [];
  if (labData) {
    itemTypes = Object.keys(labData).map((key) => key);
  }

  const [deleteIventoryItem, deleteItemState] =
    useDeleteInventoryItemMutation();

  const navigate = useNavigate();

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  const onDeleteItem = async () => {
    if (!selectedItem) {
      return;
    }

    const res: any = await deleteIventoryItem({
      inventoryItemId: selectedItem?.id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Test deleted successfully");
    setShowDelete(false);
  };

  const renderHeading = () => {
    return (
      <div className="flex justify-end w-full gap-10">
        <AppButton
          buttontype={"cyan"}
          title="Add New Item"
          onClick={() => navigate("/new-item")}
        />
        {/* <AppButton
                    buttontype={'green'}
                    onClick={() => setShowTypeForm(true)}
                >
                    Add New Item Type
                </AppButton> */}
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
            <div
              className="container"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                className="w-full font-semibold bg-sky-100 p-4 border border-sky-200 rounded-lg shadow-md"
                style={{ display: "flex", alignItems: "center" }}
              >
                <BsFilterSquareFill
                  size="1.5rem"
                  style={{ marginRight: "0.5rem" }}
                />
                Filter Inventory Items
              </div>
            </div>

            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Item Type</label>
                <Field className={inputClasses} as="select" name="itemType">
                  <option value="e" disabled hidden>
                    Select
                  </option>
                  <option value="">All</option>
                  {itemTypes.map((type) => {
                    return <option key={type} value={type} label={type} />;
                  })}
                </Field>
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Item Name</label>
                <Field className={inputClasses} name="itemName" />
              </div>
            </div>
            {/* <div className='flex justify-between gap-4'>
                            <div>
                                <label className={labelClasses}>Hospital Number</label>
                                <Field className={inputClasses} name="itemNumber" />
                            </div>
                        </div> */}
          </div>
        </Form>
      </FormikProvider>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            className="w-full my-4 font-semibold bg-emerald-100 p-4 border border-sky-200 rounded-lg shadow-md"
            style={{ display: "flex", alignItems: "center" }}
          >
            <VscOutput size="1.5rem" style={{ marginRight: "0.5rem" }} />
            Results
            {itemsLoading && <Spinner />}
          </div>
        </div>
        <div className="tableContainer relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 "></th>
                <th scope="col" className="px-6 py-3">
                  Item Name
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Item Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Item Type
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Cost
                </th>
                <th scope="col" className="px-6 py-3">
                  Selling
                  <br /> Price
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantity
                  <br /> Per unit
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Location
                </th>
                <th scope="col" className="px-6 py-3">
                  Expiration <br /> date
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Date
                  <br />
                  added
                </th>
                <th scope="col" className="px-6 py-3">
                  Added by
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Updated last
                  <br /> on
                </th>
                <th scope="col" className="px-6 py-3">
                  Update
                  <br />
                  by
                </th>
              </tr>
            </thead>
            <tbody>
              {itemsData?.data?.map((item: any) => {
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <div className="flex gap-2">
                        <Link to={"/update-item/" + item?.id}>
                          <div className="p-2 bg-[#2fade7] text-white rounded-md">
                            <MdModeEditOutline />
                          </div>
                        </Link>
                        <div
                          onClick={() => {
                            setShowDelete(true);
                            setSelected(item);
                          }}
                          className="p-2 bg-[#dc5d3a] text-white rounded-md"
                        >
                          <MdOutlineClose />
                        </div>
                      </div>
                    </td>

                    <td className="px-6 py-4">{item?.itemName}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.itemNumber}
                    </td>
                    <td className="px-6 py-4">{item?.itemType}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.cost.toLocaleString('en-NG')}</td>
                    <td className="px-6 py-4"></td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.quantity}</td>
                    <td className="px-6 py-4">{item?.quantityPerUnit}</td>
                    <td className="px-6 py-4 bg-gray-50 "></td>
                    <td className="px-6 py-4">
                      {moment(item?.expirationDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {moment(item?.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">
                      {item?.addedBy}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {moment(item?.updatedAt).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4"></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Items</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {renderHeading()}
        {renderFilters()}
        {renderResults()}
        {itemsData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={itemsData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
        {deleteItemState.isLoading && <Spinner />}
        <ItemDelete
          show={showDeleteConfirm}
          onClose={() => {
            setSelected(undefined);
            setShowDelete(false);
          }}
          onDelete={onDeleteItem}
          data={selectedItem}
        />
      </div>
      <AddItemType
        show={showTypeForm}
        onClose={() => setShowTypeForm(false)}
        onSubmit={() => { }}
      />
    </div>
  );
};

export default Page;
