import { createSlice } from "@reduxjs/toolkit";
import { userSlice } from "./userSlice";

const initialState = {
  dashboardNotificationViewed: false,
};

export const miscSlice = createSlice({
  name: "misc",
  initialState,
  reducers: {
    markNotificationViewed: (state) => {
      state.dashboardNotificationViewed = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userSlice.actions.logout, (state) => {
      // Update state in miscellaneousSlice here
      state.dashboardNotificationViewed = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { markNotificationViewed } = miscSlice.actions;

export default miscSlice.reducer;
