import * as React from 'react'

import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AppButton from '../../components/UI/button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useResetPasswordMutation } from '../../store/rtk-query/userApi';
import Spinner from '../../components/UI/Spinner';
import Logo from '../../components/Logo';
import { formatServerErrorMessage } from '../../utils/functions';

const SignIn = () => {
    const [resetPass, resetPassState] = useResetPasswordMutation()

    const [showStep2, setShowStep2] = React.useState<boolean>(false)

    const [success, setSuccess] = React.useState<boolean>(false)

    const form1Val = {
        email: '',
    }

    const form2Val = {
        code: '',
        password: ''
    }

    const form1ValidationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
    });

    const form2ValidationSchema = Yup.object().shape({
        code: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
    });

    const inputClasses = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'
    const inputWrap = 'flex justify-between lg:justify-start gap-4'

    const sendForgotPassword = async (values: typeof form1Val) => {
        // setShowStep2(true)
        let data = {
            email: values?.email
        };

        const res: any = await resetPass(data)

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }

        if (res?.data?.success) {
            setSuccess(true)
        }

        toast.success("An email has been sent to your mail box");
    }


    const renderForm1 = () => {
        return (
            <Formik
                initialValues={form1Val}
                validationSchema={form1ValidationSchema}
                onSubmit={values => {
                    // same shape as initial values
                    console.log(values);
                    sendForgotPassword(values)
                }}
            >
                <Form>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Email Address</label>
                            <Field name="email" className={inputClasses} type="email" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="email" />
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-6 w-full">

                        <AppButton
                            type='submit'
                            buttontype={'primary'}
                        >
                            Submit
                        </AppButton>
                    </div>
                </Form>
            </Formik>
        )
    }

    const renderForm2 = () => {
        return (
            <Formik
                initialValues={form2Val}
                validationSchema={form2ValidationSchema}
                onSubmit={values => {
                    // same shape as initial values
                    console.log(values);
                }}
            >
                <Form>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Code</label>
                            <Field name="code" className={inputClasses} type="code" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="code" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-1">Password</label>
                            <Field name="password" className={inputClasses} type="password" />
                            <ErrorMessage className={errorMessageClasses} component={'p'} name="password" />
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-6">

                        <AppButton
                            type='submit'
                            buttontype={'primary'}>
                            <Link to='/signin'>Submit</Link>
                        </AppButton>
                        {resetPassState.isLoading && <Spinner />}
                    </div>
                </Form>
            </Formik>
        )
    }

    return (
        <main className="bg-white">
            <div className="relative flex">
                {/* Content */}
                <div className="w-full md:w-1/2">
                    <div className="min-h-screen h-full flex flex-col after:flex-1">
                        {/* Header */}
                        <div className="flex-1">
                            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                                {/* Logo */}
                                <Logo />
                            </div>
                        </div>
                        <div className="max-w-sm mx-auto px-4 py-8">
                            <h1 className="text-3xl text-gray-800 font-bold mb-6">Reset Password</h1>
                            {/* Form */}
                            {success ? <div className='my-3 text-sm text-center text-[#25D366]'>An email has just been sent to your mailbox with all the required steps</div>
                                : undefined
                            }
                            {renderForm1()}

                        </div>
                    </div>
                </div>
                {/* Image */}
                <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
                    <img className="object-cover object-center w-full h-full" src="images/pexels-cottonbro-studio-5721680.jpg" width={760} height={1024} alt="Authentication image" />
                    <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block" src="images/auth-decoration.png" width={218} height={224} alt="Authentication decoration" />
                </div>
            </div>
        </main>

    )
}

export default SignIn