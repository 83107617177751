import { updatedDiff } from 'deep-object-diff';
import { Field, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { toast } from 'react-toastify';


import { LabTestsProviderValues, ResultTemplateType } from '../../@types/app-types';
import { LabTestContext } from '../../context/LabTestProvider';
import { usePrintResultMutation } from '../../store/rtk-query/labResult';
import { formatServerErrorMessage, generateAndDownloadResultPdf, objectIsNotEmpty } from '../../utils/functions';
import LabNetQuillEditor from '../LabNetQuillEditor';
import AppButton from '../UI/button';
import Spinner from '../UI/Spinner';

interface Props {
    onChangeForm: any
}

const NonGynaeCytology = (props: Props) => {
    const {
        originalTestResult,
        isUpdateMode,
        labTestResultId
    } = React.useContext<LabTestsProviderValues>(LabTestContext)

    const [printResult, printResultState] = usePrintResultMutation()

    const nonGynaeResult = originalTestResult?.nongynaecologicCytology

    let initVals = {
        consultantPathologist: 'Dr. Izu Achusi',
        materialCollected: "",
        "clinician": "",
        "slideNumber": "",
        testName: '',
        //
        clinicalSummaryOrDiagnosis: '',
        macroscopy: '',
        microscopy: '',
        diagnosis: '',
        comments: '',
    }

    let initialValues = React.useMemo(() => {
        if (isUpdateMode) {

            if (nonGynaeResult) {
                initVals['materialCollected'] = nonGynaeResult?.materialCollected || ''
                initVals['clinician'] = nonGynaeResult?.clinician || ''
                initVals['slideNumber'] = nonGynaeResult?.slideNumber || ''
                initVals['testName'] = nonGynaeResult?.testName || ''
                initVals['clinicalSummaryOrDiagnosis'] = nonGynaeResult?.clinicalSummaryOrDiagnosis || ''
                initVals['macroscopy'] = nonGynaeResult?.macroscopy || ''
                initVals['microscopy'] = nonGynaeResult?.microscopy || ''
                initVals['diagnosis'] = nonGynaeResult?.diagnosis || ''
                initVals['comments'] = nonGynaeResult?.comments || ''
                initVals['consultantPathologist'] = nonGynaeResult?.consultantPathologist || ''
            }
        }

        return initVals
    }, [isUpdateMode, originalTestResult])


    const formik = useFormik({
        initialValues,
        onSubmit: values => {

        },
        enableReinitialize: true
    });

    React.useEffect(() => {
        /**
      * remove this form key from final result compilation if user
      * removes all changes
      */
        const updatedObj = updatedDiff(initVals, formik.values)
        if (Object.keys(updatedObj).length == 0) {
            props.onChangeForm(undefined, 'nongynaecologicCytology')
            return
        }

        props.onChangeForm({
            ...formik.values,
            "makeResultAvailable": true
        }, 'nongynaecologicCytology')
    }, [formik.values])

    const onPrintResult = async () => {
        const res: any = await printResult({
            labResultId: labTestResultId,
            queryParams: {
                templateType: ResultTemplateType.NON_GYNAECOLOGY_TEST_TEMPLATE
            }
        });

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }

        generateAndDownloadResultPdf(res?.data, 'NonGynaeTest')

        toast.success("Result pdf generated successfully");
    };

    const inputClasses =
        "bg-gray-50 border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;


    return (
        <FormikProvider
            value={formik}
        >
            <div className='text-2xl font-semibold mb-2'>NON GYNAECOLOGIC CYTOLOGY</div>
            {isUpdateMode && objectIsNotEmpty(nonGynaeResult) && <div className='text-[#c09853] text-lg mb-5'>
                Updated on {moment(nonGynaeResult?.updatedAt).format('DD ddd MMM[,] YYYY')}
            </div>}
            <div className='grid grid-cols-2 gap-3'>
                <div className='flex'>
                    <label className={labelClasses}>Material Collected:
                    </label>
                    <Field
                        name="materialCollected"
                        as='textarea' className={inputClasses} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>Clinician:
                    </label>
                    <Field name="clinician" className={inputClasses} />
                </div>
                {/* <div className='flex gap-3'>
                    <label className={labelClasses}>Hosp/Ward:
                    </label>
                    <input className={inputClasses} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>Hosp No:
                    </label>
                    <input className={inputClasses} />
                </div> */}
                <div className='flex gap-3'>
                    <label className={labelClasses}>Clinical Summary/Diagnosis:
                    </label>
                    <Field
                        name="clinicalSummaryOrDiagnosis"
                        className={inputClasses} />
                </div>
                <div className='flex gap-3 pb-[80px]'>
                    <label className={labelClasses}>Macroscopy:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.macroscopy}
                        onChange={(val: any) => formik.setFieldValue('macroscopy', val)} />
                </div>
                <div className='flex gap-3 pb-[80px]'>
                    <label className={labelClasses}>Microscopy:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.microscopy}
                        onChange={(val: any) => formik.setFieldValue('microscopy', val)} />
                </div>
                <div className='flex gap-3 pb-[80px]'>
                    <label className={labelClasses}>Diagnosis:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.diagnosis}
                        onChange={(val: any) => formik.setFieldValue('diagnosis', val)} />
                </div>
                <div className='flex gap-3 pb-[80px]'>
                    <label className={labelClasses}>Comments:
                    </label>
                    <LabNetQuillEditor theme="snow"
                        value={formik.values.comments}
                        onChange={(val: any) => formik.setFieldValue('comments', val)} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>Slide No:
                    </label>
                    <Field
                        name='slideNumber'
                        className={inputClasses} />
                </div>

                <div className='flex gap-3 cols-span-2'>
                    <label className={labelClasses}>Consultant Pathologist:
                    </label>
                    <Field
                        name='consultantPathologist'
                        className={inputClasses} />
                </div>
                <div className='flex gap-3'>
                    <label className={labelClasses}>Test Name:
                    </label>
                    <Field name="testName" className={inputClasses} />
                </div>
            </div>
            {
                isUpdateMode && objectIsNotEmpty(nonGynaeResult) && <div className='flex gap-1 mt-8'>
                    <AppButton
                        onClick={onPrintResult}
                        buttontype={'cyan'}
                        title='Generate Result'
                        extraclass='!text-xs'
                    />
                    {printResultState.isLoading && <Spinner />}
                </div>
            }

        </FormikProvider>
    )
}

export default NonGynaeCytology