import * as React from 'react'

import { useFormikContext, Formik, Form, Field, FormikProps, ErrorMessage, useFormik, FormikProvider } from 'formik';
import FormikChildListener from '../../utils/FormikChildListener';

import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../components/UI/Spinner';


import { MdModeEditOutline, MdOutlineClose } from 'react-icons/md'
import AppButton from '../../components/UI/button';

const Page = () => {
    const [showDeleteConfirm, setShowDelete] = React.useState(false)
    const [showUpdate, setShowUpdate] = React.useState(false)
    const [selectedItem, setSelected] = React.useState<any>()

    const navigate = useNavigate()

    const initialValues = {
        itemCategory: '',
        periodFrom: '',
        periodTo: ''
    }

    const onValueChange = (values: typeof initialValues) => {

    }

    const inputClasses = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `
    const errorMessageClasses = 'mt-2 text-xs text-red-600 font-medium'

    const formik = useFormik({
        initialValues,
        onSubmit: values => {
            // same shape as initial values
            console.log(values);
        }
    })

    const renderFilters = () => {
        return (
            <FormikProvider
                value={formik}
            >
                <Form>
                    <div className='w-full'>
                        <div className='font-semibold my-2'>Filter Items</div>

                        <div className='flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full'>
                            <div className='w-full sm:w-1/3'>
                                <label className={labelClasses}>Item Category</label>
                                <Field className={inputClasses} name="itemCategory" />
                            </div>
                        </div>
                        <div className='flex justify-between gap-4'>
                            <div>
                                <label className={labelClasses}>From</label>
                                <Field className={inputClasses} type="date" name="periodFrom" />
                            </div>
                            <div>
                                <label className={labelClasses}>To</label>
                                <Field className={inputClasses} type="date" name="periodTo" />
                            </div>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
        )
    }

    const renderResults = () => {
        return (
            <div className='w-full'>
                <div className='font-semibold my-2 flex gap-2 items-center'>Results
                    {false && <Spinner />}
                </div>
                <div className="tableContainer relative shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-gray-700 uppercase">
                            <tr>
                                <th scope="col" className="px-6 py-3 bg-gray-50 ">

                                </th>
                                <th scope="col" className="px-6 py-3">
                                    DPH Receipt No.
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                                    Customer Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total Debt
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                                    Amount Paid
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Balance
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                                    Mode of Payment
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Sales Receipt No.
                                </th>
                                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                                    Date Paid
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Received by
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                "q12".split("").map((item: any) => {
                                    return (
                                        <tr
                                            key={item}
                                            className="border-b border-gray-200 ">

                                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 ">
                                                <div className='flex gap-2'>
                                                    <Link to={'/update-purchase/' + item?.id}>
                                                        <div

                                                            className='p-2 bg-[#2fade7] text-white rounded-md'>
                                                            <MdModeEditOutline
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div
                                                        onClick={() => {
                                                            setShowDelete(true);
                                                            setSelected(item)
                                                        }}
                                                        className='p-2 bg-[#dc5d3a] text-white rounded-md'>
                                                        <MdOutlineClose
                                                        />
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="px-6 py-4">

                                            </td>
                                            <td className="px-6 py-4 bg-gray-50 ">

                                            </td>
                                            <td className="px-6 py-4">

                                            </td>
                                            <td className="px-6 py-4 bg-gray-50 ">

                                            </td>
                                            <td className="px-6 py-4">

                                            </td>
                                            <td className="px-6 py-4 bg-gray-50 ">

                                            </td>
                                            <td className="px-6 py-4">

                                            </td>
                                            <td className="px-6 py-4 bg-gray-50 ">

                                            </td>
                                            <td className="px-6 py-4">

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <div>
            <h3 className='text-3xl my-3' >Debt Repayment</h3>
            <div className='p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md '>
                <div className='text-2xl font-semibold'>Filters</div>
                {renderFilters()}

                {renderResults()}
            </div>
        </div>
    )
}

export default Page