import React from "react";

interface ItemsAndAmountProps {
  inputString: string;
}
const ItemsAndAmount = (props: ItemsAndAmountProps) => {
  const items = props.inputString.split(", ");

  return (
    <div>
      {items.map((item, index) => (
        <span key={index}>{item}</span>
      ))}
    </div>
  );
};

export default ItemsAndAmount;
