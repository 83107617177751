import { updatedDiff } from 'deep-object-diff';
import { useFormik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { toast } from 'react-toastify';

import { LabTestsProviderValues, ResultTemplateType } from '../../../@types/app-types';
import { LabTestContext } from '../../../context/LabTestProvider';
import { usePrintResultMutation } from '../../../store/rtk-query/labResult';
import { formatServerErrorMessage, generateAndDownloadResultPdf } from '../../../utils/functions';
import LabNetQuillEditor from '../../LabNetQuillEditor';
import AppButton from '../../UI/button';
import Spinner from '../../UI/Spinner';

interface Props {
    onChangeForm: any
}

const SemenAnalysis = (props: Props) => {
    const {
        originalTestResult,
        isUpdateMode,
        labTestResultId
    } = React.useContext<LabTestsProviderValues>(LabTestContext)

    const [printResult, printResultState] = usePrintResultMutation()

    const semenAnalysisResult = originalTestResult?.semenAnalysis?.[0]

    let initVals = {
        testResult: ''
    }

    let initialValues = React.useMemo(() => {
        if (isUpdateMode) {

            if (semenAnalysisResult) {
                initVals['testResult'] = semenAnalysisResult?.testResult
            }
        }

        return initVals
    }, [isUpdateMode, originalTestResult])


    const formik = useFormik({
        initialValues,
        onSubmit: values => {

        },
        enableReinitialize: true
    });

    React.useEffect(() => {
        /**
* remove this form key from final result compilation if user
* removes all changes in this form file
*/
        const updatedObj = updatedDiff(initVals, formik.values)
        if (Object.keys(updatedObj).length == 0) {
            props.onChangeForm(undefined, 'semenAnalysisResult')
            return
        }


        props.onChangeForm({
            ...formik.values,
            makeResultAvailable: true
        }, 'semenAnalysisResult')
    }, [formik.values])

    const onPrintResult = async () => {
        const res: any = await printResult({
            labResultId: labTestResultId,
            queryParams: {
                templateType: ResultTemplateType.SEMEN_ANALYSIS_TEST_TEMPLATE
            }
        });

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }

        generateAndDownloadResultPdf(res?.data, 'SemenAnalysisTest')

        toast.success("Result pdf generated successfully");
    };

    const inputClasses =
        "bg-gray-50 border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;

    return (
        <div>
            {isUpdateMode && semenAnalysisResult && <div className='text-[#c09853] text-lg mb-5'>
                Updated on {moment(semenAnalysisResult?.updatedAt).format('DD ddd MMM[,] YYYY')}
            </div>}
            <div className='flex gap-3 pb-[80px]'>
                <label className={labelClasses}>Test Result:
                </label>
                <LabNetQuillEditor theme="snow"
                    value={formik.values.testResult}
                    onChange={(val: any) => formik.setFieldValue('testResult', val)} />
            </div>
            {
                isUpdateMode && semenAnalysisResult && <div className='flex gap-1 mt-8'>
                    <AppButton
                        onClick={onPrintResult}
                        buttontype={'cyan'}
                        title='Generate Result'
                        extraclass='!text-xs'
                    />
                    {printResultState.isLoading && <Spinner />}
                </div>
            }
        </div>
    )
}

export default SemenAnalysis