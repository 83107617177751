import * as React from "react";

import {
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";
import AppButton from "../../components/UI/button";
import { useGetAllLabCategoriesQuery } from "../../store/rtk-query/labApi";
import Spinner from "../UI/Spinner";
import { useGetAllHmosQuery } from "../../store/rtk-query/hmoApi";
import { useGetAllCompaniesQuery } from "../../store/rtk-query/companyApi";

interface FormValues {
  [key: string]: string | number;
}

let initialValues = {
  itemName: "",
  itemNumber: "",
  itemType: "",
  quantityPerUnit: "",
  quantity: "",
  location: "",
  cost: "",
  expirationDate: "",
  isLabTest: "",
  refRange: "",
  units: "",
  generalPrice: "",
  isLaboratoryTest: "",
  useGeneralPriceForCompanyAndHMO: "",
};

interface Props {
  onSubmit: any;
  initialValues?: FormValues;
  isLoading: boolean;
}

const ItemsForm = (props: Props) => {
  const { data: labData } = useGetAllLabCategoriesQuery({});

  const { data: hmoData, isLoading: hmoLoading } = useGetAllHmosQuery({});
  const { data: companyData, isLoading: companyLoading } =
    useGetAllCompaniesQuery({});

  /**
   *
   *
   * @description
   * add company and hmo price fields
   * Naming style = company_{companyId}
   * Naming style = hmo_{hmoId}
   */
  if (companyData) {
    const companyFormVals: FormValues = {};
    companyData?.data?.forEach((company: any) => {
      companyFormVals[`company_${company?.id}`] = 0;
    });
    //add to formik initial  vals
    initialValues = {
      ...initialValues,
      ...companyFormVals,
    };
  }

  if (hmoData) {
    const hmoFormVals: FormValues = {};
    hmoData?.data?.forEach((hmo: any) => {
      hmoFormVals[`hmo_${hmo?.id}`] = 0;
    });

    initialValues = {
      ...initialValues,
      ...hmoFormVals,
    };
  }

  //initialize with values from parent component
  if (props.initialValues) {
    initialValues = {
      ...initialValues,
      ...props.initialValues,
    };
  }

  let testTypes: string[] = [];

  if (labData) {
    testTypes = Object.keys(labData).map((key) => key);
  }

  const formValidationSchema = Yup.object().shape({
    itemName: Yup.string().required("Required"),
    itemNumber: Yup.string().required("Required"),
    itemType: Yup.string().required("Required"),
    quantityPerUnit: Yup.string().required("Required"),
    quantity: Yup.string().required("Required"),
    // location: Yup.string().required('Required'),
    cost: Yup.string().required("Required"),
    expirationDate: Yup.string().required("Required"),
    isLaboratoryTest: Yup.string().required("Required"),
    useGeneralPriceForCompanyAndHMO: Yup.string().required("Required"),
    // refRange: Yup.string().required('Required'),
    // units: Yup.string().required('Required'),
    // generalPrice: Yup.string().required('Required'),
  });

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";
  const inputWrap = "flex items-center justify-center md:justify-start gap-8";

  const renderPriceScheme = () => {
    return (
      <div className="w-9/12 mx-auto mt-8 border rounded-lg p-6 space-y-6">
        <h3 className="text-3xl">Price Scheme</h3>

        {/* General Price */}
        <div className="flex items-center space-x-4">
          <label className="font-medium w-48">General Price</label>
          <div className="flex-1">
            <Field
              type="number"
              className="w-full px-4 py-2 border rounded-lg"
              name="generalPrice"
            />
            <ErrorMessage
              className="text-red-500 mt-1"
              component="p"
              name="generalPrice"
            />
          </div>
        </div>

        {/* Company Prices and HMO Prices */}
        <div className="flex justify-between">
          {/* Company Prices */}
          <div className="space-y-4">
            <div
              className="container"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="w-full font-semibold bg-sky-100 p-4 border border-sky-200 rounded-lg shadow-md">
                Company Prices
              </div>
            </div>
            {companyData?.data?.map((company: any) => (
              <div
                key={company?.id}
                className="border border-sky-200 p-4 rounded-lg space-y-2"
              >
                <label className="font-medium">{company?.name}</label>
                <div>
                  <Field
                    type="number"
                    className="w-full px-4 py-2 border rounded-lg"
                    name={`company_${company?.id}`}
                  />
                  <ErrorMessage
                    className="text-red-500 mt-1"
                    component="p"
                    name={`company_${company?.id}`}
                  />
                </div>
              </div>
            ))}
          </div>

          {/* HMO Prices */}
          <div className="space-y-4">
            <div
              className="container"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="w-full font-semibold bg-green-100 p-4 border border-green-200 rounded-lg shadow-md">
                HMO Prices
              </div>
            </div>
            {hmoData?.data?.map((hmo: any) => (
              <div
                key={hmo?.id}
                className="border  border-green-200 p-4 rounded-lg space-y-2"
              >
                <label className="font-medium">{hmo?.name}</label>
                <div>
                  <Field
                    type="number"
                    className="w-full px-4 py-2 border rounded-lg"
                    name={`hmo_${hmo?.id}`}
                  />
                  <ErrorMessage
                    className="text-red-500 mt-1"
                    component="p"
                    name={`hmo_${hmo?.id}`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleSubmit = (values: FormValues) => {
    let newValues: any = {
      ...values,
    };

    //add restructured hmo and company arrays
    const hmoPrice: any = [];
    hmoData?.data?.forEach((hmo: any) => {
      const price = values[`hmo_${hmo?.id}`];
      if (price > 0) {
        hmoPrice.push({
          hmoId: hmo?.id,
          price,
        });
      }
    });

    const companyPrice: any = [];
    companyData?.data?.forEach((company: any) => {
      const price = values[`company_${company?.id}`];
      if (price > 0) {
        companyPrice.push({
          companyId: company?.id,
          price,
        });
      }
    });

    newValues = {
      ...newValues,
      hmoPrice,
      companyPrice,
      isLaboratoryTest: JSON.parse(values.isLaboratoryTest as string),
      useGeneralPriceForCompanyAndHMO: JSON.parse(
        values.useGeneralPriceForCompanyAndHMO as string
      ),
    };

    props.onSubmit(newValues);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
      handleSubmit(values);
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form className="px-8 pt-6 pb-8 mb-4">
        <>
          <div className="w-9/12 mx-auto mt-2 border rounded-lg p-6 space-y-2">
            <div className={inputWrap}>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Item Name</label>
                <Field className={inputClasses} name="itemName" />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="itemName"
                />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Item Number</label>
                <Field className={inputClasses} name="itemNumber" />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="itemNumber"
                />
              </div>
            </div>
            <div className={inputWrap}>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Quantity</label>
                <Field type="number" className={inputClasses} name="quantity" />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="quantity"
                />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Quantity per unit</label>
                <Field
                  type="number"
                  className={inputClasses}
                  name="quantityPerUnit"
                />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="quantityPerUnit"
                />
              </div>
            </div>
            <div className={inputWrap}>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Item Type</label>
                <Field as="select" name="itemType" className={inputClasses}>
                  <option value="" disabled hidden>
                    Select Type
                  </option>
                  {testTypes.map((type, index) => {
                    return (
                      <option key={index} value={type?.toUpperCase()}>
                        {type?.toUpperCase()}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="itemType"
                />
              </div>
            </div>
            <div className={inputWrap}>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Location</label>
                <Field className={inputClasses} name="location" />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="location"
                />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Cost</label>
                <Field type="number" className={inputClasses} name="cost" />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="cost"
                />
              </div>
            </div>

            <div className={inputWrap}>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Expiration Date</label>
                <Field
                  type="date"
                  className={inputClasses}
                  name="expirationDate"
                />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="expirationDate"
                />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Units</label>
                <Field className={inputClasses} name="units" />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="units"
                />
              </div>
            </div>
            <div className={inputWrap}>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Reference range</label>
                <Field className={inputClasses} name="refRange" />
                <ErrorMessage
                  className={errorMessageClasses}
                  component={"p"}
                  name="refRange"
                />
              </div>
            </div>
            <div className={`${inputWrap} mt-4`}>
              <label className={labelClasses}>Mark as Lab Test</label>
              <label>
                <Field type="radio" name="isLaboratoryTest" value={"true"} />
                Yes
              </label>
              <label>
                <Field type="radio" name="isLaboratoryTest" value={"false"} />
                No
              </label>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="isLaboratoryTest"
              />
            </div>
          </div>
          {renderPriceScheme()}
          <div className="w-9/12 mx-auto mt-2">
            <div className={`${inputWrap} mt-4`}>
              <label className={`${labelClasses} text-lg text-[#3a87ad]`}>
                Should I use the general price for the HMOs and Companies?
              </label>
              <label>
                <Field
                  type="radio"
                  name="useGeneralPriceForCompanyAndHMO"
                  value={"true"}
                />
                Yes
              </label>
              <label>
                <Field
                  type="radio"
                  name="useGeneralPriceForCompanyAndHMO"
                  value={"false"}
                />
                No
              </label>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="useGeneralPriceForCompanyAndHMO"
              />
            </div>
            {props.isLoading && <Spinner />}
            <AppButton extraclass="mt-4" type="submit" buttontype={"primary"}>
              {props.initialValues ? "Update" : "Add New Item"}
            </AppButton>
          </div>
        </>
      </Form>
    </FormikProvider>
  );
};

export default ItemsForm;
