import React, { useState, useEffect } from 'react';

const GreetingWithIcons: React.FC = () => {
  const [currentTime, setCurrentTime] = useState<string>('');
  const [greeting, setGreeting] = useState<string>('');
  const [icon, setIcon] = useState<string>('');

  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    setCurrentTime(`${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`);

    if (hours >= 5 && hours < 12) {
      setGreeting('Good morning!');
      setIcon('☀️');
    } else if (hours >= 12 && hours < 17) {
      setGreeting('Good afternoon!');
      setIcon('🌤️');
    } else {
      setGreeting('Good evening!');
      setIcon('🌙');
    }
  }, []);

  return (
    <div className="flex flex-row justify-start items-start p-1 font-semibold">
      <div className="text-lg mx-5">
        <span role="img" aria-label="Greeting Icon">{icon}</span> {greeting}
      </div>
      <div className="text-lg mx-5">
        <span className="mr-1">Current time:</span> {currentTime}
      </div>
    </div>
  );
};

export default GreetingWithIcons;
