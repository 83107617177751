import * as React from "react";

import { BsInfoSquareFill } from "react-icons/bs";

const PageHeader = (props: any) => {
  const { title } = props;
  return (
    <div
      className="container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div
        className="w-full font-semibold bg-sky-200 p-4 border border-sky-200 rounded-lg shadow-md"
        style={{ display: "flex", alignItems: "center" }}
      >
        <BsInfoSquareFill size="1.5rem" style={{ marginRight: "0.5rem" }} />
        {title}
      </div>
    </div>
  );
};

export default PageHeader;
