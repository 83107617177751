import { userApi } from "./userApi";

export const dashboardApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardLabInfo: builder.query({
      query: () => {
        return {
          url: "dashboard/laboratory-info",
          method: "GET",
        };
      },
    }),
    getGraphData: builder.query({
      query: () => {
        return {
          url: "dashboard/graph-data",
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetDashboardLabInfoQuery, useGetGraphDataQuery } =
  dashboardApi;
