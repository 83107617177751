import * as React from "react";

import {
  Form,
  Field,
  FormikProvider,
  useFormik,
} from "formik";
import { VscOutput } from "react-icons/vsc";

import { useNavigate } from "react-router-dom";
import Spinner from "../../components/UI/Spinner";

import { MdOutlineClose } from "react-icons/md";
import AppButton from "../../components/UI/button";
import SaleDelete from "../../components/modals/SaleDelete";
import {
  useDeleteSaleMutation,
  useGetAllSalesQuery,
} from "../../store/rtk-query/saleApi";
import moment from "moment";
import { toast } from "react-toastify";
import Paginate from "../../components/Paginate";
import { usePrintInvoiceMutation } from "../../store/rtk-query/invoiceApi";
import {
  formatServerErrorMessage,
  generateAndDownloadInvoicePdf,
} from "../../utils/functions";
import ExportDataModal from "../../components/modals/exportData";
import { RenderSalesRecordContent } from "../../components/modals/exportSalesRecord";
import _ from "lodash";
import { useGetAllLabCategoriesQuery } from "../../store/rtk-query/labApi";
import { useGetAllCompaniesQuery } from "../../store/rtk-query/companyApi";
import { useGetAllHmosQuery } from "../../store/rtk-query/hmoApi";
import FilterHeader from "../../components/filterHeader";
import ItemsAndAmount from "../../components/ItemsAndAmount";

const Page = () => {
  const [showDeleteConfirm, setShowDelete] = React.useState(false);
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [selectedItem, setSelected] = React.useState<any>();
  const [showExportModal, setShowExportModal] = React.useState<boolean>(false);
  const { data: labCategoryData } = useGetAllLabCategoriesQuery({});

  const navigate = useNavigate();

  const [deleteSale, deleteSaleState] = useDeleteSaleMutation();
  const [printInvoice, printInvoiceState] = usePrintInvoiceMutation();
  const { data: companyData, isLoading: companyLoading } =
    useGetAllCompaniesQuery({});

  const { data: hmoData, isLoading: hmoLoading } = useGetAllHmosQuery({});

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  let labCategories: string[] = [];

  if (labCategoryData) {
    labCategories = Object.keys(labCategoryData);
  }

  const initialValues = {
    searchParams: "",
    itemCategory: "",
    saleDate: "",
    periodFrom: "",
    periodTo: "",
    hmo: "",
    company: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
    },
  });

  let queryParams: any = {
    searchPhrase: formik.values.searchParams
      ? formik.values.searchParams
      : undefined,
    hmoId: formik.values.hmo,
    companyId: formik.values.company,
    itemCategory: formik.values.itemCategory,
    //dates
    ...(formik.values.periodFrom && {
      periodFrom: moment(formik.values.periodFrom).format("YYYY-MM-DD"),
    }),
    ...(formik.values.periodTo && {
      periodTo: moment(formik.values.periodTo).format("YYYY-MM-DD"),
    }),
  };

  queryParams = _.omitBy(queryParams, (val) => {
    return val === undefined || val?.toString()?.trim() == "";
  });

  const { data: salesData, isLoading: saleLoading } = useGetAllSalesQuery({
    params: {
      ...queryParams,
      skip: itemOffset,
      take: perPage,
    },
  });

  const onDeleteItem = async () => {
    if (!selectedItem) {
      return;
    }

    const res: any = await deleteSale({ saleId: selectedItem?.id });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Sale deleted successfully");
    setShowDelete(false);
  };

  const onPrintInvoice = async (selectedItem: any) => {
    if (!selectedItem) {
      return;
    }

    const res: any = await printInvoice({ invoiceId: selectedItem?.id });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadInvoicePdf(res?.data, selectedItem);

    toast.success("Invoice pdf generated successfully");
  };

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  const renderHeading = () => {
    return (
      <div className="flex justify-end w-full gap-10">
        {/* <AppButton
                    buttontype={'cyan'}
                    title="Add New Sale"
                    onClick={() => navigate('/add-sale')}
                /> */}
      </div>
    );
  };

  const renderSearchBar = () => {
    return (
      <div className="flex">
        <div className="relative w-full">
          <Field
            name="searchParams"
            type="text"
            id="search-dropdown"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg   border border-gray-300 focus:ring-blue-500 focus:border-blue-500
                        "
            placeholder="Search Sales"
          />
          <button
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
            <FilterHeader title="Filter Sales" />
            {renderSearchBar()}

            <div className="flex flex-wrap sm:flex-nowrap justify-between gap-4 w-full">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Item Category</label>
                <Field className={inputClasses} as="select" name="testCategory">
                  <option value="" disabled hidden>
                    Select Test Category
                  </option>

                  {labCategories.map((Category) => {
                    return (
                      <option value={Category} key={Category}>
                        {Category}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Date</label>
                <Field className={inputClasses} type="date" name="saleDate" />
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period From</label>
                <Field className={inputClasses} type="date" name="periodFrom" />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period To</label>
                <Field className={inputClasses} type="date" name="periodTo" />
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>HMO</label>
                <Field className={inputClasses} name="hmo" as="select">
                  <option value="">Choose an HMO</option>
                  {hmoData?.data?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Company</label>
                <Field className={inputClasses} name="company" as="select">
                  <option value="">Choose a Company</option>
                  {companyData?.data?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            className="w-full my-4 font-semibold bg-emerald-100 p-4 border border-sky-200 rounded-lg shadow-md"
            style={{ display: "flex", alignItems: "center" }}
          >
            <VscOutput size="1.5rem" style={{ marginRight: "0.5rem" }} />
            Results
            {saleLoading && <Spinner />}
          </div>
        </div>
        <div className="tableContainer relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 "></th>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Customer Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Receipt No.
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50  min-w-[250px]">
                  Items
                </th>
                <th scope="col" className="px-6 py-3">
                  Grand Total
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Discount
                </th>
                <th scope="col" className="px-6 py-3">
                  Net Amount
                  <br /> Payable
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Mode of
                  <br />
                  Payment
                </th>
                <th scope="col" className="px-6 py-3">
                  Transaction <br /> date
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Sold
                  <br />
                  by
                </th>
              </tr>
            </thead>
            <tbody>
              {salesData?.data?.map((item: any) => {
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <div className="flex gap-2">
                        <div
                          onClick={() => {
                            setShowDelete(true);
                            setSelected(item);
                          }}
                          className="p-2 bg-[#dc5d3a] text-white rounded-md h-[fit-content]"
                        >
                          <MdOutlineClose />
                        </div>
                        <AppButton
                          title="Print"
                          buttontype={"green"}
                          onClick={() => {
                            onPrintInvoice(item);
                          }}
                        />
                      </div>
                    </td>

                    <td className="px-6 py-4">{item?.id}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.customerPhoneNumber}
                    </td>
                    <td className="px-6 py-4">{item?.receiptNumber}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      <ItemsAndAmount inputString={item?.itemsAndAmount} />
                    </td>
                    <td className="px-6 py-4">
                      {item?.grandTotal.toLocaleString("en-NG")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.discount.toLocaleString("en-NG")}
                    </td>
                    <td className="px-6 py-4">
                      {item?.netAmountPayable.toLocaleString("en-NG")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.modeOfPayment}
                    </td>
                    <td className="px-6 py-4">
                      {moment(item?.transactionDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.soldBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Sales</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {/* {renderHeading()} */}
        <div className="flex justify-end">
          <AppButton
            onClick={() => setShowExportModal(true)}
            title="Export Data"
            extraclass="!bg-[#2fade7] hover:!bg-[#3492c9]"
          />
        </div>

        {renderFilters()}
        {renderResults()}
        {salesData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={salesData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
      </div>
      <SaleDelete
        show={showDeleteConfirm}
        onClose={() => {
          setSelected(undefined);
          setShowDelete(false);
        }}
        onDelete={onDeleteItem}
        data={selectedItem}
      />

      <ExportDataModal
        show={showExportModal}
        onClose={() => setShowExportModal(false)}
        content={() => {
          return <RenderSalesRecordContent />;
        }}
      />
    </div>
  );
};

export default Page;
