import * as React from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  value: string;
  onChange: (val: any) => void;
  theme?: string;
  placeholder?: string;
}

const LabNetQuillEditor = (props: Props) => {
  const editorRef = React.useRef<any>(undefined);

  function removeTags(str: string) {
    if (str === null || str === "") return "";
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  const handleChange = (val: any) => {
    const editorText = removeTags(val as string);
    if (editorText?.trim() == "") {
      props.onChange("");
    } else {
      props.onChange(val);
    }
  };

  return (
    <div className="">
      <ReactQuill
        theme={props.theme || "snow"}
        value={props.value}
        ref={editorRef}
        onChange={handleChange}
        modules={LabNetQuillEditor.modules}
        formats={LabNetQuillEditor.formats}
        placeholder="Enter result..."
      />
    </div>
  );
};

LabNetQuillEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

LabNetQuillEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "script",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export default LabNetQuillEditor;
