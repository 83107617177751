import { Field, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { LabTestsProviderValues, ResultTemplateType, SerologyItem } from '../../../@types/app-types';

import _ from 'lodash'
import { updatedDiff } from 'deep-object-diff';
import { LabTestContext } from '../../../context/LabTestProvider';
import AppButton from '../../UI/button';
import { usePrintResultMutation } from '../../../store/rtk-query/labResult';
import { toast } from 'react-toastify';
import { formatServerErrorMessage, generateAndDownloadResultPdf } from '../../../utils/functions';
import Spinner from '../../UI/Spinner';

interface Props {
    onChangeForm: any
}

const Serology = (props: Props) => {
    const {
        originalTestResult,
        isUpdateMode,
        labTestResultId
    } = React.useContext<LabTestsProviderValues>(LabTestContext)

    const [printResult, printResultState] = usePrintResultMutation()

    const tests = [
        {
            key: 'hivIandII',
            label: 'HIV I&II SCREENING'
        },
        {
            key: 'HBsAg',
            label: 'HBsAg SCREENING',
        },
        {
            key: 'HCV',
            label: 'HCV SCREENING',
        },
        {
            key: 'VDRL',
            label: 'VDRL',
        },
        {
            key: 'pregnancy',
            label: 'PREGNANCY',
        },
        {
            key: 'hPylori',
            label: 'H. PYLORI',
        }
    ]



    const serologyResult = originalTestResult?.widalScreening

    const formColumns = [
        //will become {testName}_
        'Test Findings',

    ]

    const testFindings = [
        'NON-REACTIVE', 'REATIVE',
    ]

    const convertResultArrayToFormVals = (resultArray: SerologyItem[]): { [key: string]: any } => {
        const formVals: { [key: string]: any } = {};

        resultArray?.forEach((item) => {
            const testKey = tests.find((test) => test.label?.toLowerCase() == item?.testName?.toLowerCase())?.key as string

            if (testKey) {
                formVals[`${testKey}_`] = item.testFindings;
            }
        });
        return formVals;
    }

    let initVals: any = React.useMemo(() => {
        let formikKeys: string[] = []
        //loop through test
        tests.forEach((test) => {
            let testKey = test.key;

            //loop through form columns and generate formik form keys;

            formColumns.forEach((_formCol) => {
                //example is {testName}_key
                let formikKey = `${testKey}_${_formCol}`
                formikKeys.push(formikKey)
            })
        })

        //build object from formik keys
        let values: any = {}

        formikKeys.forEach((_key) => {
            values[_key] = '';
        })

        return values
    }, [])

    //build initialValues from tests and there respective colums
    let initialValues = React.useMemo(() => {

        let vals = convertResultArrayToFormVals(serologyResult)

        const values: any = {
            ...initVals,
            ...vals
        }

        return values
    }, [serologyResult])

    const formik = useFormik({
        initialValues: {
            ...initialValues
        },
        onSubmit: values => {

        },
        enableReinitialize: true
    });

    React.useEffect(() => {
        /**
 * remove this form key from final result compilation if user
 * removes all changes
 */
        const updatedObj = updatedDiff(initVals, formik.values)

        if (Object.keys(updatedObj).length == 0) {
            props.onChangeForm(undefined, 'serologyResult')
            return
        }

        const resultArray = convertObjectToWidalArray(updatedObj)
        props.onChangeForm(resultArray, 'serologyResult')
    }, [formik.values])

    function convertObjectToWidalArray(inputObj: { [key: string]: any }): SerologyItem[] {
        const resultArray: SerologyItem[] = [];
        const addedTestNames: { [key: string]: boolean } = {};

        _.forEach(inputObj, (value, key) => {
            const testName = key.split('_')[0];
            // const propName = key.split('_')[1];
            const testLabelName = tests.find((test) => test?.key == testName)?.label as string

            if (!addedTestNames[testName]) {
                const testObj: SerologyItem = {
                    testName: testLabelName,
                    testFindings: inputObj[`${testName}_testFindings`],
                    makeResultAvailable: true
                };

                addedTestNames[testName] = true;
                resultArray.push(testObj);

            }
        });

        return resultArray;
    }


    const onPrintResult = async () => {
        const res: any = await printResult({
            labResultId: labTestResultId,
            queryParams: {
                templateType: ResultTemplateType.HIV_HPYLORI_HBSAG_TEST_TEMPLATE
            }
        });

        if (res?.error) {
            let message = formatServerErrorMessage(res?.error?.data?.message)

            if (message.length > 0) {
                toast.error(message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            return;
        }

        generateAndDownloadResultPdf(res?.data, 'Widal')

        toast.success("Result pdf generated successfully");
    };

    const inputClasses =
        "bg-white border h-[fit-content] border-gray-500 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
    const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;


    return (
        <FormikProvider
            value={formik}
        >

            <div className='text-[#c09853] text-md'> Key: L= Low, H = High, WNL= Within Normal Limits, *= Critical value
            </div>
            <div className="tableContainer relative overflow-x-visible shadow-md sm:rounded-lg">
                <table className="horiz-table w-full text-sm">
                    <thead className="text-xs text-gray-700 uppercase">
                        <tr>
                            <th scope="col" className="px-6 py-3 bg-gray-50 ">
                                Test Name
                            </th>
                            <th scope="col" className="px-6 py-3 min-w-[300px]">
                                Test Findings
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tests?.map((test) => {
                            return (
                                <tr key={test?.key} className="border-b border-gray-200 ">
                                    <td className="px-6 py-3 bg-gray-50 ">
                                        {test.label}
                                    </td>
                                    <td className="px-6 py-3 bg-gray-50 min-w-[120px] ">
                                        <Field
                                            as="select"
                                            name={`${test.key}_o`}
                                            className={inputClasses}
                                            defaultValue=''
                                        >
                                            <option value=""  >Select</option>
                                            {testFindings.map((opt) => {
                                                return (
                                                    <option key={opt} value={opt} label={opt} />
                                                )
                                            })}
                                        </Field>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {
                    isUpdateMode && !_.isEmpty(serologyResult) && <div className='flex gap-1 mt-8'>
                        <AppButton
                            onClick={onPrintResult}
                            buttontype={'cyan'}
                            title='Generate Result'
                            extraclass='!text-xs'
                        />
                        {printResultState.isLoading && <Spinner />}
                    </div>
                }
            </div>
        </FormikProvider>
    )
}

export default Serology