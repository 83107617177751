import * as React from "react";
import AppButton from "../../components/UI/button";

import {
  useFormikContext,
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage,
  FormikProvider,
  useFormik,
} from "formik";
import * as Yup from "yup";
import { MdNavigateNext, MdCancel } from "react-icons/md";

import * as csc from "country-state-city";
import FormikChildListener from "../../utils/FormikChildListener";
import { useAddFirstVisitMutation } from "../../store/rtk-query/patientApi";
import Spinner from "../../components/UI/Spinner";
import { BsInfoSquareFill } from "react-icons/bs";

import moment from "moment";
import { toast } from "react-toastify";
import { useGetAllLabCategoriesQuery } from "../../store/rtk-query/labApi";
import { useNavigate } from "react-router-dom";
import { useGetAllHmosQuery } from "../../store/rtk-query/hmoApi";
import { useGetAllCompaniesQuery } from "../../store/rtk-query/companyApi";
import { formatServerErrorMessage } from "../../utils/functions";
import PhoneNumberInput from "../../components/PhoneTelInput";

interface FormValues {
  firstName: string;
  lastName: string;
  otherName: string;
  address: string;
  sex: string;
  age: string;
  phoneNo: string;
  city: string;
  dateOfLabTest: string;
  dateOfBirth: string;
  plan: string;
  email: string;
  clinicalDetails: string;
  patientType: string;
  labTestType: string;
  vitalSigns: string;
  referral: string;
  country: string;
  state: string;
  //
  hmoId: string;
  companyId: string;
}

const Page = () => {
  const [selectedCountry, setSelectedCountry] = React.useState<
    csc.ICountry | undefined
  >();
  const [countries, setCountries] = React.useState<Array<csc.ICountry | any>>(
    []
  );
  const [states, setStates] = React.useState<Array<csc.IState | any>>([]);

  const [addFirstVisit, addVisitState] = useAddFirstVisitMutation();

  const { data: labData } = useGetAllLabCategoriesQuery({});

  const [trackedFormVals, setTrackedVals] = React.useState<
    undefined | FormValues
  >(undefined);

  const navigate = useNavigate();

  React.useEffect(() => {
    // Fetch the list of countries from the library
    const fetchedCountries = csc.Country.getAllCountries();

    // Find and move Nigeria to the front
    const nigeriaIndex = fetchedCountries.findIndex(country => country.isoCode === 'NG');
    if (nigeriaIndex !== -1) {
        const nigeria = fetchedCountries.splice(nigeriaIndex, 1)[0];
        fetchedCountries.unshift(nigeria);
    }

    // Set the countries and select Nigeria
    setCountries(fetchedCountries);
    setSelectedCountry(fetchedCountries[0]); // Select the first country (Nigeria)
}, []);

  React.useEffect(() => {
    if (selectedCountry) {
      const _states = csc.State.getStatesOfCountry(selectedCountry?.isoCode);
      setStates(_states);
    }
  }, [selectedCountry]);

  const patientTypes = ["FAMILY", "NHIS", "PRIVATE", "COMPANY", "HMO"];

  let testTypes = [
    "microbiology",
    "immunohistochemistry",
    "special stain",
    "conventional pap test",
    "liquid based pap test",
    "non gynae",
    "clinical",
    "ultrasound",
  ];

  if (labData) {
    testTypes = Object.keys(labData).map((key) => key);
  }

  let initialValues = {
    firstName: "",
    lastName: "",
    otherName: "",
    address: "",
    sex: "MALE",
    age: "",
    phoneNo: "",
    city: "Abuja",
    dateOfLabTest: "",
    dateOfBirth: "",
    plan: "",
    email: "",
    clinicalDetails: "",
    patientType: "",
    labTestType: "",
    vitalSigns: "",
    referral: "",
    country: "Nigeria",
    state: "",
    //
    hmoId: "",
    companyId: "",
  };

  const formValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    // otherName: Yup.string().required('Last name is Required'),
    lastName: Yup.string().required("Last name is required"),
    address: Yup.string().required("Address is required"),
    sex: Yup.string().required("Sex is required"),
    // age: Yup.string().required('Required'),
    phoneNo: Yup.string().required("Required"),
    // city: Yup.string().required("Required"),
    dateOfLabTest: Yup.string().required("Required"),
    dateOfBirth: Yup.string().required("Required"),
    //plan: Yup.string().required('Required'),
    // email: Yup.string().email("Invalid email"),
    // clinicalDetails: Yup.string().required("Required"),
    patientType: Yup.string().required("Required"),
    labTestType: Yup.string().required("Required"),
    // vitalSigns: Yup.string().required("Required"),
    //
    ...(trackedFormVals?.patientType === "HMO" && {
      hmoId: Yup.string().required("HMO must be selected for HMO patients"),
    }),
    ...(trackedFormVals?.patientType === "COMPANY" && {
      companyId: Yup.string().required(
        "Company must be selected for Company patients"
      ),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
      onSubmit(values);
    },
    enableReinitialize: true,
  });

  const { data: hmoData, isLoading: hmoLoading } = useGetAllHmosQuery({
    headers: {
      X_pagination_take: 20,
    },
  });

  const { data: companyData, isLoading: companyLoading } =
    useGetAllCompaniesQuery({
      headers: {
        X_pagination_take: 20,
      },
    });

  React.useEffect(() => {
    setTrackedVals(formik.values);
    if (!formik.values.country) return;

    if (formik.values.country !== selectedCountry?.name) {
      let countryObj = countries.find(
        (country) => country.name == formik.values.country
      );
      setSelectedCountry(countryObj);
    }
  }, [formik.values]);

  const inputClasses =
    "bg-gray-50 border border-[#CACACA] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full md:w-full lg:w-full xl:w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";
  const inputWrap = "flex gap-[12%] justify-center";

  const onSubmit = async (values: typeof initialValues) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      otherNames: values.otherName,
      patientType: values.patientType,
      address: values.address,
      dateOfBirth: moment(values.dateOfBirth).format("MM/DD/YYYY"),
      city: values.city,
      state: values.state,
      country: values.country,
      laboratoryTestTypes: values.labTestType,
      phoneNumber: values.phoneNo,
      clinicalDetails: values.clinicalDetails,
      email: values.email,
      vitalSigns: values.vitalSigns,
      sex: values.sex,
      dateOfLabTest: moment(values.dateOfLabTest).format("MM/DD/YYYY"),
      ...(formik?.values.patientType == "HMO" && { hmoId: values.hmoId }),
      ...(formik?.values.patientType == "COMPANY" && {
        companyId: values.companyId,
      }),
    };

    const res: any = await addFirstVisit(data);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("First visit added successfully");

    console.log("res ", res);

    const visitId = res?.data?.patientVisit?.id;
    const patientId = res?.data?.patient?.id;

    navigate("/add-to-queue/" + patientId, { state: { visitId } });
  };

  const renderPatientTypeInfo = () => {
    return (
      <div className="w-9/12 mx-auto mt-8 border rounded-lg p-6 space-y-6">
        <div className="flex flex-wrap sm:flex-nowrap justify-center gap-[10%] my-3">
          <div className="w-full sm:w-1/3">
            <label className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Patient Type
            </label>
            <Field as="select" name="patientType" className={inputClasses}>
              <option value="" disabled hidden>
                Select Type
              </option>
              {patientTypes.map((type) => {
                return (
                  <option key={type} value={type?.toUpperCase()}>
                    {type}
                  </option>
                );
              })}
            </Field>
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="patientType"
            />
          </div>
          <div className="w-full sm:w-1/3">
            <label className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Lab Test Types
            </label>
            <Field as="select" name="labTestType" className={inputClasses}>
              <option value="" disabled hidden>
                Select LabTest Type
              </option>

              {testTypes.map((type) => {
                return (
                  <option key={type} value={type?.toUpperCase()}>
                    {type?.toUpperCase()}
                  </option>
                );
              })}
            </Field>
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="labTestType"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCompanyAndHmoDropdown = () => {
    return (
      <div className="w-full">
        <div className={inputWrap}>
          {formik.values.patientType == "HMO" && (
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>HMO</label>
              <Field className={inputClasses} as="select" name="hmoId">
                <option value="" disabled hidden>
                  Select HMO
                </option>
                {hmoData?.data?.map((hmo: any) => {
                  return (
                    <option key={hmo?.id} value={hmo?.id}>
                      {hmo?.name}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="hmoId"
              />
            </div>
          )}
          {formik.values.patientType == "COMPANY" && (
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Company</label>
              <Field as="select" name="companyId" className={inputClasses}>
                <option value="" disabled hidden>
                  Select Company
                </option>
                {companyData?.data?.map((company: any) => {
                  return (
                    <option key={company?.id} value={company?.id}>
                      {company?.name}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="companyId"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderPatientGeneralInfo = () => {
    return (
      <div className="w-9/12 mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
        <div className="flex flex-wrap sm:flex-nowrap gap-2 justify-center md:gap-4 mb-2">
          <div className="w-full sm:w-1/3 md:w-2/3 lg:w-1/2 xl:w-1/4">
            <label className={labelClasses}>First Name</label>
            <Field className={inputClasses} name="firstName" />
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="firstName"
            />
          </div>
          <div className="w-8 sm:w-1/3 md:w-2/3 lg:w-1/2 xl:w-1/4">
            <label className={labelClasses}>Surname</label>
            <Field className={inputClasses} name="lastName" />
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="lastName"
            />
          </div>
          <div className="w-full sm:w-1/3 md:w-2/3 lg:w-1/2 xl:w-1/4">
            <label className={labelClasses}>Other Name</label>
            <Field className={inputClasses} name="otherName" />
            <ErrorMessage
              className={errorMessageClasses}
              component={"p"}
              name="otherName"
            />
          </div>
        </div>
        <div className="mb-2">
          <div className={inputWrap}>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Date of Birth</label>
              <Field className={inputClasses} type="date" name="dateOfBirth" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="dateOfBirth"
              />
            </div>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Sex</label>
              <Field as="select" name="sex" className={inputClasses}>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="sex"
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className={inputWrap}>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Address</label>
              <Field as="textarea" className={inputClasses} name="address" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="address"
              />
            </div>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Vital Signs</label>
              <Field as="textarea" className={inputClasses} name="vitalSigns" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="vitalSigns"
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className={`${inputWrap} flex-wrap`}>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Referral</label>
              <Field className={inputClasses} name="referral" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="referral"
              />
            </div>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Phone Number</label>
              <PhoneNumberInput
                buttonClass="!rounded-l-lg"
                containerClass={'min-h-[40px] '}
                inputClass={'min-h-[40px] !rounded-lg'}
                name="phoneNo" />

              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="phoneNo"
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className={inputWrap}>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Treatment/Lab Test date</label>
              <Field
                className={inputClasses}
                type="date"
                name="dateOfLabTest"
              />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="dateOfLabTest"
              />
            </div>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>City</label>
              <Field className={inputClasses} name="city" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="city"
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className={inputWrap}>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Country</label>
              <Field as="select" name="country" className={inputClasses}>
                {countries.map((country) => {
                  return (
                    <option key={country.name} value={country.name}>
                      {country.name}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="country"
              />
            </div>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>State</label>
              <Field as="select" name="state" className={inputClasses}>
                {states.map((state) => {
                  return (
                    <option key={state.name} value={state.name}>
                      {state.name}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="state"
              />
            </div>
          </div>
        </div>
        {renderCompanyAndHmoDropdown()}
        <div className="mb-2">
          <div className={inputWrap}>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Email</label>
              <Field className={inputClasses} name="email" />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="email"
              />
            </div>
            <div className="w-full sm:w-1/3">
              <label className={labelClasses}>Clinical Details</label>
              <Field
                as="textarea"
                className={inputClasses}
                name="clinicalDetails"
              />
              <ErrorMessage
                className={errorMessageClasses}
                component={"p"}
                name="clinicalDetails"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Patient's First Visit's Record
        </h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md  ">
        <FormikProvider value={formik}>
          <Form>
            <>
              <div
                className="container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className="w-full font-semibold bg-sky-200 p-4 border border-sky-200 rounded-lg shadow-md"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <BsInfoSquareFill
                    size="1.5rem"
                    style={{ marginRight: "0.5rem" }}
                  />
                  Patient Type Information
                </div>
              </div>
              {renderPatientTypeInfo()}
              <div
                className="container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  className="w-full mt-6 font-semibold bg-sky-200 p-4 border border-sky-200 rounded-lg shadow-md"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <BsInfoSquareFill
                    size="1.5rem"
                    style={{ marginRight: "0.5rem" }}
                  />
                  Patient's General Information
                </div>
              </div>
              {renderPatientGeneralInfo()}
              {addVisitState.isLoading && <Spinner />}
              <div className="flex items-center justify-center">
                <button
                  onClick={() => {
                    formik.resetForm();
                  }}
                  type="button"
                  className="flex flex-row items-center  justify-center focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-lg px-8 py-1 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                >
                  <MdCancel fill="white" fontSize={22} />
                  Cancel{" "}
                </button>
                <button
                  type="submit"
                  className="flex flex-row items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-8 py-1 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Next <MdNavigateNext fontSize={24} />
                </button>
              </div>
            </>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};

export default Page;
