import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../../components/UI/button";

import { Tab } from '@headlessui/react'

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useGetTestOnQueueDetailsQuery } from "../../store/rtk-query/testQueueApi";
import GeneralTest from "../../components/LabTests/GeneralTest";
import HistoTest from "../../components/LabTests/HistoTest";
import GynaeCytology from "../../components/LabTests/GynaeCytology";
import NonGynaeCytology from "../../components/LabTests/NonGynaeCytology";
import GroupTests from "../../components/LabTests/GroupTests";
import Others from "../../components/LabTests/Others";

import {
    LabAllFormValuesInterface as AllFormValuesInterface,
    ECSResultInterface,
    GeneralTestResultItem,
    GroupTestResultItem,
    MCSResultInterface,
    MolecularAssayResultInterface,
    WidalResultItem,
    LabTestsProviderValues
} from "../../@types/app-types";
import { useAttendToPatientMutation } from "../../store/rtk-query/labResult";
import Spinner from "../../components/UI/Spinner";
import LabTestProvider, { LabTestContext } from "../../context/LabTestProvider";

import { debounce } from "lodash";

interface Props {
    laboratoryTests?: any;
    onUpdateForms?: (data: any) => any;
    onSubmit: (data: any) => any;
    isLoading: boolean;
    setAllResultsEntered?: (val: any) => any;
    submitError?: string;
    isUpdateMode: boolean;
    laboratoryTestResult?: any;
    laboratoryTestResultId?: any
}

const LabTests = (props: Props) => {
    // const { allFormValues, setAllFormValues } = props
    const { onSubmit,
        isLoading,
        setAllResultsEntered, submitError,
        laboratoryTestResult
    } = props

    //for keeping track of form update promise chain
    const [updatePromise, setUpdatePromise] = React.useState<Promise<any> | null>(null);

    const [pendingUpdatesCount, setPendingUpdateCount] = React.useState<number>(0)
    const pendingUpdatesRef = React.useRef<Array<{ key: keyof AllFormValuesInterface; data: any }>>([]);

    const {
        setIsUpdateMode,
        isUpdateMode,
        setOriginalTestResult,
        //
        setAllFormValues,
        allFormValues,
        //
        setLabTestResultId
    } = React.useContext<LabTestsProviderValues>(LabTestContext)


    React.useEffect(() => {
        //set prop value into lab tests context
        setIsUpdateMode?.(props.isUpdateMode)

        //if update mode store lab result id
        if (props.isUpdateMode && props.laboratoryTestResultId) {
            setLabTestResultId?.(props.laboratoryTestResultId)
        }

        if (laboratoryTestResult) {
            setOriginalTestResult?.(laboratoryTestResult)
        }
    }, [props.isUpdateMode, laboratoryTestResult])


    const onFormUpdate = React.useCallback(
        (data: any, key: keyof AllFormValuesInterface) => {
            // Add the update to the pendingUpdates REF array
            pendingUpdatesRef.current = [...pendingUpdatesRef.current, { key, data }];
            setPendingUpdateCount((prev: number) => prev + 1)
        },
        []
    );

    const callPendingUpdates = React.useCallback(async () => {
        const newAllFormVals = { ...allFormValues };

        if (pendingUpdatesRef.current.length < 1) {
            return
        }

        pendingUpdatesRef.current.forEach((update) => {
            const { key, data } = update;

            newAllFormVals[key] = data;

            //remove result if the data is undefined
            if (data == undefined) {
                delete newAllFormVals[key]
            }
        });

        pendingUpdatesRef.current = [];

        setAllFormValues?.(newAllFormVals)
        props.onUpdateForms?.(newAllFormVals);
        //reset count
        setPendingUpdateCount(0)
    }, [allFormValues]);

    React.useEffect(() => {
        if (pendingUpdatesRef.current.length > 0) {
            const debouncedCallPendingUpdates = debounce(callPendingUpdates, 2000);
            debouncedCallPendingUpdates();
        }
    }, [pendingUpdatesCount]);

    const tabs = [
        {
            title: 'General Test',
            component: <GeneralTest
                onChangeForm={onFormUpdate}
                laboratoryTests={props?.laboratoryTests}
            />
        },
        {
            title: 'Histology',
            component: <HistoTest
                onChangeForm={onFormUpdate}
            />
        },
        {
            title: 'Gynaecological Cytology',
            component: <GynaeCytology
                onChangeForm={onFormUpdate}
            />
        },
        {
            title: 'Non Gynaecological Cytology',
            component: <NonGynaeCytology
                onChangeForm={onFormUpdate}
            />
        },
        {
            title: 'Others',
            component: <Others
                onChangeForm={onFormUpdate}
            />
        },
        {
            title: 'Group Tests',
            component: <GroupTests
                onChangeForm={onFormUpdate}
            />
        },
    ]

    const classNames = (...classes: any) => {
        return classes.filter(Boolean).join(' ')
    }

    const renderFormCompletePrompt = () => {
        //ignore if its form update mode
        if (isUpdateMode) return

        return (

            <div>
                <div className="text-[#c09853] text-lg">
                    Have you entered all the laboratory test results?
                </div>
                <div

                    className="flex gap-3 items-center">
                    <div>
                        <span className="mr-2">Yes</span>
                        <input id="default-radio-1"
                            onChange={(e) => {
                                if (e.target.checked && setAllResultsEntered) {
                                    setAllResultsEntered(true)
                                }
                            }}
                            type="radio" value={"true"} name="default-radio" className={radioClasses} />
                    </div>
                    <div>
                        <span className="mr-2">No</span>
                        <input
                            onChange={(e) => {
                                if (e.target.checked && setAllResultsEntered) {
                                    setAllResultsEntered(false)
                                }
                            }}
                            id="default-radio-2" type="radio" value={"false"} name="default-radio" className={radioClasses} />
                    </div>
                    <div className="text-red-500 text-sm ml-3">{submitError}</div>
                </div>
            </div>
        )
    }

    const radioClasses = "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"


    return (
        <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
            <div className="font-semibold text-lg">Laboratory Tests</div>
            <Tab.Group>
                <Tab.List className="flex flex-wrap sm:flex-nowrap space-x-1 rounded-xl bg-blue-900 p-1">
                    {
                        tabs.map((tabData) => {
                            return <Tab key={tabData?.title}
                                className={({ selected }) =>
                                    classNames(
                                        ' w-[40%] sm:w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                        selected
                                            ? 'bg-white shadow'
                                            : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                                    )
                                }
                            >
                                {tabData?.title}
                            </Tab>
                        })
                    }
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {tabs.map((tabData) => (
                        <Tab.Panel
                            key={tabData?.title}
                            className={classNames(
                                'rounded-xl bg-white p-3',
                                'ring-white ring-opacity-60 ring-offset-2 focus:outline-none '
                            )}
                            unmount={false}
                        >
                            {tabData.component}
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
            <form
                onSubmit={onSubmit}
            >
                {renderFormCompletePrompt()}
                <AppButton
                    title={isUpdateMode ? "Update" : "Save"}
                    buttontype={'green'}
                    extraclass='ml-[20px] mt-[13px]'
                    type="submit"
                />
                {isLoading && <Spinner />}
            </form>
        </div>
    )
}

const Wrapper = (props: Props) => {
    return <LabTestProvider>
        <LabTests
            {...props}
        />
    </LabTestProvider>
}

export default Wrapper