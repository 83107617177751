import { userApi } from "./userApi";

export const labResultApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    attendToPatient: builder.mutation({
      query: ({ body, testQueueId }) => {
        return {
          url: "laboratory-result/attend-to-patient/" + testQueueId,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["TestsOnQueue"],
    }),
    updateVisitResult: builder.mutation({
      query: ({ body, patientVisitId }) => {
        return {
          url: "laboratory-result/update/" + patientVisitId,
          method: "PUT",
          body,
        };
      },
    }),
    printResult: builder.mutation({
      query: ({ labResultId, queryParams }) => {
        return {
          url: "laboratory-result/print/" + labResultId,
          method: "GET",
          params: { ...queryParams },
          responseHandler: (response: any) => {
            const buffer = response.arrayBuffer();
            return buffer;
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useAttendToPatientMutation,
  usePrintResultMutation,
  useUpdateVisitResultMutation,
} = labResultApi;
