import { userApi } from "./userApi";

export const birthdayApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUpcomingBdays: builder.query({
      query: ({ queryParams }) => {
        return {
          url: "birthdays/upcoming-birthdays",
          method: "GET",
          params: { ...queryParams },
        };
      },
      providesTags: ["Birthdays"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllUpcomingBdaysQuery } = birthdayApi;
