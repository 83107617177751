import { Dialog, Transition } from "@headlessui/react";
import * as React from "react";
import { ModalProps } from "../../@types/app-types";

const SideOverlay = (props: ModalProps) => {
  return (
    <Transition appear show={props.show} as={React.Fragment}>
      <Dialog
        open={props.show}
        onClose={() => {
          if (props.backdropClose) {
            props.onClose(false);
          }
        }}
        as="div"
        className="relative z-50"
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex flex-col min-h-full items-end text-center">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition-all duration-300 ease-out"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition-all duration-200 ease-in"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="h-full flex-1 w-full max-w-[75%] transform overflow-hidden rounded-[3px] bg-white p-6 text-left align-middle shadow-xl">
                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SideOverlay;
