import * as React from "react";

import { useFormikContext } from "formik";

interface Props {
    errors?: any;
    name: string;
    extraclass?: string
}

const FormikInputError = (props: Props) => {
    // const context = useFormikContext();
    const errors = props?.errors;
    const name = props?.name;
    const extraclass = props?.extraclass

    if (!name || !errors?.[name]) return <div />;

    return <div className={"text-xs text-center text-red-500 " + extraclass}>{errors[name]}</div>;
};

export default FormikInputError;
