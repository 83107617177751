import * as React from "react";

import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import AppButton from "../components/UI/button";

import { Tab } from "@headlessui/react";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useGetTestOnQueueDetailsQuery } from "../store/rtk-query/testQueueApi";


import {
  LabAllFormValuesInterface as AllFormValuesInterface,
 
} from "../@types/app-types";

import {
  useAttendToPatientMutation,
  useUpdateVisitResultMutation,
} from "../store/rtk-query/labResult";
import Spinner from "../components/UI/Spinner";
import LabTests from "../components/LabTests";
import { useGetVisitResultQuery } from "../store/rtk-query/visitApi";

import _ from "lodash";
import { formatServerErrorMessage } from "../utils/functions";
import TestRequestModal from "../components/modals/TestRequested";
import { ViewTestRequested } from "../components/modals/viewTestRequested";

const UpdateVisitDetails = () => {
  const { visit_id } = useParams();

  const [showPrintModal, setShowPrintModal] = React.useState<boolean>(false);

  const { data: visitData, isLoading: visitDataLoading } =
    useGetVisitResultQuery({
      visitId: visit_id,
    });

  const { data: testOnQueueData } = useGetTestOnQueueDetailsQuery({
    testId: visitData?.testQueueId,
  });

  const [updateVisitResult, updateVisitResultState] =
    useUpdateVisitResultMutation();

  const [allResultsEntered, setAllResultsEntered] =
    React.useState<boolean>(false);

  const [allFormValues, setAllFormValues] = React.useState<
    AllFormValuesInterface | undefined
  >(undefined);
  const [submitError, setSubmitError] = React.useState<string | undefined>(
    undefined
  );

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setSubmitError(undefined);

    const data = {
      ...allFormValues,
      makeAllResultAvailable: true,
    };

    //convert all objects to arrays of 1 object
    let newData: any = {};

    const skipKeys: (keyof AllFormValuesInterface)[] = [
      "histologyTest",
      "gynaecologicCytology",
      "nongynaecologicCytology",
    ];

    for (let key in data) {
      const keyResult = data[key as keyof AllFormValuesInterface];
      if (Array.isArray(keyResult)) {
        newData[key] = keyResult;
      } else {
        if (!skipKeys.some((skipKey) => skipKey == key)) {
          newData[key] = [keyResult];
        } else {
          newData[key] = keyResult;
        }
      }
    }

    newData["makeAllResultAvailable"] = true;

    newData = _.omitBy(newData, _.isNil);

    console.log("new data ", newData);
    // return
    //
    const res: any = await updateVisitResult({
      body: newData,
      patientVisitId: visitData?.id,
    });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message);

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Results submitted successfully");
  };

  const inputWrap = "flex justify-between md:justify-start gap-4";
  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const radioClasses =
    "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";

  const renderTestInfo = () => {
    return (
      <div className=" w-1/2 p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <div className="font-semibold text-lg">Test Information</div>

        <div className={inputWrap}>
          <div className="flex p-2">
            <AppButton
              buttontype="outline"
              onClick={() => setShowPrintModal(true)}
              title="View Tests Requested"
            />
          </div>
          <div className="flex p-2">
            <AppButton
              buttontype="outline"
              onClick={() => setShowPrintModal(true)}
              title="Print results"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPatientInfo = () => {
    return (
      <div className="w-1/2  mr-2 p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
        <div className="font-semibold text-lg">Visit Information</div>
        <div className={inputWrap}>
          <div className="w-1/2">
            <label className={labelClasses}>Patient Name</label>
            <input
              className={inputClasses}
              disabled
              value={`${visitData?.patientName}`}
            />
          </div>
          <div className="w-1/2">
            <label className={labelClasses}>Patient REG Number</label>
            <input
              className={inputClasses}
              disabled
              defaultValue={visitData?.patientRegNumber}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-3">
      <div className="w-full z-30 bg-white shadow-md mb-1 flex items-center">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">
          Patients Record
        </h3>
        <span>{visitDataLoading && <Spinner />}</span>
      </div>
      <div className="flex justify-between">
        {renderPatientInfo()}
        {renderTestInfo()}
      </div>
      <LabTests
        onSubmit={onSubmit}
        isLoading={updateVisitResultState.isLoading}
        laboratoryTests={visitData?.laboratoryTest}
        onUpdateForms={setAllFormValues}
        setAllResultsEntered={setAllResultsEntered}
        submitError={submitError}
        isUpdateMode={true}
        laboratoryTestResult={visitData?.laboratoryTestResult}
        laboratoryTestResultId={visitData?.laboratoryTestResultId}
      />
      <TestRequestModal
        show={showPrintModal}
        onClose={() => setShowPrintModal(false)}
        content={() => {
          return <ViewTestRequested data={testOnQueueData} />;
        }}
      />
    </div>
  );
};

export default UpdateVisitDetails;
