import * as React from 'react'

import ReactPaginate from 'react-paginate';

interface Props {
    onPageChange: (data: any) => any;
    pageRangeDisplayed?: number;
    pageCount: number;
    itemsPerPage: number
}

const Paginate = (props: Props) => {

    const {
        pageCount,
        pageRangeDisplayed,
        onPageChange,
        itemsPerPage
    } = props

    return (
        <div className='my-2'>
            <div className='text-md my-2 text-[#468847]'>
                Items per page {itemsPerPage}
            </div>
            <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={onPageChange}
                pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : 5}
                pageCount={pageCount}
                previousLabel="< prev"
                renderOnZeroPageCount={null}
                containerClassName='flex'
                pageClassName='h-[36px] w-[36px] border shadow-sm rounded-sm flex '
                activeClassName="bg-blue-700 text-white"
                nextClassName='border shadow-sm rounded-sm flex items-center justify-center px-3'
                previousClassName='border shadow-sm rounded-sm flex items-center justify-center px-3'
                pageLinkClassName='w-full h-full flex items-center justify-center'
            />
        </div>
    )
}

export default Paginate