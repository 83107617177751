import * as React from "react";

import {
  Form,
  Field,
  FormikProvider,
  useFormik,
} from "formik";
import { BsFilterSquareFill } from "react-icons/bs";
import { VscOutput } from "react-icons/vsc";


import {  useNavigate } from "react-router-dom";
import Spinner from "../../components/UI/Spinner";

import {  MdOutlineClose } from "react-icons/md";
import AppButton from "../../components/UI/button";

import {
  useDeleteSaleMutation,
  useGetAllSalesQuery,
} from "../../store/rtk-query/saleApi";
import moment from "moment";
import { toast } from "react-toastify";
import Paginate from "../../components/Paginate";
import {
  useDeleteInvoiceMutation,
  useGetAllInvoiceQuery,
  usePrintInvoiceMutation,
} from "../../store/rtk-query/invoiceApi";
import InvoiceDelete from "../../components/modals/InvoiceDelete";
import { formatServerErrorMessage, generateAndDownloadInvoicePdf } from "../../utils/functions";

const Page = () => {
  const [showDeleteConfirm, setShowDelete] = React.useState(false);

  const [selectedItem, setSelected] = React.useState<any>();

  const navigate = useNavigate();

  const [deleteInvoice, deleteInvoiceState] = useDeleteInvoiceMutation();
  const [printInvoice, printInvoiceState] = usePrintInvoiceMutation();

  const [perPage] = React.useState<number>(10);
  const [itemOffset, setItemOffset] = React.useState<number>(0);

  const { data: invoicesData, isLoading: invoicesLoading } =
    useGetAllInvoiceQuery({
      params: {
        skip: itemOffset,
        take: perPage,
      },
    });

  const initialValues = {
    periodFrom: "",
    periodTo: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // same shape as initial values
      console.log(values);
    },
  });

  const onDeleteItem = async () => {
    if (!selectedItem) {
      return;
    }

    const res: any = await deleteInvoice({ invoiceId: selectedItem?.id });

    console.log("res ", res);

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    toast.success("Invoice deleted successfully");
    setShowDelete(false);
  };

  const onPrintInvoice = async (selectedItem: any) => {
    if (!selectedItem) {
      return;
    }

    const res: any = await printInvoice({ invoiceId: selectedItem?.id });

    if (res?.error) {
      let message = formatServerErrorMessage(res?.error?.data?.message)

      if (message.length > 0) {
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return;
    }

    generateAndDownloadInvoicePdf(res?.data, selectedItem)

    toast.success("Invoice pdf generated successfully");
  };

  const inputClasses =
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5";
  const labelClasses = `block mb-1 text-sm font-medium text-gray-900 `;
  const errorMessageClasses = "mt-2 text-xs text-red-600 font-medium";

  const renderFilters = () => {
    return (
      <FormikProvider value={formik}>
        <Form>
          <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
            <div
              className="container"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                className="w-full font-semibold bg-sky-100 p-4 border border-sky-200 rounded-lg shadow-md"
                style={{ display: "flex", alignItems: "center" }}
              >
                <BsFilterSquareFill
                  size="1.5rem"
                  style={{ marginRight: "0.5rem" }}
                />
                Filter Inventory Invoice
              </div>
            </div>

            <div className="flex justify-between gap-4">
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period From</label>
                <Field className={inputClasses} type="date" name="periodFrom" />
              </div>
              <div className="w-full sm:w-1/3">
                <label className={labelClasses}>Period To</label>
                <Field className={inputClasses} type="date" name="periodTo" />
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    );
  };

  const renderResults = () => {
    return (
      <div className="w-full mx-auto mt-5 mb-5 border rounded-lg p-6 space-y-6">
        <div
          className="container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            className="w-full my-4 font-semibold bg-emerald-100 p-4 border border-sky-200 rounded-lg shadow-md"
            style={{ display: "flex", alignItems: "center" }}
          >
            <VscOutput size="1.5rem" style={{ marginRight: "0.5rem" }} />
            Results
            {invoicesLoading && <Spinner />}
          </div>
        </div>
        <div className="tableContainer relative shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase">
              <tr>
                <th scope="col" className="px-6 py-3 bg-gray-50 "></th>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Customer Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Invoice No.
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50  min-w-[150px]">
                  Payment Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Grand Total
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Balance
                </th>
                <th scope="col" className="px-6 py-3">
                  Net Amount
                  <br /> Payable
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Mode of
                  <br />
                  Payment
                </th>
                <th scope="col" className="px-6 py-3">
                  Transaction <br /> date
                </th>
                <th scope="col" className="px-6 py-3 bg-gray-50 ">
                  Sold
                  <br />
                  by
                </th>
              </tr>
            </thead>
            <tbody>
              {invoicesData?.data?.map((item: any) => {
                return (
                  <tr key={item?.id} className="border-b border-gray-200 ">
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 "
                    >
                      <div className="flex gap-2">
                        <div
                          onClick={() => {
                            setShowDelete(true);
                            setSelected(item);
                          }}
                          className="p-2 bg-[#dc5d3a] text-white rounded-md h-[fit-content]"
                        >
                          <MdOutlineClose />
                        </div>
                        <AppButton
                          title="Print"
                          onClick={() => {
                            onPrintInvoice(item);
                          }}
                          buttontype={"green"}
                        />
                      </div>
                    </td>

                    <td className="px-6 py-4">{item?.id}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.customerPhoneNumber}
                    </td>
                    <td className="px-6 py-4">{item?.invoiceNumber}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.paymentStatus}
                    </td>
                    <td className="px-6 py-4">{item?.grandTotal.toLocaleString('en-NG')}</td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.balance.toLocaleString('en-NG')}</td>
                    <td className="px-6 py-4">{item?.netAmountPayable.toLocaleString('en-NG')}</td>
                    <td className="px-6 py-4 bg-gray-50 ">
                      {item?.modeOfPayment}
                    </td>
                    <td className="px-6 py-4">
                      {moment(item?.transactionDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4 bg-gray-50 ">{item?.soldBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="w-full z-30 bg-white shadow-md mb-1">
        <h3 className="text-lg my-3 p-3  font-semibold font-sans">Invoices</h3>
      </div>
      <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
        {renderFilters()}
        {renderResults()}
        {invoicesData && (
          <Paginate
            onPageChange={(data) => {
              setItemOffset(data?.selected * perPage);
            }}
            pageCount={invoicesData?.totalPage}
            itemsPerPage={perPage}
          />
        )}
      </div>
      <InvoiceDelete
        show={showDeleteConfirm}
        onClose={() => {
          setSelected(undefined);
          setShowDelete(false);
        }}
        onDelete={onDeleteItem}
        data={selectedItem}
        isLoading={deleteInvoiceState.isLoading}
      />
    </div>
  );
};

export default Page;
