import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import TimeFrameSelector from "../TimeFrameSelector";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const scores = [6, 5, 5, 5, 3, 4, 6, 4];
const scores2 = [1, 3, 2, 2, 4, 4, 5, 3];
const scores3 = [1, 5, 7, 2, 4, 9, 5, 3];

const scores4 = [1, 3, 8, 2, 4, 6, 5, 3];

const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const options = {
  fill: true,
  responsive: true,
  scales: {
    y: {
      min: 0,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'top' as const,
    },
  },
};

interface Props {
  data: any
}


export default function LineChart(props: Props) {
  const [timeFrame, setTimeFrame] = React.useState<string>('Week')

  const formatData = (lineKey: string) => {
    let formattedData: number[] = []
    let labels: string[] = []

    const filterKey = `${timeFrame?.toLowerCase()}ly`

    let filteredData = props.data?.[filterKey]
    filteredData = filteredData?.filter((data: any) => data?.status == lineKey)[0]

    formattedData = filteredData?.dailyCounts?.map((item: any) => item?.count)
    labels = filteredData?.dailyCounts?.map((item: any) => {
      if (item?.date) {
        if (timeFrame?.toLowerCase() == "week") {
          return moment(item?.date).format('ddd')
        }
        if (timeFrame?.toLowerCase() == "month") {
          return moment(item?.date).week()
        }
        if (timeFrame?.toLowerCase() == "year") {
          return moment(item?.date).format('MMM')
        }
        return moment(item?.date).format('DD/MM/YYYY')
      } else {
        return "No date"
      }
    })

    return {
      formattedData,
      labels
    }
  }

  const data = useMemo(function () {
    const { formattedData: awaitingResultData, labels } = formatData('AWAITING')
    const { formattedData: resultAvailableData } = formatData('RESULT_AVAILABLE')
    const { formattedData: testCompletedData } = formatData('COMPLETED')
    const { formattedData: pendingResultData } = formatData('PENDING_RESULT')

    return {
      datasets: [
        {
          label: "Test completed",
          data: testCompletedData,
          tension: 0.3,
          borderColor: "rgb(75, 192, 192)",
          pointRadius: 6,
          pointBackgroundColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.3)",
        },
        {
          label: "Result Available",
          tension: 0.3,
          data: resultAvailableData,
          borderColor: "green",
          backgroundColor: "rgba(0, 255, 0, 0.3)",
          pointRadius: 6,
        },
        {
          label: "Awaiting Result",
          tension: 0.3,
          data: awaitingResultData,
          borderColor: "purple",
          backgroundColor: "rgba(174,174,225, 0.3)",
          pointRadius: 6,
        },
        {
          label: "Pending Approval",
          tension: 0.3,
          data: pendingResultData,
          borderColor: "pink",
          backgroundColor: "rgba(255,182,193, 0.3)",
          pointRadius: 6,
        },
      ],
      labels,
    };
  }, [props.data, timeFrame]);

  return (
    <div className="h-full w-full">
      <TimeFrameSelector
        onSelect={(val) => setTimeFrame(val)}
      />
      <Line data={data} options={options} />
      <p className="text-gray-600 text-sm text-center">Number</p>
    </div>
  )
}