import * as React from 'react'

export const AppContext = React.createContext({})

const AppContextProvider = (props: any) => {
    const [hideSideBar, setHideSideBar] = React.useState<boolean>(true)

    return <AppContext.Provider
        value={{
            hideSideBar,
            setHideSideBar
        }}
    >
        {props.children}
    </AppContext.Provider>
}

export default AppContextProvider