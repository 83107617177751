import * as React from 'react'

const Templates = () => {
    return (
        <div>
            <div className="w-full z-30 bg-white shadow-md mb-1">
                <h3 className="text-lg my-3 p-3  font-semibold font-sans">
                    Templates
                </h3>
            </div>
            <div className="p-3 sm:p-4 md:p-6 bg-white border border-gray-200 rounded-lg shadow-md">
            </div>
        </div>
    )
}

export default Templates