import * as React from 'react'

import { LabAllFormValuesInterface } from '../@types/app-types'
import useStateCallback from '../hooks/useStateCallback'

export const LabTestContext = React.createContext({})

const LabTestProvider = (props: any) => {
    const [isUpdateMode, setIsUpdateMode] = React.useState<boolean>(false)
    const [originalTestResult, setOriginalTestResult] = React.useState<any>(undefined)
    const [allFormValues, setAllFormValues] = useStateCallback<any>(undefined)

    const [labTestResultId, setLabTestResultId] = React.useState<string | undefined>(undefined)

    return <LabTestContext.Provider
        value={{
            isUpdateMode,
            setIsUpdateMode,
            //
            originalTestResult,
            setOriginalTestResult,
            //
            allFormValues,
            setAllFormValues,
            //
            labTestResultId,
            setLabTestResultId
        }}
    >
        {props.children}
    </LabTestContext.Provider>
}

export default LabTestProvider