import { userApi } from "./userApi";

export const purchaseApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPurchaseTypes: builder.query({
      query: () => {
        return {
          url: "inventory/purchase/all-purchase-types",
          method: "GET",
        };
      },
      providesTags: ["PurchaseType"],
    }),
    addNewPurchaseType: builder.mutation({
      query: (body) => {
        return {
          url: "inventory/purchase/add-new-purchase-type",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["PurchaseType"],
    }),
    getAllPurchaseItems: builder.query({
      query: ({ params }) => {
        return {
          url: "inventory/purchase/get-all-purchase-items",
          method: "GET",
          params: { ...params },
        };
      },
      providesTags: ["Purchase"],
    }),
    addNewPurchaseItem: builder.mutation({
      query: (body) => {
        return {
          url: "inventory/purchase/add-new-purchase-item",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Purchase"],
    }),

    deletePurchaseItem: builder.mutation({
      query: ({ purchaseItemId }) => {
        return {
          url: "inventory/purchase/delete/" + purchaseItemId,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Purchase"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllPurchaseItemsQuery,
  useAddNewPurchaseItemMutation,
  useDeletePurchaseItemMutation,
  //
  useGetAllPurchaseTypesQuery,
  useAddNewPurchaseTypeMutation,
} = purchaseApi;
