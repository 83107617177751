import { userApi } from "./userApi";

export const visitApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getVisitResult: builder.query({
      query: ({ visitId, params }) => {
        return {
          url: "patients/visits/result/" + visitId,
          method: "GET",
          params: { ...params },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetVisitResultQuery } = visitApi;
