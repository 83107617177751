import * as React from 'react';
import { useGetPatientsQuery, useSearchPatientsQuery } from '../store/rtk-query/patientApi';

import { HiOutlineChevronDown } from 'react-icons/hi';
import { Menu, Transition } from '@headlessui/react';

interface PatientSchema {
    id: number;
    firstName: string;
    lastName: string;
    otherNames: string;
    regNumber: string;
    age: string;
    sex: string;
    qrCodeUrl: string;
}

interface Props {
    onSelectPx: (patient: PatientSchema) => void;
    extraclass?: string
}

const PatientSearchBar = (props: Props) => {
    const [touched, setTouched] = React.useState(false)
    const [search, setSearch] = React.useState("")
    const [selectedPx, setSelectedPx] = React.useState<PatientSchema | undefined>()
    const [showDropdown, setShowDropdown] = React.useState<boolean>()

    const { data: patientsData, isLoading } = useSearchPatientsQuery({
        searchPhrase: search ? search : ''
    });

    React.useEffect(() => {
        if (!touched) return
        if (!showDropdown && patientsData?.data?.length > 0) setShowDropdown(true)
    }, [patientsData?.data])

    return (
        <div className={props?.extraclass + " flex bg-gray-50 border border-gray-300 items-center rounded-lg "}>

            <Menu
                as="div" className="relative inline-block text-left min-w-[80px] h-full" >
                <div >

                    <Menu.Button
                        className='flex gap-1 items-center '
                        onClick={() => { setShowDropdown(!showDropdown) }}
                        id="menu-button" aria-expanded="true" aria-haspopup="true">
                        <div className='pl-2 text-sm font-bold'> Patients</div>
                        <HiOutlineChevronDown
                            style={{

                            }}
                        />
                    </Menu.Button>

                </div>

                <Transition
                    as={React.Fragment}
                    show={showDropdown}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items static
                        className="absolute z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y-2"
                    >
                        {patientsData?.data?.map((patient: any) => {
                            return <Menu.Item
                                key={patient?.id}
                            // as={React.Fragment}
                            >
                                <div
                                    onClick={() => {
                                        setSelectedPx(patient)
                                        props.onSelectPx(patient)
                                        setShowDropdown(false)
                                    }}
                                    className='text-sm font-semibold h-[45px] flex flex-col justify-center p-2 cursor-pointer'>
                                    #{patient?.id} {patient?.firstName} {patient?.lastName}</div>
                            </Menu.Item>
                        })}

                    </Menu.Items>
                </Transition>
                {/* </Transition> */}
            </Menu>

            <div className="relative w-full ml-2" >
                <input
                    onChange={(e) => {
                        setSearch(e.target.value)
                        if (!touched) {
                            setTouched(true)
                        }
                    }}
                    onBlur={() => setTouched(true)}
                    type="search"
                    value={search}
                    id="search-dropdown"
                    className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-gray-50 border-l-2  focus:ring-blue-500 focus:border-blue-500
                "
                    placeholder="Search Patient" />
                <button type="submit" className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    <span className="sr-only">Search</span>
                </button>
            </div>
        </div>
    )
}

export default PatientSearchBar