import { userApi } from "./userApi";

export const labApis = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLabTests: builder.query({
      query: () => {
        return {
          url: "laboratory/get-all-laboratories",
          method: "GET",
        };
      },
    }),
    getAllLabCategories: builder.query({
      query: () => {
        return {
          url: "laboratory/get-laboratory-categories",
          method: "GET",
        };
      },
    }),
    // getTestsinLabCat: builder.query({
    //   query: ({ laboratoryCategory, x_pagination_take, x_pagination_skip }) => {
    //     return {
    //       url: "laboratory/get-laboratory",
    //       method: "GET",
    //       params: { laboratoryCategory },
    //       headers: { x_pagination_take, x_pagination_skip },
    //     };
    //   },
    //   providesTags: ["Tests"],
    // }),
    // searchTest: builder.query({
    //   query: ({ searchPhrase, x_pagination_take, x_pagination_skip }) => {
    //     return {
    //       url: "laboratory/search",
    //       method: "GET",
    //       params: { searchPhrase },
    //       headers: { x_pagination_take, x_pagination_skip },
    //     };
    //   },
    // }),
    createNewLabTest: builder.mutation({
      query: (body) => {
        return {
          url: "laboratory/create-new-test",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Tests"],
    }),
    // editTest: builder.mutation({
    //   query: ({ body, testId }) => {
    //     return {
    //       url: "laboratory/test/edit/" + testId,
    //       method: "PUT",
    //       body,
    //     };
    //   },
    //   invalidatesTags: ["Tests"],
    // }),
    // deleteTest: builder.mutation({
    //   query: ({ testId }) => {
    //     return {
    //       url: "laboratory/test/delete/" + testId,
    //       method: "DELETE",
    //     };
    //   },
    //   invalidatesTags: ["Tests"],
    // }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllLabTestsQuery,
  useGetAllLabCategoriesQuery,
  // useGetTestsinLabCatQuery,
  useCreateNewLabTestMutation,
  // useEditTestMutation,
  // useDeleteTestMutation,
} = labApis;
